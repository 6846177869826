import React, { useEffect, useState } from "react"
import Header from "../Header"
import useForm from "../../../utils/useForm"
import { getHomeFailure, getHomeRequest } from "../Home/redux/action"
import "./contact.scss"
import Foter from "../Foter"
import { connect } from "react-redux"
import { getFooterRequest } from "../Foter/redux/action"
import { Spinner } from "react-bootstrap"
const Contact = props => {
  const {
    getHomeRequest,
    getFooterRequest,
    mydataFooter,
    requesting,
    error,
    getHomeFailure
  } = props

  const stateSchema = {
    email: {
      value: "",
      error: ""
    },
    subject: {
      value: "",
      error: ""
    },
    message: {
      value: "",
      error: ""
    }
  }
  const validationStateSchema = {
    email: {
      required: true
    },
    subject: {
      required: true
    },
    message: {
      required: true
    }
  }

  const { state, handleOnChange, disable, setState } = useForm(
    stateSchema,
    validationStateSchema
  )

  const submit = async () => {
    const data = {
      email: state.email.value,
      subject: state.subject.value,
      message: state.message.value
    }

    await getHomeRequest(data)
  }
  useEffect(() => {
    getFooterRequest()
    getHomeFailure(false)
    setState(stateSchema)
  }, [])

  return (
    <>
      {/* <HomeLayout dashboardHeader={true}> */}
      <section style={{}}>
        <Header />

        <section class="banner-img-contact">
          <div
            class="bg-section-contact"
            // style="background-image: url({% static "images/site-engineer.png" %})"
          >
            <div class="contact-content-section">
              <h2
              
              // style={{color:}}
              class="heading-Contact2"
             
              >Contact US</h2>
              <div>
                <p class="main-para">
                  Vivamus porttitor fringilla mi, vitae pellentesque massa
                  iaculis nec. Sed
                </p>
                <p class="main-para m-0">
                  congue neque dolor, vitae tempus lectus hendrerit acen
                </p>
              </div>
            </div>
          </div>
        </section>
        <section class="form-section">
          <div class="container">
            <div class="row mt-4">
              <div class="col-md-6">
                <h3 class="heading-text">Contact Information:</h3>
                <p class="heading-from">
                  Address:{" "}
                  <span class="text-from">
                    {mydataFooter?.company?.address}
                  </span>
                </p>
                <p class="heading-from">
                  Phone number:
                  <span class="text-from">
                    {mydataFooter?.company?.phone_number}{" "}
                  </span>
                </p>
                <p class="heading-from">
                  Email address:{" "}
                  <span class="text-from">{mydataFooter?.company?.email} </span>
                </p>
              </div>
              <div class="col-md-6">
                {/* <form 
                // method="POST"
                > */}
               
                <div class="main-div-input">
                  <input
                    value={state.email.value}
                    type="text"
                    class="input"
                    name="email"
                    placeholder="Enter your email"
                    onChange={val => {
                      handleOnChange("email", val.target.value)
                      getHomeFailure(false)
                    }}
                  />

                  <input
                    value={state.subject.value}
                    placeholder="Enter subject"
                    name="subject"
                    type="text"
                    class="input"
                    onChange={val =>
                      handleOnChange("subject", val.target.value)
                    }
                  />

                  {/* {state.email.error && (
                      <label style={{ color: "red", display: "flex" }}>
                        {state.email.error}
                      </label>
                    )} */}
                </div>
                <div style={{ marginTop: 5,display:"flex",justifyContent:"space-between" }}>
                  <label className="errer-text">
                    {error?.email}
                  </label>
                  <label className="errer-text-message">
                    {error?.message}
                  </label>
                </div>
                <textarea
                  value={state.message.value}
                  name="message"
                  placeholder="Enter your message"
                  class="textarea"
                  onChange={val => handleOnChange("message", val.target.value)}
                ></textarea>
               <div style={{ marginTop: 5 }}>
                  <label className="errer-text">
                    {error?.subject}
                  </label>
                </div>
                <button onClick={() => submit()} class="btn2" type="submit">
                  {requesting ? (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  ) : (
                    "send"
                  )}
                </button>
                {/* </form> */}
              </div>
            </div>
          </div>
        </section>
      </section>
      <Foter />
     
    </>
    
  )
}
const mapStateToProps = state => ({
  homeData: state.homeData.homeData,
  requesting: state.homeData.requesting,
  error: state.homeData.error,

  mydataFooter: state.dataFooter.mydataFooter
})

const mapDispatchToProps = dispatch => ({
  getHomeRequest: data => dispatch(getHomeRequest(data)),
  getFooterRequest: data => dispatch(getFooterRequest(data)),
  getHomeFailure: data => dispatch(getHomeFailure(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(Contact)


