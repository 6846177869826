import React, { useEffect } from "react"
import HomeLayout from "../../../layout/HomeLayout"
import { history } from "../../../reduxStore/store"
import Button from "../../../components/Button"

import backIcon from "../../../assets/images/back-icon-circle.svg"
import uploadFile from "../../../assets/images/uploadfile.svg"
import { Col, Row } from "antd"
import "./style.scss"
import { connect } from "react-redux"
import {
  getAdminApprovedRequest,
  putAdminSoilRequest
} from "../SoilRequest/redux/action"
import { Spinner } from "react-bootstrap"
import Skeleton from "react-loading-skeleton"

const SoilRequestDetail = props => {
  const {
    AdminApprovedRequestdata,
    putAdminSoilRequest,
    loginInfo,
    requesting,
    getAdminApprovedRequest
  } = props

  let savedMaterialId = localStorage.getItem("materialId")

  const handleAproved = e => {
    history.push({
      pathname: `soil-request-confirm`,
      id: savedMaterialId
    })
  }
  useEffect(() => {
    getAdminApprovedRequest(`${savedMaterialId}/`)
  }, [])

  const handleRegect = e => {
    history.push("soil-request-reject")
  }
  const isLastItem = (currIndex, list) =>
    !(currIndex === list?.length - 1) && <>,</>

  return (
    <>
      <HomeLayout dashboardHeader={true}>
        {requesting ? (
          <div className="soil-request-detail-section">
            <div className="body-detail bodyScroll">
              <div className="contractor-company">
                <Skeleton width="40%" />
                <div className="company-info" style={{ gap: "0px" }}>
                  <Skeleton width="50%" />
                  <div className="contact">
                    <div className="address mt-3">
                      <Skeleton width="70%" />
                      <Skeleton width="50%" />
                      <Skeleton width="70%" />
                      <Skeleton width="50%" />
                    </div>
                  </div>
                  <div className="material-info mt-3">
                    <Skeleton width="70%" />
                    <Skeleton width="50%" />
                    <Skeleton width="70%" />
                  </div>
                  {/* <div className="material-analytical-data">
                    <Skeleton width="90%" />
               
                  </div> */}
                  <div className="material-analytical-data mt-3">
                    <div className="data-title">
                      <Skeleton width="100%" />
                    </div>
                  </div>
                </div>
                <div className="notes-wrapper">
                  <h3 className="notes-heading">
                    <Skeleton width="50%" />
                  </h3>
                  <p className="notes-body">
                    <Skeleton width="30%" />
                  </p>
                </div>
              </div>
              {/* <div className="button-wrapper"> */}
              <div className="mt-5 mb-5">
                <Skeleton width="50%" />
              </div>
              {/* </div>
               */}
            </div>
          </div>
        ) : (
          <section className="soil-request-detail-section bodyScroll" style={{top:0}}>
            <div className="heading-detail">
              <div className="back" onClick={() => history.goBack()}>
                <img src={backIcon} alt="" />
              </div>
              <div className="contractor-name">
                <h1>Request Details</h1>
              </div>
            </div>
            <section className="content-section adminContractorDetail-section1" style={{padding:0,marginTop:15,}}>
              <Row className="contentStyle">

                <span className="border-bottom w-100 d-md-flex">
                  <Col md={12} sm={24} className=' pb-4'>
                    <h1 className="heading-name">
                     {AdminApprovedRequestdata?.aes_po_no || ""}
                    </h1>
                  </Col>
                  <Col md={12} sm={24} className=' pb-4'>
                    <div className="button-wrapper">
                      {AdminApprovedRequestdata?.status == "pending" || AdminApprovedRequestdata?.status == 'open' ? (
                        <>
                          <Button
                            title="Approve"
                            className="aes-btn orange-btn"
                            onClick={() => {
                              handleAproved()
                            }}
                          />
                          <Button
                            title="Decline"
                            className="aes-btn danger-transparent-btn"
                            onClick={() => handleRegect()}
                          />
                        </>
                      ) : (
                        <h3 style={{ textTransform: "capitalize",fontSize:'1.35rem' }}>
                          Status: {AdminApprovedRequestdata?.status || ""}
                        </h3>
                      )}
                    </div>
                  </Col>
                </span>
                <Col sm={12} xs={24} className="border-bottom py-2">
                  <h2>
                  Developer Name:{" "}
                    <span>
                    {AdminApprovedRequestdata?.company_name || ""}
                    </span>
                  </h2>
                </Col>
                <Col sm={12} xs={24} className="border-bottom py-2">
                  <h2>
                  Project Address:{" "}
                    <span>{AdminApprovedRequestdata.company_address}</span>
                  </h2>
                </Col>
                <Col sm={12} xs={24} className="border-bottom py-2">
                  <h2>
                  Project Name:{" "}
                    <span>{AdminApprovedRequestdata.project_name}</span>
                  </h2>
                </Col>
                <Col sm={12} xs={24} className="border-bottom py-2">
                  <h2>
                  Project Start Date:{" "}
                    <span>{AdminApprovedRequestdata.start_date}</span>
                  </h2>
                </Col>
                <Col sm={12} xs={24} className="border-bottom py-2">
                  <h2>
                  Email:{" "}
                    <span>
                      {AdminApprovedRequestdata?.user?.email}
                    </span>
                  </h2>
                </Col>
                <Col sm={12} xs={24} className="border-bottom py-2">
                  <h2>
                  Phone:{" "}
                    <span>{AdminApprovedRequestdata.user?.company
                        ?.position_in_company}</span>
                  </h2>
                </Col>
                
                
                <Col sm={12} xs={24} className="border-bottom py-2">
                  
                    
                    {AdminApprovedRequestdata?.deposit_request?.slice(0, 4).map(
                      (material, index) => (
                        <>
                          <div className="d-flex" style={{ gap: 10 }}>
                          <h2>
                          Soil Type:{" "}
                             <span>
                            
                              {material?.material_name?.name}&nbsp;|&nbsp;
                              {material.material_type == 1
                                ? "Structural"
                                : "Non-Structural"}{" "}
                              &nbsp;|&nbsp;{material?.material_amount}{" "}
                              {material?.material_mass_type === "1" ? (
                                <>mt </>
                              ) : (
                                <span>
                                  m<sup>3</sup>
                                </span>
                              )}
                              
                              
                            </span>
                            </h2>
                          </div>
                        </>
                      )
                    )}
                    
                  
                </Col>
                <Col sm={12} xs={24} className="border-bottom py-2">
                  
                    
                    {AdminApprovedRequestdata?.deposit_request?.slice(0, 4).map(
                      (material, index) => (
                        <>
                          <div className="d-flex" style={{ gap: 10 }}>
                          <h2>
                             Environmental/Geotechnical Analytical :{" "}
                             <span>
                            
                             { ( AdminApprovedRequestdata?.environmental &&
                        AdminApprovedRequestdata?.environmental.length > 0 ) &&
                        <div className="img-bg"> {
                          AdminApprovedRequestdata.environmental.map(
                            (file, index) => (
                              <a key={index} href={file.environmental} download target="blank">
                                <span  >
                                  <img src={uploadFile} alt="Upload File" />
                                </span>
                              </a>
                            )
                          ) 
                        }
                     
                        </div>
                        }
                              
                              
                            </span>
                            </h2>
                          </div>
                        </>
                      )
                    )}
                    
                  
                </Col>
                <Col sm={12} xs={24} className="border-bottom py-2">
                  <h2>
                  Laboratory File #:{" "}
                    <span>{AdminApprovedRequestdata.laboratory_file}</span>
                  </h2>
                </Col>
                <Col sm={12} xs={24} className="border-bottom py-2">
                  <h2>
                  Contractor Name:{" "}
                    <span>{AdminApprovedRequestdata.contractor_name}</span>
                  </h2>
                </Col>
              </Row>
            </section>
            <div className="body-detail" style={{paddingBottom:20}}>
              <div className="notes-wrapper">
                <h3 className="notes-heading">Notes:</h3>
                <p className="notes-body">
                  {/* {AdminApprovedRequestdata?.note} */}
                  <div
                    style={{ height: 70, overflow: "auto" }}
                    dangerouslySetInnerHTML={{
                      __html:
                        AdminApprovedRequestdata &&
                        AdminApprovedRequestdata?.note?.replace(/\r\n/g, "<br>")
                    }}
                  ></div>
                </p>
              </div>
              
            </div>
          </section>
        )}
      </HomeLayout>
    </>
  )
}
const mapStateToProps = state => {
  // debugger

  return {
    loginInfo: state.login.loginInfo,

    AdminApprovedRequestdata: state.AdminSoilRequest.AdminApprovedRequestdata,

    requesting: state.AdminSoilRequest.requesting
  }
}

const mapDispatchToProps = dispatch => ({
  putAdminSoilRequest: (data, id) => dispatch(putAdminSoilRequest(data, id)),
  getAdminApprovedRequest: (data, id) =>
    dispatch(getAdminApprovedRequest(data, id))
})

export default connect(mapStateToProps, mapDispatchToProps)(SoilRequestDetail)
