import React, { useState, useEffect } from "react"
import Button from "../../components/Button"
import Modal from "react-bootstrap/Modal"
import Input from "../../components/Input"
import { useHistory } from "react-router-dom"
import closeIcon from "../../assets/images/closeIcon.svg"
import truck from "../../assets/images/truck.png"

import "./style.scss"
import { connect } from "react-redux"
import moment from "moment"
import { history } from "../../reduxStore/store"

const RequestSoilModal = props => {
  const {
    forgotRequest,
    forgotInfo,
    requesting,
    error,
    modalShow,
    setModalShow,
    body,
    componetFormRef,
    itemId,
    getNotfiactionModalData,
    NotfiactionModalData,
    Clear
  } = props

  return (
    <>
      <Modal
        // {...props}
        show={modalShow}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered={true}
        className="warning-modal RequestSoilModal-modal"
      >
        <Modal.Body className="modal-body">
          <div
            className="close-icon"
            onClick={() => {
              setModalShow(false)
              history.push("my-requests")
              componetFormRef.current.reset()
            }}
          >
            <img src={closeIcon} alt="" />
          </div>
          <div className="main-div">
            <div className="heading-Congrats">
              <h3>
              Thank you, your request has been <br /> successfully sent
                {/* Thank You, your request is <br /> successfully sent. */}
              </h3>
            </div>
            <div className="button-wrapper">
              <Button
                title="OK"
                className="aes-btn"
                onClick={() => {
                  history.push("my-requests")
                  setModalShow(false)
                  componetFormRef.current.reset()
                }}
              />
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default RequestSoilModal
