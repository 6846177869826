import React, { useEffect, useState } from "react"
import HomeLayout from "../../../../layout/HomeLayout"
import Search from "../../../../components/Search"
import Button from "../../../../components/Button"
import report from "../../../../assets/images/report-img.png"
import truck from "../../../../assets/images/truck.png"
import "react-loading-skeleton/dist/skeleton.css"
import { toast, Toaster } from "react-hot-toast"
import useForm from "../../../../utils/useForm"
import { history } from "../../../../reduxStore/store"
import backIcon from "../../../../assets/images/back-icon-circle.svg"
import { useDispatch, useSelector } from "react-redux"
import { getReportRequest, getReportSucess } from "../redux/action"
import moment from "moment"
import { Modal } from 'react-bootstrap';

const ReportDetails = (props) => {
    const [showModal, setShowModal] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);

    const handleImageClick = (image) => {
        setSelectedImage(image);
        setShowModal(true);
    };

    // Function to close the modal
    const CloseImageModal = () => {
        setShowModal(false);
        setSelectedImage(null);
    };

    const dispatch = useDispatch()

    const reportId = localStorage.getItem("reportId")
    useEffect(() => {
        if (reportId) {
            dispatch(getReportRequest(`${reportId}/`))
        }
    }, [reportId])
    const { reportData, requesting } = useSelector(
        state => state.Report
    )
    return (
        <>
            <Modal className="image-popup-modal" show={showModal} onHide={CloseImageModal} aria-labelledby="contained-modal-title-vcenter"
                centered width={800} >
                <Modal.Header closeButton>

                </Modal.Header>
                <Modal.Body>
                    {selectedImage && (
                        <img src={selectedImage} alt="Selected" className="img-fluid" />
                    )}
                </Modal.Body>
            </Modal>
            <HomeLayout dashboardHeader={true}>
                <Toaster position="top-center" />
                <section className="content-section px-5">
                    <div className="heading-confirm">
                        <div className="back" onClick={() => { history.goBack(); dispatch(getReportSucess(false)) }}>
                            <img src={backIcon} alt="" />
                        </div>
                        <div className="contractor-name">
                            <h1>Report Details</h1>
                        </div>
                    </div>
                    <section className="background">
                        <div className="bottomline">
                            <div className="right-side noline">

                                <div className="date">
                                    <span>{moment(reportData.created_at).format("DD MMM")}</span>
                                </div>
                            </div>
                            <div className="d-flex align-items-center justify-content-between w-100 px-4">
                                <div className="d-flex align-items-center">

                                    <img
                                        className=" mr-2"
                                        src={report}
                                        alt="report"
                                        width={30}
                                        height={30}
                                    />
                                    <p className="text">
                                        {reportData.report_category?.name ? reportData.report_category?.name : "---"
                                        }
                                    </p>
                                </div>
                                {/* <div>
                                <Button
                                    title="Download"
                                    className="aes-btn green-btn"
                                />
                            </div> */}

                            </div>
                        </div>

                        <div className="right-text">
                            <p>
                                Report ID:
                                <span>{reportData.report_id
                                }</span>
                            </p>
                        </div>
                        <div className="right-text">
                            <p>
                                Reported on:
                                <span>  {moment(reportData.updated_at).format("dddd, MMM DD, YYYY")}</span>
                            </p>
                        </div>
                        <div className="right-text">
                            <p>
                                Report category:
                                <span>
                                    {reportData.report_category?.name ? reportData.report_category?.name : "---"}
                                </span>
                            </p>
                        </div>
                        <div className="right-text">
                            <p>
                                Notes:
                                <div>
                                    <span>{reportData.note}</span>
                                </div>

                            </p>
                        </div>
                        <div className="right-text">
                            <p>
                                {moment(reportData.updated_at).format(" dddd,  MMM  DD, YYYY")}
                                {/* Wed, Nov 17, 2022: */}
                                <div className="truck-div row mt-3">
                                    {reportData.image?.length ? reportData.image?.map((item, index) =>

                                        <>
                                            <div className="col-12 col-lg-3 col-md-4 mb-3">
                                                <div className="img-div ">
                                                    <img
                                                        key={index}
                                                        className="img-fluid h-100"
                                                        src={item.image}
                                                        alt="upload"
                                                        width={400}
                                                        height={400}
                                                        onClick={() => handleImageClick(item.image)}
                                                    />
                                                </div>
                                            </div>
                                        </>

                                    )
                                        : <>
                                            <span className="mx-auto my-5">no image found </span>
                                        </>
                                    }
                                </div>
                            </p>

                        </div>
                    </section>
                </section>
            </HomeLayout >
        </>
    )
}


export default ReportDetails

