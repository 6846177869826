import React, { useState, useEffect } from "react"
import Button from "../../../components/Button"
import Input from "../../../components/Input"
import HomeLayout from "../../../layout/HomeLayout"
import DetailSidebar from "./components/DetailSidebar"
import { DatePicker, Slider } from "antd"
import "./style.scss"
import { history } from "../../../reduxStore/store"
import Filter from "../../../components/Filter"
import {
  getFillsiteRequest,
  postBookFailure,
  postBookSucess,
  postBookRequest
} from "./redux/action"
import { setBookID } from "../../AuthScreens/LoginScreen/redux/action"

import { connect } from "react-redux"
import moment from "moment"
import dayjs from "dayjs"
import { Toaster, toast } from "react-hot-toast"
import filter from "../../../assets/images/filter.svg"
const DepositSite = props => {
  const {
    FillsiteData,
    getFillsiteRequest,
    postBookRequest,
    bookData,
    bookDataRequesting,
    error,
    postBookFailure,
    depositeSiteID,
    postBookSucess,
    setBookID
  } = props

  const [filterState, setfilterState] = useState(false)
  const [checkStatus, setCheckStatus] = useState(false)
  const [loadingdate, setLoadingdate] = useState(null)
  const [newDate, setNewDate] = useState(new Date())
  const [truckVal, setTruckLeftVal] = useState(null)
  const [date, setDate] = useState(new Date())

  useEffect(() => {
    const nowDate = moment(date).format("DD/MM/YY")
    if (depositeSiteID.id && depositeSiteID.depReq && nowDate) {
      getFillsiteRequest(depositeSiteID.id, depositeSiteID.depReq, nowDate)
    }
  }, [depositeSiteID, date])

  useEffect(() => {
    if (bookData?.id) {
      setBookID(bookData?.id)
      history.push("soil-form")
      postBookSucess(false)
    }
  }, [bookData?.id])

  useEffect(() => {
    postBookFailure(false)
    setTruckLeftVal("")

    // setState(stateSchema)
  }, [])

  useEffect(() => {
    if (FillsiteData?.request_availability?.length > 0) {
      setTruckLeftVal(FillsiteData?.request_availability[0]?.booked_value)
      setCheckStatus(FillsiteData?.request_availability[0]?.status)
    } else {
      setCheckStatus(false)
      setTruckLeftVal("")
    }
  }, [FillsiteData])

  const loadData = [
    {
      id: 1,
      itemText: "Number of trucks scheduled total: ",
      itemDetails: `${FillsiteData?.site_owner?.max_allowed_truck_per_day || "--"
        }`
    },
    {
      id: 2,
      itemText: "Number of rounds the trucks are expected to do total: ",
      itemDetails: `${FillsiteData?.site_owner?.max_allowed_load_per_day || "--"
        }`
    },
    {
      id: 3,
      itemText: "Approximate time that they will enter site: ",
      itemDetails: `${FillsiteData?.site_owner?.approximate_time || "--"}`
    },
    {
      id: 4,
      itemText: "Configuration of trucks we can accept: ",
      itemDetails: `${"--"}`
    }
  ]

  const dateChange = (date, dateString, type) => {
    let filterModel = {
      fromDate: "",
      toDate: ""
    }

    setfilterState(false)
    switch (type) {
      case "date":
        filterModel.fromDate = dateString
        setNewDate(dateString)
        const nowDate = moment(dateString).format("YYYY-MM-DD")
        getFillsiteRequest(depositeSiteID.id, depositeSiteID.depReq, nowDate)
        setLoadingdate(filterModel)
        break
      // case "week":
      //   filterModel.fromDate = dateString

      //   filterModel.toDate = dayjs(dateString)
      //     .add(7, "day")
      //     .format("YYYY-MM-DD")
      //   setLoadingdate(filterModel)
      //   break
      // case "month":
      //   const arrDate = dateString.split("-")
      //   filterModel.fromDate = date.year() + "-" + arrDate[1] + "-01"
      //   filterModel.toDate =
      //     date.year() + "-" + arrDate[1] + "-" + date.daysInMonth()
      //   setLoadingdate(filterModel)
      //   break
      default:
        break
    }
  }
  const handleOnChangeBook = value => {
    // const newValue = parseInt(value)
    if (value > FillsiteData?.remaining_loads) {
      toast.error(`Exceeded number of trucks left`)
    }
  }
  // useEffect(()=>{
  //   handleOnChangeBook()
  // },[truckVal])

  const submit = async () => {
    // Check if truckVal exceeds the remaining loads available
    if (truckVal > FillsiteData?.remaining_loads) {
      // Display an error message if the condition is true

      toast.error(`Exceeded number of trucks left`, {
        id: "card fields"
      })
    }
    if (truckVal === "0") {
      toast.error(`Please add truck booking value`, {
        id: "card fields"
      })
    }

    // More complex condition
    if (
      depositeSiteID.depReq && // Check if depReq is truthy
      FillsiteData?.site_owner?.id && // Check if site_owner.id exists
      FillsiteData?.site_owner?.max_allowed_truck_per_day >= +truckVal && // Check max_allowed_truck_per_day
      truckVal <= FillsiteData?.remaining_loads // Check if truckVal is less than or equal to remaining_loads
    ) {
      // If all conditions are met, create a Data object
      const Data = {
        request_id: depositeSiteID.depReq,
        fill_site: FillsiteData?.site_owner?.id,
        booked_value: truckVal,
        date: moment(date).format("YYYY-MM-DD"),
      };

      // Call the postBookRequest function with the Data object
      await postBookRequest(Data);
    }
  }

  // Additional error checks outside the submit function


  const [calenderState, setCalenderState] = useState(false)

  const handleDatePicker = (dates, dateStrings) => {
    setDate(dateStrings)
  }
  const disabledDate = current => {
    return current && current < moment().startOf("day")
  }
  return (
    <>
      <HomeLayout dashboardHeader={true}>
        <section className="content-section deposit-section">
          <Toaster position="top-center" />
          {/* {isContentToggled && ( */}
          <DetailSidebar
            SetApiData={FillsiteData}
            fill_site_info={
              FillsiteData?.fill_site_info && FillsiteData?.fill_site_info[0]
            }
          />
          <section className="availability-section">
            <div className="heading-Availability">
              <h1>Availability</h1>
              {/* <Filter
                onDateChange={dateChange}
                calenderState={filterState}
                onClick={() => setfilterState(!filterState)}
                dataData={loadingdate}
              /> */}
              <div className="filter-wrapper">
                <div
                  className="filter"
                  onClick={() => setCalenderState(!calenderState)}
                >
                  <div className="button-filter">
                    <img src={filter} />
                    <p>
                      Filter by:
                      <span>{"Day"}</span>{" "}
                    </p>
                  </div>
                  <div className="date">
                    {
                      <p>
                        <span className="">{moment(date).format("ddd D")}</span>
                      </p>
                    }
                  </div>
                </div>
                {/* <div style={{ position: "absolute" }}> */}
                <DatePicker
                  className="datePicker"
                  disabledDate={disabledDate}
                  style={{ height: "unset" }}
                  onChange={handleDatePicker}
                  open={calenderState}
                  onOpenChange={open => {
                    if (!open) {
                      setCalenderState(false)
                    }
                  }}
                />
              </div>
            </div>
            {/* </div> */}
            <div className="load-details-wrapper">
              <div className="load-details-header">
                <div className="load">
                  {FillsiteData?.site_owner?.max_allowed_truck_per_day > 0 ? (
                    <p className="number">{FillsiteData.remaining_loads}</p>
                  ) : (
                    <p>No availability found on selected date.</p>
                  )}
                  {FillsiteData?.site_owner?.max_allowed_truck_per_day > 0 && (
                    <p className="text">Truck Left</p>
                  )}
                </div>
              </div>
              {FillsiteData?.site_owner?.max_allowed_truck_per_day > 0 && (
                <div className="load-details-body">
                  <div className="load-range-slider">
                    <div className="indications">
                      <div className="indication-item empty-item">Empty</div>
                      <div className="indication-item">Half booked</div>
                      <div className="indication-item">Fully booked</div>
                    </div>
                    <Slider
                      max={FillsiteData?.site_owner?.max_allowed_truck_per_day}
                      min={0}
                      value={
                        FillsiteData?.site_owner?.max_allowed_truck_per_day -
                        FillsiteData?.remaining_loads
                      }
                    />
                  </div>
                  <div className="load-body-wrapper">
                    {loadData.map(item => (
                      <div className="item" key={item.id}>
                        {item.itemText}
                        <span>{item.itemDetails}</span>
                      </div>
                    ))}
                    <div className="custom-item">
                      <p>Enter the number of loads you would like to book:</p>
                      <div className="custom-field">
                        <Input
                          placeholder="Book Trucks"
                          type="number"
                          value={truckVal}
                          showError={true}
                          disabled={!!checkStatus}
                          errorMessage={error?.booked_value}
                          onChange={val => setTruckLeftVal(val.target.value)}
                        />

                        <Button
                          title={checkStatus ? checkStatus : "Book"}
                          showSpinner={bookDataRequesting}
                          onClick={submit}
                          disabled={!!checkStatus}
                          className={
                            checkStatus === "pending"
                              ? "aes-btn inactive-btn"
                              : "aes-btn"
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </section>
        </section>
      </HomeLayout>
    </>
  )
}

const mapStateToProps = state => ({
  FillsiteData: state.FillsiteData.Data,
  requesting: state.FillsiteData.requesting,
  bookDataRequesting: state.FillsiteData.bookDataRequesting,
  error: state.FillsiteData.error,
  bookData: state.FillsiteData.bookData,
  depositeSiteID: state.login.depositeSiteID
})

const mapDispatchToProps = dispatch => ({
  getFillsiteRequest: (id, reqID, date) =>
    dispatch(getFillsiteRequest(id, reqID, date)),
  postBookRequest: data => dispatch(postBookRequest(data)),
  postBookFailure: data => dispatch(postBookFailure(data)),
  postBookSucess: data => dispatch(postBookSucess(data)),
  setBookID: data => dispatch(setBookID(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(DepositSite)
