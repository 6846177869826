import { all, call, put, takeLatest } from "redux-saga/effects"
import { BASE_URL } from "../../../../config/app"
import XHR from "../../../../utils/XHR"
// types
import { POST_OWNER_TEAM_MEMBER } from "./type"
// actions
import { addOwnerTeamMemberSuccess, addOwnerTeamMemberFailure } from "./action"

async function addOwnerTeamMemberAPI(data) {
  const URL = `${BASE_URL}fillsite/team_member/`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "POST",
    data
  }
  return XHR(URL, options)
}

function* addOwnerTeamMember({ data }) {
  try {
    const response = yield call(addOwnerTeamMemberAPI, data)
    yield put(addOwnerTeamMemberSuccess(response.data))
  } catch (e) {
    const { response } = e
    yield put(addOwnerTeamMemberFailure(response?.data))
  }
}

export default all([takeLatest(POST_OWNER_TEAM_MEMBER, addOwnerTeamMember)])
