import React, { useEffect, useState, useRef } from "react"
import backIcon from "../../../assets/images/back-icon-circle.svg"
import BoardSelect from "../../../components/BoardSelect"
import Button from "../../../components/Button"
import Input from "../../../components/Input"
import HomeLayout from "../../../layout/HomeLayout"
import { history } from "../../../reduxStore/store"
import useForm from "../../../utils/useForm"
import "./style.scss"
import { getProfileDataRequest, putProfileDataRequest, putProfileDataSuccess } from "./redux/action"
import { connect } from "react-redux"
import validator from "../../../utils/validation"
import { CountryName } from "../../../components/CountryName"
import { Toaster } from "react-hot-toast"
import { Select } from "antd"
import { getCountryRequest } from "../../AuthScreens/SignUp/redux/action"
import AesPhoneInput from "../../../components/PhoneInput"
import { loginRequestSuccess } from "../../AuthScreens/LoginScreen/redux/action"

const AccountInformation = props => {
  const {
    getProfileDataRequest,
    putProfileDataRequest,
    loginRequestSuccess,
    putProfileDataSuccess,
    ProfileData,
    putRequesting,
    error,
    CountryData,
    getCountryRequest,
    loginInfo
  } = props
  useEffect(() => {
    getCountryRequest()
  }, [])

  useEffect(() => {
    if (ProfileData.profile_picture) {
      loginRequestSuccess({
        user: {
          ...loginInfo.user,
          profile_picture: ProfileData.profile_picture
        }
      })
    }
    putProfileDataSuccess(false)
  }, [ProfileData.profile_picture])

  // const [country, setCountry] = useState("")
  const stateSchema = {
    companyName: {
      value: "",
      error: ""
    },
    email: {
      value: "",
      error: ""
    },
    street: {
      value: "",
      error: ""
    },
    city: {
      value: "",
      error: ""
    },
    postalCode: {
      value: "",
      error: ""
    },
    province: {
      value: "",
      error: ""
    },
    country: {
      value: "",
      error: ""
    },
    phone: {
      value: "",
      error: ""
    }
  }

  const validationStateSchema = {
    companyName: {
      required: true
    },
    email: {
      required: true,
      validator: validator.email
    },
    phone: {
      required: true
      // validator: validator.phone
    },
    street: {
      required: true
    },
    city: {
      required: true
    },
    province: {
      required: true
    },
    country: {
      required: true
    },
    postalCode: {
      required: true
    }
  }

  const { state, handleOnChange, disable, setState } = useForm(
    stateSchema,
    validationStateSchema
  )
  useEffect(() => {
    getProfileDataRequest()
  }, [])

  useEffect(() => {
    ProfileData &&
      ProfileData?.billing?.country_name &&
      ProfileData &&
      handleOnChange("country", ProfileData?.billing?.country_name)
    ProfileData &&
      ProfileData?.company?.company_name &&
      handleOnChange("companyName", ProfileData?.company?.company_name)
    ProfileData &&
      ProfileData?.user?.email &&
      handleOnChange("email", ProfileData?.user?.email)
    ProfileData &&
      ProfileData?.billing?.street &&
      handleOnChange("street", ProfileData?.billing?.street)
    ProfileData &&
      ProfileData?.billing?.city_postal_code &&
      handleOnChange("postalCode", ProfileData?.billing?.city_postal_code)
    ProfileData &&
      ProfileData?.billing?.province &&
      handleOnChange("province", ProfileData?.billing?.province)
    ProfileData &&
      ProfileData?.billing?.billing_phone_number &&
      handleOnChange("phone", ProfileData?.billing?.billing_phone_number)
    ProfileData &&
      ProfileData?.billing?.city &&
      handleOnChange("city", ProfileData?.billing?.city)
  }, [ProfileData])

  const Option = Select
  const options = CountryData?.map((items, index) => {
    return (
      <Option key={index} value={items.name}>
        {items.name}
      </Option>
    )
  })
  const submit = async () => {
    const formData = new FormData();
    formData.append('email', state.email.value);
    formData.append('billing[street]', state.street.value);
    formData.append('billing[city]', state.city.value);
    formData.append('billing[city_postal_code]', state.postalCode.value);
    formData.append('billing[province]', state.province.value);
    formData.append('billing[country_name]', state.country.value);
    formData.append('billing[billing_phone_number]', state.phone.value);
    formData.append('company[company_name]', state.companyName.value);
    formData.append('profile_picture', selectedImage);

    await putProfileDataRequest(formData);
  }
  const handleFormatChanger = value => {
    if (value.length > 0) {
      handleOnChange("phone", ` +${value}`)
    } else handleOnChange("phone", value)
  }
  const [selectedImage, setSelectedImage] = useState("")
  const fileInputRef = useRef(null)

  const handleImageUpload = () => {
    fileInputRef.current.click()
  }

  const handleFileChange = event => {
    const file = event.target.files[0]
    setSelectedImage(file)
  }

  return (
    <>
      <HomeLayout dashboardHeader={true}>
        <Toaster position="top-center" />

        <section className="content-section account-info-section">
          <div className="account-info-content-section">
            <div className="heading-account-info">
              <h3>
                {ProfileData?.company?.company_name
                  ? ProfileData?.company?.company_name
                    .split(" ")[0]
                    .charAt(0)
                    .toUpperCase() +
                  ProfileData?.company?.company_name.split(" ")[0].slice(1)
                  : ""}{" "}
                Account Information
              </h3>
            </div>
            <div className="top-form-section">
              <div className="row top-form-row">
                <div className="col-md-4 field">
                  <Input
                    placeholder="Enter full name"
                    showLabel={true}
                    label="Company Name"
                    value={state.companyName.value}
                    showError={true}
                    errorMessage={
                      state.companyName.error || error?.billing?.company_name
                    }
                    onChange={val =>
                      handleOnChange("companyName", val.target.value)
                    }
                  />
                </div>
                <div className="col-md-4 field">
                  <Input
                    placeholder="Enter email"
                    showLabel={true}
                    disabled
                    label="Email"
                    value={state?.email ? state.email.value : ""}
                    showError={true}
                    errorMessage={state.email.error}
                    onChange={val => handleOnChange("email", val.target.value)}
                  />
                </div>
                <div
                  className="col-md-4 field"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                  }}
                >
                  <div>
                    <input
                      type="file"
                      onChange={handleFileChange}
                      hidden
                      ref={fileInputRef}
                    />
                    <Button
                      onClick={handleImageUpload}
                      title="Upload company logo"
                    />
                    {/* {selectedImage && (
        <div>
          <h4>Selected Image:</h4>
          <img src={selectedImage} alt="Selected" />
        </div>
      )} */}
                  </div>
                </div>
              </div>
            </div>
            <div className="bottom-form-section">
              <div className="row bottom-form-row ">
                <div className="col-md-12 field-heading mt-4 mb-2">
                  Billing Information
                </div>
                <div className="col-md-4 field">
                  <Input
                    placeholder="New Halls Ferry Rd; Florissant, Mississ..."
                    showLabel={true}
                    label="Street"
                    value={state.street.value}
                    showError={true}
                    errorMessage={state.street.error}
                    onChange={val => handleOnChange("street", val.target.value)}
                  />
                </div>
                <div className="col-md-4 field">
                  <Input
                    placeholder="Los Angeles, CA"
                    showLabel={true}
                    label="City"
                    value={state.city.value}
                    showError={true}
                    errorMessage={state.city.error}
                    onChange={val => handleOnChange("city", val.target.value)}
                  />
                </div>
                <div className="col-md-4 field">
                  <Input
                    placeholder="12771"
                    showLabel={true}
                    label="ZIP/Postal Code"
                    value={state.postalCode.value}
                    showError={true}
                    errorMessage={state.postalCode.error}
                    onChange={val =>
                      handleOnChange("postalCode", val.target.value)
                    }
                  />
                </div>
                <div className="col-md-4 field">
                  <Input
                    placeholder="State/Province"
                    showLabel={true}
                    label="State/Province"
                    value={state.province.value}
                    showError={true}
                    errorMessage={state.province.error}
                    onChange={val =>
                      handleOnChange("province", val.target.value)
                    }
                  />
                </div>
                <div className="col-md-4 field  ">
                  <label className="label">Country</label>
                  <BoardSelect
                    options={options}
                    placeholder={ProfileData?.billing?.country_name}
                    onChange={e => {
                      handleOnChange("country", e)
                    }}
                    showSearch={true}
                    // filterOption={(input, option) =>
                    //   (option?.label ?? "").includes(input)
                    // }
                    optionFilterProp="children"
                    value={state.country.value}
                  />
                </div>
                <div className="col-md-4 fieldl ">
                  <label className="label">Phone number</label>
                  <AesPhoneInput
                    placeholder="Enter phone"
                    type="number"
                    value={state.phone.value}
                    // onChange={val => handleOnChange("phone", val.target.value)}
                    onChange={handleFormatChanger}
                    showError={true}
                  // errorMessage={PostError?.phone_number && PostError?.phone_number}
                  />
                  {/* <Input
                    placeholder="202-555-0151"
                    showLabel={true}
                    label="Phone number"
                    value={state.phone.value}
                    showError={true}
                    // errorMessage={state.phone.error}
                    onChange={val => handleOnChange("phone", val.target.value)}
                  /> */}
                </div>
              </div>
              <div className="row ">
                <div className="button-wrapper col-lg-5 ">
                  <Button
                    title="Save Changes"
                    className="aes-btn green-btn"
                    showSpinner={putRequesting}
                    onClick={() => {
                      submit()
                    }}
                  />
                  <div
                    onClick={() => history.push("changePasword")}
                    className="change col-lg-4 "
                    style={{ whiteSpace: "nowrap" }}
                  >
                    Change Password
                  </div>
                  <hr className="m-0" />
                </div>
              </div>
            </div>
          </div>
        </section>
      </HomeLayout>
    </>
  )
}
const mapStateToProps = state => ({
  loginInfo: state.login?.loginInfo,
  ProfileData: state.ProfileData.profileData,
  putRequesting: state.ProfileData.putRequesting,
  error: state.ProfileData.error,
  CountryData: state.signUp.CountryData,


  // ProfileData: state.ProfileData.profileData
})

const mapDispatchToProps = dispatch => ({
  getProfileDataRequest: () => dispatch(getProfileDataRequest()),
  putProfileDataRequest: data => dispatch(putProfileDataRequest(data)),
  getCountryRequest: data => dispatch(getCountryRequest(data)),
  loginRequestSuccess: data => dispatch(loginRequestSuccess(data)),
  putProfileDataSuccess: data => dispatch(putProfileDataSuccess(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(AccountInformation)
