export const GET_ADMINCONTRACTORS_REQUEST = "GET_ADMINCONTRACTORS_REQUEST"
export const GET_ADMINCONTRACTORS_SUCCESS =
  "GET_ADMINCONTRACTORS_SUCCESS"


// export const ADMINCONTRACTORS_UPDATE_USER = "ADMINCONTRACTORS_UPDATE_USER"
// export const ADMINCONTRACTORS_UPDATE_USER_SUCCESS =
//   "ADMINCONTRACTORS_UPDATE_USER_SUCCESS"
// export const ADMINCONTRACTORS_UPDATE_USER_FAILURE =
//   "ADMINCONTRACTORS_UPDATE_USER_FAILURE"
// export const GET_MATERIAL_REQUEST = "GET_MATERIAL_REQUEST"
// export const GET_MATERIAL_SUCCESS = "GET_MATERIAL_SUCCESS"
// export const GET_MATERIAL_FAILURE = "GET_MATERIAL_FAILURE"

export const USER_ACTIVECONTRACTOR_REQUEST = "USER_ACTIVECONTRACTOR_REQUEST"
export const USER_ACTIVECONTRACTOR_SUCCESS = "USER_ACTIVECONTRACTOR_SUCCESS"


// export const DELETE_FIILL_REQUEST = "DELETE_FIILL_REQUEST"
// export const DELETE_FIILL_SUCCESS = "DELETE_FIILL_SUCCESS"

export const CONTRACTOR_DETAIL_REQUEST = "CONTRACTOR_DETAIL_REQUEST"
export const CONTRACTOR_DETAIL_REQUEST_FAILURE = "CONTRACTOR_DETAIL_REQUEST_FAILURE"
export const CONTRACTOR_DETAIL_REQUEST_SUCCESS = "CONTRACTOR_DETAIL_REQUEST_SUCCESS"