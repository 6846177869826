import {
  GET_OWNER_NOTIFICATION_REQUEST,
  GET_OWNER_NOTIFICATION_SUCCESS,
  GET_OWNER_NOTIFICATION_FAILURE,
  GET_OWNER_MODAL_DATA_SUCCESS
  // GET_NOTIFICATION_MODAL_DATA,
  // GET_NOTIFICATION_MODAL_SUCCESS,
  // GET_NOTIFICATION_MODAL_FAILURE,
  // PAGE_REQUEST,
  // PAGE_SUCCESS,
  // PAGE_FALUIRE,
} from "./type"

//  GET_NOTIFICATION_FAILURE
export const getOwnerNotificationRequest = (filterModel,id )=> (
 
  {
  type: GET_OWNER_NOTIFICATION_REQUEST,
  filterModel,
  id
})

export const getOwnerNotificationSuccess = data => ({
  type: GET_OWNER_NOTIFICATION_SUCCESS,
  data
})
export const getOwnerNotificationFailure = data => ({
  type: GET_OWNER_NOTIFICATION_FAILURE,
  data
})
export const getOwnerModaldatasuccess = data => ({
  type: GET_OWNER_MODAL_DATA_SUCCESS,
  data
})

// export const getNotfiactionModalData = (id ,data)=> ({
//   type: GET_NOTIFICATION_MODAL_DATA,
//   id,
//   data
// })

// export const getNotfiactionModalSuccess = data => ({
//   type: GET_NOTIFICATION_MODAL_SUCCESS,
//   data
// })

// export const getNotfiactionModalFailure = data => ({
//   type: GET_NOTIFICATION_MODAL_FAILURE,
//   data
// })


// export const pageRequest = data => ({
//   type: PAGE_REQUEST,
//   data
// })

// export const pageSuccess = data => ({
//   type: PAGE_SUCCESS,
//   data
// })

// export const pageFaluire = data => ({
//   type: PAGE_FALUIRE,
//   data
// })