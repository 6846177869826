// GET LOGIN
export const POST_REQUEST_LOGIN = "POST_REQUEST_LOGIN"
export const POST_REQUEST_LOGIN_SUCCESS = "POST_REQUEST_LOGIN_SUCCESS"
export const POST_REQUEST_LOGIN_FAILURE = "POST_REQUEST_LOGIN_FAILURE"
export const IS_VISITING = "IS_VISITING"

export const SET_INV0ICE_ID = "SET_INV0ICE_ID"
export const SET_ACCESS_TOKEN = "SET_ACCESS_TOKEN"
export const LOGOUT = "LOGOUT"
export const SCHEDULE_ID = "SCHEDULE_ID"
export const SET_SEEMORE_ID = "SET_SEEMORE_ID"
export const SET_DEPOSITESITE_ID = "SET_DEPOSITESITE_ID"
export const SET_BOOK_ID = "SET_BOOK_ID"
export const SET_FILLSITE_ID = "SET_FILLSITE_ID"
export const RESET="RESET"

