import { all, call, put, takeLatest } from "redux-saga/effects"
import { BASE_URL } from "../../../../config/app"
import XHR from "../../../../utils/XHR"
import {
  getFillsiteSuccess,
  getFillsiteFailure,
  postBookSucess,
  postBookFailure
} from "./action"
import {
  GET_FILLSITE_AVAILABILITY_REQUEST,
  POST_BOOK_LOAD_REQUEST
} from "./type"
import { history } from "../../../../reduxStore/store"
import { toast } from 'react-hot-toast'


const FillsiteAPI = async (id, reqID, date) => {
  let URL = `${BASE_URL}api/v1/fill_site_request_availability/${id}/`
  if (reqID && date) {
    URL += `?request_id=${reqID}&date=${date}`
  }
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "GET"
  }
  return XHR(URL, options)
}

function* FillsiteData({ id, reqID, date }) {
  try {
    const response = yield call(FillsiteAPI, id, reqID, date)
    yield put(getFillsiteSuccess(response?.data))
  } catch (e) {
    const { response } = e
    yield put(getFillsiteFailure(response?.data))
  }
}
async function BookSucessAPI(data) {
  const URL = `${BASE_URL}api/v1/request_availability/`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "POST",
    data
  }
  return XHR(URL, options)
}

function* BookSucessInfo({ data }) {
  try {
    const response = yield call(BookSucessAPI, data)
    yield put(postBookSucess(response.data))
  } catch (e) {
    const { response } = e
    if (response.status == 400 && response?.data?.msg) {
      toast.error(response?.data?.msg)
    }
    yield put(postBookFailure(response?.data))
  }
}

export default all([
  takeLatest(GET_FILLSITE_AVAILABILITY_REQUEST, FillsiteData),
  takeLatest(POST_BOOK_LOAD_REQUEST, BookSucessInfo)

])
