import { toast } from "react-hot-toast"
import { BASE_URL } from "../../../config/app"
import XHR from "../../../utils/XHR"
import { all, call, put, takeLatest } from "redux-saga/effects"

// types
import {
  GET_FILLSITE_TRUCKS_REQUEST,
  POST_UPLOAD_INVOICE_REQUEST
} from "./type"
import {
  getFillsiteTrucksSuccess,
  getFillsiteTrucksFailure,
  postUploadInvoiceSuccess,
  postUploadInvoiceFailure
} from "./action"
import { getSingleInvoiceRequest } from "../../../pages/Admin/Invoices/redux/action"
import { getAdminFillRequest } from "../../../pages/Admin/RequestConfirmPage/redux/action"

async function getTrucksAPI({ contractor, fill_site, startDate, endDate }) {

  const URL = `${BASE_URL}api/v1/admin/truck_list/?contractor=${contractor}&fill_site=${fill_site}&start_date=${startDate}&end_date=${endDate}`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "GET"
  }
  return XHR(URL, options)
}

function* getTrucksRequest({ data }) {
  try {
    const response = yield call(getTrucksAPI, data)
    yield put(getFillsiteTrucksSuccess(response.data))
  } catch (e) {
    const { response } = e
    yield put(getFillsiteTrucksFailure(response.data))
    // toast.error("An Error occured while adding fill site!")
  }
}
async function postUploadInvoiceAPI(data) {
  const URL = `${BASE_URL}api/v1/admin/upload_invoice/`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
      Authorization: `Token ${token}`
    },
    method: "POST",
    data
  }
  return XHR(URL, options)
}

function* postUploadInvoiceRequest(action) {
  try {
    const response = yield call(postUploadInvoiceAPI, action.data)
    yield put(postUploadInvoiceSuccess(response.data))
    toast.success("Invoice Uploaded Successfully")
    yield put(getSingleInvoiceRequest(response.data.contractor))
  } catch (e) {
    const { response } = e
    yield put(postUploadInvoiceFailure(response.data))
    toast.error("An Error occured!")
  } finally {
    yield put(getAdminFillRequest())
  }
}

export default all([
  takeLatest(GET_FILLSITE_TRUCKS_REQUEST, getTrucksRequest),
  takeLatest(POST_UPLOAD_INVOICE_REQUEST, postUploadInvoiceRequest)
])
