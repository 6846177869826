import HomeLayout from "../../../layout/HomeLayout"
import { history } from "../../../reduxStore/store"
import backIcon from "../../../assets/images/back-icon-circle.svg"

import "./style.scss"
import Button from "../../../components/Button"
import useForm from "../../../utils/useForm"
import { useEffect } from "react"
import Input from "../../../components/Input"
import {
  postSoilAcceptFailure,
  postSoilAcceptRequest
} from "../RequestConfirmPage/redux/action"
import { connect } from "react-redux"
import { Toaster } from "react-hot-toast"

const RejectRequestPage = props => {
  const { error, postSoilAcceptRequest ,postSoilAcceptRequesting} = props

  const stateSchema = {
    note: {
      value: "",
      error: ""
    }
  }
  const validationStateSchema = {
    note: {
      required: true
    }
  }
  const savedMaterialId = localStorage.getItem("materialId")
  const submit = async () => {
    const formData = new FormData()
    formData.append("note", state.note.value)
    formData.append("deposit_request", savedMaterialId)

    formData.append("status", "rejected")
    await postSoilAcceptRequest(formData)
  }

  useEffect(() => {
    postSoilAcceptFailure(false)
    setState(stateSchema)
  }, [])

  const { state, handleOnChange, disable, setState } = useForm(
    stateSchema,
    validationStateSchema
  )
  return (
    <>
      <HomeLayout dashboardHeader={true}>
        <Toaster />
        <section className="soil-request-reject-section">
          <div className="heading-reject">
            <div className="back" onClick={() => history.goBack()}>
              <img src={backIcon} alt="" />
            </div>
            <div className="contractor-name">
              <h3>Decline SDR</h3>
            </div>
          </div>
          <div className="body-reject scrollBody">
            <div className="body-content">
              <div className="note-wrapper">
                <h3 className="note-text">Note: </h3>
                <textarea
                  className="note-textarea"
                  // type=""
                  type="text"
                  placeholder="Enter text"
                  value={state.note.value}
                  onChange={val => handleOnChange("note", val.target.value)}
                  // showError={true}
                  // errorMessage={state.note.error || error?.note}
                />
                <label
                  className={`error-message ${error.note ? "opacity-1" : ""}`}
                >
                  {error?.note && error?.note}
                </label>
              </div>
              <div className="reject-btn">
                <Button
                showSpinner={postSoilAcceptRequesting}
                  className="aes-btn danger-btn"
                  title="Decline SDR"
                  onClick={submit}
                />
              </div>
            </div>
          </div>
        </section>
      </HomeLayout>
    </>
  )
}
const mapStateToProps = state => {
  // debugger

  return {
    error: state.SoilConfirm.error,
    postSoilAcceptRequesting:state.SoilConfirm.postSoilAcceptRequesting,
  }
}

const mapDispatchToProps = dispatch => ({
  postSoilAcceptRequest: data => dispatch(postSoilAcceptRequest(data)),
  postSoilAcceptFailure: data => dispatch(postSoilAcceptFailure(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(RejectRequestPage)
