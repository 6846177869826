import { all } from "redux-saga/effects"
import login from "../pages/AuthScreens/LoginScreen/redux/saga"
import signUp from "../pages/AuthScreens/SignUp/redux/saga"
import forgotPassword from "../authModal/ForgetModal/redux/saga"
import confirmPassword from "../pages/AuthScreens/ChangePasword/redux/saga"
import FillsiteTable from "../pages/Contractor/AccountFillSite/redux/saga"
import noficationInfo from "../pages/Contractor/Dashboard/redux/saga"
import soilDeposit from "../pages/Contractor/RequestSoilDeposit/redux/saga"
import RequestData from "../pages/Contractor/RequestList/redux/saga"
import TeamMembers from "../pages/Contractor/MyTeam/redux/saga"
import AddTeamMember from "../pages/Contractor/AddTeamMember/redux/saga"
import TeamMemberProfile from "../pages/Contractor/TeamMemberProfile/redux/saga"
import FillsiteData from "../pages/Contractor/DepositSite/redux/saga"
import ProfileData from "../pages/Contractor/AccountInformation/redux/saga"
import OwnerNotification from "../pages/FillSiteOwner/OwnerFillSiteDashbord/redux/saga"

import Project from "../pages/Contractor/ProjectsList/redux/saga"
import AddProject from "../pages/Contractor/AddProjects/redux/saga"

import OwnerTeamMembers from "../pages/FillSiteOwner/MyTeam/redux/saga"
import OwnerAddTeamMember from "../pages/FillSiteOwner/AddTeamMember/redux/saga"
import OwnerTeamMemberProfie from "../pages/FillSiteOwner/TeamMemberProfile/redux/saga"
import HomeScreenData from "../pages/AuthScreens/HomeScreen/redux/saga"
import dataFooter from "../pages/VisitingSite/Foter/redux/saga"
import homeData from "../pages/VisitingSite/Home/redux/saga"
import adminDashboardUserData from "../pages/Admin/AdminDashboard/redux/saga"
import NewFileSite from "../pages/Admin/AdminDashboard/NewFileSite/redux/saga"
import AdminSoilRequest from "../pages/Admin/SoilRequest/redux/saga"
import SoilConfirm from "../pages/Admin/RequestConfirmPage/redux/saga"
import schedule from "../pages/FillSiteOwner/Schedule/redux/saga"
import Invoices from "../pages/Admin/Invoices/redux/saga"
import SoilAuthorizationForm from "../pages/Contractor/SoilAuthorizationForm/redux/saga"
import UploadInvoice from "../components/UploadInvoice/redux/saga"
import profileFillData from "../pages/FillSiteOwner/AccountInformation/redux/saga"
import DocumentAgreement from "../pages/Admin/DocumentAgreement/redux/saga"
import Report from "../pages/Admin/Reports/redux/saga"
import Notification from "../pages/Admin/Notifications/redux/saga"
import adminContractor from "../pages/Admin/AdminContractors/redux/saga"
import AccountRequest from "../pages/Admin/AccountRequest/redux/saga"
import MaterialTracking from "../pages/Admin/MaterialTracking/redux/saga"


import Truck from "../pages/Contractor/TruckList/redux/saga"
import Schedule from "../pages/Contractor/ScheduleList/redux/saga"

import AddTruck from "../pages/Contractor/AddTruck/redux/saga"
import AddSchedule from "../pages/Contractor/AddSchedule/redux/saga"

export function* rootSaga() {
  yield all([
    Notification,
    adminContractor,
    login,
    signUp,
    forgotPassword,
    confirmPassword,
    noficationInfo,
    soilDeposit,
    RequestData,
    TeamMembers,
    AddTeamMember,
    TeamMemberProfile,
    FillsiteData,
    ProfileData,
    OwnerNotification,
    FillsiteTable,
    OwnerTeamMembers,
    OwnerAddTeamMember,
    adminDashboardUserData,
    OwnerTeamMemberProfie,
    HomeScreenData,
    dataFooter,
    homeData,
    NewFileSite,
    AdminSoilRequest,
    SoilConfirm,
    schedule,
    SoilAuthorizationForm,
    Invoices,
    UploadInvoice,
    profileFillData,
    DocumentAgreement,
    Report,
    AccountRequest,
    MaterialTracking,
    AddProject,
    Project,
    Truck,
    Schedule,
    AddTruck,
    AddSchedule
  ])
}
