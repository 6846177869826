import {
  GET_Admin_NOTIFICATION_REQUEST,
  GET_Admin_NOTIFICATION_SUCCESS,
  DELETE_NOTIFICATION_REQUEST,
  DELETE_NOTIFICATION_SUCCESS,
  POST_ADMIN_REQUEST,
    POST_ADMIN_SUCCESS,
    POST_ADMIN_FAILED,

} from "./type"
export const getAdminNotificationRequest = data => ({
  type:  GET_Admin_NOTIFICATION_REQUEST,
  data
})
export const getNotificationSucess = data => ({
  type:  GET_Admin_NOTIFICATION_SUCCESS,
  data
})
export const deleteNotificationRequest = data => ({
  type:  DELETE_NOTIFICATION_REQUEST,
  data
})
export const deleteNotificationSucess = data => ({
  type:  DELETE_NOTIFICATION_SUCCESS,
  data
})
export const PostAdminRequest = (data,handleCancel) => ({
  type:  POST_ADMIN_REQUEST,
  data,handleCancel
})
export const PostAdminSucess = data => ({
  type:POST_ADMIN_SUCCESS,
  data
})
export const PostAdminFailed = data => ({
  type:   POST_ADMIN_FAILED,
  data
})
