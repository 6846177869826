import React from "react"
import backIcon from "../../../../../assets/images/back-icon-circle.svg"
import { history } from "../../../../../reduxStore/store"
import moment from "moment"

const DetailSidebar = props => {
  const { SetApiData, fill_site_info } = props

  const detailData = [
    {
      id: 1,
      title: "Address:",
      text: `${fill_site_info?.street || ""}, ${fill_site_info?.city || ""}, ${
        fill_site_info?.province || ""
      }, ${fill_site_info?.city_postal_code || "--"}`
    },
    {
      id: 2,
      title: "Max allowable trucks per day:",
      text: `${SetApiData?.site_owner?.max_allowed_truck_per_day || "--"}`
    },
    {
      id: 3,
      title: "Max allowable loads per day:",
      text: `${SetApiData?.site_owner?.max_allowed_load_per_day || "--"}`
    },
    {
      id: 4,
      title: "CSR acceptance",
      text: `${SetApiData?.site_owner?.csr_acceptance || "--"}`
    },
    {
      id: 4,
      title: "Availability",
      text: (
        <div>
          <h4 style={{ fontSize: "15px", fontWeight: "600" }}>
            {SetApiData?.fill_site_scheduled?.general_scheduled
              ? SetApiData?.fill_site_scheduled?.general_scheduled
                ? "General Schedule"
                : "Specific Date Schedule"
              : "No schedule available"}
          </h4>
          {SetApiData?.fill_site_scheduled?.general_scheduled ? (
            <div
              className="ScheduleDaysStyle"
              style={{ flexWrap: "wrap", justifyContent: "unset" }}
            >
              {[1, 2, 3, 4, 5, 6, 7].map(day => (
                <div
                  key={day}
                  style={{ marginRight: "10px", marginTop: "10px" }}
                  className={
                    SetApiData.fill_site_scheduled?.week_days[day] === false
                      ? "days daylist"
                      : "selectedDays daylist"
                  }
                >
                  <span>
                    {["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"][day - 1]}
                  </span>
                </div>
              ))}
            </div>
          ) : (
            <>
              {SetApiData?.fill_site_scheduled?.date
                ? moment(SetApiData?.fill_site_scheduled?.date).format(
                    "DD MMMM YYYY"
                  )
                : ""}
            </>
          )}
        </div>
      )
    }
  ]

  return (
    <section className="detail-sidebar-section">
      <div className="details-header">
        <div className="header-wrapper">
          <div
            className="back"
            onClick={() => history.push("approved-requests")}
          >
            <img src={backIcon} alt="" />
          </div>
          <div className="text">{SetApiData?.name}</div>
        </div>
      </div>
      <div className="site-details">
        {detailData.map(item => (
          <div className="detail-item" key={item.id}>
            <h3>{item.title}</h3>
            <p>{item.text}</p>
          </div>
        ))}
      </div>
    </section>
  )
}

export default DetailSidebar
