import {
  GET_NOTIFICATION_REQUEST,
  GET_NOTIFICATION_SUCCESS,
  GET_NOTIFICATION_FAILURE,
  GET_MODAL_DATA_SUCCESS,
  // GET_NOTIFICATION_MODAL_DATA,
  // GET_NOTIFICATION_MODAL_SUCCESS,
  // GET_NOTIFICATION_MODAL_FAILURE,
  // PAGE_REQUEST,
  // PAGE_SUCCESS,
  // PAGE_FALUIRE,
  GET_TRUCK_REQUEST,
  GET_TRUCK_SUCCESS
} from "./type"

//  GET_NOTIFICATION_FAILURE
export const getNotificationRequest = (filterModel,id )=> (
 
  {
  type: GET_NOTIFICATION_REQUEST,
  filterModel,
  id
})

export const getNotificationSuccess = data => ({
  type: GET_NOTIFICATION_SUCCESS,
  data
})
export const getNotificationFailure = data => ({
  type: GET_NOTIFICATION_FAILURE,
  data
})
export const getModaldatasuccess = data => ({
  type: GET_MODAL_DATA_SUCCESS,
  data
})
export const getTruckListRequest = data => ({
  type: GET_TRUCK_REQUEST,
  data
})
export const getTruckListSuccess = data => ({
  type: GET_TRUCK_SUCCESS,
  data
})
// export const getNotfiactionModalData = (id ,data)=> ({
//   type: GET_NOTIFICATION_MODAL_DATA,
//   id,
//   data
// })

// export const getNotfiactionModalSuccess = data => ({
//   type: GET_NOTIFICATION_MODAL_SUCCESS,
//   data
// })

// export const getNotfiactionModalFailure = data => ({
//   type: GET_NOTIFICATION_MODAL_FAILURE,
//   data
// })


// export const pageRequest = data => ({
//   type: PAGE_REQUEST,
//   data
// })

// export const pageSuccess = data => ({
//   type: PAGE_SUCCESS,
//   data
// })

// export const pageFaluire = data => ({
//   type: PAGE_FALUIRE,
//   data
// })