import { DatePicker, Select, Space } from "antd"
import moment from "moment"
import React, { useState, useEffect, useRef } from "react"
import { Spinner } from "react-bootstrap"
import filter from "../../assets/images/filter.svg"

import "./style.scss"

const Filter = ({ ...props }) => {
  const {
    onDateChange,
    onClick,
    setCalenderState,
    calenderState,
    dataData,
    open,
    setOpen,
    onOpenChange,
    onClickOutside,
    isTypeClick,
    value,
    datType,
    disabledDate
  } = props
  const ref = useRef(null)
  const [type, setType] = useState("date")
  const [date, setDate] = useState()
  const [selectedDate, setSelectedDate] = useState("")

  const PickerWithType = ({ type, onChange, value }) => {
    if (type === "date") {
      return (
        <DatePicker
          onChange={onChange}
          autoFocus={false}
          value={selectedDate || null}
          onOpenChange={onOpenChange}
          className="datePicker"
          open={calenderState}
          disabledDate={disabledDate}
        />
      )
    } else if (type === "week") {
      return (
        <div className="">
          <Space>
            <DatePicker
              onChange={onChange}
              autoFocus={false}
              onOpenChange={onOpenChange}
              className="datePicker"
              value={value}
              open={calenderState}

            />
          </Space>
        </div>
      )
    } else {
      return (
        <DatePicker
          picker={type}
          value={value}
          onChange={onChange}
          className="datePicker"
          open={calenderState}
          onOpenChange={onOpenChange}
          autoFocus={false}

        />
      )
    }
  }

  const renderContent = () => {
    if (type === "week") {
      const startDate = dataData?.fromDate
      const endDate = dataData?.toDate


      return (
        <p>
          <span className="">
            {dataData == undefined ? "dd" : moment(startDate).format("D-MMM")} -{" "}
            {endDate == undefined || endDate == ""
              ? "dd"
              : moment(endDate).format("D-MMM")}
          </span>
        </p>
      )
    } else if (type === "month") {
      return <p>{moment(dataData?.fromDate).format("MMMM")}</p>
    } else {
      return (
        dataData?.fromDate ?
          <p>
            {moment(dataData?.fromDate).format("ddd")}{" "}
            <span className="">{moment(dataData?.fromDate).format("D")}</span>{" "}
          </p>
          : "Select"
      )
    }
  }

  return (
    <div className="filter-wrapper">
      <div className="filter" onClick={onClick}>
        <div className="button-filter">
          <img src={filter} />
          <p>
            Filter by:
            <span>
              {type == "date"
                ? "Day"
                : type.charAt(0).toUpperCase() + type.slice(1)}
            </span>{" "}
          </p>
        </div>
        <div className="display-date">{renderContent()}</div>
      </div>
      <div style={{ position: "absolute", zIndex: '1' }}>
        {calenderState && (
          <>
            <div>
              <div
                style={{
                  display: "flex",
                  gap: "20px",
                  borderBottom: "1px solid #CFCFCF",
                  justifyContent: "space-around",
                  backgroundColor: "#FFFFFF",
                  borderTopLeftRadius: "5px",
                  borderTopRightRadius: "5px",

                  marginTop: 10,
                  height: "40px",
                  paddingTop: "10px",
                  width: "275px",
                  height: "62px",
                  backgroundColor: "#ffffff",
                  alignItems: "center"
                }}
              >
                <span
                  className={type == "date" ? "type" : "untype"}
                  onClick={() => {
                    setType("date")
                  }}
                >
                  Day
                </span>
                {datType != "date" &&
                  <>
                    <span
                      className={type == "week" ? "type" : "untype"}
                      onClick={() => {
                        setType("week")
                      }}
                    >
                      Week
                    </span>
                    <span
                      className={type == "month" ? "type" : "untype"}
                      onClick={() => {
                        setType("month")
                      }}
                    >
                      Month
                    </span>
                  </>
                }
              </div>
              <div>
                <Space>
                  <PickerWithType
                    className="datePicker"
                    type={type}
                    autoFocus={true}
                    onOpenChange={onOpenChange}
                    // onOpenChange={blurr}
                    onChange={(date, dateString, fitlerType) => {
                      onDateChange(date, dateString, type)

                      setSelectedDate(date)
                    }}
                    value={selectedDate}
                  />
                </Space>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  )
}
export default Filter
