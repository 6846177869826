import {
  GET_TEAM_MEMBERS_REQUEST,
  GET_TEAM_MEMBERS_SUCCESS,
  GET_TEAM_MEMBERS_FAILURE,
  DELETE_TEAM_MEMBER_REQUEST,
  DELETE_TEAM_MEMBER_SUCCESS,
  DELETE_TEAM_MEMBER_FAILURE,
  UPDATE_TEAM_MEMBERS,
  UPDATE_TEAM_MEMBERS_SUCCESS
} from "./type"

const initialState = {
  myTeamMembers: [],
  requesting: false,
  error: false,
  deleteRequest: false,
  deleteStatus: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_TEAM_MEMBERS_REQUEST:
      return {
        ...state,
        requesting: true
      }
    case GET_TEAM_MEMBERS_SUCCESS:
      return {
        ...state,
        requesting: false,
        myTeamMembers: action.data
      }
    case GET_TEAM_MEMBERS_FAILURE:
      return {
        ...state,
        requesting: false,
        error: action.data
      }

    case DELETE_TEAM_MEMBER_REQUEST:
      return {
        ...state,
        deleteRequest: true,
        deleteStatus: false
      }
    case DELETE_TEAM_MEMBER_SUCCESS:
      return {
        ...state,
        deleteRequest: false,
        deleteStatus: true
        //myTeamMembers: action.data
      }
    case DELETE_TEAM_MEMBER_FAILURE:
      return {
        ...state,
        deleteRequest: false,
        deleteStatus: false,
        error: action.data
      }
    case UPDATE_TEAM_MEMBERS:
      return {
        ...state
      }
    case UPDATE_TEAM_MEMBERS_SUCCESS:
      return {
        ...state,
        myTeamMembers: action.data
      }
    default:
      return state
  }
}
