import { all, call, put, takeLatest } from "redux-saga/effects"
import { BASE_URL } from "../../../../config/app"
import XHR from "../../../../utils/XHR"
import {
  getFillSiteSuccess,
  getFillSiteFailure,
  getInvoicesSuccess,
  getInvoicesFailure,
  searchFillSiteSucess,
  searchInvoicesSucess
} from "./action"
import { GET_FILL_SITE_REQUEST, GET_INV0ICES_REQUEST } from "./type"

const FillSiteAPI = async (data, namefill) => {
  const URL = `${BASE_URL}/api/v1/contractor/fill_site_list/${data}`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "GET"
  }
  return XHR(URL, options)
}

function* FillSiteData({ data, namefill }) {
  try {
    const response = yield call(FillSiteAPI, data, namefill)

    yield put(getFillSiteSuccess(response.data))

    yield put(searchFillSiteSucess(response.data))
  } catch (e) {
    const { response } = e
    yield put(getFillSiteFailure(response.data))
  }
}

const InvoicesAPI = async (data, invoicefill) => {
  const URL = `${BASE_URL}api/v1/invoices/${data}`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "GET"
  }
  return XHR(URL, options)
}

function* InvoicesData({ data, invoicefill }) {
  try {
    const response = yield call(InvoicesAPI, data, invoicefill)
    yield put(getInvoicesSuccess(response.data))
    yield put(searchInvoicesSucess(response.data))
  } catch (e) {
    const { response } = e
    yield put(getInvoicesFailure(response.data))
  }
}

export default all([
  takeLatest(GET_FILL_SITE_REQUEST, FillSiteData),
  takeLatest(GET_INV0ICES_REQUEST, InvoicesData)
])
