import { Switch, Redirect } from "react-router-dom"
import AuthLayout from "./layout/Auth/index"
import VisitingLayout from "./layout/Visiting/index"
import AdminLayout from "./layout/Admin/index"
import OwnerLayout from "./layout/Owner/index"
import RouteGuard from "./RouterGuard/index"
import { connect } from "react-redux"
import ContractorLayout from "./layout/Contractor/index"
import { routes } from "./Router"
import { Toaster } from "react-hot-toast"


function App(props) {
  const { loginInfo, isVisting } = props

  return (
    <>
      <Toaster position="top-center" />
      <Switch>
        {isVisting ? (
          <RouteGuard
            path={[
              ...routes
                .filter(route => route?.layout === "/auth")
                .map(filteredRoute => filteredRoute?.path),
              "/auth"
            ]}
            component={props => <AuthLayout {...props} />}
          />
        ) : (
          <RouteGuard
            path="/visiting"
            component={props => <VisitingLayout {...props} />}
          />
        )}
        {loginInfo && loginInfo?.user?.user_type === "owner" && (
          <RouteGuard
            path="/owner"
            component={props => <OwnerLayout {...props} />}
            isProtected
          />
        )}

        {loginInfo && loginInfo?.user?.user_type === "contractor" && (
          <RouteGuard
            path="/contractor"
            component={props => <ContractorLayout {...props} />}
            isProtected
          />
        )}
        {loginInfo && loginInfo?.user?.user_type === "admin" && (
          <RouteGuard
            path="/admin"
            component={props => <AdminLayout {...props} />}
            isProtected
          />
        )}

        {/* <Landing/> */}
        {isVisting ? (
          <Redirect to="/auth/account" />
        ) : (
          <Redirect to="/visiting/home" />
        )}
        {/* <RouteGuard
          path={[
            ...routes
              .filter(route => route.layout === "home")
              .map(filteredRoute => filteredRoute.path),
            "/auth"
          ]}
          component={props => <AuthLayout {...props} />}
        /> */}
        {/* <Redirect to="/" /> */}
      </Switch>
    </>
  )
}

const mapStateToProps = state => ({
  accessToken: state.login.accessToken,
  loginInfo: state.login.loginInfo,
  isVisting: state.login.isVisting
})

export default connect(mapStateToProps, null)(App)
