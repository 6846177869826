import { all, call, put, takeLatest } from "redux-saga/effects"
import { BASE_URL } from "../../../../config/app"
import XHR from "../../../../utils/XHR"
// types
import { PUT_TEAM_MEMBER } from "./type"
// actions
import { putTeamMemberSuccess, putTeamMemberFailure } from "./action"
import { toast } from "react-hot-toast"
import { history } from "../../../../reduxStore/store"

async function putTeamMemberRequestAPI(data, id) {
  const URL = `${BASE_URL}team/team_member/${id}/`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "PUT",
    data
  }

  return XHR(URL, options)
}

function* putTeamMember({ data, id }) {
  try {
    const response = yield call(putTeamMemberRequestAPI, data, id)
    yield put(putTeamMemberSuccess(response.data))

    yield toast.success("Successfully Update the Team Member", {
      id: "card"
    })
    setTimeout(() => {
       history.push("my-team")
    }, 2000);
   
  
  } catch (e) {
    const { response } = e
    yield put(putTeamMemberFailure(response?.data))
  }
}

export default all([takeLatest(PUT_TEAM_MEMBER, putTeamMember)])
