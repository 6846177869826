import {
  GET_FILL_SITE_REQUEST,
  GET_FILL_SITE_SUCCESS,
  GET_FILL_SITE_FAILURE,
  GET_INV0ICES_REQUEST,
  GET_INV0ICES_SUCCESS,
  GET_INV0ICES_FAILURE,
  SEARCH_FILL_SITE_SUCCESS,
  SEARCH_INV0ICES_SUCCESS,
  SET_INV0ICE_ID
} from "./type"

//  GET_NOTIFICATION_FAILURE
export const getFillSiteRequest = (data, search) => ({
  type: GET_FILL_SITE_REQUEST,
  data,
  search
})

export const getFillSiteSuccess = data => ({
  type: GET_FILL_SITE_SUCCESS,
  data
})
export const getFillSiteFailure = data => ({
  type: GET_FILL_SITE_FAILURE,
  data
})
export const getInvoicesRequest = data => ({
  type: GET_INV0ICES_REQUEST,
  data
})

export const getInvoicesSuccess = data => ({
  type: GET_INV0ICES_SUCCESS,
  data
})
export const getInvoicesFailure = data => ({
  type: GET_INV0ICES_FAILURE,
  data
})

export const searchFillSiteSucess = data => ({
  type: SEARCH_FILL_SITE_SUCCESS,
  data
})
export const searchInvoicesSucess = data => ({
  type: SEARCH_INV0ICES_SUCCESS,
  data
})
