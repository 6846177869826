import {
  GET_PROFILE_DATA,
  GET_PROFILE_DATA_SUCCESS,
  GET_PROFILE_DATA_FAILURE,
  PUT_PROFILE_DATA,
  PUT_PROFILE_DATA_SUCCESS,
  PUT_PROFILE_DATA_FAILURE
} from "./type"

const initialState = {
  profileData: false,
  error: false,
  requesting: false,
  putRequesting: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_PROFILE_DATA:
      return {
        ...state,
        requesting: true
      }
    case GET_PROFILE_DATA_SUCCESS:
      return {
        ...state,
        requesting: false,
        profileData: action.data
      }
    case GET_PROFILE_DATA_FAILURE:
      return {
        ...state,
        requesting: false,
        error: action.data,
      
      }
    case PUT_PROFILE_DATA:
      return {
        ...state,
        putRequesting: true,
      
      }
    case PUT_PROFILE_DATA_SUCCESS:
      return {
        ...state,
        putRequesting: false,
        profileData: action.data
      }
    case PUT_PROFILE_DATA_FAILURE:
      return {
        ...state,
        putRequesting: false,
        error: action.data,
        
      }
    default:
      return state
  }
}
