import React, { useState, useEffect, useRef } from "react"
import { Modal, Form } from "antd"
import Input from "../../Input"
import Button from "../../Button"
import AeSelect from "../../AeSelect"
import { PostReportRequest, getContractorRequest } from "../../../pages/Admin/Reports/redux/action"
import { connect, useDispatch, useSelector } from "react-redux"


const RequestReport = props => {
    const { modalShow, setModalShow, } = props
    const [Contractor, setContractor] = useState([])
    const [selectContractor, setSelectContractor] = useState([])
    const [needInput, setNeedInput] = useState("")
    const dispatch = useDispatch()
    const handleCancel = () => {
        setModalShow(false)
        formRef.current?.resetFields();
        setNeedInput("")
    }
    const { contractorData, Postrequesting } = useSelector(
        state => state.Report
    )
    const { getAdminFillSite, } = useSelector(
        state => state.SoilConfirm
    )
    useEffect(() => {
        dispatch(getContractorRequest())
    }, [])
    useEffect(() => {
        if (getAdminFillSite) {
            const Contractor_array = []
            getAdminFillSite?.map((a, b) => {
                Contractor_array.push({ value: a.id, label: a.username })
            })
            setContractor(Contractor_array)
        }

    }, [getAdminFillSite])
    const submit = () => {
        const formData = new FormData()

        formData.append("user", selectContractor)
        formData.append("reported_on", needInput)
        dispatch(PostReportRequest(formData, handleCancel))
    }
    const formRef = useRef(null)

    return (
        <>
            <Modal
                title={` `}
                open={modalShow}
                onCancel={handleCancel}
                className="UploadDocoment-modal"
                cancelButtonProps={{ style: { display: "none" } }}
                footer={null}
            >
                <div className="modal-div">
                    <h3>
                        Request Report
                    </h3>
                    <Form
                        ref={formRef}
                        name="control-ref"
                        labelWrap={true}
                        layout="vertical"
                        onFinish={submit}
                    >
                        <h2>Choose Fill Site</h2>
                        <Form.Item
                            name="user"
                            rules={[
                                {
                                    required: true,
                                    message: "This field may not be blank.",
                                },
                            ]}
                        >
                            <AeSelect
                                options={Contractor}
                                placeholder={"select"}
                                onChange={(e) => setSelectContractor(e)}
                            />
                        </Form.Item>
                        <h2>Report needed on</h2>
                        <Form.Item
                            name="needed"
                            rules={[
                                {
                                    required: true,
                                    message: "This field may not be blank.",
                                },
                            ]}
                        >
                            <Input
                                onChange={(e) => setNeedInput(e.target.value)}
                                value={needInput}
                                type="text"
                                placeholder=" Enter report title"
                                className="tile-input"
                            // disabled={true}
                            />
                        </Form.Item>
                        <div className="btn-wraper">

                            <Button

                                title="Send"
                                className="aes-btn green-btn "

                                showSpinner={Postrequesting}
                            />
                        </div>
                    </Form>
                </div>
            </Modal>
        </>
    )
}

export default RequestReport
