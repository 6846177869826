// GET TEAM MEMBERS
export const GET_OWNER_TEAM_MEMBERS = "GET_OWNER_TEAM_MEMBERS"
export const GET_OWNER_TEAM_MEMBERS_SUCCESS = "GET_OWNER_TEAM_MEMBERS_SUCCESS"
export const GET_OWNER_TEAM_MEMBERS_FAILURE = "GET_OWNER_TEAM_MEMBERS_FAILURE"

// DELETE TEAM MEMBER
export const DELETE_OWNER_TEAM_MEMBER = "DELETE_OWNER_TEAM_MEMBER"
export const DELETE_OWNER_TEAM_MEMBER_SUCCESS =
  "DELETE_OWNER_TEAM_MEMBER_SUCCESS"
export const DELETE_OWNER_TEAM_MEMBER_FAILURE =
  "DELETE_OWNER_TEAM_MEMBER_FAILURE"

export const UPDATE_OWNER_TEAM_MEMBERS = "UPDATE_OWNER_TEAM_MEMBERS"
export const UPDATE_OWNER_TEAM_MEMBERS_SUCCESS =
  "UPDATE_OWNER_TEAM_MEMBERS_SUCCESS"
