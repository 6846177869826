import {
  GET_FILL_SITE_REQUEST,
  GET_FILL_SITE_SUCCESS,
  GET_FILL_SITE_FAILURE,
  GET_INV0ICES_REQUEST,
  GET_INV0ICES_SUCCESS,
  GET_INV0ICES_FAILURE,
  SEARCH_FILL_SITE_REQUEST,
  SEARCH_FILL_SITE_SUCCESS,
  SEARCH_INV0ICES_REQUEST,
  SEARCH_INV0ICES_SUCCESS,
  SET_INV0ICE_ID
} from "./type"
const initialState = {
  fillsiteData: false,
  novicesData: false,
  Novicesrequesting: false,
  error: false,
  searchFill: false,
  searchnovices: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_FILL_SITE_REQUEST:
      return {
        ...state,
        requesting: true
      }
    case GET_FILL_SITE_SUCCESS:
      return {
        ...state,
        requesting: false,
        fillsiteData: action.data
      }
    case GET_FILL_SITE_FAILURE:
      return {
        ...state,
        requesting: false,
        error: action.data
      }
    case GET_INV0ICES_REQUEST:
      return {
        ...state,
        Novicesrequesting: true
      }
    case GET_INV0ICES_SUCCESS:
      return {
        ...state,
        Novicesrequesting: false,
        novicesData: action.data
      }
    case GET_INV0ICES_FAILURE:
      return {
        ...state,
        Novicesrequesting: false,
        error: action.data
      }

    case SEARCH_FILL_SITE_SUCCESS:
      return {
        ...state,
        fillsiteData: action.data
      }

    case SEARCH_INV0ICES_SUCCESS:
      return {
        ...state,
        novicesData: action.data
      }

    default:
      return state
  }
}
