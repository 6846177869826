import {
  GET_MATERIAL_TRACKING_REQUEST,
  GET_MATERIAL_TRACKING_SUCCESS,
  GET_MATERIAL_TRACKING_FAILURE,
} from "./type"

//  GET_NOTIFICATION_FAILURE
export const getMaterialTrackingRequest = data => ({
  type: GET_MATERIAL_TRACKING_REQUEST,
  data
})

export const getMaterialTrackingSuccess = data => ({
  type: GET_MATERIAL_TRACKING_SUCCESS,
  data
})
export const getMaterialTrackingFailure = data => ({
  type: GET_MATERIAL_TRACKING_FAILURE,
  data
})
