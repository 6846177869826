import {
  GET_HOME_SCREEN_REQUEST,
  GET_HOME_SCREEN_SUCCESS,
  GET_HOME_SCREEN_FAILURE
} from "./type"

//  GET_NOTIFICATION_FAILURE
// export const getFillsiteRequest =(filterModel) => ({
//   type: GET_FILLSITE_AVAILABILITY_REQUEST,
//   // data
//   filterModel

// })
// export const getHomeScreenRequest = (data) => {
//    { type: GET_HOME_SCREEN_REQUEST,data }
// }\
export const getHomeScreenRequest = data => ({
  type: GET_HOME_SCREEN_REQUEST,
  data
})

export const getHomeScreenSuccess = data => ({
  type: GET_HOME_SCREEN_SUCCESS,
  data
})
export const getHomeScreenFailure = data => ({
  type: GET_HOME_SCREEN_FAILURE,
  data
})
