import { Space, Table } from "antd"
import React, { useEffect, useState } from "react"
import Button from "../Button"
import "./style.scss"
import { history } from "../../reduxStore/store"
import { getFillSiteRequest, getFillSiteSuccess } from "../../pages/Contractor/AccountFillSite/redux/action"
import { setFillSiteId } from "../../pages/AuthScreens/LoginScreen/redux/action"

import { connect } from "react-redux"
import { useDispatch } from "react-redux"

import Skeleton from "react-loading-skeleton"

const FillSiteTable = props => {
  const { FillSiteTableData, getFillSiteRequest, isloading, setFillSiteId, getFillSiteSuccess } = props
  const [memberID, setMemberData] = useState()
  const dispatch = useDispatch()


  const info = []
  FillSiteTableData &&
    FillSiteTableData?.map(item => {
      // setMemberID(item.id)
      info.push({
        key: item.owner_id,
        fillSiteName: `${item.owner_name ? item.owner_name : '--'}`,
        truck: item.truck,
        weight: item.weight,
        total_money: `${item.total_money || '--'}`
      })
    })
  const columns = [
    {
      title: "Fill site name:",
      dataIndex: "fillSiteName",
      key: "fillSiteName",
      render: (_, fillSiteName) => (
        <Space size="middle" className="full-name">
          {fillSiteName.fillSiteName}
        </Space>
      )
    },
    {
      title: "Truck:",
      dataIndex: "truck",
      key: "truck",
      render: (_, truck) => <Space size="middle">{truck.truck}</Space>
    },
    {
      title: "Weight:",
      dataIndex: "weight:",
      key: "weight",
      render: (_, weight) => (
        <Space size="middle">{weight?.weight || '--'}</Space>
      )

    },
    // {
    //   title: "Total money:",
    //   dataIndex: "total_money:",
    //   key: "total_money",
    //   render: (_, total_money) => <Space size="middle">{total_money.total_money}</Space>
    // },
    {
      title: "Total Money",
      key: "total_money",
      dataIndex: "total_money",
      render: (_, total_money) => {
        return (
          <div className="role d-flex justify-content-between">
            <p className="mb-0">--</p>
            <a
              style={{ color: "#0a5f59" }}
              onClick={() => {
                const InvoiceId = total_money.key


                dispatch(setFillSiteId(total_money?.key))
              
                history.push('account-summary')
              }}
            >
              See Details
            </a>
          </div>
        )
      }
    }
  ]
  return (
    <>    {isloading ?
      <Table
        rowKey="key"
        pagination={false}
        dataSource={[...Array(5)].map((_, index) => ({
          key: `key${index}`
        }))}
        columns={columns.map(column => {
          return {
            ...column,
            render: function renderPlaceholder() {
              return <Skeleton width={"40%"} />
            }
          }
        })}
      />
      :
      <Table
        className="fill-site-table"
        columns={columns}
        dataSource={info}
        pagination={false}
        scroll={{
          y: 500
        }}
      />
    }
    </>
  )
}

const mapStateToProps = state => {
  return {
    getFillSite: state.FillsiteTable.fillsiteData
  }
}

const mapDispatchToProps = dispatch => ({
  getFillSiteRequest: data => dispatch(getFillSiteRequest(data)),
  setFillSiteId: data => dispatch(setFillSiteId(data)),
  getFillSiteSuccess: data => dispatch(getFillSiteSuccess(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(FillSiteTable)
