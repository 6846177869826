import { toast } from "react-hot-toast"
import { all, call, put, takeLatest } from "redux-saga/effects"
import { BASE_URL } from "../../../../config/app"
import XHR from "../../../../utils/XHR"
import {
  getTeamMembersSuccess,
  getTeamMembersFailure,
  deleteTeamMemberSuccess,
  deleteTeamMemberFailure,
  updateTeamMembersSuccess
} from "./action"
import {
  GET_TEAM_MEMBERS_REQUEST,
  DELETE_TEAM_MEMBER_REQUEST,
  UPDATE_TEAM_MEMBERS
} from "./type"

const TeamMemebrsDataAPI = async id => {
  const URL = `${BASE_URL}team/team_member${id}/`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "GET"
  }
  return XHR(URL, options)
}

function* TeamMemebersData({ id }) {
  try {
    const response = yield call(TeamMemebrsDataAPI, id)
    // if (!id) {
      yield put(getTeamMembersSuccess(response.data))
   
    // } else {
    //   getTeamMembersSuccess(false)
    // }
  } catch (e) {
    const { response } = e

    yield put(getTeamMembersFailure(response.data))
  }
}

// Remove team member
const DeleteTeamMemebrAPI = async member => {
  const URL = `${BASE_URL}team/team_member/${member.id}/`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "DELETE"
  }
  return XHR(URL, options)
}

function* TeamMemeberDelete({ member }) {
  try {
    const response = yield call(DeleteTeamMemebrAPI, member)

    yield put(deleteTeamMemberSuccess())
    toast.success("Team member removed", {
      id: "ds"
    })
  } catch (e) {
    const { response } = e

    yield put(deleteTeamMemberFailure(response.data))
  }
}

function* TeamMemebersUpdate({ members }) {
  try {
    yield put(updateTeamMembersSuccess(members))
  } catch (e) {}
}

export default all([
  takeLatest(GET_TEAM_MEMBERS_REQUEST, TeamMemebersData),
  takeLatest(DELETE_TEAM_MEMBER_REQUEST, TeamMemeberDelete),
  takeLatest(UPDATE_TEAM_MEMBERS, TeamMemebersUpdate)
])
