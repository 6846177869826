import dashboardIcon from "../assets/sidebarImg/dashboard-icon.svg"
import dashboardActiveIcon from "../assets/sidebarImg/dashboard-active-icon.svg"
import requestIcon from "../assets/sidebarImg/request-icon.svg"
import requestActiveIcon from "../assets/sidebarImg/request-active-icon.svg"
import myRequestsIcon from "../assets/sidebarImg/my-requests-icon.svg"
import myRequestsActiveIcon from "../assets/sidebarImg/my-requests-active-icon.svg"
import teamMembersIcon from "../assets/sidebarImg/team-members-icon.svg"
import teamMembersActiveIcon from "../assets/sidebarImg/team-members-active-icon.svg"
import accountOverviewIcon from "../assets/sidebarImg/account-overview-icon.svg"
import accountOverviewActiveIcon from "../assets/sidebarImg/account-overview-active-icon.svg"
import scheduleiconActiveIcon from "../assets/sidebarImg/scheduleicon-active-icon.svg"
import scheduleiconIcon from "../assets/sidebarImg/scheduleicon-icon.svg"
import invoiceImg from "../assets/sidebarImg/invoiceIcon.svg"
import invoiceActive from "../assets/sidebarImg/invoiceIconWhite.svg"
import reportactive from "../assets/sidebarImg/report-active.svg"
import reportwhite from "../assets/sidebarImg/report-white.svg"
import DocumentAgreement from "../assets/sidebarImg/document-agreement.png"
import DocumentAgreementActive from "../assets/sidebarImg/dcument-agreement-active.png"
import activeReport from "../assets/sidebarImg/active-report.png"
import reports from "../assets/sidebarImg/report.png"
import bellActive from "../assets/sidebarImg/bellActive.png"
import bell from "../assets/sidebarImg/bell.png"
import contractor from "../assets/sidebarImg/contractor.png"
import AccountRequest from "../assets/sidebarImg/AccountRequest.svg"
import AccountRequestActive from "../assets/sidebarImg/AccountRequestActive.svg"
import contractorUnactive from "../assets/sidebarImg/contractorUnactive.png"
import mainlogo from "../assets/images/logo-light.png"
import mainLogoDark from "../assets/images/logo-dark.png"
import MaterialTracking from "../assets/sidebarImg/MaterialTracking.svg"
import MaterialTrackingactive from "../assets/sidebarImg/MaterialTrackingactive.svg"










export const Images = {
  contractor,
  contractorUnactive,
  bell,
  bellActive,
  dashboardIcon,
  dashboardActiveIcon,
  requestIcon,
  requestActiveIcon,
  myRequestsIcon,
  myRequestsActiveIcon,
  teamMembersIcon,
  teamMembersActiveIcon,
  accountOverviewIcon,
  accountOverviewActiveIcon,
  scheduleiconActiveIcon,
  scheduleiconIcon,
  invoiceImg,
  invoiceActive,
  reportactive,
  reportwhite,
  DocumentAgreement,
  DocumentAgreementActive,
  activeReport,
  reports,
  mainlogo,
  mainLogoDark,
  AccountRequest,
  AccountRequestActive,
  MaterialTracking,
  MaterialTrackingactive
}
