import {
  POST_SAVE_SEND,
  POST_SAVE_SEND_SUCCESS,
  POST_SAVE_SEND_FAILURE,
  GET_AVAILABILITY_DATA,
  GET_AVAILABILITY_DATA_SUCCESS,
  GET_AVAILABILITY_DATA_FAILURE
} from "./type"

//  GET_NOTIFICATION_FAILURE
export const postSaveSendRequest =(data) => ({
  type: POST_SAVE_SEND,
  data
})
export const postSaveSendRequestSuccess = data => ({
  type: POST_SAVE_SEND_SUCCESS,
  data
})
export const postSaveSendRequestFailure = data => ({
  type: POST_SAVE_SEND_FAILURE,
  data
})

export const getAvailability =(data) => ({
  type: GET_AVAILABILITY_DATA,
  data
})
export const getAvailabilitySuccess = data => ({
  type: GET_AVAILABILITY_DATA_SUCCESS,
  data
})
export const getAvailabilityFailure = data => ({
  type: GET_AVAILABILITY_DATA_FAILURE,
  data
})