import React, { useEffect, useState } from "react"
import HomeLayout from "../../../layout/HomeLayout"
import imgright from "../images/img-right.png"
import imgleft from "../images/img-left.png"

import crane from "../images/crane.png"

import depositscreen from "../images/deposit-screen-bg.png"
import Foter from "../Foter"
import Header from "../Header"
import "./deposit-screen.scss"
import { connect } from "react-redux"
import { isVistingAction } from "../../AuthScreens/LoginScreen/redux/action"
import { history } from "../../../reduxStore/store"

const DepositScreen = props => {
 const { isVisting,isVistingAction}=props
  return (
    <>
      <Header/>
     
      <div className="DepositScreen-main-sction">
        <section
          class="deposit-banner-section"
          //  style="background-image: url({% static "images/deposit-screen-bg.png" %})"
        >
          <div class="row">
            <div class="col-md-12 content-section2">
              <div class="title">Soil Deposite Screen</div>
              <div class="sub-title">
                Vivamus porttitor fringilla mi, vitae pellentesque massa iaculis
                nec. Sed congue neque dolor, vitae tempus lectus hendrerit acen
              </div>
            </div>
          </div>
        </section>
        <section class="deposit-section">
          <div class="container">
            <div class="row content-row">
              <div class="col-md-6 left-details">
                <div class="title">SOIL DEPOSITE SITES</div>
                <div class="sub-title">AES Environmental management system</div>
                <p class="content-para">
                  Donec rutrum rhoncus pharetra. Aliquam aliquam felis augue, id
                  blandit nulla feugiat in. Sed pellentesque mauris nec odio
                  tristique mattis. Integer condimentum non metus ac
                  consectetur. Aliquam ultricies dolor sed elementum dictum.
                  Fusce facilisis tincidunt arcu tristique consectetur. Mauris
                  interdum quam sed diam tincidunt.
                </p>
                <p class="content-para">
                  Sed pellentesque mauris nec odio tristique mattis. Integer
                  condimentum non metus ac consectetur. Aliquam ultricies dolor
                  sed elementum dictum. Fusce facilisis tincidunt arcu tristique
                  consectetur. Mauris interdum quam sed diam tincidunt.
                </p>
                <p class="content-para">
                  Aliquam ultricies dolor sed elementum dictum. Fusce facilisis
                  tincidunt arcu tristique consectetur.
                </p>
              </div>
              <div class="col-md-6 right-details">
                <div class="img-wrapper">
                  <img src={crane} class="img-fluid" />
                  {/* <img src="{% static 'images/crane.png' %}" alt="" class="img-fluid"> */}
                </div>
              </div>
            </div>
            <div class="row card-row">
              <h2 class="text-center">A FEW EASY STEPS</h2>
              <h3 class="text-center">How TO Sign IN</h3>
              <div class="col-md-4 deposit-card-wrapper">
                <div class="deposit-card">
                  <div class="number">Step 1</div>
                  <p class="content-para">
                    Sed pellentesque mauris nec odio tristique mattis. Integer
                    condimentum non metus ac consectetur aliquam.
                  </p>
                </div>
              </div>
              <div class="col-md-4 deposit-card-wrapper">
                <div class="deposit-card">
                  <div class="number">Step 2</div>
                  <p class="content-para">
                    Sed pellentesque mauris nec odio tristique mattis. Integer
                    condimentum non metus ac consectetur aliquam.
                  </p>
                </div>
              </div>
              <div class="col-md-4 deposit-card-wrapper">
                <div class="deposit-card">
                  <div class="number">Step 3</div>
                  <p class="content-para">
                    Sed pellentesque mauris nec odio tristique mattis. Integer
                    condimentum non metus ac consectetur aliquam.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="about-section-deposite">
          <div class="container">
            <div class="row content-row">
              <div class="col-md-6 left-details">
                <div class="title">A FEW EASY STEPS</div>
                <div class="sub-title">You NEED TO CREATE ACCOUNT</div>
                <p class="content-para">
                  Donec rutrum rhoncus pharetra. Aliquam aliquam felis augue, id
                  blandit nulla feugiat in. Sed pellentesque mauris nec odio
                  tristique mattis. Integer condimentum non metus ac
                  consectetur. Aliquam ultricies dolor sed elementum dictum.
                  Fusce facilisis tincidunt arcu tristique consectetur. Mauris
                  interdum quam sed diam tincidunt.
                </p>
              </div>
              <div class="col-md-6 right-details">
                <div class="img-wrapper">
                  <img src={imgright} class="img-fluid"/>
                  {/* <img src="{% static 'images/img-right.png' %}" alt="" class="img-fluid"> */}
                </div>
              </div>
            </div>
            <div class="row content-row">
              <div class="col-md-6 left-details">
                <div class="img-wrapper">
                <img src={imgleft} class="img-fluid"/>
                  
                  {/* <img src="{% static 'images/img-left.png' %}" alt="" class="img-fluid"> */}
                </div>
              </div>
              <div class="col-md-6 right-details right-content-details">
                <div class="title">A FEW EASY STEPS</div>
                <div class="sub-title">
                  USE A DASHBOARD <br /> TO SEE NOTIFICATIONS
                </div>
                <p class="content-para">
                  Donec rutrum rhoncus pharetra. Aliquam aliquam felis augue, id
                  blandit nulla feugiat in. Sed pellentesque mauris nec odio
                  tristique mattis. Integer condimentum non metus ac
                  consectetur. Aliquam ultricies dolor sed elementum dictum.
                  Fusce facilisis tincidunt arcu tristique consectetur. Mauris
                  interdum quam sed diam tincidunt.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section class="application-section">
          <div class="content-section2">
            
            <h3>CHECK OUR AES Environmental soil deposit APPLICATION</h3>
            {/* <a href="{% url "react_app" %}"> */}
            <div 
            onClick={()=>{
              isVistingAction(true)
              setTimeout(() => {
               history.push("/auth/account")
              }, 100);
            }}
            
            class="button">Go To Application</div>
            {/* </a> */}
          </div>
        </section>
      </div>
        <Foter/>
    </>
  )
}
const mapStateToProps = state => ({
 
  isVisting:state.login.isVisting
})
const mapDispatchToProps = dispatch => ({
  
  isVistingAction: (data) => dispatch(isVistingAction(data)),

})
export default connect(mapStateToProps, mapDispatchToProps)(DepositScreen)

