import React, { useEffect } from "react"
import "./style.scss"
import HomeLayout from "../../../layout/HomeLayout"
import uploadfile from "../../../assets/images/uploadfile.svg"
import Button from "../../../components/Button"
import { connect } from "react-redux"
import { history } from "../../../reduxStore/store"
import { getIdRequest } from "../RequestList/redux/action"
import moment from "moment"
import {
  setDepositeSiteID,
  setBookID
} from "../../AuthScreens/LoginScreen/redux/action"
import backIcon from "../../../assets/images/back-icon-circle.svg"
import { Col, Row } from "antd"

const MyRequests = props => {
  const {
    myReguestData,
    setBookID,
    getIdRequest,
    seeMoreID,
    setDepositeSiteID
  } = props

  useEffect(() => {
    if (seeMoreID) {
      getIdRequest(seeMoreID)
    }
  }, [seeMoreID])

  const onSeeAvailability = (item, depReq) => {
    if ((item?.book_id, item?.book_status)) {
      setBookID(item?.book_id)
      history.push("soil-form")
    } else {
      if ((item.id, depReq)) {
        setDepositeSiteID({ id: item.id, depReq: depReq })
        history.push("deposit-site")
      }
    }
  }

  const addressCreate = (adress) => {
    if (adress?.length) {
      return adress?.map(item => (
        <span>
          {`${item?.street ? item?.street + ',' : ''} ${item?.city ? item?.city + ',' : ''} ${item?.province ? item?.province + ',' : ''} ${item?.city_postal_code || ''}`}
        </span>
      ))
    }
  }

  return (
    <>
      <HomeLayout dashboardHeader={true}  title="Soil Deposit Requests" subtitle="View">
        <section className="content Request-section">
          <div style={{flexDirection:'row',display:'flex',width:'100%',justifyContent:'space-between'}}>
            <div className="headerText">
              <div className="back" onClick={() => history.goBack()}>
                <img src={backIcon} alt="" />
              </div>
              <h1>{myReguestData?.soil_request?.aes_po_no}</h1>
            </div>
            <div className="button-wrapper pb-4 mt-4">
                  <Button
                    
                    title="Schedule A Soil Deposit"
                    className="aes-btn"
                    onClick={() => {
                      history.push("/contractor/add-schedule")
                    }}
                  />
            </div>
          </div>
          

          <div className="main-div">
            <div className="card p-0 requestCard">
              <h3>Project Information</h3>
              <Row className="contentStyle">
              <Col sm={12} xs={24} className=" py-0">
                <div className="right-text">
                  <p>
                    Project Name:
                    <span>
                      {myReguestData?.soil_request?.project?.name || ""}
                    </span>
                  </p>
                </div>
              </Col>
              <Col sm={12} xs={24} className=" py-0">
                <div className="right-text">
                  <p>
                    Developer Name:
                    <span>
                      {myReguestData?.soil_request?.project?.developer_name || ""}
                    </span>
                  </p>
                </div>
              </Col>
              <Col sm={12} xs={24} className=" py-0">
                <div className="right-text">
                  <p>
                  Project Start Date:
                    <span>
                      {myReguestData?.soil_request?.project?.start_date || ""}
                    </span>
                  </p>
                </div>
              </Col>
              <Col sm={12} xs={24} className=" py-0">
                <div className="right-text">
                  <p>
                  Originating Location:
                    <span>
                      {myReguestData?.soil_request?.project?.address || ""}
                    </span>
                  </p>
                </div>
              </Col>
              <Col sm={24} xs={24} className=" py-0">
                <div className="right-text">
                  <p>
                  Notes:
                    <span>
                      {myReguestData?.soil_request?.note || ""}
                    </span>
                  </p>
                </div>
              </Col>
              </Row>
            </div>
            <div className="card p-0 requestCard">
              <h3> Material Information</h3>
              {myReguestData?.soil_request?.material_need?.map(
                (item, index) => (
                  <div className="right-text">
                    <p>
                      CSR/Amount:
                      <>
                        <span key={index}>
                          {item.material_name?.name} /{item.material_amount}
                          &nbsp;
                          {item.material_mass_type == 1 ? (
                            "Metric Tonnes"
                          ) : (
                            <span>
                              m<sup>3</sup>
                            </span>
                          )}
                        </span>
                      </>
                    </p>
                  </div>
                )
              )}
              
              <div className="right-text border-0">
                <p>
                  Structural/Non-Structural:
                  {myReguestData?.soil_request?.material_need?.map(
                    (item, index) => (
                      <>
                        <span>
                          {item.material_type == 1
                            ? "Structural"
                            : "Non-Structural"}
                        </span>
                        {index !==
                          myReguestData?.soil_request?.material_need.length -
                          1 && " , "}
                      </>
                    )
                  )}
                </p>
              </div>
            </div>
            <div className="card p-0 requestCard">
              <h3>Supporting Documents</h3>
              <Row className="contentStyle">
              <Col sm={24} xs={24} className=" py-0">
                <div className="right-text">
                  <p>
                    Laboratory File #:
                    <span>
                      {myReguestData?.soil_request?.laboratory_file || ""}
                    </span>
                  </p>
                </div>
              </Col>
              
                <Col sm={24} xs={24} className=" py-0">
                  <div className="right-text">
                  <p>
                  Environmental/Geotechnical/Analytical Data:
                    {myReguestData?.soil_request?.material_need &&
                      myReguestData?.soil_request?.material_need[0]?.environmental
                        .length > 0 &&
                      myReguestData?.soil_request?.material_need[0]?.environmental.map(
                        (file, index) => (
                          <>
                            <a key={index} href={file.environmental} download target="blank">
                              <span style={{ marginRight: 10 }}>
                                <img src={uploadfile} alt="Upload File" />
                              </span>
                            </a>
                          </>
                        )
                      )}
                  </p>
                  </div>
                </Col>
                </Row>
            </div>
            {/* ))} */}
            <div className="wrap-text">
              <div>
                {myReguestData?.fill_site?.csr_acceptance ? (
                  <div className="wrap-text1">
                    <p>{myReguestData?.fill_site?.csr_acceptance}</p>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
            {myReguestData?.fill_site &&
              myReguestData?.fill_site_owner?.map(item => (
                <div className="card requestCard p-0  ">
                  <div className="button-wraper">
                    <h3 className="border-0 m-0 mt-1">
                      {item?.user?.name || ""}
                    </h3>
                    <Button
                      onClick={() =>
                        onSeeAvailability(
                          item,
                          myReguestData?.fill_site?.deposit_request
                        )
                      }
                      title="See Availability"
                      className="aes-btn "
                    />
                  </div>
                  <div className="right-text">
                    <p>
                      Operation hours:
                      <span>{`${item?.owner_schedule[0]?.working_time_from
                        ? item?.owner_schedule[0]?.working_time_from
                        : "--"
                        } - 
                      ${item?.owner_schedule[0]?.working_time_to
                          ? item?.owner_schedule[0]?.working_time_to
                          : "--"
                        }
                       `}</span>
                    </p>
                  </div>
                  <div className="right-text ">
                    <p>
                      Address:
                      {addressCreate(item?.fill_site_info)}
                    </p>
                  </div>
                  <div className="right-text ">
                    <p>
                      Max allowable trucks per day:
                      <span>
                        {item?.owner_schedule[0]?.max_truck_per_day || "--"}
                      </span>
                    </p>
                  </div>
                  <div className="right-text ">
                    <p>
                      Max allowable loads per day:
                      <span>
                        {item?.owner_schedule[0]?.max_load_per_day || "--"}
                      </span>
                    </p>
                  </div>
                  <div className="right-text border-0 ">
                    <p>
                      CSR acceptance:
                      <span>{item?.csr}</span>
                    </p>
                  </div>
                </div>
              ))}
              
          </div>
        </section>
      </HomeLayout>
    </>
  )
}
const mapStateToProps = state => ({
  myReguestData: state.RequestData.updateData,
  seeMoreID: state.login.seeMoreID
})

const mapDispatchToProps = dispatch => ({
  getIdRequest: data => dispatch(getIdRequest(data)),
  setDepositeSiteID: data => dispatch(setDepositeSiteID(data)),
  setBookID: data => dispatch(setBookID(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(MyRequests)
