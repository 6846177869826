import React from "react"
import "./style.scss"
import { Table } from "antd"
import Button from "../../../components/Button"


function OwnerNotification(props) {
  const { date, title, subtitle, tableData, time, onClick, aes_po_no, subMessage, message_receiver } = props;
  return (
    <div className="notification-wrapper">

      <div className="notification-item date-time-wrapper">
        <div className="date">{date}</div>
        <hr />
        <div className="time">{time}</div>
      </div>

      <div className="notification-item notification-body">
        <div className="notification-header">
          <div className="notification-title">
            <h3>{title}</h3>
            {/* {item.message_receiver ?
              <p className="message-text">{item.message_receiver}</p>
              : typeof subMessage === 'object' || subMessage == "Your password Successfully Changed" ?
                <p style={{ display: "none" }}>
                </p>
                : <p className="notification-subtitle">
                  {subMessage}
                </p>
            } */}

            {message_receiver ?
              <p className="message-text">{message_receiver}</p> :
              typeof subMessage === 'object' || subMessage == "Your password Successfully Changed" ?
                <p style={{ display: "none" }}>
                </p>
                : <p className="notification-subtitle">
                  {subMessage}
                </p>}
            {aes_po_no && <h3> My Earth Network PO#: {aes_po_no}</h3>}


            {subtitle && (<p className="notification-subtitle">{subtitle}</p>)}

          </div>
          {title == "Truck details sent" || title == "Booking schedule" ? <Button
            title="Show More"
            className="aes-btn transparent-btn "
            onClick={onClick}
          /> : ""



          }

        </div>


        <div className="notification-body">
          <div className="notification-table">
            {tableData && (<Table dataSource={tableData?.dataSource} columns={tableData?.columns} pagination={false} size="small" />)}

          </div>
        </div>
      </div>
    </div>
  )
}

export default OwnerNotification
