import React, { useState, useEffect, useLayoutEffect, useRef } from "react"
import "./style.scss"
import { useHistory } from "react-router-dom"
import HomeLayout from "../../../layout/HomeLayout"
import filter from "../../../assets/images/filter.svg"
import Button from "../../../components/Button"
import { Calendar, Modal, message } from "antd"
import { DatePicker, Space } from "antd"
import { connect } from "react-redux"

import { Spinner } from "react-bootstrap"
import moment from "moment"
import DashboardModal from "../../../constratarsModal/dashboardModal"
import Filter from "../../../components/Filter"
import OnwerDashboardTable from "../../../components/OnwerDashboardTable"
import OwnerNotification from "../../../components/fillSiteComponents/OwnerNotification"
import {
  getOwnerModaldatasuccess,
  getOwnerNotificationRequest
} from "../OwnerFillSiteDashbord/redux/action"
import Skeleton from "react-loading-skeleton"
import dayjs from "dayjs"
import { scheduleId } from "../../AuthScreens/LoginScreen/redux/action"
import { getScheduleRequest } from "../Schedule/redux/action"

const OwnerFillSiteDashbord = props => {
  const [modalShow, setModalShow] = useState(false)
  const [showStyle, setshowStyle] = useState(false)
  const [selectedModalId, setSelectedModalId] = useState(false)
  const [loadingdate, setLoadingdate] = useState()
  const [filterState, setfilterState] = useState(false)
  const [dateOpen, setDateOpen] = useState(false)
  const [pageLoader, setPageLoader] = useState(true)
  const [open, setOpen] = useState()
  const [openTable, setOpenTable] = useState(-1)

  const ref = useRef()
  const history = useHistory()

  const {
    notificationData,
    detail,
    requesting,
    error,
    getOwnerNotificationRequest,
    getModaldatasuccess,
    getNotfiactionModalData,
    requestingmodal,
    detailRequest,
    scheduleId,
    getScheduleRequest
  } = props


  useEffect(() => {
    const fetchNotifications = () => {
      getOwnerNotificationRequest("")
    };

    // Initial fetch
    fetchNotifications();

    // Set up interval to fetch notifications every 10 seconds
    const intervalId = setInterval(fetchNotifications, 10000);

    // Clean up interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  const handleShowDetail = id => {
    getOwnerNotificationRequest(`${id}/`, "id")
    setModalShow(true)
    // setshowStyle(true)
  }
  const dateChange = (date, dateString, type) => {
    let filterModel = {
      fromDate: "",
      toDate: ""
    }

    setfilterState(false)
    switch (type) {
      case "date":
        filterModel.fromDate = dateString

        getOwnerNotificationRequest(
          `?start_date=${dateString}&end_date=${filterModel.toDate}`
        )
        setLoadingdate(filterModel)
        break
      case "week":
        filterModel.fromDate = dateString

        filterModel.toDate = dayjs(dateString)
          .add(7, "day")
          .format("YYYY-MM-DD")

        const dateStr = `?start_date=${filterModel.fromDate}&end_date=${filterModel.toDate}`

        getOwnerNotificationRequest(dateStr)
        setLoadingdate(filterModel)
        break
      case "month":
        const arrDate = dateString.split("-")
        filterModel.fromDate = date.year() + "-" + arrDate[1] + "-01"
        filterModel.toDate =
          date.year() + "-" + arrDate[1] + "-" + date.daysInMonth()
        getOwnerNotificationRequest(
          `?start_date=${filterModel.fromDate}&end_date=${filterModel.toDate}`
        )
        setLoadingdate(filterModel)
        break
      default:
        getOwnerNotificationRequest()
        break
    }
  }

  const parsedData =
    notificationData &&
    notificationData?.map(item => {
      let messageObj = item.message
      try {
        messageObj = JSON.parse(item.message.replace(/'/g, '"'))
      } catch (error) { }
      return { ...item, message: messageObj }
    })

  return (
    <>
      <HomeLayout dashboardHeader={true}>
        <section className="content OwnerDashbord">
          <div className="row">
            <div className="col-md-6">
              <h1>Notification Board</h1>
            </div>
            <div className="col-md-6 headerFilter">
              {/* <Filter
                // isTypeClick={setTypeClick}
                setCalenderState={setfilterState}
                onClickOutside={() => setfilterState(!filterState)}
                open={open}
                maskClosable={false}
                setOpen={setOpen}
                onDateChange={dateChange}
                onClick={() => setfilterState(!filterState)}
                calenderState={filterState}
                dataData={loadingdate}
              /> */}
              {/* <Button title="Upload Scale Data" className="aes-btn green-btn" /> */}
            </div>

            {/* ):null} */}
          </div>
          {/* body */}

          {requesting ? (
            [1, 2, 3, 4, 5, 6, 7].map(item => (
              <div className="notification-wrapper">
                <div className="notification-item date-time-wrapper">
                  <Skeleton width="100%" />
                  <hr />
                  <Skeleton width="100%" />
                </div>

                <div className="notification-item notification-body">
                  <div className="notification-header ">
                    <div className="w-50">
                      <Skeleton width="100%" />
                      <div className="w-50">
                        <Skeleton width="100%" />
                      </div>
                    </div>
                    <div className="w-25">
                      <Skeleton width="100%" />
                    </div>
                  </div>

                  {/* <div className="notification-body">
                    <div className="notification-table">
                    {tableData && (<Table dataSource={tableData?.dataSource} columns={tableData?.columns}  pagination={false} size="small"/>)}
        
                    </div>
                  </div> */}
                </div>
              </div>
            ))
          ) : parsedData && parsedData?.length > 0 ? (
            parsedData.map(item => {
              // console.log("item",item);
              return (
                <OwnerNotification
                  date={moment(item.created_at).format("DD MMM")}
                  time={moment(item.created_at).format("LT")}
                  title={item.subject}
                  subMessage={item.message}
                  subtitle={item.message.message}
                  aes_po_no={item.message.aes_po_no}
                  message_receiver={item.message_receiver}
                  onClick={() => {
                    history.push("ScheduleDetail")

                    // scheduleId(item.message.request_availability)
                    // getScheduleRequest(item.message.request_availability)
                    scheduleId(item.message.request_availability)
                  }
                  }
                // setIndex
                // handleShowDetail(item.id)
                // setSelectedModalId(item.id)

                />
              )
            })
          ) : (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "70vh"
              }}
            >
              <p>No Data Found</p>
            </div>
          )}
        </section>
        {modalShow && (
          <DashboardModal
            itemId={selectedModalId}
            modalShow={modalShow}
            setModalShow={setModalShow}
            notification={detail}
          />
        )}
      </HomeLayout>
    </>
  )
}

const mapStateToProps = state => ({
  notificationData: state.OwnerNotification.data,
  detail: state.OwnerNotification.detail,
  requesting: state.OwnerNotification.requesting,
  error: state.OwnerNotification.error,
  detailRequest: state.OwnerNotification.detailRequest
})

const mapDispatchToProps = dispatch => ({
  getOwnerNotificationRequest: (filterModel, id) => {
    dispatch(getOwnerNotificationRequest(filterModel, id))
  },
  scheduleId: (data) => {
    dispatch(scheduleId(data))
  },
  getScheduleRequest: (data) => {
    dispatch(getScheduleRequest(data))
  }
  // getOwnerModaldatasuccess: (filterModel, id) => {
  //   dispatch(getOwnerModaldatasuccess(filterModel, id))
  // }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OwnerFillSiteDashbord)
