import React, { useState, useEffect, useRef } from "react"
import { Modal, Upload, Form } from "antd"
import pdfupload2 from "../../../assets/images/pdfupload2.svg"
import uploadfile from "../../../assets/images/uploadfile.svg"
import uploadImg from "../../../assets/images/uploadImg.png"
import uploadImgActive from "../../../assets/images/uploadImgActive.png"
import {  useDispatch, useSelector } from "react-redux"
import Button from "../../Button"
import AeSelect from "../../AeSelect"
import { PostReportRequest, getCategryRequest } from "../../../pages/Admin/Reports/redux/action"
import { getAdminFillRequest } from "../../../pages/Admin/RequestConfirmPage/redux/action"
import TextArea from "../../TextArea"
const CreateNewReport = props => {
    const { newReportModalShow, setNewReportModalShow } = props
    const [selectedFile, setSelectedFile] = useState([])
    const [Category, setCategory] = useState([])
    const [fillSite, setfillSite] = useState([])
    const [selectefillSite, setSelectefillSite] = useState([])
    const [selectlCategory, setSelectlCategory] = useState([])
    const [note, setNote] = useState("")
    const [fileList, setFileList] = useState([])
    const [selectedImage, setSelectedImage] = useState([])
    const handleCancel = () => {
        setNewReportModalShow(false)
        setNote("")
        setSelectedImage([])
        setSelectedFile([])
        setSelectlCategory("")
        setSelectefillSite([])
        formRef.current?.resetFields();
    }

    const dispatch = useDispatch()
    const submit = () => {
        const formData = new FormData();
        if (fileList) {
            for (let key in fileList) {
                fileList && formData.append(`file${key}`, fileList[key]?.originFileObj)
            }
        }

        if (selectedImage) {
            for (let key in selectedImage) {
                selectedImage && formData.append(`image${key}`, selectedImage[key]?.originFileObj)
            }
        }
        if (selectedImage || selectedFile) {
            formData.append("report_category", selectlCategory);
            formData.append("note", note);
        }
        formData.append("user", selectefillSite);
        dispatch(PostReportRequest(formData, handleCancel));
    };
    useEffect(() => {
        dispatch(getCategryRequest())
        dispatch(getAdminFillRequest())
    }, [])
    const { categoryData, Postrequesting } = useSelector(
        state => state.Report
    )
    const { getAdminFillSite, } = useSelector(
        state => state.SoilConfirm
    )
    useEffect(() => {
        if (categoryData) {
            const category_array = []
            categoryData?.map((a, b) => {
                category_array.push({ value: a.id, label: a.name })
            })
            setCategory(category_array)
        }
        if (getAdminFillSite) {
            const fill_site_array = []
            getAdminFillSite?.map((a, b) => {
                fill_site_array.push({ value: a.id, label: a.name })
            })
            setfillSite(fill_site_array)
        }
    }, [categoryData,getAdminFillSite])
    const { Dragger } = Upload
    const ubloadFile = {
        onRemove: file => {
            const index = fileList.indexOf(file)
            const newFileList = fileList.slice()
            newFileList.splice(index, 1)
            setFileList(newFileList)
        },
        beforeUpload: () => false,
        onChange: ({ file, fileList }) => {
            setFileList(fileList)
        },
        multiple: true
    }
    const ubloadImg = {
        onRemove: file => {
            const index = selectedImage.indexOf(file)
            const newFileList = selectedImage.slice()
            newFileList.splice(index, 1)
            setSelectedImage(newFileList)
        },
        beforeUpload: () => false,
        onChange: ({ file, fileList }) => {
            setSelectedImage(fileList)
        },
        multiple: true
    }
    const formRef = useRef(null)
    return (
        <>
            <Modal
                title={` `}
                open={newReportModalShow}
                onCancel={handleCancel}
                className="UploadDocoment-modal"
                cancelButtonProps={{ style: { display: "none" } }}
                footer={null}>
                <div className="modal-div">
                    <h3>Create new report</h3>
                    <h4>Category</h4>
                    <Form
                        ref={formRef}
                        name="control-ref"
                        labelWrap={true}
                        layout="vertical"
                        onFinish={submit}
                    >
                        <Form.Item
                            name="category"
                            rules={[
                                {
                                    required: true,
                                    message: "This field may not be blank.",
                                },
                            ]}
                        >
                            <AeSelect
                                placeholder={"Weather Issue"}
                                options={Category}
                                onChange={(e) => setSelectlCategory(e)}
                            />
                        </Form.Item>
                        <h4>Fill Site</h4>
                        <Form.Item
                            name="fillSite"
                            rules={[
                                {
                                    required: true,
                                    message: "This field may not be blank.",
                                },
                            ]}
                        >
                            <AeSelect
                                placeholder={"Fill site"}
                                options={fillSite}
                                onChange={(e) => setSelectefillSite(e)}
                            />
                        </Form.Item>
                        <h4>Notes</h4>
                        <Form.Item
                            name="notes"
                            rules={[
                                {
                                    required: true,
                                    message: "This field may not be blank.",
                                },
                            ]}
                        >
                            <TextArea
                                value={note}
                                onChange={(e) => setNote(e.target.value)}
                                placeholder="Notes"
                                autoSize={{
                                    minRows: 3,
                                    maxRows: 5,
                                }}
                            />
                        </Form.Item>
                        <Form.Item
                            name="Image"
                            rules={[
                                {
                                    required: true,
                                    message: "This field may not be blank.",
                                },
                            ]}
                        >
                            <Dragger {...ubloadImg} fileList={selectedImage} accept="image/png, image/jpeg, image/svg+xml">
                                <div className="uplod-file mt-1 border">
                                    <img className="pdfupload2"
                                        src={selectedImage.length ? uploadImgActive : uploadImg}
                                    />
                                    <div>
                                        <a className="attch">{"Attach Images"}</a>
                                    </div>
                                </div>
                            </Dragger>
                        </Form.Item>
                        <Form.Item
                            fileList={fileList}
                            name="pdf"
                            rules={[
                                {
                                    required: true,
                                    message: "This field may not be blank.",
                                },
                            ]}
                        >
                            <Dragger {...ubloadFile} accept=" application/pdf">
                                <div className="uplod-file mt-1 border">
                                    <img className="pdfupload2"
                                        src={selectedFile.length ? uploadfile : pdfupload2}
                                    />
                                    <div>
                                        <a className="attch">{"Upload  Report"}</a>
                                    </div>
                                </div>
                            </Dragger>
                        </Form.Item>
                        <div className="btn-wraper">
                            <Button
                                title="Create report"
                                className="aes-btn green-btn  w-50"
                                showSpinner={Postrequesting}
                            />
                        </div>
                    </Form>

                </div>
            </Modal>
        </>
    )
}
export default CreateNewReport;