import {
  POST_REQUEST_SOIL_DEPOSIT,
  POST_REQUEST_SOIL_DEPOSIT_SUCCESS,
  POST_REQUEST_SOIL_DEPOSIT_FAILURE,
  GET_MATERIALS_REQUEST,
  GET_MATERIALS_SUCCESS,
  GET_MATERIALS_FAILURE,
  SUCCESS_GETTING_ADMIN_PROJECT,
  ERROR_GETTING_ADMIN_PROJECT,
  GET_ADMIN_PROJECT
} from "./type"

// POST_SOIL_DEPOSIT_REQUEST
export const soilDepositRequest = (data,clearData) => ({
  type: POST_REQUEST_SOIL_DEPOSIT,
  data,
  clearData
})

export const soilDepositRequestSuccess = data => ({
  type: POST_REQUEST_SOIL_DEPOSIT_SUCCESS,
  data
})

export const soilDepositRequestFailure = data => ({
  type: POST_REQUEST_SOIL_DEPOSIT_FAILURE,
  data
})

// GET_MATERIALS_REQUEST
export const getMaterialRequest = () => ({
  type: GET_MATERIALS_REQUEST
 
})

export const getMaterialSuccess = data => ({
  type: GET_MATERIALS_SUCCESS,
  data
})
export const getMaterialFailure = data => ({
  type: GET_MATERIALS_FAILURE,
  data
})


export const getAdminProject = data => {
  return { type: GET_ADMIN_PROJECT, data }
}

export const getAdminProjectSuccess = data => ({
  type: SUCCESS_GETTING_ADMIN_PROJECT,
  data
})
export const getAdminProjectFailure = data => ({
  type: ERROR_GETTING_ADMIN_PROJECT,
  data
})
