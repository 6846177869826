import {
  POST_OWNER_TEAM_MEMBER,
  POST_OWNER_TEAM_MEMBER_SUCCESS,
  POST_OWNER_TEAM_MEMBER_FAILURE
} from "./type"

// POST_PAYMENT_INFORMATION
export const addOwnerTeamMember = data => ({
  type: POST_OWNER_TEAM_MEMBER,
  data
})

export const addOwnerTeamMemberSuccess = data => ({
  type: POST_OWNER_TEAM_MEMBER_SUCCESS,
  data
})

export const addOwnerTeamMemberFailure = data => ({
  type: POST_OWNER_TEAM_MEMBER_FAILURE,
  data
})
