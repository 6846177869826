
import { toast } from "react-hot-toast"
import { all, call, put, takeLatest } from "redux-saga/effects"
import { BASE_URL } from "../../../../config/app"
import XHR from "../../../../utils/XHR"
import {
  getTruckListSuccess,
  getTruckListFailure,
  getIdSuccess,
  getIdFailure,

 
} from "./action"
import {
  GET_TRUCK_LIST_REQUEST,
  GET_ID_TRUCK,
  
} from "./type"

const MyTruckAPI = async (data) => {
  const URL = `${BASE_URL}material/trucks/`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "GET"
  }
  return XHR(URL, options)
}

function* MyTruckData({data}) {
  try {
    const response = yield call(MyTruckAPI,data)
    yield put(getTruckListSuccess(response.data))
    
    // if(data){
    //   yield put(getIdData(response.data))
    // }
   
  } catch (e) {
    const { response } = e
    yield put(getTruckListFailure(response.data))
  }
}

// Remove team member

const getIdDataAPI = async member => {
  const URL = `${BASE_URL}material/trucks/${member}/`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "GET"
  }
  return XHR(URL, options)
}

function* getIdData({ member }) {
  try {
    const response = yield call(getIdDataAPI, member)
  
    yield put(getIdSuccess(response.data))
    // toast.success("sucessfully remove", {
    //   id: "ds"
    // })
  } catch (e) {
    const { response } = e

    yield put(getIdFailure(response.data))
  }
}
export default all([
  takeLatest(GET_TRUCK_LIST_REQUEST, MyTruckData),
  takeLatest(GET_ID_TRUCK, getIdData),


])
