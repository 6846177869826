import {
  POST_REQUEST_ADD_TRUCK,
  POST_ADD_TRUCK_SUCCESS,
  POST_ADD_TRUCK_FAILURE
} from "./type"

// POST_PAYMENT_INFORMATION
export const addTruckRequest = data => ({
  type: POST_REQUEST_ADD_TRUCK,
  data
})

export const addTruckSuccess = data => ({
  type: POST_ADD_TRUCK_SUCCESS,
  data
})

export const addTruckFailure = data => ({
  type: POST_ADD_TRUCK_FAILURE,
  data
})
