import {
  GET_Admin_NOTIFICATION_REQUEST,
  GET_Admin_NOTIFICATION_SUCCESS,
  DELETE_NOTIFICATION_REQUEST,
  DELETE_NOTIFICATION_SUCCESS,
  POST_ADMIN_REQUEST,
  POST_ADMIN_SUCCESS,
  POST_ADMIN_FAILED,

} from "./type"
const initialState = {
  NotificationData: false,
  requesting: false,
  error: false,
  PostAdminrequesting: false,
  categoryData: false,
  contractorData: false,
  delRequesting:false,
  PostRequesting:false,
  Notification:false,

}
export default (state = initialState, action) => {
  switch (action.type) {
    case GET_Admin_NOTIFICATION_REQUEST:
      return {
        ...state,
        requesting: true
      }
    case GET_Admin_NOTIFICATION_SUCCESS:
      return {
        ...state,
        requesting: false,
        NotificationData: action.data
      }
    case DELETE_NOTIFICATION_REQUEST:
      return {
        ...state,
        delRequesting: true
      }
    case DELETE_NOTIFICATION_SUCCESS:
      return {
        ...state,
        delRequesting: false,
        
      }
      case   POST_ADMIN_REQUEST:
            return {
                ...state,
                PostAdminrequesting: true
            }
        case POST_ADMIN_SUCCESS:
            return {
                ...state,
                PostAdminrequesting: false,
               
            }
            case POST_ADMIN_FAILED:
            return {
                ...state,
                PostAdminrequesting:false,
                error: action.data
            }

    default:
      return state
  }
}