import {
  GET_ADMIN_REQUEST_APPROVED_REQUEST,
  GET_ADMIN_REQUEST_APPROVED_SUCCESS,
  GET_ADMIN_REQUEST_APPROVED_FAILURE,
  PUT_ADMIN_SOIL_REQUEST,
  PUT_ADMIN_SOIL_SUCCESS,
  PUT_ADMIN_SOIL_FAILURE
} from "./type"

const initialState = {
  AdminApprovedRequestdata: [],
  requesting: false,
  error: false,
  putSoildata:false,
  putRequesting:false

}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ADMIN_REQUEST_APPROVED_REQUEST:
      return {
        ...state,
        requesting: true
      }
    case GET_ADMIN_REQUEST_APPROVED_SUCCESS:
      return {
        ...state,
        requesting: false,
        AdminApprovedRequestdata: action.data
      }
    case GET_ADMIN_REQUEST_APPROVED_FAILURE:
      return {
        ...state,
        requesting: false,
        error: action.data
      }
    case PUT_ADMIN_SOIL_REQUEST:
      return {
        ...state,
        requesting: true
      }
    case PUT_ADMIN_SOIL_SUCCESS:
      return {
        ...state,
        putRequesting: false,
        putRequestdata: action.data
      }
    case PUT_ADMIN_SOIL_FAILURE:
      return {
        ...state,
        putRequesting: false,
        error: action.data
      }
    default:
      return state
  }
}
