import {
  ADMIN_DASHBOARD_USERLIST,
  ADMIN_DASHBOARD_USERLIST_FAILURE,
  ADMIN_DASHBOARD_USERLIST_SUCCESS,
  ADMIN_DASHBOARD_UPDATE_USER,
  ADMIN_DASHBOARD_UPDATE_USER_SUCCESS,
  ADMIN_DASHBOARD_UPDATE_USER_FAILURE,
  USER_ACTIVE_REQUEST,
  USER_ACTIVE_SUCCESS,
  GET_MATERIAL_REQUEST,
  GET_MATERIAL_SUCCESS,
  GET_MATERIAL_FAILURE,

  DELETE_FIILL_REQUEST,
  DELETE_FIILL_SUCCESS
} from "./type"

export const getAdmindashboardList = data => ({
  type: ADMIN_DASHBOARD_USERLIST,
  data
})

export const getAdminDashboardListSuccess = data => ({
  type: ADMIN_DASHBOARD_USERLIST_SUCCESS,
  data
})

export const getAdminDashboardListFailure = data => ({
  type: ADMIN_DASHBOARD_USERLIST_FAILURE,
  data
})

export const getAdmindashboardUpdateUser = (id, data) => ({
  type: ADMIN_DASHBOARD_UPDATE_USER,
  id,
  data
})

export const getAdminDashboardUpdateUserSuccess = data => ({
  type: ADMIN_DASHBOARD_UPDATE_USER_SUCCESS,
  data
})

export const getAdminDashboardUpdateUserFailure = data => ({
  type: ADMIN_DASHBOARD_UPDATE_USER_FAILURE,
  data
})

export const userActiveRequest = (data, id, showSuccessToast) => ({
  type: USER_ACTIVE_REQUEST,
  data,
  id,
  showSuccessToast
})
export const userActiveSuccess = data => ({
  type: USER_ACTIVE_SUCCESS,
  data
})

export const getMaterialRequest = () => ({
  type: GET_MATERIAL_REQUEST
})
export const getMaterialSuccess = data => ({
  type: GET_MATERIAL_SUCCESS,
  data
})
export const getMaterialFailure = data => ({
  type: GET_MATERIAL_FAILURE,
  data
})
export const deleteFillRequest= id => ({
  type: DELETE_FIILL_REQUEST,
  id
})

export const deleteFillSuccess = data => ({
  type:DELETE_FIILL_SUCCESS,
  data
})