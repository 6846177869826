// POST TEAM MEMBER
export const POST_REQUEST_ADD_SCHEDULE = "POST_REQUEST_ADD_SCHEDULE"
export const POST_ADD_SCHEDULE_SUCCESS = "POST_ADD_SCHEDULE_SUCCESS"
export const POST_ADD_SCHEDULE_FAILURE = "POST_ADD_SCHEDULE_FAILURE"


export const GET_ADMIN_SCHEDULE = "GET_ADMIN_SCHEDULE"
export const SUCCESS_GETTING_ADMIN_SCHEDULE = "SUCCESS_GETTING_ADMIN_SCHEDULE"
export const ERROR_GETTING_ADMIN_SCHEDULE = "ERROR_GETTING_ADMIN_SCHEDULE"

