import {
  GET_FILLSITE_TRUCKS_REQUEST,
  GET_FILLSITE_TRUCKS_SUCCESS,
  GET_FILLSITE_TRUCKS_FAILURE,
  POST_UPLOAD_INVOICE_REQUEST,
  POST_UPLOAD_INVOICE_SUCCESS,
  POST_UPLOAD_INVOICE_FAILURE
} from "./type"
const initialState = {
  responseData: false,
  loading: false,
  responseError: false,
  postData: false,
  postLoading: false,
  postError: false
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
  switch (action.type) {
    case GET_FILLSITE_TRUCKS_REQUEST:
      return {
        ...state,
        loading: true
      }
    case GET_FILLSITE_TRUCKS_SUCCESS:
      return {
        ...state,
        loading: false,
        responseData: action.data
      }
    case GET_FILLSITE_TRUCKS_FAILURE:
      return {
        ...state,
        loading: false,
        responseError: action.data
      }
    case POST_UPLOAD_INVOICE_REQUEST:
      return {
        ...state,
        postLoading: true
      }
    case POST_UPLOAD_INVOICE_SUCCESS:
      return {
        ...state,
        postLoading: false,
        postData: action.data
      }
    case POST_UPLOAD_INVOICE_FAILURE:
      return {
        ...state,
        postLoading: false,
        postError: action.data
      }

    default:
      return state
  }
}
