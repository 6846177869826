import { all, call, put, takeLatest } from "redux-saga/effects"
import { BASE_URL } from "../../../../config/app"
import XHR from "../../../../utils/XHR"
import {
  getNotificationSuccess,
  getNotificationFailure,
  getModaldatasuccess,
  getNotificationRequest,
  getTruckListSuccess
} from "./action"
import {
  GET_NOTIFICATION_REQUEST,
  GET_TRUCK_REQUEST
  
  
} from "./type"

const NotificationDataAPI =  (filterModel,id) => {

  let URL = `${BASE_URL}notification/notification/${filterModel}`
 

  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "GET"
  }
  return XHR(URL, options)
}

function* NotificationData({ filterModel, id}) {

  
  try {
    const response = yield call(NotificationDataAPI,filterModel)

    if(id){
      yield put(getModaldatasuccess(response.data))
    } else {
      yield put(getNotificationSuccess(response.data))
    }
 
  } catch (e) {
    const { response } = e
    yield put(getNotificationFailure(response.data))
  }
}

const TruckListDataAPI =  (data) => {
  console.log("data",data);
  let URL = `${BASE_URL}api/v1/contractor/get_truck/${data}`
 

  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "GET"
  }
  return XHR(URL, options)
}

function* TruckListData({ data}) {

  
  try {
    const response = yield call(TruckListDataAPI,data)
    yield put(getTruckListSuccess(response.data))
 
  } catch (e) {
    const { response } = e
    // yield put(getNotificationFailure(response.data))
  }
}


export default all([


  takeLatest(GET_NOTIFICATION_REQUEST, NotificationData),
  takeLatest(GET_TRUCK_REQUEST, TruckListData),


])
