/* eslint-disable import/no-anonymous-default-export */
import {
  GET_INVOICE_DATA,
  GET_INVOICE_DATA_SUCCESS,
  GET_INVOICE_DATA_FAILURE,
  GET_SINGLE_INVOICE_DATA_FAILURE,
  GET_SINGLE_INVOICE_DATA_SUCCESS,
  GET_SINGLE_INVOICE_DATA,
  PATCH_INVOICE_STATUS,
  PATCH_INVOICE_STATUS_SUCCESS,
  PATCH_INVOICE_STATUS_FAILURE
} from "./type"

const initialState = {
  invoiceData: false,
  requesting: false,
  singleInvoiceData: false,
  error: false,

  patchInvoice: false,
  patchInvoiceLoad: false,
  patchInvoiceError: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_INVOICE_DATA:
      return {
        ...state,
        requesting: true
      }
    case GET_INVOICE_DATA_SUCCESS:
      return {
        ...state,
        requesting: false,
        invoiceData: action.data
      }

    case GET_INVOICE_DATA_FAILURE:
      return {
        ...state,
        requesting: false,
        error: action.data
      }
    case GET_SINGLE_INVOICE_DATA:
      return {
        ...state,
        requesting: true
      }
    case GET_SINGLE_INVOICE_DATA_SUCCESS:
      return {
        ...state,
        requesting: false,
        singleInvoiceData: action.data
      }

    case GET_SINGLE_INVOICE_DATA_FAILURE:
      return {
        ...state,
        requesting: false,
        error: action.data
      }
      case PATCH_INVOICE_STATUS:
      return {
        ...state,
        patchInvoiceLoad: true
      }
    case PATCH_INVOICE_STATUS_SUCCESS:
      return {
        ...state,
        patchInvoiceLoad: false,
        patchInvoice: action.data
      }

    case PATCH_INVOICE_STATUS_FAILURE:
      return {
        ...state,
        patchInvoiceLoad: false,
        patchInvoiceError: action.data
      }
    default:
      return state
  }
}
