import { all, call, put, takeLatest } from "redux-saga/effects"
import { BASE_URL } from "../../../../config/app"
import XHR from "../../../../utils/XHR"
// types
import {
  GET_PROFILE_FILL_DATA,
  GET_PROFILE_FILL_DATA_SUCCESS,
  GET_PROFILE_FILL_DATA_FAILURE,
  PUT_FILL_PROFILE_DATA,
  PUT_PROFILE_FILL_DATA_SUCCESS,
  PUT_PROFILE_FILL_DATA_FAILURE
} from "./type"
// actions
import {
  getFillProfileDataSuccess,
  getFillProfileDataFailure,
  putFillProfileDataSuccess,
  putFillProfileDataFailure,
  getFillProfileDataRequest
} from "./action"
import toast from "react-hot-toast"
import { history } from "../../../../reduxStore/store"

async function getProfileDataAPI() {
  const URL = `${BASE_URL}api/v1/fill_site/profile/`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "GET"
  }
  return XHR(URL, options)
}

function* getProfileData() {
  try {
    const response = yield call(getProfileDataAPI)
    yield put(getFillProfileDataSuccess(response.data))
  } catch (e) {
    const { response } = e
    yield put(getFillProfileDataFailure(response?.data))
  }
}

async function putProfileDataAPI(data, id) {
  const URL = `${BASE_URL}api/v1/fill_site/profile/${id}/`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "patch",
    data
  }

  return XHR(URL, options)
}

function* putProfileData({ data, id }) {
  try {
    const response = yield call(putProfileDataAPI, data, id)
    yield put(putFillProfileDataSuccess(response.data))
    yield put(getFillProfileDataRequest())
    toast.success("success", {
      id: "card"
    })
    //  yield put (history.push("dashboard"))
  } catch (e) {
    const { response } = e
    yield put(putFillProfileDataFailure(response?.data))
    // toast.error("Site field email may not be blank")
  }
}

export default all([
  takeLatest(GET_PROFILE_FILL_DATA, getProfileData),
  takeLatest(PUT_FILL_PROFILE_DATA, putProfileData)
])
