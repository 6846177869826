import React from "react"
import Input from "../Input"
import searchIcon from "../../assets/images/search-icon.svg"
import "./style.scss"

const Search = ({ placeholder = "", onChange, value, onClick }) => {
  return (
    <div className="search-wrapper">
      <div className="search-field" id="basic-addon1">
        <div className="search-icon">
          <img src={searchIcon} alt="" />
        </div>
        <Input
          type="text"
          placeholder={placeholder || "Search"}
          showIcon={true}
          onClick={onClick}
          onChange={onChange}
          value={value}
          onKeyPress={e => {
            e.key === "Enter" && e.preventDefault()
          }}
        />
      </div>
    </div>
  )
}

export default Search
