import React, { useRef } from "react"
import uploadIcon from "../../assets/images/pdfupload2.svg"
import "./style.scss"

const UploadFile = props => {
  const fileRef = useRef()

  return (
   
    <div className="upload-file-wrapper">
      <div className="upload-file">
        <div className="icon-wrapper">
          <img
            src={uploadIcon}
            alt="upload"
            onClick={() => fileRef.current.click()}
          />
          <div className="sample" onClick={() => fileRef.current.click()}>
            {props.title}
          </div>
          <input
            type="file"
            name="file"
            ref={fileRef}
            accept=".pdf"
            onChange={props.onChange}
            hidden
          />
          <div>
            <p style={{ margin: "0px", marginLeft: "5px" }}>
              {props.selectedFile}
            </p>
          </div>
        </div>
      </div>
    
    </div>
    
 
  )
}

export default UploadFile
