import { Space, Table } from "antd"
import React from "react"
import Button from "../Button"
import moment from "moment"
import { history } from "../../reduxStore/store"
import { useDispatch } from "react-redux"
import { setInvoiceId } from "../../pages/AuthScreens/LoginScreen/redux/action"
import Skeleton from "react-loading-skeleton"

const InvoicesTable = props => {
  const { InvoicesTableData, isloading } = props
  const dispatch = useDispatch()
  const capFirstL = string => {
    return string.charAt(0).toUpperCase() + string.slice(1)
  }

  const info = []
  InvoicesTableData &&
    InvoicesTableData?.map(item => {
      info.push({
        key: item.id,
        invoice_number: ` ${item.invoices_number}`,
        fillSiteName: `${item.fill_site}`,
        invoice_status: `${item.invoice_status}`,
        payment_due_date: `${moment(item.payment_due_date).format(
          "MMM DD, YYYY "
        )}`
      })
    })

  const columns = [
    {
      title: "Invoice number",
      dataIndex: "invoice_number",
      key: "invoice_number",
      render: (_, invoice_number) => (
        <Space size="middle" className="invoice-number">
          {invoice_number.invoice_number}
        </Space>
      )
    },
    {
      title: "Fill site name",
      dataIndex: "fillSiteName",
      key: "fillSiteName",
      render: (_, fillSiteName) => (
        <Space size="middle">{fillSiteName.fillSiteName}</Space>
      )
    },
    {
      title: "Invoice status",
      dataIndex: "invoice_status",
      key: "invoice_status",
      render: (_, invoice_status) => (
        <Space size="middle" className="paid ">
          {" "}
          {/* add waiting class */}
          {capFirstL(invoice_status.invoice_status)}
        </Space>
      )
    },
    {
      title: "Payment due date",
      key: "payment_due_date",
      dataIndex: "payment_due_date",
      render: (_, payment_due_date) => {
        return (
          <div className="role d-flex  align-items-center justify-content-between">
            <p className="mb-0">{payment_due_date.payment_due_date}</p>
            <div className="button-wrapper">
              <Button
                title="See Invoices"
                className="aes-btn transparent-btn"
                onClick={() => {
                  // getInvoicesRequest(`${payment_due_date.key}/`)
                  dispatch(setInvoiceId(payment_due_date?.key))
                  history.push("invoice-Details")
                }}
              />
              {/* <Button
                title="See Details"
                className="aes-btn transparent-btn"
                onClick={() => {
                  // getInvoicesRequest(`${payment_due_date.key}/`)
                }}
              /> */}
            </div>
          </div>
        )
      }
    }
  ]
  return (
    <>
    {isloading ?
     <Table
     rowKey="key"
     pagination={false}
     dataSource={[...Array(5)].map((_, index) => ({
       key: `key${index}`
     }))}
     columns={columns.map(column => {
       return {
         ...column,
         render: function renderPlaceholder() {
           return <Skeleton width={'40%'}/>
         }
       }
     })}
   />
      :
      <Table
      className="fill-site-table invoices-table"
      columns={columns}
      dataSource={info}
      pagination={false}
    />
    }
    </>

  )
}

export default InvoicesTable
