import React, { useEffect, useState } from "react"
import backIcon from "../../../assets/images/back-icon-circle.svg"
import Button from "../../../components/Button"
import Input from "../../../components/Input"
import HomeLayout from "../../../layout/HomeLayout"
import { history } from "../../../reduxStore/store"
import useForm from "../../../utils/useForm"
import validator from "../../../utils/validation"
import {
  getOwnerTeamMember,
  getOwnerTeamMemberFailure,
  putOwnerTeamMember
} from "./redux/action"
import { connect } from "react-redux"

import "./style.scss"

const OwnerTeamMemberProfile = props => {
  const {
    user,
    getOwnerTeamMember,
    getOwnerTeamMemberFailure,
    putOwnerTeamMember,
    teamMember,
    requesting,
    putRequesting,
    error
  } = props

  const [memberID, setMemberID] = useState()

  const stateSchema = {
    fullName: {
      value: "",
      error: ""
    },
    email: {
      value: "",
      error: ""
    },
    phone: {
      value: "",
      error: ""
    },
    location: {
      value: "",
      error: ""
    },
    role: {
      value: "",
      error: ""
    }
  }

  const validationStateSchema = {
    fullName: {
      required: true
    },
    email: {
      required: true,
      validator: validator.email
    },
    phone: {
      required: true,
      validator: validator.phone
    },
    location: {
      required: true
    },
    role: {
      required: true
    }
  }

  const { state, handleOnChange, disable, setState } = useForm(
    stateSchema,
    validationStateSchema
  )

  useEffect(function () {
    setState(stateSchema)
    getOwnerTeamMember(user?.id)
  }, [])

  useEffect(
    function () {
      if (teamMember) {
        stateSchema.fullName.value = teamMember.name
        stateSchema.email.value = teamMember.email
        stateSchema.phone.value = teamMember.phone_number
        stateSchema.location.value = teamMember.location
        stateSchema.role.value = teamMember.role

        setMemberID(teamMember.id)
        setState(stateSchema)
      }
    },
    [teamMember]
  )

  const submit = async () => {
    const formData = new FormData()
    formData.append("name", state.fullName.value)
    formData.append("email", state.email.value)
    formData.append("location", state.location.value)
    formData.append("role", state.role.value)
    formData.append("phone_number", state.phone.value)

    await putOwnerTeamMember(formData, memberID)
  }

  return (
    <>
      <HomeLayout dashboardHeader={true}>
        <section className="content-section team-member-profile-section">
          <div className="team-member-profile-content-section">
            <div className="heading">
              <div className="back" onClick={() => history.push("add-member")}>
                <img src={backIcon} alt="" />
              </div>
              <h3>{state?.fullName ? state.fullName.value : ""}</h3>
            </div>
            <div className="add-members-form-section">
              <div className="container">
                <div className="row">
                  <div className="col-md-4 add-member-field">
                    <Input
                      showLabel={true}
                      label="Full Name"
                      value={state?.fullName ? state.fullName.value : ""}
                      showError={true}
                      errorMessage={state.fullName.error || error?.name}
                      onChange={val =>
                        handleOnChange("fullName", val.target.value)
                      }
                    />
                  </div>
                  <div className="col-md-4 add-member-field">
                    <Input
                      placeholder="Enter email"
                      showLabel={true}
                      label="Email"
                      type="email"
                      showError={true}
                      errorMessage={state.email.error || error?.email}
                      value={state.email.value}
                      onChange={val =>
                        handleOnChange("email", val.target.value)
                      }
                    />
                  </div>
                  <div className="col-md-4 add-member-field">
                    <Input
                      placeholder="Enter phone"
                      showLabel={true}
                      label="Phone"
                      type="number"
                      showError={true}
                      errorMessage={state.phone.error}
                      value={state.phone.value || error?.phone_number}
                      onChange={val =>
                        handleOnChange("phone", val.target.value)
                      }
                    />
                  </div>
                  <div className="col-md-4 add-member-field">
                    <Input
                      placeholder="Enter location"
                      showLabel={true}
                      label="Location"
                      value={state.location.value}
                      showError={true}
                      errorMessage={state.location.error || error?.location}
                      onChange={val =>
                        handleOnChange("location", val.target.value)
                      }
                    />
                  </div>
                  <div className="col-md-4 add-member-field">
                    <Input
                      placeholder="Enter role"
                      showLabel={true}
                      label="Role"
                      value={state.role.value}
                      showError={true}
                      errorMessage={state.role.error || error?.role}
                      onChange={val => handleOnChange("role", val.target.value)}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="button-wrapper">
                    <Button
                      showSpinner={putRequesting}
                      title="Save"
                      className="aes-btn green-btn"
                      onClick={() => submit()}
                    />
                    <div
                      className="change"
                      onClick={() => history.push("changePasword")}
                    >
                      Change Password
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </HomeLayout>
    </>
  )
}

const mapStateToProps = state => ({
  user: state.login?.loginInfo.user,
  getOwnerTeamMember: state.OwnerTeamMemberProfie.getOwnerTeamMember,
  putOwnerTeamMember: state.OwnerTeamMemberProfie.putOwnerTeamMember,
  requesting: state.OwnerTeamMemberProfie.requesting,
  putRequesting: state.OwnerTeamMemberProfie.putRequesting,
  error: state.OwnerTeamMemberProfie.error,
  teamMember: state.OwnerTeamMemberProfie.teamMember
})

const mapDispatchToProps = dispatch => ({
  getOwnerTeamMember: id => dispatch(getOwnerTeamMember(id)),
  getOwnerTeamMemberFailure: data => dispatch(getOwnerTeamMemberFailure(data)),
  putOwnerTeamMember: (data, id) => dispatch(putOwnerTeamMember(data, id))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OwnerTeamMemberProfile)
