import {
  POST_REQUEST_ADD_TEAM_MEMBER,
  POST_ADD_TEAM_MEMBER_SUCCESS,
  POST_ADD_TEAM_MEMBER_FAILURE
} from "./type"

// POST_PAYMENT_INFORMATION
export const addTeamMemberRequest = data => ({
  type: POST_REQUEST_ADD_TEAM_MEMBER,
  data
})

export const addTeamMemberSuccess = data => ({
  type: POST_ADD_TEAM_MEMBER_SUCCESS,
  data
})

export const addTeamMemberFailure = data => ({
  type: POST_ADD_TEAM_MEMBER_FAILURE,
  data
})
