import React, { useState, useEffect } from "react"
import "./style.scss"
import { useHistory } from "react-router-dom"
import splashLogo from "../../../assets/images/splashLogo.svg"

const Landing = props => {
  const history = useHistory()
  const {} = props
  useEffect(() => {
    // Wait for 3 seconds
    setTimeout(() => {
     { history.push("/auth/account")}
    }, 3000);
  }, []); 
  return (
    <>
      <section className="splsah-page-section">
        <img src={splashLogo}></img>
      </section>
    </>
  )
}

export default Landing
