import React from "react";
import { Select } from "antd";
import { CaretDownOutlined } from "@ant-design/icons";

import "./style.scss";

const BoardSelect = ({
  defaultValue,
  options,
  label = "",
  onChange,
  placeholder,
  showLabel,
  showSearch,
  disabled,
  filterOption,
  value,
  filterSort,
  optionFilterProp,
  mode
}) => {
  const showLabelMessage = showLabel ? showLabel : false;

  return (
    <>
      <div className="board-select-wrapper">
        {showLabelMessage && <label className="select-label">{label}</label>}
        <Select
          getPopupContainer={trigger => trigger.parentNode}
          // SelectProps={SelectProps}
          placeholder={placeholder}
          defaultValue={defaultValue}
          suffixIcon={false}
          // filterOptions={filterOptions}
          onChange={onChange}
          disabled={disabled ? disabled : false}
          showSearch={showSearch}
          // filterSort={filterSort}
          // filterOption={filterOption}
          value={value || undefined}
          optionFilterProp={optionFilterProp}
          mode={mode}
        >
          {options}
        </Select>
      </div>
    </>
  );
};

export default BoardSelect;