import React from "react"
import { connect } from "react-redux"
import { Route, Redirect } from "react-router-dom"
import NotFound from "../pages/NotFound"
import { routes } from "../Router"

const RouteGuard = ({
  isProtected = false,
  accessToken,
  loginInfo,
  component: Component,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={props => {
        const isPathExists = routes.find(
          e =>
            `${e.layout}${e.path}` == props.location.pathname ||
            `${e.path}` == props.location.pathname
        )

        if (isProtected) {
          return accessToken ? (
            <>
              {isPathExists ? (
                <Component {...props} />
              ) : (
                <Route component={NotFound} />
              )}
            </>
          ) : (
            <Redirect to={{ pathname: "/auth/landing" }} />
          )
        } else {
          return accessToken ? (
            loginInfo?.user.user_type == "contractor" ? (
              <Redirect
                to={{
                  pathname: "/contractor/dashboard"
                }}
              />
            ) : loginInfo?.user.user_type == "owner" ? (
              loginInfo?.user.change_password_status == false ? (
                <Redirect
                  to={{
                    pathname: "/owner/changePasword"
                  }}
                />
              ) : (
                <Redirect
                  to={{
                    pathname: "/owner/dashboard"
                  }}
                />
              )
            ) : (
              <Redirect
                to={{
                  pathname: "/admin/dashboard"
                }}
              />
            )
          ) : (
            <>
              {isPathExists ? (
                <Component {...props} />
              ) : (
                <Route component={NotFound} />
              )}
            </>
          )
        }
      }}
    />
  )
}

const mapStateToProps = state => ({
  accessToken: state.login.accessToken,
  loginInfo: state.login.loginInfo
})

export default connect(mapStateToProps, null)(RouteGuard)
