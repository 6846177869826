// GET LOGIN
export const GET_FILL_SITE_REQUEST = "GET_FILL_SITE_REQUEST"
export const GET_FILL_SITE_SUCCESS = "GET_FILL_SITE_SUCCESS"
export const GET_FILL_SITE_FAILURE = "GET_FILL_SITE_FAILURE"

export const SEARCH_FILL_SITE_SUCCESS = "SEARCH_FILL_SITE_SUCCESS"

export const GET_INV0ICES_REQUEST = "GET_INV0ICES_REQUEST"
export const GET_INV0ICES_SUCCESS = "GET_INV0ICES_SUCCESS"
export const GET_INV0ICES_FAILURE = "GET_INV0ICES_FAILURE"

export const SEARCH_INV0ICES_SUCCESS = "SEARCH_INV0ICES_SUCCESS"
