import React, { useState } from "react"

import Pagination from "react-js-pagination"

const PaginationPack = props => {
  const {
    onChange,
    activePage,
    totalItemsCount,
    itemsCountPerPage,
    pageRangeDisplayed
  } = props

  const [currentpage, setCurrentPage] = useState(1)
  const [totalCount, setTotalCount] = useState(50)

  // const handlePageChange = page => {
  //   setCurrentPage(page)

  //   // props.getAllCustomers(page)
  // }

  return (
    <>
      <Pagination
        aria-label="Page navigation example"
        itemClass="page-item"
        linkClass="page-link"
        prevPageText="<"
        nextPageText=">"
        // firstPageText="<"
        // lastPageText=">"
        hideFirstLastPages={true}
        onChange={onChange}
        activePage={activePage}
        pageRangeDisplayed={5}
        totalItemsCount={totalItemsCount}
        itemsCountPerPage={itemsCountPerPage}
      />
    </>
  )
}

export default PaginationPack
