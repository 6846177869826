import {
  GET_OWNER_NOTIFICATION_REQUEST,
  GET_OWNER_NOTIFICATION_SUCCESS,
  GET_OWNER_NOTIFICATION_FAILURE,
  GET_OWNER_MODAL_DATA_SUCCESS,
 
} from "./type"

const initialState = {
  data: false,
  requesting: false,
  error: false,
  detail: false,
  detailRequest: false,
  pagination: false,
  
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_OWNER_NOTIFICATION_REQUEST:
      return {
        ...state,
        requesting: true
      }
    case GET_OWNER_NOTIFICATION_SUCCESS:
      return {
        ...state,
        requesting: false,
        data: action.data,
       
      }
    case GET_OWNER_NOTIFICATION_FAILURE:
      return {
        ...state,
        requesting: false,
        error: action.data
      }
      case GET_OWNER_MODAL_DATA_SUCCESS:
      return {
        ...state,
        requesting: false,
        detail: action.data,
        detailRequest: true,
      }

    // case GET_NOTIFICATION_MODAL_DATA:
    //   return {
    //     ...state,
    //     detailRequest: true
    //   }
    // case GET_NOTIFICATION_MODAL_SUCCESS:
    //   return {
    //     ...state,
    //     detailRequest: false,
    //     detail: action.data,
    //     data: action.data
    //   }
    // case GET_NOTIFICATION_MODAL_FAILURE:
    //   return {
    //     ...state,
    //     detailRequest: false,
    //     error: action.data
    //   }



    // case PAGE_REQUEST:
    //   return {
    //     ...state,
    //     requesting: true
    //   }

    // case PAGE_SUCCESS:
    //   return {
    //     ...state,
    //     requesting: false,
    //     pagination: action.data,
    //     detail: action.data
    //   }

    // case PAGE_FALUIRE:
    //   return {
    //     ...state,
    //     requesting: false,
    //     error: action.data
    //   }
    default:
      return state
  }
}
