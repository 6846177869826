import { all, call, put, takeLatest } from "redux-saga/effects"
import { BASE_URL } from "../../../config/app"

import XHR from "../../../utils/XHR"
import { POST_FORGOT_PASSWORD } from "./type"
import {
  postForgotRequestSuccess,
  postForgotRequestFailure,
  setNotFirst
} from "./action"
import { toast } from "react-hot-toast"

async function postForgotAPI(data) {
  const URL = `${BASE_URL}api/v1/password/reset/`
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    method: "POST",
    data
  }
  return XHR(URL, options)
}

function* postForgotInfo({ data }) {
  try {
    const response = yield call(postForgotAPI, data)
    yield put(postForgotRequestSuccess(response?.data))
    yield put(setNotFirst({ status: false, email: data.email }))
    toast.success("Password Reset Link has been sent successfully")
  } catch (e) {
    const { response } = e
    yield put(postForgotRequestFailure(response?.data))
    toast.error("There was an error")
  }
}

export default all([takeLatest(POST_FORGOT_PASSWORD, postForgotInfo)])
