import {
  POST_REQUEST_LOGIN,
  POST_REQUEST_LOGIN_SUCCESS,
  POST_REQUEST_LOGIN_FAILURE,
  SET_ACCESS_TOKEN,
  IS_VISITING,
  LOGOUT,
  SCHEDULE_ID,
  SET_SEEMORE_ID,
  SET_DEPOSITESITE_ID,
  SET_BOOK_ID,
  SET_INV0ICE_ID,
  SET_FILLSITE_ID,
  RESET
} from "./type"

const initialState = {
  loginInfo: false,
  error: false,
  requesting: false,
  accessToken: false,
  isVisting: false,
  scheduleId: false,
  seeMoreID: false,
  depositeSiteID: false,
  invoiceId: null,
  bookID: false,
  fillSiteId:false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case POST_REQUEST_LOGIN:
      return {
        ...state,
        requesting: true
      }

    case POST_REQUEST_LOGIN_SUCCESS:
      return {
        ...state,
        requesting: false,
        loginInfo: action.data
      }
    case POST_REQUEST_LOGIN_FAILURE:
      return {
        ...state,
        requesting: false,
        error: action.data
      }
    case SET_ACCESS_TOKEN:
      return {
        ...state,
        accessToken: action.accessToken
      }

    case LOGOUT:
      return {
        ...state,
        requesting: false,
        accessToken: false,
        isVisting: false
      }
    case IS_VISITING:
      return {
        ...state,
        isVisting: action.data
      }
    case SCHEDULE_ID:
      return {
        ...state,
        scheduleId: action.data
      }
    case SET_SEEMORE_ID:
      return {
        ...state,
        seeMoreID: action.data
      }
    case SET_DEPOSITESITE_ID:
      return {
        ...state,
        depositeSiteID: action.data
      }
    case SET_BOOK_ID:
      return {
        ...state,
        bookID: action.data
      } 
    case SET_INV0ICE_ID:
      return {
        ...state,
        invoiceId: action.data
      }
      case SET_FILLSITE_ID:
      return {
        ...state,
        fillSiteId: action.data
      }
      case RESET:
        return {
          ...state,
          loginInfo: false,
          error: false,
          requesting: false,
          accessToken: false,
          isVisting: true,
          scheduleId: false,
          seeMoreID: false,
          depositeSiteID: false,
          invoiceId: null,
          bookID: false,
          fillSiteId:false
        }
    default:
      return state
  }
}
