


import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
// import HomeLayout from "../../../layout/HomeLayout"
import fbicon from "../images/fb-icon.svg"
import instaicon from "../images/insta-icon.svg"
import linkedinicon from "../images/linkedin-icon.svg"
import { getFooterRequest } from "./redux/action"
import { history } from "../../../reduxStore/store"

import "./style.css"

const Foter = props => {
const {mydataFooter,getFooterRequest}=props
useEffect(()=>{
    getFooterRequest()
},[])

  return (
    <>
    
    
<script src="https://cdnjs.cloudflare.com/ajax/libs/bootstrap/5.2.3/js/bootstrap.min.js" integrity="sha512-1/RvZTcCDEUjY/CypiMz+iqqtaoQfAITmNSJY17Myp4Ms5mdxPS5UV7iOfdZoxcGhzFbOm6sntTKJppjvuhg4g==" crossorigin="anonymous" referrerpolicy="no-referrer"></script>


<section class="footer-section" 

    >
        <div class="container">
            <div class="row footer-content-row">
                <div class="col-md-4 footer-content">
                    <div class="footer-heading">ABOUT COMPANY</div>
                    <p
                    style={{marginTop:"25px"}}
                    >{mydataFooter?.company?.note}</p>
                    {/* <div class="icon-wrapper">
                            <a  class="icon" href={mydataFooter && mydataFooter?.social_link[2]?.link}
                              target="_blank"
                            >
                                <img src={fbicon} alt="" />
                               
                            </a>
                            <a  class="icon" href={mydataFooter && mydataFooter?.social_link[1]?.link
                            
                            }
                            target="_blank"
                            >
                            <img src={instaicon} alt="" />

                              
                            </a>
                            <a  class="icon" href={mydataFooter && mydataFooter?.social_link[0]?.link}
                            target="_blank"
                            >
                            <img src={linkedinicon} alt="" />

                               
                            </a>
                    </div> */}
                </div>
                <div class="col-md-4 footer-content company-details">
                    <div class="footer-heading   mt-0 mb-4"
                    
                    >
                      COMPANY DETAILS
                   
                    </div>
                    <p>Phone number: <span>{mydataFooter?.company?.phone_number}</span></p>
                    <p>Zipcode: <span>{mydataFooter?.company?.zip_code}</span></p>
                    <p>Address: <span>{mydataFooter?.company?.address}</span></p>
                </div>
                <div class="col-md-4 footer-content">
                    <div class="footer-heading">
                        USEFUL LINKS
                    </div>
                    <ul>
                        <li 
                        
                        style={{ cursor: "pointer" ,marginTop:"20px"}}
                        onClick={() => {
                      history.push("/home")
                    }}><a >Home</a></li>
                        <li 
                        style={{ cursor: "pointer",marginTop:"14px" }}
                         onClick={() => {
                            history.push("/visiting/services")
                          }}
                        ><a >Services</a></li>
                        <li
                        style={{ cursor: "pointer" ,marginTop:"14px"}}
                         onClick={() => {
                            history.push("/visiting/contact")
                          }}
                        ><a >Contact us</a></li>
                        <li
                        style={{ cursor: "pointer",marginTop:"14px" }}
                         onClick={() => {
                            history.push("/visiting/soil-deposite-site")
                          }}
                        ><a>SOIL DEPOSITE</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </section>
    <section class="copy-right-section">
        Copyright  <span id='copyright-sign'> &copy; </span> 2022 AES Environmental
    </section>
   
    
    </>
  )
}

const mapStateToProps = state => ({
    mydataFooter: state.dataFooter.mydataFooter,
    requesting: state.dataFooter.requesting,
   
  })
  
  const mapDispatchToProps = dispatch => ({
    getFooterRequest: (data) => dispatch(getFooterRequest(data)),
   
  })
  
  export default connect(mapStateToProps, mapDispatchToProps)(Foter)


