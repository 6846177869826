import HomeLayout from "../../../layout/HomeLayout"
import { history } from "../../../reduxStore/store"
import Button from "../../../components/Button"
import backIcon from "../../../assets/images/back-icon-circle.svg"
import UploadFile from "../../../components/UploadFile"
import uploadFile from "../../../assets/images/uploadfile.svg"
import "./style.scss"
import { Select, Space,Row,Col } from "antd"
import Input from "../../../components/Input"
import useForm from "../../../utils/useForm"
import {
  postSoilAcceptRequest,
  postSoilAcceptFailure,
  getAdminFillRequest
} from "./redux/action"
import { useEffect, useState } from "react"
import { connect, useDispatch, useSelector } from "react-redux"
import AeSelect from "../../../components/AeSelect"
import { getAdminApprovedRequest } from "../SoilRequest/redux/action"

const SoilRequestConfirmPage = props => {

  const {
    postSoilAccept,
    postSoilAcceptRequest,
    error,
    postSoilAcceptFailure,
    getAdminFillRequest,
    selectData,
    putSoildata,
    postSoilAcceptRequesting,
    putAdminSoilSuccess
  } = props
  const { AdminApprovedRequestdata, } = useSelector(
    state => state.AdminSoilRequest

  )
  const stateSchema = {
    Originatinglocation: {
      value: "",
      error: ""
    },
    CSRClassification: {
      value: "",
      error: ""
    },
    laboratoryfilenumber: {
      value: "",
      error: ""
    },
    qep_signature: {
      value: "",
      error: ""
    }
  }

  const validationStateSchema = {
    Originatinglocation: {
      required: true
    },
    CSRClassification: {
      required: true
    },
    laboratoryfilenumber: {
      required: true
    },
    qep_signature: {
      required: true
    }
  }

  const { state, handleOnChange, disable, setState } = useForm(
    stateSchema,
    validationStateSchema
  )

  const [selectedFile, setSelectedFile] = useState(false)
  const [selectedFile2, setSelectedFile2] = useState(false)
  const [adminData, setAdminData] = useState(false)

  const [state1, setState1] = useState([{
    CSRClassification: { value: '', error: '' },
    selectedFillSite: null
  }]
  );

  const handlefile1 = e => {
    const file = e.target.files[0]
    const fileType = file.type
    const fileSize = file.size

    // Check if file type is PDF
    if (fileType !== "application/pdf") {
      alert("Please upload a PDF file.")
      return
    }

    // Check if file size is less than or equal to 50MB
    if (fileSize > 50 * 1024 * 1024) {
      alert("Please upload a file smaller than 50MB.")
      return
    }

    setSelectedFile(file)
  }

  const handlefile = e => {
    const file = e.target.files[0]
    const fileType = file.type
    const fileSize = file.size

    // Check if file type is PDF
    if (fileType !== "application/pdf") {
      alert("Please upload a PDF file.")
      return
    }

    // Check if file size is less than or equal to 50MB
    if (fileSize > 50 * 1024 * 1024) {
      alert("Please upload a file smaller than 50MB.")
      return
    }

    setSelectedFile2(file)
  }
  const savedMaterialId = localStorage.getItem("materialId")
  const [adminId, setAdminId] = useState([])
  const [search, setSearch] = useState()

  useEffect(() => {
    getAdminFillRequest()
  }, [])

  useEffect(() => {
    getAdminUser()
  })

  const { Option } = Select
  const handleChange = (id, value) => {
    setSearch(value)
    setAdminId(id)
  }

  function getAdminUser() {
    if (selectData) {
      selectData.map(data => {
        setAdminData(data)
      })
    }
  }
  useEffect(() => {
    setState(stateSchema)
    postSoilAcceptFailure(false)
  }, [])
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getAdminApprovedRequest(`${savedMaterialId}/`))
  }, [])

  useEffect(() => {
    if (AdminApprovedRequestdata) {
      handleOnChange("Originatinglocation", AdminApprovedRequestdata?.originating_location)
      handleOnChange("laboratoryfilenumber", AdminApprovedRequestdata?.laboratory_file)
      if(AdminApprovedRequestdata?.deposit_request?.length > 0){
        const newState = [...state1];
        newState[0].CSRClassification.value = AdminApprovedRequestdata?.deposit_request[0]?.material_name?.name
        setState1(newState);
        handleOnChange("CSRClassification",AdminApprovedRequestdata?.deposit_request[0]?.material_name?.name)
       // handleCSRClassificationChange(0, AdminApprovedRequestdata?.deposit_request[0]?.material_name?.name)
      }

    }
  }, [AdminApprovedRequestdata])

  const submit = async () => {
    const formData = new FormData()
    formData.append("csr_classification", state.CSRClassification.value)
    formData.append("laboratory_file_no", state.laboratoryfilenumber.value)
    formData.append("originating_location", state.Originatinglocation.value)
    formData.append("qep_signature", state.qep_signature.value)
    formData.append("deposit_request", savedMaterialId)
    formData.append("fill_site_owner", JSON.stringify(adminId))
    formData.append("status", "accepted")
    formData.append("soil_authorization_form", selectedFile)
    await postSoilAcceptRequest(formData)
  }
  const handleCSRClassificationChange = (index, e) => {
    handleOnChange("CSRClassification",e.target.value)
  };

  const handleFillSiteChange = (index, value) => {
    const newState = [...state1];
    newState[index].selectedFillSite = value;
    setState1(newState);
  };
  return (
    <>
      <HomeLayout dashboardHeader={true}>
        <section className="soil-request-confirm-section">
          <div className="heading-confirm" style={{justifyContent:'space-between',alignContent:'center',alignItems:'center'}}>
            <div className="heading-confirm">
              <div className="back" onClick={() => history.goBack()}>
                <img src={backIcon} alt="" />
              </div>
              <div className="contractor-name">
                <h3 style={{marginBottom:0}}>Approve SDR</h3>
              </div>
            </div>
            <div className="confirm-btn">
                <Button
                  title="Approve SDR"
                  className="aes-btn orange-btn"
                  onClick={() => submit()}
                  showSpinner={postSoilAcceptRequesting}
                />
              </div>
          </div>
          <div className="body-confirm bodyScroll" style={{top:10}}>
          <section className="content-section adminContractorDetail-section1" style={{padding:0,marginTop:15,}}>
              <Row className="contentStyle">

                <Col sm={12} xs={24} className="border-bottom py-2">
                  <h2>
                  Company Name:{" "}
                    <span>
                    {AdminApprovedRequestdata?.company_name || ""}
                    </span>
                  </h2>
                </Col>
                <Col sm={12} xs={24} className="border-bottom py-2">
                  <h2>
                  Company Address:{" "}
                    <span>{AdminApprovedRequestdata.company_address}</span>
                  </h2>
                </Col>
                <Col sm={12} xs={24} className="border-bottom py-2">
                  <h2>
                  Project Name:{" "}
                    <span>{AdminApprovedRequestdata.project_name}</span>
                  </h2>
                </Col>
                <Col sm={12} xs={24} className="border-bottom py-2">
                  <h2>
                  Project Start Datee:{" "}
                    <span>{AdminApprovedRequestdata.start_date}</span>
                  </h2>
                </Col>
                <Col sm={12} xs={24} className="border-bottom py-2">
                  <h2>
                  Email:{" "}
                    <span>
                      {AdminApprovedRequestdata?.user?.email}
                    </span>
                  </h2>
                </Col>
                <Col sm={12} xs={24} className="border-bottom py-2">
                  <h2>
                  Phone:{" "}
                    <span>{AdminApprovedRequestdata.user?.company
                        ?.position_in_company}</span>
                  </h2>
                </Col>
                
                
                <Col sm={12} xs={24} className="border-bottom py-2">
                  
                    
                    {AdminApprovedRequestdata?.deposit_request?.slice(0, 4).map(
                      (material, index) => (
                        <>
                          <div className="d-flex" style={{ gap: 10 }}>
                          <h2>
                          Soil Type:{" "}
                             <span>
                            
                              {material?.material_name?.name}&nbsp;|&nbsp;
                              {material.material_type == 1
                                ? "Structural"
                                : "Non-Structural"}{" "}
                              &nbsp;|&nbsp;{material?.material_amount}{" "}
                              {material?.material_mass_type === "1" ? (
                                <>mt </>
                              ) : (
                                <span>
                                  m<sup>3</sup>
                                </span>
                              )}
                              
                              
                            </span>
                            </h2>
                          </div>
                        </>
                      )
                    )}
                    
                  
                </Col>
                <Col sm={12} xs={24} className="border-bottom py-2">
                  
                    
                    {AdminApprovedRequestdata?.deposit_request?.slice(0, 4).map(
                      (material, index) => (
                        <>
                          <div className="d-flex" style={{ gap: 10 }}>
                          <h2>
                             Environmental / geotechnical analytical :{" "}
                             <span>
                            
                             { ( AdminApprovedRequestdata?.environmental &&
                        AdminApprovedRequestdata?.environmental.length > 0 ) &&
                        <div className="img-bg"> {
                          AdminApprovedRequestdata.environmental.map(
                            (file, index) => (
                              <a key={index} href={file.environmental} download target="blank">
                                <span  >
                                  <img src={uploadFile} alt="Upload File" />
                                </span>
                              </a>
                            )
                          ) 
                        }
                     
                        </div>
                        }
                              
                              
                            </span>
                            </h2>
                          </div>
                        </>
                      )
                    )}
                    
                  
                </Col>
                <Col sm={12} xs={24} className="border-bottom py-2">
                  <h2>
                  Laboratory File #:{" "}
                    <span>{AdminApprovedRequestdata.laboratory_file}</span>
                  </h2>
                </Col>
                <Col sm={12} xs={24} className="border-bottom py-2">
                  <h2>
                  Contractor Name:{" "}
                    <span>{AdminApprovedRequestdata.contractor_name}</span>
                  </h2>
                </Col>
              </Row>
            </section>
          
            <div style={{flexDirection:'row',display:'flex',width:'100%',justifyContent:'space-between',marginTop:25}} className="poie">
              <Input
                placeholder=" - "
                label="Laboratory File #: "
                type="text"
                showLabel={true}
                showError={true}
                disabled={true}
                errorMessage={error?.laboratory_file_no}
                value={state.laboratoryfilenumber.value}
                onChange={val =>
                  handleOnChange("laboratoryfilenumber", val.target.value)
                }
                
              />
              <Input
                placeholder="Enter originating location"
                label="Originating Location: "
                showLabel={true}
                showError={true}
                disabled={true}
                // onFocus={ postSoilAcceptFailure(false)}
                errorMessage={
                  state.Originatinglocation.error || error?.originating_location
                }
                value={state.Originatinglocation.value}
                onChange={val =>
                  handleOnChange("Originatinglocation", val.target.value)
                }
              />

              
            </div>
            <div style={{marginBottom:15}}>
            {AdminApprovedRequestdata?.deposit_request && Array.from({ length: AdminApprovedRequestdata?.deposit_request.length }).map((_, index) => (
            <div style={{flexDirection:'row',display:'flex',width:'100%',justifyContent:'space-between'}} className="poie">
              <Input
                  placeholder="Enter Soil Type"
                  label="Soil Type"
                  showLabel={true}
                  showError={true}
                  errorMessage={state?.CSRClassification.error || error?.csr_classification}
                  value={state?.CSRClassification.value}
                  onChange={e => handleCSRClassificationChange(index, e)}
                  style={{width:'50%'}}
                />

                <div className="fill-site-select">
                  <p>Select fill site:</p>
                  <Select
                    placeholder="Select fill site"
                    maxTagCount="responsive"
                    
                    style={{
                      width: "100%",
                      height: 45
                    }}
                    value={state?.selectedFillSite}
                    filterOption={(inputValue, option) =>
                      option.label
                        .toLowerCase()
                        .indexOf(inputValue.toLowerCase()) !== -1
                    }
                    // label={adminData?.username}
                    
                    onChange={value => handleFillSiteChange(index, value)}
                  >
                    {selectData ? (
                      selectData.map(data => (
                        <Option
                          key={data.id}
                          value={data.id}
                          label={data.username}
                        >
                          <Space>{data.username}</Space>
                        </Option>
                      ))
                    ) : (
                      <Option value={null} disabled>
                        No data
                      </Option>
                    )}
                  </Select>

                  {error && error?.fill_site_owner  && <label
                    className={`error-message  ${error && error?.fill_site_owner ? "opacity-1" : ""
                      }`}
                  >
                    {error && error?.fill_site_owner}
                  </label>}
                </div>
            </div>))}
            <div style={{flexDirection:'row',display:'flex',width:'100%',justifyContent:'space-between'}} className="poie">
              <Input
                placeholder="Enter the QEP name"
                label="QEP Signature (required)"
                showLabel={true}
                value={state.qep_signature.value}
                showError={true}
                errorMessage={error?.qep_signature || ""}
                onChange={val =>
                  handleOnChange("qep_signature", val.target.value)
                }
                style={{width:'50%'}}
              />
            </div>
            
            </div>
            <hr />
            <div className="doc-wrapper">
              <div className="document">
                <div className="logo-wrapper">
                  <div className="logo-text">
                    <UploadFile
                      title={
                        selectedFile?.name
                          ? selectedFile.name
                          : "Upload Soil Authorization form"
                      }
                      onChange={handlefile1}
                    />
                  </div>
                </div>
              </div>
              <div className="error-flex">
                <label
                  className={`error-message ${error?.soil_authorization_form ? "opacity-1" : ""
                    }`}

                >
                  {error?.soil_authorization_form &&
                    error?.soil_authorization_form}
                </label>
              </div>
            </div>
            </div>
        </section>
        
      </HomeLayout>
    </>
  )
}

const mapStateToProps = state => ({
  postSoilAccept: state.SoilConfirm.postSoilAccept,
  postSoilAcceptRequesting: state.SoilConfirm.postSoilAcceptRequesting,
  error: state.SoilConfirm.error,
  selectData: state.SoilConfirm.getAdminFillSite
})

const mapDispatchToProps = dispatch => ({
  postSoilAcceptRequest: data => dispatch(postSoilAcceptRequest(data)),
  postSoilAcceptFailure: data => dispatch(postSoilAcceptFailure(data)),

  getAdminFillRequest: data => dispatch(getAdminFillRequest(data))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SoilRequestConfirmPage)
