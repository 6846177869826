import {
  POST_FORGOT_PASSWORD,
  POST_FORGOT_PASSWORD_SUCCESS,
  POST_FORGOT_PASSWORD_FAILURE,
  NOT_IS_FIRST
} from "./type"

const initialState = {
  requesting: false,
  forgotInfo: false,
  error: false,
  isFirst: true,
  email: ""
}

export default (state = initialState, action) => {
  switch (action.type) {
    case NOT_IS_FIRST:
      return {
        ...state,
        isFirst: action.data.status,
        email: action.data.email
      }
    case POST_FORGOT_PASSWORD:
      return {
        ...state,
        requesting: true
      }
    case POST_FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        requesting: false,
        forgotInfo: action.data
      }
    case POST_FORGOT_PASSWORD_FAILURE:
      return {
        ...state,
        requesting: false,
        error: action.error
      }
    default:
      return state
  }
}
