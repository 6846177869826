import {
  GET_FOOTER_REQUEST,
  GET_FOOTER_SUCCESS,
  GET_FOOTER_FAILURE,
} from "./type"

const initialState = {
  mydataFooter: false,
  requesting: false,
  error: false,

}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_FOOTER_REQUEST:
      return {
        ...state,
        requesting: true
      }
    case GET_FOOTER_SUCCESS:
      return {
        ...state,
        requesting: false,
        mydataFooter: action.data
      }
    case GET_FOOTER_FAILURE:
      return {
        ...state,
        requesting: false,
        error: action.data
      }

   
    default:
      return state
  }
}
