/* eslint-disable import/no-anonymous-default-export */
import {
    GET_DOCUMENT_REQUEST,
    GET_DOCUMENT_SUCCESS,
    DELETE_DOCUMENT_REQUEST,
    DELETE_DOCUMENT_SUCCESS,
    POST_DOCUMENT_FAILED,
    POST_DOCUMENT_SUCCESS,
    POST_DOCUMENT_REQUEST
} from "./types"

const initialState = {
    documentData: false,
    requesting: false,
    deleteRequest: false,
    error:false,
    postrequesting:false

}

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_DOCUMENT_REQUEST:
            return {
                ...state,
                requesting: true
            }
        case GET_DOCUMENT_SUCCESS:
            return {
                ...state,
                requesting: false,
                documentData: action.data
            }
        case DELETE_DOCUMENT_REQUEST:
            return {
                ...state,
                deleteRequest: true
            }
        case DELETE_DOCUMENT_SUCCESS:
            return {
                ...state,
                deleteRequest: false,
                documentData: action.data
            }
            case POST_DOCUMENT_REQUEST:
                return {
                    ...state,
                    postrequesting: true,
                  
                }
            case POST_DOCUMENT_SUCCESS:
                return {
                    ...state,
                // documentData: action.data,
                postrequesting: false,
                }
            case   POST_DOCUMENT_FAILED:
                return {
                    ...state,
                    postrequesting: false,
                    error: action.data
                }
        default:
            return state
    }
}
