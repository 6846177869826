export const POST_REQUEST_SOIL_DEPOSIT = "POST_REQUEST_SOIL_DEPOSIT"
export const POST_REQUEST_SOIL_DEPOSIT_SUCCESS =
  "POST_REQUEST_SOIL_DEPOSIT_SUCCESS"
export const POST_REQUEST_SOIL_DEPOSIT_FAILURE =
  "POST_REQUEST_SOIL_DEPOSIT_FAILURE"

export const GET_MATERIALS_REQUEST = "GET_MATERIALS_REQUEST"
export const GET_MATERIALS_SUCCESS = "GET_MATERIALS_SUCCESS"
export const GET_MATERIALS_FAILURE = "GET_MATERIALS_FAILURE"


export const GET_ADMIN_PROJECT = "GET_ADMIN_PROJECT"
export const SUCCESS_GETTING_ADMIN_PROJECT = "SUCCESS_GETTING_ADMIN_PROJECT"
export const ERROR_GETTING_ADMIN_PROJECT = "ERROR_GETTING_ADMIN_PROJECT"

