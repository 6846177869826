import React, { useEffect } from "react"
import { Space, Table } from "antd"
import moment from "moment"
import Button from "../Button"
import { history } from "../../reduxStore/store"
import "./style.scss"
import { connect } from "react-redux"
import { Spinner } from "react-bootstrap"
import { getProjectListRequest } from "../../pages/Contractor/ProjectsList/redux/action"
import { setSeeMoreID } from "../../pages/AuthScreens/LoginScreen/redux/action"
import Skeleton from "react-loading-skeleton"
import "react-loading-skeleton/dist/skeleton.css"

const ProjectsListTable = props => {
  const { getProjectListRequest, setSeeMoreID, myProjectsData, requesting } =
    props

  useEffect(() => {
    getProjectListRequest("")
  }, [])

  const loadingData = new Array(10).fill().map(() => ({ loading: true }))

  const onSeeMore = id => {
    if (id) {
      setSeeMoreID(id)
      history.push("/contractor/approved-requests")
    }
  }

  const columns = [
    {
      title: "Project ID",
      dataIndex: "project_id",
      key: "project_id",
      render: (_, { project_id, loading }) => {
        if (loading) {
          return <Skeleton width="40%" />
        } else {
          return (
            <Space size="middle" className="full-name">
              {project_id}
            </Space>
          )
        }
      }
    },
    {
      title: "Project Name",
      dataIndex: "name",
      key: "name",
      render: (_, { name, loading }) => {
        if (loading) {
          return <Skeleton width="40%" />
        } else {
          return (
            <Space size="middle" className="full-name">
              {name}
            </Space>
          )
        }
      }
    },
    {
      title: "Developer Name",
      dataIndex: "developer_name",
      key: "developer_name",
      render: (_, { developer_name, loading }) => {
        if (loading) {
          return <Skeleton width="40%" />
        } else {
          return (
            <Space size="middle" className="full-name">
              {developer_name}
            </Space>
          )
        }
      }
    },
    {
      title: "Project Start Date",
      dataIndex: "start_date",
      key: "start_date",
      render: (_, { start_date, loading }) => {
        if (loading) {
          return <Skeleton width="40%" />
        } else {
          return (
            <Space size="middle" className="email">
              {start_date ? moment(start_date).format("DD/MM/YYYY"):'-'}{" "}
            </Space>
          )
        }
      }
    },{
      title: " ",
      key: "status",
      render: (_, { status, loading }) => {
        if (loading) {
          return (
            <div style={{ textAlign: "right" }}>
              <Skeleton width="20%" />
            </div>
          )
        } else {
          return (
            <div className="roleAction">
              <Button
                  title="Create SDR"
                  className="aes-btn transparent-btn"
                  onClick={() => {
                    history.push("/contractor/request-soil-deposit")
                  }}
                />
            </div>
          )
        }
      }
    }
  ]
  return (
    <>
      {/* {requesting ? (
        <div className="loader-container">
          <Spinner variant="primary" />
        </div>
      ) : ( */}
      <Table
        className="fill-site-table2"
        columns={columns}
        dataSource={myProjectsData?.length > 0 ? myProjectsData: []}
        pagination={false}
        rowKey="id"
        
      />
      {/* )} */}
    </>
  )
}

const mapStateToProps = state => ({
  myProjectsData: state.Project.projectsList,
  requesting: state.Project.requesting
})

const mapDispatchToProps = dispatch => ({
  getProjectListRequest: data => dispatch(getProjectListRequest(data)),
  setSeeMoreID: data => dispatch(setSeeMoreID(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(ProjectsListTable)
