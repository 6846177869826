// Account Request
export const GET_ACCOUNT_REQUEST = "GET_ACCOUNT_REQUEST"
export const GET_ACCOUNT_REQUEST_SUCCESS = "GET_ACCOUNT_REQUEST_SUCCESS"
export const GET_ACCOUNT_REQUEST_FAILURE = "GET_ACCOUNT_REQUEST_FAILURE"


// Patch Account Request
export const PATCH_ACCOUNT_REQUEST = "PATCH_ACCOUNT_REQUEST"
export const PATCH_ACCOUNT_REQUEST_SUCCESS = "PATCH_ACCOUNT_REQUEST_SUCCESS"
export const PATCH_ACCOUNT_REQUEST_FAILURE = "PATCH_ACCOUNT_REQUEST_FAILURE"
