import {
  POST_CONFIRM_PASSWORD,
  POST_CONFIRM_PASSWORD_SUCCESS,
  POST_CONFIRM_PASSWORD_FAILURE,
  POST_USER_PASSWORD,
  POST_USER_PASSWORD_SUCCESS,
  POST_USER_PASSWORD_FAILURE
} from "./type"

const initialState = {
  confirmInfo: false,
  userInfo: false,
  requesting: false,
  error: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case POST_CONFIRM_PASSWORD:
      return {
        ...state,
        requesting: true,
        error: false
      }
    case POST_CONFIRM_PASSWORD_SUCCESS:
      return {
        ...state,
        requesting: false,
        confirmInfo: action.data
      }
    case POST_CONFIRM_PASSWORD_FAILURE:
      return {
        ...state,
        requesting: false,
        error: action.data
      }
      case POST_USER_PASSWORD:
        return {
          ...state,
          requesting: true,
          error: false
        }
      case POST_USER_PASSWORD_SUCCESS:
        return {
          ...state,
          requesting: false,
          userInfo: action.data
        }
      case   POST_USER_PASSWORD_FAILURE:
        return {
          ...state,
          requesting: false,
          error: action.data
        }
    default:
      return state
  }
}
