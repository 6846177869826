import React, { useEffect, useState } from "react"
import "./style.scss"
import HomeLayout from "../../../layout/HomeLayout"
import Search from "../../../components/Search"
import Button from "../../../components/Button"
import { connect } from "react-redux"
import moment from "moment"
import "react-loading-skeleton/dist/skeleton.css"
import { toast, Toaster } from "react-hot-toast"
import uploadfile from "../../../assets/images/uploadfile.svg"
import UploadDocoment from "../../../components/AdminModal/UploadDocoment"
import Skeleton from "react-loading-skeleton"
import { DeleteDocumentRequest, getDocumentRequest } from "./redux/action"
import useForm from "../../../utils/useForm"
const DocumentAgreement = (props) => {
    const { getDocumentRequest, documentData, DeleteDocumentRequest, deleteRequest, requesting } = props
    const [modalShow, setModalShow] = useState(false)


    useEffect(() => {
        getDocumentRequest("")
    }, [])
    const handleOndel = async (item) => {


        await DeleteDocumentRequest(item.id);

        toast.success(`${item.title} successfully removed`)
    };

    let timeoutId

    const HandelSearch = e => {
        clearTimeout(timeoutId)

        timeoutId = setTimeout(() => {
            getDocumentRequest(`?title=${e.target.value}`)
        }, 1000)
    }

    function formatSizeUnits(bytes)
    {
        if ( ( bytes >> 30 ) & 0x3FF )
            bytes = ( bytes >>> 30 ) + '.' + ( bytes & (3*0x3FF )) + 'GB' ;
        else if ( ( bytes >> 20 ) & 0x3FF )
            bytes = ( bytes >>> 20 ) + '.' + ( bytes & (2*0x3FF ) ) + 'MB' ;
        else if ( ( bytes >> 10 ) & 0x3FF )
            bytes = ( bytes >>> 10 ) + '.' + ( bytes & (0x3FF ) ) + 'KB' ;
        else if ( ( bytes >> 1 ) & 0x3FF )
            bytes = ( bytes >>> 1 ) + 'Bytes' ;
        else
            bytes = bytes + 'Byte' ;
        return bytes ;
    }

    return (
        <HomeLayout dashboardHeader={true}>
            <Toaster position="top-center" />
            <section className="content-section DocumentAgreement-section">

                <div className="headingStyle">
                    <span
                        style={{
                            display: "flex",
                            gap: "10px",
                            flexGrow: "1",
                            alignItems: "baseline"
                        }}
                    >
                        <h1 className="w-100">Documents and Agreements</h1>
                    </span>
                    <form>
                        <Search
                            placeholder="Search Document"

                            onChange={val => (
                                HandelSearch(val)
                            )
                            }

                        />
                    </form>
                    <div className="buttondiv">
                        <Button
                            title="Upload Document"
                            className="newFileStyle"
                            onClick={e => setModalShow(true)}
                        />
                    </div>
                </div>
                <div className="loader">
                    <div className="inner row px-4 mt-3 justify-content-between">
                        {requesting ? (
                            [1, 2, 3].map(item => (
                                <div className="col-md-5 docomentcard d-block" key={item.id}>
                                    <div>
                                        <Skeleton width="100%" />
                                        <Skeleton width="90%" />
                                    </div>
                                    <div>
                                        <Skeleton width="90%" className="pdf-Link" />
                                    </div>
                                </div>
                            ))
                        ) : documentData && documentData.length > 0 ? (
                            <section>
                                <div className="row px-4 mt-3 justify-content-between">
                                    {documentData.map(item => (
                                        <div className="col-md-5 docomentcard" key={item.id}>
                                            <div>
                                                <div>
                                                    <img className="img-upload" src={uploadfile} alt="upload" />
                                                    <a className="pdf-Link" href={item.attachment} target="_blank">
                                                        {item.title} 
                                                    </a>
                                                </div>
                                                
                                                <span style={{fontSize:11}}>{moment(item.created_at).format("MMM DD, YYYY")} - {formatSizeUnits(item.attachment_size)}</span>
                                            </div>
                                            <div>
                                                <Button
                                                    title="Remove"
                                                    className="aes-btn transparent-btn"
                                                    onClick={() => handleOndel(item)}
                                                    showSpinner={item.isLoading}
                                                />
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </section>
                        ) : (
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    height: "70vh"
                                }}
                            ><h3>No documents found</h3></div>
                        )}
                    </div>

                </div>

            </section>
            <UploadDocoment
                setModalShow={setModalShow}
                modalShow={modalShow}
            />
        </HomeLayout >
    )
}
const mapStateToProps = state => ({
    requesting: state.DocumentAgreement.requesting,

    documentData: state.DocumentAgreement.documentData,
    deleteRequest: state.DocumentAgreement.deleteRequest,
})

const mapDispatchToProps = dispatch => ({
    getDocumentRequest: data => dispatch(getDocumentRequest(data)),
    DeleteDocumentRequest: data => dispatch(DeleteDocumentRequest(data)),

})

export default connect(mapStateToProps, mapDispatchToProps)(DocumentAgreement)

