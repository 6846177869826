import React, { useEffect, useState } from "react"
import "./style.scss"
import HomeLayout from "../../../layout/HomeLayout"
import Search from "../../../components/Search"
import Button from "../../../components/Button"
import { connect, useDispatch, useSelector } from "react-redux"

import report from "../../../assets/images/report-img.png"
import "react-loading-skeleton/dist/skeleton.css"
import { toast, Toaster } from "react-hot-toast"

import useForm from "../../../utils/useForm"
import RequestReport from "../../../components/AdminModal/ReportsModal/RequestReport"
import CreateNewReport from "../../../components/AdminModal/ReportsModal/CreateNewReport.js"
import { history } from "../../../reduxStore/store"
import { getReportRequest } from "./redux/action"
import Skeleton from "react-loading-skeleton"
import "react-loading-skeleton/dist/skeleton.css"

import moment from "moment"
const Reports = (props) => {
    const [modalShow, setModalShow] = useState(false)
    const [newReportModalShow, setNewReportModalShow] = useState(false)
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(getReportRequest(""))
    }, [])
    const { reportData, requesting } = useSelector(
        state => state.Report
    )
    let timeoutId

    const SearchData = e => {
        clearTimeout(timeoutId)

        timeoutId = setTimeout(() => {
            dispatch(getReportRequest(`?report_category=${e.target.value}`))
        }, 1000)
    }
    return (
        <HomeLayout dashboardHeader={true}>
            <Toaster position="top-center" />
            <section className="content-section Reports">

                <div className="headingStyle">
                    <span
                        style={{
                            display: "flex",
                            gap: "10px",
                            flexGrow: "1",
                            alignItems: "baseline"
                        }}
                    >
                        <h1 className="w-100">Reports</h1>
                    </span>
                    <form>
                        <Search
                            placeholder="Search Document"
                            onChange={e => {
                                SearchData(e)
                            }}
                        />
                    </form>
                    <div className="buttondiv">
                        <Button
                            title="Request Report"
                            className="newFileStyle"
                            onClick={e => setModalShow(true)}
                        />
                    </div>
                    <div className="buttondiv">
                        <Button
                            title="Upload New Report"
                            className="newFileStyle"
                            onClick={e => { setNewReportModalShow(!newReportModalShow) }}
                        />
                    </div>
                </div>
                <div className="bodyScroll">

               
                {requesting ? (
                    [1, 2, 3, 4].map(item => (
                        <div className="report-card ">
                            <div className="right-side"
                                key={item.id}
                            >
                                <div > <Skeleton width="50px" />

                                </div>
                            </div>
                            <div className="d-flex align-items-center justify-content-between w-100 px-4">

                                <div className="d-flex align-items-center">

                                    <div>  <Skeleton width="100px" /></div>
                                    <p className="text">  <Skeleton width="200px" /></p>

                                </div>
                                <div>
                                    <Skeleton width="200px" />


                                </div>

                            </div>

                        </div>

                    ))
                ) :
                reportData && reportData.length > 0 ? (
                    reportData.map((item) => (
                      <>
                        <div className="report-card " key={item.id}>
                          <div className="right-side">
                            <div className="date">
                              <span>{moment(item.created_at).format("DD MMM")}</span>
                            </div>
                          </div>
                          <div className="d-flex align-items-center justify-content-between w-100 px-4">
                            <div className="d-flex align-items-center">
                              <img className="img-upload mr-2" src={report} alt="report"
                               width={30}
                               height={30}
                              />
                              <p className="text">{item.report_category?.name}</p>
                            </div>
                            <div>
                              <Button
                                title="See details"
                                className="aes-btn transparent-btn"
                                onClick={() => {
                                  history.push("Report-Details");
                                  localStorage.setItem("reportId", item.id);
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </>
                    ))
                  ) : (
                    <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "70vh"
                    }}
                  >
                    <h3 >No Data Found</h3>
                  </div>
                
                  )}
                    </div>


            </section>

            <RequestReport
                setModalShow={setModalShow}
                modalShow={modalShow}
            />
            <CreateNewReport
                setNewReportModalShow={setNewReportModalShow}
                newReportModalShow={newReportModalShow}
            />

        </HomeLayout >
    )
}


export default Reports

