import { Space, Table } from "antd"
import React from "react"
import Button from "../Button"
import "./style.scss"
// import { getFillSiteRequest } from "../../pages/Constrators/AccountFillSite/redux/action"
import { connect } from "react-redux"
import moment from "moment"
import Skeleton from "react-loading-skeleton"

const SingleSummaryTable = props => {
  const { summaryData, isloading } = props

  const info = []
  // for (let i = 0; i < 14; i++) {

  //   summaryData &&
  //     summaryData[1]?.truck_info.map(item =>

  //     )

  summaryData &&
    summaryData?.truck_info?.map(item => {
      // rest of the code
      info.push({
        key: item?.id,
        spceal_Trak_id: `${item?.specific_truck_id}`,
        Truck_weight: item?.truck_company,
        Truck_id: item.truck_id,
        Gross: item.gross,
        tare: item?.tare,
        net: item.net,
        timeIn: moment(item.time_in).format("LTS"),

        timeOut: moment(item.time_out).format("LTS"),
        specific_truck_no: item.specific_truck_id,
        PO: item.po_number,
        CSR: item.csr_acceptance,
        rate: ` $ ${item.rate}`,
        paid: `${item.paid}`
      })
    })

  //   if (summaryData && summaryData.length >= 2) {
  //     summaryData[1]?.truck_info.map(item => {
  //       // rest of the code
  //     });
  //   }
  // }

  const columns = [
    {
      title: "Specific truck ID",
      dataIndex: "spceal_Trak_id",
      key: "spceal_Trak_id",
      render: (_, spceal_Trak_id) => (
        <Space
          size="middle"
          className="invoice-number"
          title={
            spceal_Trak_id.spceal_Trak_id.length >= 20
              ? spceal_Trak_id.spceal_Trak_id
              : null
          }
        >
          {spceal_Trak_id.spceal_Trak_id &&
          spceal_Trak_id.spceal_Trak_id?.length >= 20
            ? spceal_Trak_id.spceal_Trak_id.slice(0, 17) + "..."
            : spceal_Trak_id.spceal_Trak_id}
        </Space>
      )
    },
    {
      title: "Company Name",
      dataIndex: "Truck_weight",
      key: "Truck_weight",
      render: (_, Truck_weight) => (
        <Space size="middle">{Truck_weight?.Truck_weight}</Space>
      )
    },

    // {
    //     title: "Truck weight",
    //     dataIndex: "Truck_weight",
    //     key: "Truck_weight",
    //     render: (_, Truck_weight) => (
    //         <Space size="middle">{Truck_weight.Truck_weight}</Space>
    //     )
    // },
    // {
    //     title: "Trucks IDs",
    //     dataIndex: "Truck_id",
    //     key: "Truck_id",
    //     render: (_, Truck_id) => <Space size="middle">{Truck_id.Truck_id}</Space>
    // },
    // {
    //     title: "Gross",
    //     dataIndex: "Gross",
    //     key: "Gross",
    //     render: (_, Gross) => <Space size="middle">{Gross.Gross}</Space>
    // },
    // {
    //     title: "Tare",
    //     dataIndex: "tare",
    //     key: "tare",
    //     render: (_, tare) => <Space size="middle">{tare.tare}</Space>
    // },
    // {
    //     title: "Net",
    //     dataIndex: "net",
    //     key: "net",
    //     render: (_, net) => <Space size="middle">{net.net}</Space>
    // },
    {
      title: "Time In",
      dataIndex: "timeIn",
      key: "timeIn",
      render: (_, timeIn) => <Space size="middle">{timeIn.timeIn}</Space>
    },
    {
      title: "Time Out",
      dataIndex: "timeOut",
      key: "timeOut",
      render: (_, timeOut) => <Space size="middle">{timeOut.timeOut}</Space>
    },

    // {
    //     title: "Scale ticket #",
    //     dataIndex: "specific_truck_no",
    //     key: "specific_truck_no",
    //     render: (_, specific_truck_no) => (
    //         <Space size="middle">{specific_truck_no.specific_truck_no}</Space>
    //     )
    // },
    // {
    //     title: "PO#",
    //     dataIndex: "PO",
    //     key: "PO",
    //     render: (_, PO) => <Space size="middle">{PO.PO}</Space>
    // },

    {
      title: "CSR classification",
      dataIndex: "CSR",
      key: "CSR",
      render: (_, CSR) => (
        <Space size="middle">{CSR?.csr_classification || "--"}</Space>
      )
    },
    // {
    //   title: "$$$ Rate",
    //   dataIndex: "rate",
    //   key: "rate",
    //   render: (_, rate) => <Space size="middle">{rate.rate}</Space>
    // },

    {
      title: "Paid",
      key: "paid",
      dataIndex: "paid",
      render: (_, paid) => (
        <div className="role">
          <p className="mb-0">{paid.paid}</p>
          {/* <div className="button-wrapper">
                        <Button
                            title="See Invoices"
                            className="aes-btn transparent-btn text-nowrap"
                        />
                        <Button
                            title="See Details"
                            className="aes-btn transparent-btn text-nowrap"
                        />
                    </div> */}
        </div>
      )
    }
  ]
  // summaryData && summaryData.fillsite[0].map(item =>(

  //    
  // ))

  return (
    <>
      <div className="table-header">
        <h3>
          {summaryData && summaryData.fill_site}
          {/* {summaryData[0].fillsite} */}
        </h3>
        {/* <Button className="aes-btn green-btn" title="See Invoices" /> */}
      </div>
      {isloading ? (
        <Table
          rowKey="key"
          pagination={false}
          dataSource={[...Array(5)].map((_, index) => ({
            key: `key${index}`
          }))}
          columns={columns.map(column => {
            return {
              ...column,
              render: function renderPlaceholder() {
                return <Skeleton width="40%" />
              }
            }
          })}
        />
      ) : (
        <Table
          className="fill-site-table single-summary-table"
          columns={columns}
          dataSource={info}
          pagination={false}
          scroll={{ x: 1470, y: 500 }}
        />
      )}
    </>
  )
}

export default SingleSummaryTable
