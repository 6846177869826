import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import backIcon from "../../../assets/images/back-icon-circle.svg"
import filter from "../../../assets/images//filter.svg"

import Filter from "../../../components/Filter"
import Search from "../../../components/Search"
import SingleSummaryTable from "../../../components/SingleSummaryTable"
import HomeLayout from "../../../layout/HomeLayout"
import { useHistory } from "react-router-dom"
import "./style.scss"
import {
  getFillSiteRequest,
  getFillSiteSuccess
} from "../AccountFillSite/redux/action"
import { DatePicker } from "antd"
import moment from "moment"

const AccountSingleSummary = props => {
  const {
    getFillSite,
    getFillsiteRequest,
    getFillSiteSuccess,
    requesting,
    fillSiteId
  } = props
  const history = useHistory()

  useEffect(() => {
    getFillsiteRequest(`${fillSiteId}/`)
  }, [])
  const [value, setValue] = useState({ min: 0, max: 50 })
  const handleOnChange = value => {
    setValue(value)
  }
  const { RangePicker } = DatePicker
  const [startDate, setStartDate] = useState("")
  const [endDate, setEndDate] = useState("")
  const [latestDate, setSetNewDate] = useState(new Date())

  const [calenderState, setCalenderState] = useState(false)

  const handleDatePicker = (dates, dateStrings) => {
    const startDateFormatted = moment(dateStrings[0]).format("YYYY-MM-DD")
    const endDateFormatted = moment(dateStrings[1]).format("YYYY-MM-DD")
    setStartDate(startDateFormatted) // set the start date state variable
    setEndDate(endDateFormatted)
  }
  useEffect(() => {
    if (startDate && endDate) {
      getFillsiteRequest(
        `${fillSiteId}/?start_date=${startDate}&end_date=${endDate}`
      )
      setCalenderState(false)
    }
  }, [startDate, endDate])

  return (
    <>
      <HomeLayout dashboardHeader={true}>
        <section className="content-section single-summary-section">
          <div
            className="single-summary-content-section"
          // onClick={() => setCalenderState(false)}
          >
            <div className="heading-Account">
              <div className="title">
                <div
                  className="back"
                  onClick={() => {
                    history.goBack()
                    getFillSiteSuccess(false)
                  }}
                >
                  <img src={backIcon} alt="" />
                </div>
                <h1>Account Overview</h1>
              </div>
              <div className="actions-wrapper">
                {/* <Search /> */}
                <div
                  className="filter-wrapper"
                  onClick={() => setCalenderState(true)}
                >
                  <div className="filter">
                    <div className="button-filter">
                      <img src={filter} />
                      <p>
                        Filter by:
                        <span
                          style={{
                            fontSize: "15px",
                            color: "#261d1d",
                            fontWeight: "500"
                          }}
                        >
                          {`${startDate
                            ? moment(startDate).format("MM/DD")
                            : moment(latestDate).format("MM/DD")
                            }`}{" "}
                          -
                          {` ${endDate
                            ? moment(endDate).format("MM/DD")
                            : moment(latestDate)
                              .subtract(7, "days")
                              .format("MM/DD")
                            }`}
                        </span>
                        <RangePicker
                          className="datePicker"
                          onChange={handleDatePicker}
                          open={calenderState}
                          onOpenChange={open => {
                            if (!open) {
                              setCalenderState(false)
                            }
                          }}
                        />
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="single-summary-table-wrapper">
              <SingleSummaryTable
                summaryData={getFillSite}
                isloading={requesting}
              />
            </div>
          </div>
        </section>
      </HomeLayout>
    </>
  )
}

const mapStateToProps = state => {
  return {
    getFillSite: state.FillsiteTable.fillsiteData,
    requesting: state.FillsiteTable.requesting,
    fillSiteId: state.login.fillSiteId
  }
}

const mapDispatchToProps = dispatch => ({
  getFillsiteRequest: id => dispatch(getFillSiteRequest(id)),
  getFillSiteSuccess: data => dispatch(getFillSiteSuccess(data))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountSingleSummary)
