import React, { useState, useEffect } from "react"
import "./style.scss"
import Button from "../../../components/Button"
import logo from "../../../assets/images/logo.svg"
import Input from "../../../components/Input"
import ForgetModal from "../../../authModal/ForgetModal"
import useForm from "../../../utils/useForm"
import validator from "../../../utils/validation"
import {
  loginRequest,
  loginRequestSuccess,
  loginRequestFailure
} from "./redux/action"
import { connect } from "react-redux"
import { useHistory } from "react-router-dom"
import toast, { Toaster } from "react-hot-toast"

const LoginScreen = props => {
  const { loginRequest, loginInfo, requesting, error, loginRequestFailure } =
    props

  const [modalShow, setModalShow] = useState(false)
  const [emailVali, setEmailVali] = useState(false)

  const history = useHistory()
  useEffect(() => {
    loginRequestFailure()
    setState(stateSchema)
  }, [])
  const stateSchema = {
    email: {
      value: "",
      error: ""
    },
    password: {
      value: "",
      error: ""
    }
  }
  const validationStateSchema = {
    email: {
      required: true,
      // validator: validator.email
    },
    password: {
      required: true
      // validator: validator.password
    }
  }
  const { state, handleOnChange, disable, setState } = useForm(
    stateSchema,
    validationStateSchema
  )
  const onSubmit = async () => {
    if(validator.email.regEx.test(state.email.value)){
      const data = {
        username: state.email.value,
        password: state.password.value
      }
      await loginRequest(data, history)
    }else{
      setEmailVali("Invalid email address.")
    }
  }

  return (
    <>
      <section className="content-section login">
        <Toaster position="top-center" />
        <div className="row">
          <div className="right-content col-md-12 col-lg-7">
            <div className="login-box">
              <h3>  My Earth Network</h3>
              <div className="logo">
                {/* <img src={logo}></img>  */}
              <br/>

                <h3>Login</h3>
              </div>
              <div className="input-wrapper">
                <div className="one-input ">
                  <h4> Email</h4>
                  <Input
                    type="email"
                    // src={emailIcon}
                    placeholder="Enter Email Address"
                    value={state.email.value}
                    onChange={val => {
                      setEmailVali(false)
                      handleOnChange("email", val.target.value)}}
                    showError={true}
                    errorMessage={emailVali || error?.username}
                  />
                </div>
                <div className="two-input">
                  <h4>Password</h4>
                  <Input
                    type="password"
                    showHideIcon={true}
                    // src={lockIcon}
                    placeholder="Enter Password"
                    value={state.password.value}
                    maxlength={16}
                    onChange={val =>
                      handleOnChange("password", val.target.value)
                    }
                    showError={true}
                    errorMessage={state.password.error || error?.password}
                  />
                </div>
              </div>
              <div className="Forgot">
                <p>
                  <a onClick={() => setModalShow(true)}>Forgot Password</a>
                </p>
              </div>
              <div className="button-wrapper">
                <Button
                  title="Login"
                  className="aes-btn"
                  showSpinner={requesting}
                  onClick={onSubmit}
                />
              </div>
            </div>
          </div>

          <div className="col-lg-5">
            <div className="left">
              <div className="img"></div>
            </div>
            {/* <Button
              title="Login"
              className="aes-btn transparent-btn"
            /> */}
          </div>
        </div>
      </section>
      <ForgetModal modalShow={modalShow} setModalShow={setModalShow} />
      {/* <SucessSignUp modalShow={modalShow} setModalShow={setModalShow} /> */}
    </>
  )
}

const mapStateToProps = state => ({
  loginInfo: state.login.loginInfo,
  requesting: state.login.requesting,
  error: state.login.error
})

const mapDispatchToProps = dispatch => ({
  loginRequest: (data, history) => dispatch(loginRequest(data, history)),
  loginRequestFailure: data => dispatch(loginRequestFailure(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(LoginScreen)
