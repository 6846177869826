import {
  CONTRACTOR_DETAIL_REQUEST,
  CONTRACTOR_DETAIL_REQUEST_FAILURE,
  CONTRACTOR_DETAIL_REQUEST_SUCCESS,
  GET_ADMINCONTRACTORS_REQUEST ,
  GET_ADMINCONTRACTORS_SUCCESS,
  USER_ACTIVECONTRACTOR_REQUEST,
  USER_ACTIVECONTRACTOR_SUCCESS,
} from "./type"

export const getAdminContractorReauest = data => ({
  type: GET_ADMINCONTRACTORS_REQUEST,
  data
})

export const getAdminContractorSuccess = data => ({
  type: GET_ADMINCONTRACTORS_SUCCESS,
  data
})
export const activeReauest = (data, id, showSuccessToast) => ({
  type: USER_ACTIVECONTRACTOR_REQUEST,
  data, id, showSuccessToast
})
export const activeSuccess = data => ({
  type: USER_ACTIVECONTRACTOR_SUCCESS,
  data
})

export const getContractorDetail = (id, data) => ({
  type: CONTRACTOR_DETAIL_REQUEST,
  id,
  data
})

export const getContractorDetailSuccess = data => ({
  type: CONTRACTOR_DETAIL_REQUEST_SUCCESS,
  data
})

export const getContractorDetailFailure = data => ({
  type: CONTRACTOR_DETAIL_REQUEST_FAILURE,
  data
})