import React, { useState, useEffect } from "react"
import "./style.scss"
import Button from "../../../components/Button"
import logo from "../../../assets/images/logo.svg"
import Input from "../../../components/Input"
import SucessSignUp from "../../../authModal/SucessSignUp"
import useForm from "../../../utils/useForm"
import validator from "../../../utils/validation"
import BoardSelect from "../../../components/BoardSelect"
import { Select } from "antd"
import { signUpRequest, getCountryRequest } from "./redux/action"
import { connect } from "react-redux"
import { toast, Toaster } from "react-hot-toast"
import AesPhoneInput from "../../../components/PhoneInput"
import { history } from "../../../reduxStore/store"

const SignUp = props => {
  const {
    signUpRequest,
    error,
    getCountryRequest,
    CountryData,
    signUpRequestFailure
  } = props

  const [modalShow, setModalShow] = useState(false)
  const [country, setCountry] = useState("")
  const [check, setCheck] = useState(false)
  const [conFirmPassError, setConFirmPassError] = useState("")

  const Option = Select

  useEffect(() => {
    getCountryRequest("")
  }, [])

  const stateSchema = {
    companyName: {
      value: "",
      error: ""
    },
    CompanyRepresentative: {
      value: "",
      error: ""
    },
    Address: {
      value: "",
      error: ""
    },
    userPhone: {
      value: "",
      error: ""
    },
    PositionCompany: {
      value: "",
      error: ""
    },
    Country: {
      value: "",
      error: ""
    },
    Password: {
      value: "",
      error: ""
    },
    BilingPhone: {
      value: "",
      error: ""
    },
    ConfirmPassword: {
      value: "",
      error: ""
    },
    AccountingEmail: {
      value: "",
      error: ""
    },
    StateRpovincet: {
      value: "",
      error: ""
    },
    City: {
      value: "",
      error: ""
    },
    PostalCode: {
      value: "",
      error: ""
    },
    Email: {
      value: "",
      error: ""
    },
    Companypostalcode: {
      value: "",
      error: ""
    }
  }
  const validationStateSchema = {
    companyName: {
      required: true
    },
    CompanyRepresentative: {
      required: true
    },
    Address: {
      required: false
    },
    userPhone: {
      required: true
    },
    PositionCompany: {
      required: true
    },
    Country: {
      required: false
    },
    Password: {
      required: true,
      validator: validator.currentPassword
    },
    BilingPhone: {
      required: false
    },
    ConfirmPassword: {
      required: false
    },
    AccountingEmail: {
      required: true,
      validator: validator.email
    },
    StateRpovincet: {
      required: false
    },
    Companypostalcode: {
      required: true
    },
    City: {
      required: false
    },
    PostalCode: {
      required: false
    },
    Email: {
      required: true
    }
  }

  const { state, handleOnChange, disable, setState } = useForm(
    stateSchema,
    validationStateSchema
  )

  const toggle = () => {
    setCheck(!check)
  }

  const signUP = async () => {
    if (check) {
      let apiData = {
        email: state.Email.value,
        password: state.Password.value,
        phone_number: `${state.userPhone.value}`,
        billing: {
          street: state.Address.value,
          city: state.City.value,
          cc_email: state.PostalCode.value,
          province: state.StateRpovincet.value,
          country_name: country,
          billing_phone_number: `${state.BilingPhone.value}`,
          accounting_email: state.AccountingEmail.value,
          city_postal_code: state.Companypostalcode.value
        },
        company: {
          company_name: state.companyName.value,

          position_in_company: state.PositionCompany.value,
          company_representative: state.CompanyRepresentative.value
        }
      }
      if (apiData.password == state.ConfirmPassword.value) {
        await signUpRequest(apiData, openmodal)
      } else if (!apiData.password) {
        setConFirmPassError("Please enter password")
      } else {
        setConFirmPassError(
          "The password and confirmation password do not match."
        )
      }
    } else {
      toast.error("Please accept the Terms and Conditions", {
        id: "ds"
      })
    }
  }

  const openmodal = () => {
    setModalShow(true)
  }

  useEffect(() => {
    if (error) {
      setState(stateSchema)
      signUpRequestFailure(false)
    }
  }, [])

  const options = CountryData?.map((items, index) => {
    return (
      <Option key={index} value={items.name}>
        {items.name}
      </Option>
    )
  })

  const handleFormatChanger = value => {
    if (value.length > 0) {
      handleOnChange("userPhone", ` +${value}`)
    } else handleOnChange("userPhone", value)
  }
  const BillingPhone = value => {
    if (value.length > 0) {
      handleOnChange("BilingPhone", ` +${value}`)
    } else handleOnChange("BilingPhone", value)
  }

  const matchPassword = value => {
    if (!state.Password.value) {
      setConFirmPassError(false)
    } else if (value == state.Password.value) {
      setConFirmPassError(false)
    } else {
      setConFirmPassError("The password does not match.")
    }
  }

  return (
    <>
      <section className="signup-section">
        <Toaster position="top-center" />

        <div className="row">
          <div className="right-content col-md-12 col-lg-8">
            <div className="logo">
              {/* <img src={logo}></img> */}
              <h3>Create Account</h3>
            </div>
            <div className="row right-row">
              <div className="col-md-6 pe-lg-5">
                <h3>Enter company information</h3>
                <div className="input-wraper">
                  <h4>Company name</h4>
                  <Input
                    placeholder="Enter company name"
                    type="text"
                    value={state.companyName.value}
                    showError={true}
                    errorMessage={
                      state.companyName.error ||
                      (!state?.companyName?.value &&
                        error?.company?.company_name)
                    }
                    onChange={val =>
                      handleOnChange("companyName", val.target.value)
                    }
                  />
                </div>
                <div className="input-wraper">
                  <h4>Company Representative</h4>
                  <Input
                    placeholder="Enter company representative"
                    type="text"
                    value={state.CompanyRepresentative.value}
                    showError={true}
                    errorMessage={
                      state.CompanyRepresentative.error ||
                      (!state?.CompanyRepresentative?.value &&
                        error?.company?.company_representative)
                    }
                    onChange={val =>
                      handleOnChange("CompanyRepresentative", val.target.value)
                    }
                  />
                </div>
                <div className="input-wraper">
                  <h4>Position in the company</h4>
                  <Input
                    placeholder="Enter text"
                    type="text"
                    showError={true}
                    errorMessage={
                      state?.PositionCompany?.error ||
                      (!state?.PositionCompany?.value &&
                        error?.company?.position_in_company)
                    }
                    value={state.PositionCompany.value}
                    onChange={val =>
                      handleOnChange("PositionCompany", val.target.value)
                    }
                  />
                </div>
                <div className="input-wraper">
                  <h4>Phone</h4>
                  <AesPhoneInput
                    placeholder="Enter phone"
                    type="number"
                    showError={true}
                    onChange={handleFormatChanger}
                    errorMessage={
                      error?.phone_number
                    }
                    // errorMessage={error?.phone_number}
                    value={state.userPhone.value}
                  />
                </div>
                <div className="input-wraper">
                  <h4>Email </h4>
                  <Input
                    placeholder="Enter Email"
                    type="email"
                    showError={true}
                    errorMessage={
                      error?.email
                      // state.Email.error ||
                    }
                    value={state.Email.value}
                    onChange={val => handleOnChange("Email", val.target.value)}
                  />
                </div>

                <div className="input-wraper">
                  <h4> Password</h4>
                  <Input
                    placeholder="Password"
                    type="password"
                    showHideIcon={true}
                    showError={true}
                    maxlength={16}
                    errorMessage={
                      state.Password.error ||
                      (!state?.Password?.value && error?.password)
                    }
                    value={state.Password.value}
                    onChange={val =>
                      handleOnChange("Password", val.target.value)
                    }
                  />
                </div>
                <div className="input-wraper">
                  <h4> Confirm Password</h4>
                  <Input
                    type="password"
                    placeholder="Confirm Password"
                    showHideIcon={true}
                    value={state.ConfirmPassword?.value}
                    showError={true}
                    maxlength={16}
                    errorMessage={conFirmPassError}
                    onChange={val => {
                      handleOnChange("ConfirmPassword", val.target.value)
                      matchPassword(val.target.value)
                    }}
                  />{" "}
                </div>
              </div>

              <div className="col-md-6 ps-lg-0">
                <h3>Enter billing information</h3>
                <div>
                  <div className="input-wrapper">
                    <h4>
                      Address
                      <span>Might be different than company information</span>
                    </h4>
                    <Input
                      placeholder="Street"
                      type="text"
                      value={state.Address.value}
                      showError={true}
                      errorMessage={
                        state.Address.error ||
                        (!state?.Address?.value && error?.billing?.street)
                      }
                      onChange={val =>
                        handleOnChange("Address", val.target.value)
                      }
                    />
                    <div className="input-wrapper">
                      <h4>City</h4>
                      <Input
                        placeholder="City"
                        type="text"
                        value={state.City.value}
                        showError={true}
                        errorMessage={
                          state.City.error ||
                          (!state?.City?.value && error?.billing?.city)
                        }
                        onChange={val =>
                          handleOnChange("City", val.target.value)
                        }
                      />
                    </div>
                    <div className="input-wrapper">
                      <h4>State / Province</h4>
                      <Input
                        placeholder="State / Province"
                        value={state.StateRpovincet.value}
                        showError={true}
                        errorMessage={
                          state.StateRpovincet.error ||
                          (!state?.StateRpovincet?.value &&
                            error?.billing?.province)
                        }
                        onChange={val =>
                          handleOnChange("StateRpovincet", val.target.value)
                        }
                      />
                    </div>
                    <div className="input-wraper">
                      <h4>Postal code</h4>
                      <Input
                        placeholder="Postal code"
                        showError={true}
                        type="text"
                        errorMessage={
                          state.Companypostalcode.error ||
                          (!state?.Companypostalcode?.value &&
                            error?.billing?.city_postal_code)
                        }
                        value={state.Companypostalcode.value}
                        onChange={val => {
                          {
                            handleOnChange(
                              "Companypostalcode",
                              val.target.value
                            )
                          }
                        }}
                      />
                    </div>

                    <div className="input-wrapper">
                      <h4>CC Email </h4>
                      <Input
                        placeholder="CC Email"
                        value={state.PostalCode.value}
                        type="text"
                        showError={true}
                        errorMessage={error?.billing?.cc_email}
                        onChange={val =>
                          handleOnChange("PostalCode", val.target.value)
                        }
                      />
                    </div>

                    <div className="input-wrapper">
                      <h4>Country</h4>
                      <BoardSelect
                        options={options}
                        showSearch={true}
                        placeholder={"Country"}
                        onChange={e => setCountry(e)}
                        filterOption={(input, option) =>
                          (option?.label ?? "").includes(input)
                        }
                        optionFilterProp="children"
                        value={country}
                      />
                      {error?.billing?.country_name && <label
                        className={`error-message ${error?.billing?.country_name ? "opacity-1" : ""
                          }`}
                      >
                        {country.length === 0
                          ? error?.billing?.country_name
                          : ""}
                      </label>}
                    </div>
                    <div className="input-wrapper">
                      <h4> Phone</h4>
                      <AesPhoneInput
                        type="number"
                        placeholder="Enter phone"
                        value={state.BilingPhone.value}
                        showError={true}
                        errorMessage={


                          error?.billing?.billing_phone_number
                        }
                        // errorMessage={error?.billing?.billing_phone_number}
                        onChange={BillingPhone}
                      />
                    </div>
                    <div className="input-wrapper">
                      <h4> Accounting email</h4>
                      <Input
                        type="email"
                        placeholder="Enter accounting email"
                        value={state.AccountingEmail.value}
                        showError={true}
                        errorMessage={
                          // state.AccountingEmail.error ||
                          // (!state?.AccountingEmail?.value &&
                          state.AccountingEmail.error
                            ? error?.billing?.accounting_email
                            : ""
                        }
                        onChange={val => {
                          handleOnChange("AccountingEmail", val.target.value)
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row check-wrapper">
                <div className=" col-md-6">
                  <from check className="mainChekWraper">
                    <input type="checkbox" onChange={() => toggle()}></input>
                    <div className="terms" style={{ whiteSpace: "nowrap" }}>
                      Agree to
                      <div style={{ whiteSpace: "nowrap" }}>
                        <a href="" onClick={e => e.preventDefault()}>
                          Terms and Conditions
                        </a>
                      </div>
                      and
                      <div style={{ whiteSpace: "nowrap" }}>
                        <a href="" onClick={e => e.preventDefault()}>
                          Privacy Policy
                        </a>
                      </div>
                    </div>
                  </from>
                </div>
                <div className="two-input col-md-6 ps-lg-0 ps-rg-0">
                  <div className="button-">
                    <Button
                      showSpinner={props.requesting}
                      onClick={signUP}
                      title="Sign Up"
                      className="aes-btn "
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="col-lg-4 p-0 left-content">
            <div className="left">
              <div className="img">

              <Button
                  onClick={() => history.push("/auth/login")}
                  title="Login"
                  className="aes-btn transparent-btn"
                />
              </div>
            </div>
          </div> */}
          <div className="col-lg-4 p-0 left-content">
            <div className="left">
              <div className="img">
                <Button
                  onClick={() => history.push("/auth/login")}
                  title="Login"
                  className="aes-btn transparent-btn"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <SucessSignUp modalShow={modalShow} setModalShow={setModalShow} />
    </>
  )
}

const mapStateToProps = state => ({
  requesting: state.signUp.requesting,
  error: state.signUp.error,
  signUpdata: state.signUp.signUpdata,
  CountryData: state.signUp.CountryData
})
const mapDispatchToProps = dispatch => ({
  signUpRequest: (data, openmodal) => dispatch(signUpRequest(data, openmodal)),
  getCountryRequest: data => dispatch(getCountryRequest(data)),

  signUpRequestFailure: data => dispatch(signUpRequest(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(SignUp)
