import React from "react"
import "./style.scss"
import DarkLogo from "../../../assets/images/logo-dark.png"
import Button from "../../../components/Button"
import { useHistory } from "react-router-dom"

const HomeScreen = props => {
  const history = useHistory()

  return (
    <>
      <section className="content-section homeScreen">
        <div className="row h-100">
          <div className="right-content col-md-12 col-lg-6">
            <div className="homelogo">
              <div className="logo">
                <img style={{ width: "270px" }} src={DarkLogo}></img>
                <div className="loginbuton">
                <Button
                  onClick={() => history.push("/auth/login")}
                  title="Login"
                  className="aes-btn transparent-btn"
                />
                  </div>
              </div>
              <div className="content-details">
                <div className="heading2">
                  <h3>
                    Welcome to GroundControl <br />
                    Soil & Aggregate Platform
                  </h3>
                  <div className="mt-lg-5 mt-md-3">
                    <div className="sub-text">
                      <div className="circle">1</div>
                      <div className="text-one">
                        <h6>Brickaton Construction Co</h6>
                        <p>The Broken of the wall then not well done.</p>
                      </div>
                    </div>
                  </div>
                  <div className="mt-lg-5 mt-md-3">
                    <div className="sub-text">
                      <div className="circle">2</div>
                      <div className="text-one">
                        <h6>Spruce Construction</h6>
                        <p>
                          The Lose of the thousand of the dollars the not
                          profit.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="mt-lg-5 mt-md-3">
                    <div className="sub-text">
                      <div className="circle">3</div>
                      <div className="text-one">
                        <h6>Modern Arch Construction</h6>
                        <p>
                          The Thousand of the value to construction in them.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="button-wrapper">
                    <Button
                      onClick={() => history.push("/auth/sign-up")}
                      title="Create Account"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className=" col-lg-6 col-md-12 img-right">
            <div className="left">
              <div className="img">
                <Button
                  onClick={() => history.push("/auth/login")}
                  title="Login"
                  className="aes-btn transparent-btn"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default HomeScreen
