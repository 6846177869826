// import { all, call, put, takeLatest } from "redux-saga/effects"
// // import AsyncStorage from '@react-native-community/async-storage';
// // config
// import { BASE_URL } from "../../../../config/app"
// // utils
// import XHR from "../../../../utils/XHR"
// // types
// import {
//   POST_REQUEST_SIGNUP,
//   POST_REQUEST_SIGNUP_SUCCESS,
//   POST_REQUEST_SIGNUP_FAILURE
// } from "./type"
// // actions
// import {
//   signUpRequest,
//   signUpRequestSuccess,
//   signUpRequestFailure
// } from "./action"
// import toast from "react-hot-toast"

// async function postLoginAPI(data) {
//   const URL = `${BASE_URL}api/v1/signup/`

//   const options = {
//     headers: {
//       Accept: "application/json",
//       "Content-Type": "application/json"
//     },
//     method: "POST",
//     data
//   }
//   return XHR(URL, options)
// }

// function* postLoginInfo({ data }) {
//   try {
//     const response = yield call(postLoginAPI, data)
//     yield put(loginRequestSuccess(response.data))
//     localStorage.setItem("token", response.data.token)
//     yield put(setAccessToken(response.data.token))
//   } catch (e) {
//     const { response } = e
//     toast.error(response.data.non_field_errors[0])
//     yield put(loginRequestFailure(response.data))
//   }
// }

// export default all([takeLatest(POST_REQUEST_LOGIN, postLoginInfo)])

import { all, call, put, takeLatest } from "redux-saga/effects"
// import AsyncStorage from '@react-native-community/async-storage';
// config
// import { BASE_URL } from "../../../../../config/app"
import { BASE_URL } from "../../../../config/app"

// utils
// import XHR from "../../../../../utils/XHR"
import XHR from "../../../../utils/XHR"

// types
import { POST_REQUEST_SIGNUP, GET_COUNTRY_REQUEST } from "./type"
// actions
import {
  signUpRequest,
  signUpRequestSuccess,
  signUpRequestFailure,
  getCountrySucess
} from "./action"
import toast from "react-hot-toast"

async function signUpRequestAPI(data) {
  const URL = `${BASE_URL}api/v1/signup/`

  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    method: "POST",
    data
  }
  return XHR(URL, options)
}

function* signUpRequestInfo({ data, openmodal }) {
  try {
    const response = yield call(signUpRequestAPI, data)

    yield put(signUpRequestSuccess(response.data))
    yield put(openmodal())
  } catch (e) {
    const { response } = e

    yield put(signUpRequestFailure(response?.data))
  }
}

function CounterDataApi(data) {
  const URL = `${BASE_URL}billing/country/`

  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    method: "GET",
    data
  }
  return XHR(URL, options)
}

function* CounterData({ data }) {
  const response = yield call(CounterDataApi, data)
  yield put(getCountrySucess(response.data))
 
}
export default all([
  takeLatest(POST_REQUEST_SIGNUP, signUpRequestInfo),
  takeLatest(GET_COUNTRY_REQUEST, CounterData)
])
