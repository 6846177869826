import { all, call, put, takeLatest } from "redux-saga/effects"
import { BASE_URL } from "../../../../config/app"
import XHR from "../../../../utils/XHR"
import {
  getScheduleFailure,
  getScheduleSuccess,
  postSpecificDateSuccess,
  postSpecificDateFailure,
  patchSoilAuthorizationSuccess,
  patchSoilAuthorizationFailure,
  getProfileFailure,
  getProfileSuccess
} from "./action"
import {
  GET_SCHEDULE_REQUEST,
  POST_SPECIFIC_DATE_REQUEST,
  PATCH_SOIL_AUTHORIZATION,
  GET_PROFILE_REQUEST
} from "./type"
import { toast } from "react-hot-toast"

const ScheduleDataAPI = filterModel => {
  const param = filterModel ? filterModel + "/" : ""
  let URL = `${BASE_URL}api/v1/request_availability/${param}`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "GET"
  }
  return XHR(URL, options)
}

function* ScheduleData({ filterModel }) {
  try {
    const response = yield call(ScheduleDataAPI, filterModel)
    yield put(getScheduleSuccess(response.data))
  } catch (e) {
    const { response } = e
    yield put(getScheduleFailure(response.data))
  }
}

const ProfileDataAPI = () => {
  let URL = `${BASE_URL}api/v1/owner_scheduled_request/`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "GET"
  }
  return XHR(URL, options)
}

function* ProfileData() {
  try {
    const response = yield call(ProfileDataAPI)
    yield put(getProfileSuccess(response.data))
  } catch (e) {
    const { response } = e
    yield put(getProfileFailure(response.data))
  }
}

const postSpecificAPI = data => {
  let URL = `${BASE_URL}api/v1/owner_scheduled_request/`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "POST",
    data
  }
  return XHR(URL, options)
}

const patchSoilAPI = (id, data) => {
  let URL = `${BASE_URL}api/v1/fill_site/update_truck_arrival/${id}/`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "PATCH",
    data
  }
  return XHR(URL, options)
}

function* postSpecificData({ data, hide }) {
  try {
    const response = yield call(postSpecificAPI, data)
    yield put(postSpecificDateSuccess(response.data))
    toast.success("Updated Successfully")
    hide()
  } catch (e) {
    const { response } = e
    yield put(postSpecificDateFailure(response.data))
    if (response?.status == 400 && response?.data?.msg) {
      toast.error(response?.data?.msg)
    } else {
      toast.error("An Error occured")
    }
  }
}

function* patchSoilData({ id, data }) {
  try {
    const response = yield call(patchSoilAPI, id, data)
    yield put(patchSoilAuthorizationSuccess(response.data))
  } catch (e) {
    const { response } = e
    yield put(patchSoilAuthorizationFailure(response.data))
    if (response?.status == 400 && response?.data?.msg) {
      toast.error(response?.data?.msg)
    }
  }
}

export default all([
  takeLatest(GET_SCHEDULE_REQUEST, ScheduleData),
  takeLatest(GET_PROFILE_REQUEST, ProfileData),
  takeLatest(POST_SPECIFIC_DATE_REQUEST, postSpecificData),
  takeLatest(PATCH_SOIL_AUTHORIZATION, patchSoilData)
])
