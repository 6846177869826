import React, { useState, useEffect, useRef } from "react"

import { Col, Collapse, DatePicker, Modal, Row, Table } from "antd"
import { Form } from "antd"

import pdfupload2 from "../../../assets/images/pdfupload2.svg"
import uploadfile from "../../../assets/images/uploadfile.svg"
import Input from "../../Input"
import "./style.scss"
import Button from "../../Button"
import { PostInvoiceFailed, PostInvoiceRequest } from "../../../pages/Admin/Invoices/redux/action"
import useForm from "../../../utils/useForm"
import AeSelect from "../../AeSelect"
import {
    activeReauest,
    activeSuccess,
    getAdminContractorReauest,
  
  } from "../../../pages/Admin/AdminContractors/redux/action"
import { useDispatch, useSelector,connect } from "react-redux"

const InvoiceUploadDocoment = props => {
    const { modalShow, setModalShow, notification, PostInvoiceRequest, postrequesting, error, PostInvoiceFailed } = props
    const [selectedFile, setSelectedFile] = useState(null)
    const [selectContractors, setSelectContractors] = useState([])
    const [contractors, setContractors] = useState([])

    const fileRef = useRef()
    const dispatch = useDispatch()

    const { getAdminContractor, AdminContractorRequesting, activeRequest, activeRequesting } = useSelector(
        state => state.adminContractor
      )
      useEffect(() => {
        dispatch(getAdminContractorReauest(""))
      }, [])

      useEffect(() => {
        if (getAdminContractor) {
            const fill_site_array = []
            getAdminContractor?.map((a, b) => {
                fill_site_array.push({ value: a.id, label: a.company.company_name })
            })
            setContractors(fill_site_array)
        }
    }, [getAdminContractor]) 
   
    const HandleCancel = () => {
        setModalShow(false)
        setState(stateSchema)
        PostInvoiceFailed(false)
        setSelectedFile(null)
        if (fileRef.current) {
            fileRef.current.value = '';
          }
        

    }
    const stateSchema = {
        Title: {
            value: "",
            error: ""
        },
    }
    const validationStateSchema = {
        Title: {
            required: true
        },
    }
    const { state, handleOnChange, disable, setState } = useForm(
        stateSchema,
        validationStateSchema
    )
    const handleChangeup = e => {
        const file = e.target.files[0]
        setSelectedFile(file)
    }

    const submit = () => {
        const formData = new FormData()
        formData.append("attachment", selectedFile)
        formData.append("invoices_number", state.Title.value)
        formData.append("contractor", selectContractors)
        PostInvoiceRequest(formData, HandleCancel)
    }




    return (
        <>

            <Modal
                title={` `}
                open={modalShow}
                onCancel={HandleCancel}
                className="UploadDocoment-modal"
                cancelButtonProps={{ style: { display: "none" } }}
                // confirmLoading={postLoading}
                footer={null}
            >


                <div className="modal-div">
                    <h3>
                        Upload Invoice
                    </h3>

                    <h2>Invoice Number </h2>
                    <Input
                        type="text"
                        placeholder="Enter Invoice Number"
                        className="tile-input"
                        value={state.Title.value}
                        showError={false}
                        errorMessage={
                            state.Title.error ||
                            (!state?.Title?.value &&
                                error?.title)
                        }

                        onChange={val =>
                            handleOnChange("Title", val.target.value)
                        }
                    />
                    <h2>Contractors</h2>
                        <Form.Item
                            name="contractors"
                            rules={[
                                {
                                    required: true,
                                    message: "This field may not be blank.",
                                },
                            ]}
                        >
                            <AeSelect
                                placeholder={"Contractors"}
                                options={contractors}
                                onChange={(e) => setSelectContractors(e)}
                            />
                        </Form.Item>
                    <label
                        className={`error-message mb-2 ${error ? "opacity-1" : ""}`}
                    >
                        {error?.title}
                    </label>
                    <input
                        type="file"
                        name="file"
                        ref={fileRef}
                        accept=".pdf"
                        onChange={handleChangeup}
                        hidden
                    />
                    <div className="uplod-file
                    
                    "
                        onClick={() => fileRef.current.click()}>

                        <div className="attch-div">
                            <img className="pdfupload2"
                                src={ selectedFile?uploadfile:pdfupload2 }

                            />
                            <div>


                                <a className="attch">{selectedFile?selectedFile.name:"Attach Document"}</a>
                            </div>
                        </div>
                    </div>
                    <label
                        className={`error-message mb-2 ${error ? "opacity-1" : ""}`}
                    >
                        {error?.attachment}
                    </label>
                    <div className="btn-wraper">

                        <Button
                            title="Upload"
                            className="aes-btn green-btn "
                            onClick={submit}
                            showSpinner={postrequesting}
                        />
                    </div>
                </div>



            </Modal>
        </>
    )
}
const mapStateToProps = state => ({
    documentData: state.DocumentAgreement.documentData,
    postrequesting: state.DocumentAgreement.postrequesting,
    error: state.DocumentAgreement.error,
})

const mapDispatchToProps = dispatch => ({
    PostInvoiceRequest: (data, handleCancel) => dispatch(PostInvoiceRequest(data, handleCancel)),
    PostInvoiceFailed: data => dispatch(PostInvoiceFailed(data)),



})

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceUploadDocoment)
