
// import { toast } from "react-hot-toast"
// import { all, call, put, takeLatest } from "redux-saga/effects"
// import { BASE_URL } from "../../../../config/app"
// import XHR from "../../../../utils/XHR"
// import {
//   getFooterRequest,
//   getFooterFailure,
  
// } from "./action"
// import {
//   GET_FOOTER_REQUEST,
//   GET_FOOTER_SUCCESS,
//   GET_FOOTER_FAILURE,
// } from "./type"

// const FooterDataAPI = async () => {
//   const URL = `${BASE_URL}/services/contact_us/company_detail/`
//   const token = localStorage.getItem("token")

//     const options = {
//       headers: {
//         Accept: "application/json",
//         "Content-Type": "application/json"
//       },
//     method: "GET"
//   }
//   return XHR(URL, options)
// }

// function* FooterData() {
//   try {
//     const response = yield call(FooterDataAPI)
//     yield put(getFooterRequest(response.data))
//   } catch (e) {
//     const { response } = e
//     yield put(getFooterFailure(response.data))
//   }
// }



// export default all([
//   takeLatest(GET_FOOTER_REQUEST, FooterData),
 
// ])

import { all, call, put, takeLatest } from "redux-saga/effects"
import { BASE_URL } from "../../../../config/app"
import XHR from "../../../../utils/XHR"
import {   getFooterSuccess,
    getFooterFailure,} from "./action"
import { GET_FOOTER_REQUEST } from "./type"

const HomeScreenAPI = async () => {
  let URL =  `${BASE_URL}services/contact_us/company_detail/`

  // const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    method: "GET",
  
  }
  return XHR(URL, options)
}

function* FooterData() {
  try {
    const response = yield call(HomeScreenAPI)

    yield put(getFooterSuccess(response?.data))
  } catch (e) {
    const { response } = e
   
    yield put(getFooterFailure(response?.data))
  }
}

export default all([takeLatest(GET_FOOTER_REQUEST, FooterData)])

