import { ArrowLeftOutlined } from '@ant-design/icons'
import React from 'react'
import './style.scss'

const Breadcrumb = ({onClick}) => {
    return (
        <div className='breadcrumbStyle' onClick={onClick} >
            <ArrowLeftOutlined />
        </div>
    )
}

export default Breadcrumb