import { all, call, put, takeLatest } from "redux-saga/effects"
import { BASE_URL } from "../../../../config/app"
import XHR from "../../../../utils/XHR"
import {
  activeSuccess,
  getAdminContractorReauest,
  getAdminContractorSuccess,
  getContractorDetailSuccess
} from "./action"
import {
  CONTRACTOR_DETAIL_REQUEST,
  GET_ADMINCONTRACTORS_REQUEST, USER_ACTIVECONTRACTOR_REQUEST
} from "./type"
import { toast } from "react-hot-toast"
import { history } from "../../../../reduxStore/store"

const AdminListAPI = async data => {
  // const id = data ? `${data}/` : ""
  const URL = `${BASE_URL}api/v1/admin/contractors_list/?approved=true${data}`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "GET"
  }
  return XHR(URL, options)
}

const AdminContractorDetailAPI = async (id, data) => {
  const URL = `${BASE_URL}api/v1/admin/contractors_list/${id}/?approved=true`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "GET",
    data: data
  }
  return XHR(URL, options)
}


function* AdminDashboardUserData({ data }) {
  try {
    const response = yield call(AdminListAPI, data)
    yield put(getAdminContractorSuccess(response?.data))
  } catch (e) {
    const { response } = e
    // yield put(getAdminDashboardListFailure(response?.data))
  }
}
const UserActiveAPI = (id, data) => {
  console.log("idid", id);
  const URL = `${BASE_URL}api/v1/admin/contractors_list/${id}/?approved=true`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "PATCH",
    data
  }
  return XHR(URL, options)
}

function* UserActiveData({ id, data, showSuccessToast }) {
  try {
    const response = yield call(UserActiveAPI, id, data)
    yield put(getAdminContractorReauest(""))
    yield put(activeSuccess(response?.data))
    yield put(showSuccessToast())
  } catch (e) {
    const { response } = e
  }
}

function* AdminContractorDetailData({ id, data }) {
  try {
    const response = yield call(AdminContractorDetailAPI, id, data)
    yield put(getContractorDetailSuccess(response?.data))
  } catch (e) {
    const { response } = e
  }
}

export default all([
  takeLatest(GET_ADMINCONTRACTORS_REQUEST, AdminDashboardUserData),
  takeLatest(USER_ACTIVECONTRACTOR_REQUEST, UserActiveData),
  takeLatest(CONTRACTOR_DETAIL_REQUEST, AdminContractorDetailData)


])
