import React, { useState, useEffect } from "react"
import Button from "../../components/Button"
import Modal from "react-bootstrap/Modal"
import Input from "../../components/Input"
import { useHistory } from "react-router-dom"
import closeIcon from "../../assets/images/closeIcon.svg"
import truck from "../../assets/images/truck.png"
import "./style.scss"
import { connect } from "react-redux"
import moment from "moment"

const DashboardModal = props => {
  const { modalShow, setModalShow, notification } = props
  return (
    <>
      <Modal
        // {...props}
        show={modalShow}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="warning-modal"
      >
        <Modal.Body className="modal-body">
          <div className="close-icon" onClick={() => setModalShow(false)}>
            <img src={closeIcon} alt="" />
          </div>
          <div className="p-3">
            <div className="time-text">
              <p>
                {moment(notification?.created_at).format("DD MMM")}
                <span>
                  {" "}
                  /{moment(notification?.created_at).format("LT")} /{" "}
                  {moment(notification?.created_at).format("dddd")}
                </span>
              </p>
            </div>
            <div className="text-body">
              {/* <h3 className="heading-text"> {notification.subject}</h3> */}
              <lable className="lable">Reason:</lable>
              <p className="para">{notification?.message}</p>
              {/* <div className="img-wrap">
              {notification?.image?.length === 0 && (
                <div
                  style={{
                    minHeight: "100px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    margin: "auto"
                  }}
                >
                  {" "}
                  no image found
                </div>
              )}
              {notification?.image &&
                notification?.image.map(item => (
                  <div>
                    <img src={item.image} />
                  </div>
                ))}
            </div> */}
            <div className="button-wraper">
            <Button title="Ok" onClick={() => setModalShow(false)} />
          </div>
            </div>
          </div>
         
        
        </Modal.Body>
        
      </Modal>
    </>
  )
}

export default DashboardModal
