import React, { useEffect, useState } from "react"
import HomeLayout from "../../../layout/HomeLayout"
import { history } from "../../../reduxStore/store"
import Button from "../../../components/Button"
import { Spinner } from "react-bootstrap"
import { connect } from "react-redux"

import "./style.scss"
import TeamMembersTable from "../../../components/TeamMembersTable"
import {
  deleteTeamMemberRequest,
  getTeamMembersRequest,
  updateTeamMembers,
  getTeamMembersSuccess
} from "./redux/action"

import { Toaster } from "react-hot-toast"

const MyTeam = props => {
  const {
    myTeamMembers,
    getTeamMembersRequest,
    deleteTeamMemberRequest,
    updateTeamMembers,
    requesting,
    deleteRequest,
    deleteStatus,
    error,
    getTeamMembersSuccess,
    loginInfo
  } = props

  const [selectMember, setSelectMember] = useState()
  useEffect(() => {
    getTeamMembersRequest("")
    getTeamMembersSuccess(false)
  }, [])

  useEffect(
    function () {
      if (deleteStatus && selectMember) {
        const storeMembers = myTeamMembers
        const newMembers = storeMembers.filter(m => m.id !== selectMember.id)
        updateTeamMembers(newMembers)
        setSelectMember()
      }
    },
    [deleteStatus]
  )

  const handleRemove = async member => {
    setSelectMember(member)
    await deleteTeamMemberRequest(member)
  }
  const handleEditTeam = async member => {
    setSelectMember(member)
    await getTeamMembersRequest(member)

    history.push("member-profile")
  }

  return (
    <>
      <HomeLayout dashboardHeader={true}   title="Team Member" subtitle="View All">
        <Toaster position="top-center" />
        {loginInfo && loginInfo?.user?.role == "Operator" || loginInfo && loginInfo?.user?.role == "Manager" ? (
          <div className="errordiv">
            <p>You are Operator and not permitted</p>
          </div>
        ) : (
          <section className="content-section team-members-section">
            <div className="team-members-content-section">
              <div className="heading-my-team">
                <h1>Manage Team</h1>
                <div className="button-wrapper">
                  <Button
                    title="Add Member"
                    className="aes-btn green-btn"
                    onClick={() => {
                      history.push("add-member")
                    }}
                  />
                </div>
              </div>
              <div className="team-members-table-wrapper">
                <TeamMembersTable
                  data={myTeamMembers}
                  onRemove={handleRemove}
                  loader={requesting}
                  handleEditTeam={handleEditTeam}
                />
                {/* {requesting ? (
            <div className="loader-container">
              <Spinner variant="primary" />
            </div>
          ) : myTeamMembers?.length === 0 ? (
            <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "70vh"
            }}
          >
            <p>Not Data found</p>
          </div>
          ) : (
            <TeamMembersTable
              data={myTeamMembers}
              onRemove={handleRemove}
              loader={requesting}
            />
          )} */}
              </div>
            </div>
          </section>
        )}
      </HomeLayout>
    </>
  )
}
const mapStateToProps = state => ({
  myTeamMembers: state.TeamMembers?.myTeamMembers?.length
    ? state.TeamMembers.myTeamMembers
    : [],

  // myTeamMembers: state.TeamMembers.myTeamMembers,
  requesting: state.TeamMembers.requesting,
  error: state.TeamMembers.error,
  deleteRequest: state.TeamMembers.deleteRequest,
  deleteStatus: state.TeamMembers.deleteStatus,
  loginInfo: state.login.loginInfo,

})

const mapDispatchToProps = dispatch => ({
  getTeamMembersRequest: id => dispatch(getTeamMembersRequest(id)),
  deleteTeamMemberRequest: member => dispatch(deleteTeamMemberRequest(member)),
  updateTeamMembers: members => dispatch(updateTeamMembers(members)),
  getTeamMembersSuccess: data => dispatch(getTeamMembersSuccess(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(MyTeam)
//export default MyTeam
