export const GET_SITE_API_DATA = "GET_SITE_API_DATA";
export const GET_SITE_API_DATA_SUCCESS = "GET_SITE_API_DATA_SUCCESS";
export const GET_SITE_API_DATA_FAILURE = "GET_SITE_API_DATA_FAILURE";

export const POST_SITE_API_DATA = "POST_SITE_API_DATA";
export const POST_SITE_API_DATA_SUCCESS = "POST_SITE_API_DATA_SUCCESS";
export const POST_SITE_API_DATA_FAILURE = "POST_SITE_API_DATA_FAILURE";



export const PATCH_SITE_API_DATA = "PATCH_SITE_API_DATA";