import {
    GET_ACCOUNT_REQUEST,
    GET_ACCOUNT_REQUEST_FAILURE,
    GET_ACCOUNT_REQUEST_SUCCESS,
    PATCH_ACCOUNT_REQUEST,
    PATCH_ACCOUNT_REQUEST_FAILURE,
    PATCH_ACCOUNT_REQUEST_SUCCESS
} from "./type"


const initialState = {
    accountRequestData: false,
    requesting: false,
    error: false,

    patchRequestData: false,
    patchRequesting: false,
    patchError: false,
}


export default (state = initialState, action) => {
    switch (action.type) {
        case GET_ACCOUNT_REQUEST:
            return {
                ...state,
                requesting: true
            }
        case GET_ACCOUNT_REQUEST_SUCCESS:
            return {
                ...state,
                requesting: false,
                accountRequestData: action.data
            }

        case GET_ACCOUNT_REQUEST_FAILURE:
            return {
                ...state,
                requesting: false,
                error: action.data
            }

        case PATCH_ACCOUNT_REQUEST:
            return {
                ...state,
                patchRequesting: true
            }
        case PATCH_ACCOUNT_REQUEST_SUCCESS:
            return {
                ...state,
                patchRequesting: false,
                patchRequestData: action.data
            }

        case PATCH_ACCOUNT_REQUEST_FAILURE:
            return {
                ...state,
                patchRequesting: false,
                patchError: action.data
            }
        default:
            return state
    }
}
