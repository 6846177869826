

export const GET_OWNER_NOTIFICATION_REQUEST = "GET_OWNER_NOTIFICATION_REQUEST"
export const GET_OWNER_NOTIFICATION_SUCCESS = "GET_OWNER_NOTIFICATION_SUCCESS"
export const GET_OWNER_NOTIFICATION_FAILURE = "GET_OWNER_NOTIFICATION_FAILURE"
export const GET_OWNER_MODAL_DATA_SUCCESS = "GET_OWNER_MODAL_DATA_SUCCESS"


// NOTIFICATION DETAIL
// export const GET_NOTIFICATION_MODAL_DATA = "GET_NOTIFICATION_MODAL_DATA"
// export const GET_NOTIFICATION_MODAL_SUCCESS = " GET_NOTIFICATION_MODAL_SUCCESS"
// export const GET_NOTIFICATION_MODAL_FAILURE = "GET_NOTIFICATION_MODAL_FAILURE"

// export const GET_NOTIFICATION_REQUEST = 'GET_NOTIFICATION_REQUEST'
// export const GET_NOTIFICATION_SUCCESS = 'GET_NOTIFICATION_SUCCESS';
// export const  GET_NOTIFICATION_FAILURE = 'GET_NOTIFICATION_FAILURE';

// Pagination
// export const PAGE_REQUEST = "PAGE_REQUEST"
// export const PAGE_SUCCESS = "PAGE_SUCCESS"
// export const PAGE_FALUIRE = "PAGE_FALUIRE"
