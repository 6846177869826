import {
  POST_REQUEST_SIGNUP,
  POST_REQUEST_SIGNUP_SUCCESS,
  POST_REQUEST_SIGNUP_FAILURE,
  GET_COUNTRY_REQUEST,
  GET_COUNTRY_SUCCESS
} from "./type"

// POST_PAYMENT_INFORMATION
export const signUpRequest = (data, openmodal) => ({
  type: POST_REQUEST_SIGNUP,
  data,
  openmodal
})

export const signUpRequestSuccess = data => ({
  type: POST_REQUEST_SIGNUP_SUCCESS,
  data
})

export const signUpRequestFailure = data => ({
  type: POST_REQUEST_SIGNUP_FAILURE,
  data
})
export const getCountryRequest = data => ({
  type: GET_COUNTRY_REQUEST,
  data
})
export const getCountrySucess = data => ({
  type: GET_COUNTRY_SUCCESS,
  data
})

// export const logOut = () => ({
//     type: LOGOUT,
//   })
