import {
    GET_DOCUMENT_REQUEST,
    GET_DOCUMENT_SUCCESS,
    DELETE_DOCUMENT_REQUEST,
    DELETE_DOCUMENT_SUCCESS,

    POST_DOCUMENT_REQUEST,
    POST_DOCUMENT_SUCCESS,
    POST_DOCUMENT_FAILED,


} from "./types"
export const getDocumentRequest = data => ({
    type: GET_DOCUMENT_REQUEST,
    data
})

export const getDocumentSucess = data => ({
    type: GET_DOCUMENT_SUCCESS,
    data
})
export const DeleteDocumentRequest = data => ({
    type: DELETE_DOCUMENT_REQUEST,
    data
})

export const DeleteDocumentSucess = data => ({
    type: DELETE_DOCUMENT_SUCCESS,
    data
})
export const PostDocumentRequest = (data,handleCancel) => ({
    type: POST_DOCUMENT_REQUEST,
    data,handleCancel
})
export const PostDocumentSucess = data => ({
    type: POST_DOCUMENT_SUCCESS,
    data
})
export const PostDocumentFailed = data => ({
    type: POST_DOCUMENT_FAILED,
    data
})