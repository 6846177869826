import {
  GET_INVOICE_DATA,
  GET_INVOICE_DATA_SUCCESS,
  GET_INVOICE_DATA_FAILURE,
  GET_SINGLE_INVOICE_DATA,
  GET_SINGLE_INVOICE_DATA_SUCCESS,
  GET_SINGLE_INVOICE_DATA_FAILURE,
  PATCH_INVOICE_STATUS,
  PATCH_INVOICE_STATUS_SUCCESS,
  PATCH_INVOICE_STATUS_FAILURE,
  POST_INVOICE_REQUEST,
  POST_INVOICE_SUCCESS
} from "./type"

export const getInvoiceRequest = data => ({
  type: GET_INVOICE_DATA,
  data
})

export const getInvoiceRequestSucess = data => ({
  type: GET_INVOICE_DATA_SUCCESS,
  data
})
export const getInvoiceRequestFailure = data => ({
  type: GET_INVOICE_DATA_FAILURE,
  data
})

export const getSingleInvoiceRequest = data => ({
  type: GET_SINGLE_INVOICE_DATA,
  data
})

export const getSingleInvoiceRequestSucess = data => ({
  type: GET_SINGLE_INVOICE_DATA_SUCCESS,
  data
})
export const getSingleInvoiceRequestFailure = data => ({
  type: GET_SINGLE_INVOICE_DATA_FAILURE,
  data
})

export const patchInvoiceStatus = (id, data) => ({
  type: PATCH_INVOICE_STATUS,
  id,
  data
})

export const patchInvoiceStatusSucess = data => ({
  type: PATCH_INVOICE_STATUS_SUCCESS,
  data
})
export const patchInvoiceStatusFailure = data => ({
  type: PATCH_INVOICE_STATUS_FAILURE,
  data
})

export const PostInvoiceRequest = (data,handleCancel) => ({
  type: POST_INVOICE_REQUEST,
  data,handleCancel
})
export const PostInvoiceSucess = data => ({
  type: PATCH_INVOICE_STATUS_SUCCESS,
  data
})
export const PostInvoiceFailed = data => ({
  type: PATCH_INVOICE_STATUS_FAILURE,
  data
})