import React from "react";
import { Spinner } from "react-bootstrap";

import './style.scss';
const Button = ({
  title = "",
  className = "aes-btn",
  loading = "",
  showIcon,
  showSpinner,
  src,
  onClick,
  type,
  ...props
}) => {
  const iconSrc = showIcon ? showIcon : false
  const spinner = showSpinner ? showSpinner : false
  return (
    <button
      className={`${className}`}
      onClick={onClick}
      disabled={props.disabled || props.loading}
      type={type}
    >
      {title}
      {iconSrc && (
        <span className="icon">
          <img src={src} alt="icon" />
        </span>
      )}
      {spinner && (
        <Spinner as="span" animation="border" size="sm" className="ms-2" />
      )}
    </button>
  )
}
export default Button
