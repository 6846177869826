import { all, call, put, takeLatest } from "redux-saga/effects"
import { BASE_URL } from "../../../../config/app"
import XHR from "../../../../utils/XHR"
import {
  getOwnerNotificationSuccess,
  getOwnerNotificationFailure,
  getOwnerModaldatasuccess,

} from "./action"
import {
  GET_OWNER_NOTIFICATION_REQUEST,


} from "./type"

const NotificationOwnerDataAPI = (filterModel, id) => {

  let URL = `${BASE_URL}api/v1/fillsite/notification/${filterModel}`


  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "GET"
  }
  return XHR(URL, options)
}

function* NotificationOwnerData({ filterModel, id }) {


  try {
    const response = yield call(NotificationOwnerDataAPI, filterModel)

    if (id) {
      yield put(getOwnerModaldatasuccess(response.data))
    } else {
      yield put(getOwnerNotificationSuccess(response.data))

    }

  } catch (e) {
    const { response } = e
    yield put(getOwnerNotificationFailure(response.data))
  }
}


export default all([


  takeLatest(GET_OWNER_NOTIFICATION_REQUEST, NotificationOwnerData),

])
