import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import backIcon from "../../../assets/images/back-icon-circle.svg"

import InvoiceDetailsdDrop from "../../../components/InvoiceDetailsdDrop"

import HomeLayout from "../../../layout/HomeLayout"

import { useHistory } from "react-router-dom"

import "./style.scss"
import { getInvoicesRequest } from "../AccountFillSite/redux/action"

const InvoiceDetails = props => {
  const { novicesData, Novicesrequesting } = useSelector(
    state => state.FillsiteTable
  )
  const { invoiceId } = useSelector(state => state.login)
  const dispatch = useDispatch()
  const history = useHistory()
  useEffect(() => {
    dispatch(getInvoicesRequest(`${invoiceId}/`))
  }, [])

  return (
    <>
      <HomeLayout dashboardHeader={true}>
        <section className="content-section invoice-details-section">
          <div className="invoice-details-content-section">
            <div className="heading-invoice-details">
              <div className="title">
                <div className="back" onClick={() => history.goBack()}>
                  <img src={backIcon} alt="" />
                </div>
                <h3>Invoice Details</h3>
              </div>
            </div>
            {!Novicesrequesting && (
              <div className="invoice-details-table-wrapper">
                <InvoiceDetailsdDrop novicesData={novicesData} />
              </div>
            )}
          </div>
        </section>
      </HomeLayout>
    </>
  )
}

export default InvoiceDetails
