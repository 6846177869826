import {
  POST_SOIL_ACCEPT_REQUEST,
  POST_SOIL_ACCEPT_SUCCESS,
  POST_SOIL_ACCEPT_FAILURE,
  GET_ADMIN_FILL_SITE,
  SUCCESS_GETTING_ADMIN_FILL_SITE,
  ERROR_GETTING_ADMIN_FILL_SITE
} from "./type"

const initialState = {
  error: false,
  postSoilAccept: false,
  postSoilAcceptRequesting: false,
  getAdminFillSite: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case POST_SOIL_ACCEPT_REQUEST:
      return {
        ...state,
        postSoilAcceptRequesting: true
      }
    case POST_SOIL_ACCEPT_SUCCESS:
      return {
        ...state,
        postSoilAcceptRequesting: false,
        postSoilAccept: action.data
      }

    case POST_SOIL_ACCEPT_FAILURE:
      return {
        ...state,
        postSoilAcceptRequesting: false,
        error: action.data
      }

    case GET_ADMIN_FILL_SITE:
      return {
        ...state,
        requesting: true
      }
    case SUCCESS_GETTING_ADMIN_FILL_SITE:
      return {
        ...state,
        requesting: false,
        getAdminFillSite: action.data
      }

    case ERROR_GETTING_ADMIN_FILL_SITE:
      return {
        ...state,
        requesting: false,
        error: action.data
      }

    default:
      return state
  }
}
