import React from 'react';
import PhoneInput from 'react-phone-input-2';
// import profileIcon from '../../assets/images/profile.svg';

import './style.scss';

const AesPhoneInput = ({
    onChange,
    value,
    placeholder,
    errorMessage = "",
    showError,
    disabled,
    onFocus
}) => {

    return (
        <div className="phone-input-wrapper">
            <PhoneInput
                country={'us'}
                value={value}
                placeholder={placeholder}
                specialLabel={false}
                onChange={onChange}
                disabled={disabled ? disabled : false}
                masks={{ us: '... ...-....' }}
                enableAreaCodes={true}
                onFocus={onFocus}
                isValid={(value, country) => {
                    if (value.match(value)) {
                        return 'Invalid value: ' + value + ', ' + country.name;
                    } else if (value.match(value)) {
                        return false
                    } else {
                        return true;
                    }
                }}
            />
            {errorMessage && <label
                className={`error-message ${errorMessage ? "displayed" : ""}`}
            >
                {errorMessage}
            </label>}
        </div>
    )
}

export default AesPhoneInput