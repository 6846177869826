import {
  GET_PROJECT_LIST_REQUEST,
  GET_PROJECT_LIST_SUCCESS,
  GET_PROJECT_LIST_FAILURE,
  GET_ID_PROJECT,
  GET_ID_SUCCESS,
  GET_ID_FAILURE,

} from "./type"

export const getProjectListRequest = (data) => ({
  type: GET_PROJECT_LIST_REQUEST,
  data
  //filterModel
})


export const getProjectListSuccess = data => ({
  type: GET_PROJECT_LIST_SUCCESS,
  data
})

export const getProjectListFailure = data => ({
  type: GET_PROJECT_LIST_FAILURE,
  data
})

export const getIdProject = member => ({
  type:GET_ID_PROJECT,
  member
})

export const getIdSuccess = (data) => ({
  type:GET_ID_SUCCESS,
  data
})

export const getIdFailure = data => ({
  type:GET_ID_FAILURE,
  data
})

