import React, { useEffect, useState } from "react"
import Header from "../Header"
import HomeLayout from "../../../layout/HomeLayout"
import testingSoil1 from "../images/testingSoil-1.svg"
import testingSoil2 from "../images/testingSoil-2.svg" 
import testingSoil3 from "../images/testingSoil-3.svg"
import mediumshot from "../images/medium-shot-men.png"


import "./services.scss"
import Foter from "../Foter"
const Services = props => {
  return (
    <>
      {/* <HomeLayout dashboardHeader={true}> */}
      <section style={{}} >
      <Header/>
      
      <section class="banner-img-services">
          <div
            class="bg-section-services"
            //  style="background-image: url({% static "images/agronomist-hands.png" %})"
          >
            <div class="services-section">
              <h2 class="heading-Services">Our Services</h2>
              <div>
                <p class="main-para">
                  Vivamus porttitor fringilla mi, vitae pellentesque massa
                  iaculis nec. Sed
                </p>
                <p class="main-para m-0">
                  congue neque dolor, vitae tempus lectus hendrerit acen
                </p>
              </div>
            </div>
          </div>
        </section>
        <section class="card-data">
          <div class="container">
            <div class="row maindiv">
              <div class="col-lg-4 col-sm-6 mt-sm-2 mb-3">
                <div class="card visitingCard">
                  <img
                    class="testingSoil"
                    src={testingSoil2}
                  />
                  <h4 class="testingSoil-heading">Testing Soil</h4>
                  <p class="testingSoil-para">
                    Donec rutrum rhoncus pharetra. Aliquam aliquam felis augue,
                    id blandit nulla feugiat in. Sed pellentesque mauris nec
                    odio tristique mattis. Integer condimentum.
                  </p>
                </div>
              </div>
              <div class="col-lg-4 col-sm-6 mt-sm-2 mb-3">
                <div class="card visitingCard">
                  <img
                    class="testingSoil"
                    src={testingSoil1}
                  />
                  <h4 class="testingSoil-heading">Testing Soil</h4>
                  <p class="testingSoil-para">
                    Donec rutrum rhoncus pharetra. Aliquam aliquam felis augue,
                    id blandit nulla feugiat in. Sed pellentesque mauris nec
                    odio tristique mattis. Integer condimentum.
                  </p>
                </div>
              </div>
              <div class="col-lg-4 col-sm-6 mt-sm-2 mb-3">
                <div class="card visitingCard">
                  <img
                    class="testingSoil"
                    src={testingSoil3}

                  />
                  <h4 class="testingSoil-heading">Testing Soil</h4>
                  <p class="testingSoil-para">
                    Donec rutrum rhoncus pharetra. Aliquam aliquam felis augue,
                    id blandit nulla feugiat in. Sed pellentesque mauris nec
                    odio tristique mattis. Integer condimentum.
                  </p>
                </div>
              </div>
            </div>
            <div class="container detail-container">
              <div class="row detail-row">
                <div class="col-lg-6 detail-description">
                  <p class="about">About us</p>
                  <h3 class="about-heading">
                    SHAPING THE FUTURE OF SUSTAINABLE BUILDING
                  </h3>
                  <p class="para">
                    Donec rutrum rhoncus pharetra. Aliquam aliquam felis augue,
                    id blandit nulla feugiat in. Sed pellentesque mauris nec
                    odio tristique mattis. Integer condimentum non metus ac
                    consectetur. Aliquam ultricies dolor sed elementum dictum.
                    Fusce facilisis tincidunt arcu tristique consectetur. Mauris
                    interdum quam sed diam tincidunt.
                  </p>
                </div>
                <div class="col-lg-6 detail-description worker-img">
                  <img
                    class="about-img"
                    src={mediumshot}
                  />
                </div>
              </div>
              <div class="row detail-row mb-0">
                <div class="col-lg-6 detail-description">
                  <img
                    class="about-img"

                    src={mediumshot}

                  />
                </div>
                <div class="col-lg-6 detail-description">
                  <p class="about">About us</p>
                  <h3 class="about-heading">
                    SHAPING THE FUTURE OF SUSTAINABLE BUILDING
                  </h3>
                  <p class="para">
                    Donec rutrum rhoncus pharetra. Aliquam aliquam felis augue,
                    id blandit nulla feugiat in. Sed pellentesque mauris nec
                    odio tristique mattis. Integer condimentum non metus ac
                    consectetur. Aliquam ultricies dolor sed elementum dictum.
                    Fusce facilisis tincidunt arcu tristique consectetur. Mauris
                    interdum quam sed diam tincidunt.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Foter/>
        </section>
  
    </>

  )
}

export default Services

