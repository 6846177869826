import { Checkbox, Col, Row, Select } from "antd"
import React, { useState, useEffect } from "react"
import BoardSelect from "../../../../components/BoardSelect"
import Button from "../../../../components/Button"
import Input from "../../../../components/Input"
import AesPhoneInput from "../../../../components/PhoneInput"
import HomeLayout from "../../../../layout/HomeLayout"
import "./style.scss"
import useForm from "../../../../utils/useForm"
import { postData, postDataSuccess } from "./redux/action"
import { getCountryRequest } from "../../../AuthScreens/SignUp/redux/action"
import { connect } from "react-redux"
import { Toaster } from "react-hot-toast"
// import {backIcon} from "../../../../"
import backIcon from "../../../../assets/images/back-icon-circle.svg"
import { history } from "../../../../reduxStore/store"
import validator from "../../../../utils/validation"

const NewContractors = props => {
  const {
    postData,
    getCountryRequest,
    CountryData,
    PostError,
    PostLoading,
    PostInfo,
    postDataSuccess,
    error
  } = props
  const [country, setCountry] = useState("")
  const [conFirmPassError, setConFirmPassError] = useState("")
  const [siteOffers, setSiteOffers] = useState({
    check1: false,
    check2: false,
    check3: false,
    check4: false,

  })

  const { Option } = Select
  const stateSchema = {
    companyName: {
      value: "",
      error: ""
    },
    CompanyRepresentative: {
      value: "",
      error: ""
    },
    Address: {
      value: "",
      error: ""
    },
    userPhone: {
      value: "",
      error: ""
    },
    PositionCompany: {
      value: "",
      error: ""
    },
    Country: {
      value: "",
      error: ""
    },
    Password: {
      value: "",
      error: ""
    },
    BilingPhone: {
      value: "",
      error: ""
    },
    ConfirmPassword: {
      value: "",
      error: ""
    },
    AccountingEmail: {
      value: "",
      error: ""
    },
    StateRpovincet: {
      value: "",
      error: ""
    },
    City: {
      value: "",
      error: ""
    },
    PostalCode: {
      value: "",
      error: ""
    },
    Email: {
      value: "",
      error: ""
    },
    Companypostalcode: {
      value: "",
      error: ""
    }
  }
  const validationStateSchema = {
    companyName: {
      required: true
    },
    CompanyRepresentative: {
      required: true
    },
    Address: {
      required: false
    },
    userPhone: {
      required: true
    },
    PositionCompany: {
      required: true
    },
    Country: {
      required: false
    },
    Password: {
      required: true,
      validator: validator.currentPassword
    },
    BilingPhone: {
      required: false
    },
    ConfirmPassword: {
      required: false
    },
    AccountingEmail: {
      required: true,
      validator: validator.email
    },
    StateRpovincet: {
      required: false
    },
    Companypostalcode: {
      required: true
    },
    City: {
      required: false
    },
    PostalCode: {
      required: false
    },
    Email: {
      required: true
    }
  }
  const options = CountryData?.map((items, index) => {
    return (
      <Option key={index} value={items.name}>
        {items.name}
      </Option>
    )
  })
  const { state, handleOnChange, disable, setState } = useForm(
    stateSchema,
    validationStateSchema
  )
  useEffect(() => {
    getCountryRequest("")
  }, [])
  const onSubmit = async () => {
    let apiData = {
      email: state.Email.value,
      password: state.Password.value,
      phone_number: `${state.userPhone.value}`,
      billing: {
        street: state.Address.value,
        city: state.City.value,
        cc_email: state.PostalCode.value,
        province: state.StateRpovincet.value,
        country_name: country,
        billing_phone_number: `${state.BilingPhone.value}`,
        accounting_email: state.AccountingEmail.value,
        city_postal_code: state.Companypostalcode.value
      },
      company: {
        company_name: state.companyName.value,

        position_in_company: state.PositionCompany.value,
        company_representative: state.CompanyRepresentative.value
      }
    }
    if (apiData.password == state.ConfirmPassword.value) {
      //await signUpRequest(apiData, openmodal)
    } else if (!apiData.password) {
      setConFirmPassError("Please enter password")
    } else {
      setConFirmPassError(
        "The password and confirmation password do not match."
      )
    }
  }
  const handleFormatChanger = value => {
    if (value.length > 0) {
      handleOnChange("userPhone", ` +${value}`)
    } else handleOnChange("userPhone", value)
  }
  const BillingPhone = value => {
    if (value.length > 0) {
      handleOnChange("BilingPhone", ` +${value}`)
    } else handleOnChange("BilingPhone", value)
  }
  const matchPassword = value => {
    if (!state.Password.value) {
      setConFirmPassError(false)
    } else if (value == state.Password.value) {
      setConFirmPassError(false)
    } else {
      setConFirmPassError("The password does not match.")
    }
  }
  return (
    <HomeLayout dashboardHeader={true}>
      <Toaster />

      <div className="siteContainer">
        <div className="heading">
          <div className="back" onClick={() => history.push("Admin-Contractors")}>
            <img src={backIcon} alt="" />
          </div>
          <h1>Add Contractor</h1>
        </div>

        <Row gutter={10}>
           <Col span={8} className="inputWrapper">
              <h2>Company name</h2>
              <Input
                    placeholder="Enter company name"
                    type="text"
                    value={state.companyName.value}
                    showError={true}
                    errorMessage={
                      state.companyName.error ||
                      (!state?.companyName?.value &&
                        error?.company?.company_name)
                    }
                    onChange={val =>
                      handleOnChange("companyName", val.target.value)
                    }
                  />
           </Col>
           <Col span={8} className="inputWrapper">
                <h2>Company Representative</h2>
                  <Input
                    placeholder="Enter company representative"
                    type="text"
                    value={state.CompanyRepresentative.value}
                    showError={true}
                    errorMessage={
                      state.CompanyRepresentative.error ||
                      (!state?.CompanyRepresentative?.value &&
                        error?.company?.company_representative)
                    }
                    onChange={val =>
                      handleOnChange("CompanyRepresentative", val.target.value)
                    }
                  />
           </Col>
           <Col span={8} className="inputWrapper">
              <h2>Position in the company</h2>
                  <Input
                    placeholder="Enter text"
                    type="text"
                    showError={true}
                    errorMessage={
                      state?.PositionCompany?.error ||
                      (!state?.PositionCompany?.value &&
                        error?.company?.position_in_company)
                    }
                    value={state.PositionCompany.value}
                    onChange={val =>
                      handleOnChange("PositionCompany", val.target.value)
                    }
                  />
           </Col>   
           <Col span={8} className="inputWrapper">
              <h2>Billing Address</h2>
              <Input
                      placeholder="Street"
                      type="text"
                      value={state.Address.value}
                      showError={true}
                      errorMessage={
                        state.Address.error ||
                        (!state?.Address?.value && error?.billing?.street)
                      }
                      onChange={val =>
                        handleOnChange("Address", val.target.value)
                      }
                    />
           </Col>  
           <Col span={8} className="inputWrapper">
              <h2>Billing City</h2>
              <Input
                        placeholder="City"
                        type="text"
                        value={state.City.value}
                        showError={true}
                        errorMessage={
                          state.City.error ||
                          (!state?.City?.value && error?.billing?.city)
                        }
                        onChange={val =>
                          handleOnChange("City", val.target.value)
                        }
                      />
           </Col> 
           <Col span={8} className="inputWrapper">
              <h2>Billing State / Province</h2>
              <Input
                        placeholder="State / Province"
                        value={state.StateRpovincet.value}
                        showError={true}
                        errorMessage={
                          state.StateRpovincet.error ||
                          (!state?.StateRpovincet?.value &&
                            error?.billing?.province)
                        }
                        onChange={val =>
                          handleOnChange("StateRpovincet", val.target.value)
                        }
                      />
           </Col> 
           <Col span={8} className="inputWrapper">
              <h2>Billing Postal code</h2>
              <Input
                        placeholder="Postal code"
                        showError={true}
                        type="text"
                        errorMessage={
                          state.Companypostalcode.error ||
                          (!state?.Companypostalcode?.value &&
                            error?.billing?.city_postal_code)
                        }
                        value={state.Companypostalcode.value}
                        onChange={val => {
                          {
                            handleOnChange(
                              "Companypostalcode",
                              val.target.value
                            )
                          }
                        }}
                      />
           </Col> 
           <Col span={8} className="inputWrapper">
              <h2>CC Email</h2>
              <Input
                        placeholder="CC Email"
                        value={state.PostalCode.value}
                        type="text"
                        showError={true}
                        errorMessage={error?.billing?.cc_email}
                        onChange={val =>
                          handleOnChange("PostalCode", val.target.value)
                        }
                      />
           </Col> 
           <Col span={8} className="inputWrapper">
              <h2>Country</h2>
              <BoardSelect
                        options={options}
                        showSearch={true}
                        placeholder={"Country"}
                        onChange={e => setCountry(e)}
                        filterOption={(input, option) =>
                          (option?.label ?? "").includes(input)
                        }
                        optionFilterProp="children"
                        value={country}
                      />
           </Col> 
           <Col span={8} className="inputWrapper">
              <h2>Billing Phone</h2>
              <AesPhoneInput
                        type="number"
                        placeholder="Enter phone"
                        value={state.BilingPhone.value}
                        showError={true}
                        errorMessage={


                          error?.billing?.billing_phone_number
                        }
                        // errorMessage={error?.billing?.billing_phone_number}
                        onChange={BillingPhone}
                      />
           </Col> 
           <Col span={8} className="inputWrapper">
              <h2>Accounting Email</h2>
              <Input
                        type="email"
                        placeholder="Enter accounting email"
                        value={state.AccountingEmail.value}
                        showError={true}
                        errorMessage={
                          // state.AccountingEmail.error ||
                          // (!state?.AccountingEmail?.value &&
                          state.AccountingEmail.error
                            ? error?.billing?.accounting_email
                            : ""
                        }
                        onChange={val => {
                          handleOnChange("AccountingEmail", val.target.value)
                        }}
                      />
           </Col> 
          </Row> 
          <Row gutter={10}>
             <h5 style={{paddingLeft:5,paddingTop:20,paddingBottom:10}}>Account Information</h5>
          </Row>
          <Row gutter={10}>
           
          <Col span={8} className="inputWrapper">
              <h2>Phone</h2>
              <AesPhoneInput
                    placeholder="Enter phone"
                    type="number"
                    showError={true}
                    onChange={handleFormatChanger}
                    errorMessage={
                      error?.phone_number
                    }
                    // errorMessage={error?.phone_number}
                    value={state.userPhone.value}
                  />
           </Col>  
           <Col span={8} className="inputWrapper">
              <h2>Email</h2>
              <Input
                    placeholder="Enter Email"
                    type="email"
                    showError={true}
                    errorMessage={
                      error?.email
                      // state.Email.error ||
                    }
                    value={state.Email.value}
                    onChange={val => handleOnChange("Email", val.target.value)}
                  />
           </Col> 
           <Col span={8} className="inputWrapper">
              <h2>Password</h2>
              <Input
                    placeholder="Password"
                    type="password"
                    showHideIcon={true}
                    showError={true}
                    maxlength={16}
                    errorMessage={
                      state.Password.error ||
                      (!state?.Password?.value && error?.password)
                    }
                    value={state.Password.value}
                    onChange={val =>
                      handleOnChange("Password", val.target.value)
                    }
                  />
           </Col> 
           <Col span={8} className="inputWrapper">
              <h2>Confirm Password</h2>
              <Input
                    type="password"
                    placeholder="Confirm Password"
                    showHideIcon={true}
                    value={state.ConfirmPassword?.value}
                    showError={true}
                    maxlength={16}
                    errorMessage={conFirmPassError}
                    onChange={val => {
                      handleOnChange("ConfirmPassword", val.target.value)
                      matchPassword(val.target.value)
                    }}
                  />
           </Col> 
        </Row>
        <Button
          title="Save"
          className="SaveBtnStyle"
          onClick={onSubmit}
          showSpinner={PostLoading}
        />
      </div>
    </HomeLayout>
  )
}
const mapStateToProps = state => ({
  CountryData: state.signUp.CountryData,
  PostError: state.NewFileSite.PostError,
  PostLoading: state.NewFileSite.PostLoading,
  PostInfo: state.NewFileSite.PostInfo
})

const mapDispatchToProps = dispatch => ({
  postData: data => dispatch(postData(data)),
  getCountryRequest: data => dispatch(getCountryRequest(data)),
  postDataSuccess: data => dispatch(postDataSuccess(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(NewContractors)
