import { all, call, put, takeLatest } from "redux-saga/effects"
import { BASE_URL } from "../../../../config/app"
import XHR from "../../../../utils/XHR"
import {
  getOwnerTeamMembersSuccess,
  getOwnerTeamMembersFailure,
  deleteOwnerTeamMemberSuccess,
  deleteOwnerTeamMemberFailure,
  updateOwnerTeamMembersSuccess
} from "./action"
import {
  GET_OWNER_TEAM_MEMBERS,
  DELETE_OWNER_TEAM_MEMBER,
  UPDATE_OWNER_TEAM_MEMBERS
} from "./type"

const OwnerTeamMemebrsDataAPI = async () => {
  const URL = `${BASE_URL}fillsite/team_member/`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "GET"
  }
  return XHR(URL, options)
}

function* OwnerTeamMemebersData() {
  try {
    const response = yield call(OwnerTeamMemebrsDataAPI)
    yield put(getOwnerTeamMembersSuccess(response.data))
  } catch (e) {
    const { response } = e
    yield put(getOwnerTeamMembersFailure(response.data))
  }
}

// Remove team member
const DeleteOwnerTeamMemebrAPI = async member => {
  const URL = `${BASE_URL}fillsite/team_member/${member.id}/`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "DELETE"
  }
  return XHR(URL, options)
}

function* OwnerTeamMemeberDelete({ member }) {
  try {
    const response = yield call(DeleteOwnerTeamMemebrAPI, member)
   
    yield put(deleteOwnerTeamMemberSuccess())
  } catch (e) {
    const { response } = e
  
    yield put(deleteOwnerTeamMemberFailure(response.data))
  }
}

function* OwnerTeamMemebersUpdate({ members }) {
  try {
    yield put(updateOwnerTeamMembersSuccess(members))
  } catch (e) {}
}

export default all([
  takeLatest(GET_OWNER_TEAM_MEMBERS, OwnerTeamMemebersData),
  takeLatest(DELETE_OWNER_TEAM_MEMBER, OwnerTeamMemeberDelete),
  takeLatest(UPDATE_OWNER_TEAM_MEMBERS, OwnerTeamMemebersUpdate)
])
