import React, { useEffect, useState } from "react"
import "./style.scss"
import { useHistory } from "react-router-dom"
import HomeLayout from "../../../layout/HomeLayout"
import Button from "../../../components/Button"
import { getScheduleRequest } from "./redux/action"
import { connect } from "react-redux"
import moment from "moment"
import { scheduleId } from "../../AuthScreens/LoginScreen/redux/action"
import ScheduleModal from "../../../CommonComponents/Modal"
import { getProfileRequest } from "./redux/action"
import Skeleton from "react-loading-skeleton"

const Schedule = props => {
  const {
    getScheduleRequest,
    scheduleData,
    requesting,
    scheduleId,
    getProfileRequest
  } = props

  const history = useHistory()
  const [modal, setModal] = useState(false)

  useEffect(() => {
    getScheduleRequest("")
    getProfileRequest("")
  }, [])

  return (
    <>
      <HomeLayout dashboardHeader={true}>
        <section className="content Schedule">
          <div className="d-flex  headerFilter" style={{ flexWrap: "wrap" }}>
            <h1 style={{ flexGrow: "1" }}>Schedule</h1>
            <div className=""></div>
            <div className="">
              <Button
                title="Manage Schedule"
                className="aes-btn green-btn "
                onClick={() => setModal(true)}
                disabled={requesting}
              />
            </div>
          </div>

          <div className="schedule-container">
            {requesting ? (
              [1, 2, 3].map(item => (
                <div className="card p-0">
                  <div className="mainrow d-block">
                    <Skeleton width="50%" />
                  </div>

                  <div style={{ paddingLeft: "18px" }}>
                    <div className="right-text ">
                      <Skeleton width="30%" />
                    </div>
                    <div className="right-text">
                      <Skeleton width="50%" />
                    </div>
                    <div className="right-text border-0">
                      <Skeleton width="30%" />
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <>
                {scheduleData?.length === 0 ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "70vh"
                    }}
                  >
                    <p>Not Data found</p>
                  </div>
                ) : (
                  <>
                    {scheduleData &&
                      scheduleData?.map(item => (
                        <div className="card p-0" key={item.id}>
                          <div className="mainrow">
                            <div className="date">
                              <p>
                                {moment(item.booking_date).format(
                                  "DD MMM YYYY"
                                )}{" "}
                              </p>
                            </div>

                            <div className="btns">
                              <Button
                                title="See Details"
                                className="aes-btn transparent-btn "
                                onClick={() => {
                                  history.push("ScheduleDetail")
                                  getScheduleRequest(item.id)
                                  scheduleId(item.id)
                                }}
                              />
                            </div>
                          </div>

                          <div className="right-text">
                            <p>
                              The number of trucks booked:
                              <span>{item.number_of_arrival_truck}</span>
                            </p>
                          </div>
                          <div className="right-text">
                            <p>
                              Material:
                              <span>{item.material}</span>
                            </p>
                          </div>
                          <div className="right-text border-0">
                            <p>
                              Contractor:
                              <span>{item.contractor}</span>
                            </p>
                          </div>
                        </div>
                      ))}
                  </>
                )}
              </>
            )}
          </div>
        </section>
        {!requesting && (
          <ScheduleModal show={modal} hide={() => setModal(false)} />
        )}
      </HomeLayout>
    </>
  )
}
const mapStateToProps = state => ({
  scheduleData: state.schedule.data.length ? state.schedule.data : [],
  profileData: state.schedule.profile,
  requesting: state.schedule.requesting
})

const mapDispatchToProps = dispatch => ({
  getScheduleRequest: filterModel => {
    dispatch(getScheduleRequest(filterModel))
  },
  scheduleId: data => {
    dispatch(scheduleId(data))
  },
  getProfileRequest: () => {
    dispatch(getProfileRequest())
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(Schedule)
