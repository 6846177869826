import { all, call, put, takeLatest } from "redux-saga/effects"
import { BASE_URL } from "../../../../config/app"
import XHR from "../../../../utils/XHR"
// types
import { POST_REQUEST_ADD_SCHEDULE,GET_ADMIN_SCHEDULE } from "./type"
// actions
import { addScheduleSuccess, addScheduleFailure,getAdminScheduleSuccess,
  getAdminScheduleFailure } from "./action"
import toast from "react-hot-toast"

async function addScheduleRequestAPI(data) {
  const URL = `${BASE_URL}material/schedule/`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "POST",
    data
  }
  return XHR(URL, options)
}

function* addSchedule({ data }) {
  try {
    const response = yield call(addScheduleRequestAPI, data)
    yield put(addScheduleSuccess(response.data))
  } catch (e) {
    const { response } = e
    if(response?.status == 400 && response?.data?.msg){
      toast.error(response?.data?.msg)
    }
    yield put(addScheduleFailure(response?.data))
  }
}

const ScheduleAPI = async () => {
  // debugger
  const URL = `${BASE_URL}api/v1/my_request/`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "GET"
  }
  return XHR(URL, options)
}
function* ScheduleData() {
  try {
    const response = yield call(ScheduleAPI)
    yield put(getAdminScheduleSuccess(response.data))
  } catch (e) {
    const { response } = e
    yield put(getAdminScheduleFailure(response.data))
  }
}

export default all([
  takeLatest(POST_REQUEST_ADD_SCHEDULE, addSchedule),
  takeLatest(GET_ADMIN_SCHEDULE, ScheduleData)
])
