import {
  GET_FILLSITE_TRUCKS_REQUEST,
  GET_FILLSITE_TRUCKS_SUCCESS,
  GET_FILLSITE_TRUCKS_FAILURE,
  POST_UPLOAD_INVOICE_REQUEST,
  POST_UPLOAD_INVOICE_SUCCESS,
  POST_UPLOAD_INVOICE_FAILURE
} from "./type"

export const getFillsiteTrucksRequest = data => ({
  type: GET_FILLSITE_TRUCKS_REQUEST,
  data
})

export const getFillsiteTrucksSuccess = data => ({
  type: GET_FILLSITE_TRUCKS_SUCCESS,
  data
})

export const getFillsiteTrucksFailure = data => ({
  type: GET_FILLSITE_TRUCKS_FAILURE,
  data
})

export const postUploadInvoiceRequest = data => ({
  type: POST_UPLOAD_INVOICE_REQUEST,
  data
})
export const postUploadInvoiceSuccess = data => ({
  type: POST_UPLOAD_INVOICE_SUCCESS,
  data
})
export const postUploadInvoiceFailure = data => ({
  type: POST_UPLOAD_INVOICE_FAILURE,
  data
})
