import axios from "axios"
import { toast } from "react-hot-toast"
import { Reset } from "../pages/AuthScreens/LoginScreen/redux/action"
import { history, store } from "../reduxStore/store" // Assuming you have created the Redux store

function parseJSON(response) {
  if (response.status === 204 || response.status === 205) {
    return null
  }
  return response.json ? response.json() : response
}

function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response
  }

  const error = new Error(response.statusText)
  error.response = response
  error.status = response.status
  throw error
}

axios.interceptors.response.use(
  response => response,
  error => {
    if (error.response.status === 401) {
      toast.error("Your session has expired. Please login again.", {
        id: "card"
      })
      setTimeout(() => {
        store.dispatch(Reset())
        // localStorage.clear();
        history.push("/login")
      }, 2000)
    }
    return Promise.reject(error)
  }
)

export default (url, options) =>
  axios(url, options).then(checkStatus).then(parseJSON)
