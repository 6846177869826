import { all, call, put, takeLatest } from "redux-saga/effects"
import { BASE_URL } from "../../../../config/app"
import XHR from "../../../../utils/XHR"
import {
  postSoilAcceptSucess,
  postSoilAcceptFailure,
  getAdminFillSucess,
  getAdminFillFailure
} from "./action"
import {
  POST_SOIL_ACCEPT_REQUEST,
  GET_ADMIN_FILL_SITE,
  SUCCESS_GETTING_ADMIN_FILL_SITE
} from "./type"
import { history } from "../../../../reduxStore/store"
import { toast } from "react-hot-toast"

async function postSoilAcceptAPI(data) {
  const URL = `${BASE_URL}api/v1/admin/approval_reject_request/`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "POST",
    data
  }
  return XHR(URL, options)
}

function* postSoilAcceptInfo({ data }) {
  try {
    const response = yield call(postSoilAcceptAPI, data)
    
    yield put(postSoilAcceptSucess(response.data))
    if (data.get("status") == "accepted") {
      toast.success("Soil Deposit Request Accepted")
    } else {
      toast.success("Soil Deposit Request Rejected")
    }
    setTimeout(() => history.push("soil-request"), 2000)
  } catch (e) {
    const { response } = e
    yield put(postSoilAcceptFailure(response?.data))
    toast.error("There was an error while updating")
  }
}

const InvoicesAPI = async () => {
  // debugger
  const URL = `${BASE_URL}api/v1/admin/fill_site_owner/`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "GET"
  }
  return XHR(URL, options)
}

function* InvoicesData() {
  try {
    const response = yield call(InvoicesAPI)
    yield put(getAdminFillSucess(response.data))
  } catch (e) {
    const { response } = e
    yield put(getAdminFillFailure(response.data))
  }
}

export default all([
  takeLatest(POST_SOIL_ACCEPT_REQUEST, postSoilAcceptInfo),
  takeLatest(GET_ADMIN_FILL_SITE, InvoicesData)
])
