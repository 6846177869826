import {
  GET_PROJECT_LIST_REQUEST,
  GET_PROJECT_LIST_SUCCESS,
  GET_PROJECT_LIST_FAILURE,
  GET_ID_PROJECT,
  GET_ID_SUCCESS,
  GET_ID_FAILURE,
} from "./type"


const initialState = {
  projectsList: false,
  getIDdata:[],
  requesting: false,
  error: false,
  updateData: false,
  deleteStatus: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_PROJECT_LIST_REQUEST:
      return {
        ...state,
        projectsList: true
      }
    case GET_PROJECT_LIST_SUCCESS:
      return {
        ...state,
        requesting: false,
        projectsList: action.data
      }
    case GET_PROJECT_LIST_FAILURE:
      return {
        ...state,
        requesting: false,
        error: action.data
      }
      case GET_ID_PROJECT:
      return {
        ...state,
        requesting: true
      }
    case GET_ID_SUCCESS:
      return {
        ...state,
        requesting: false,
        updateData: action.data
      }
    case GET_ID_FAILURE:
      return {
        ...state,
        requesting: false,
        error: action.data
      }
   

      
    default:
      return state
  }
}
