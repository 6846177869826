import React, { useEffect, useState } from "react"
import "./style.scss"
import dayjs from "dayjs"
import Modal from "react-bootstrap/Modal"
import customParseFormat from "dayjs/plugin/customParseFormat"
import { Col, Row, Tabs, TimePicker } from "antd"
import Input from "../../components/Input"
import Days from "./Days.json"
import Calendar from "react-calendar"
import "react-calendar/dist/Calendar.css"
import useForm from "../../utils/useForm"
import { connect } from "react-redux"
import { postSpecificDateRequest } from "../../pages/FillSiteOwner/Schedule/redux/action"
import Button from "../../components/Button"
import { Toaster } from "react-hot-toast"

const ScheduleModal = props => {

  const {
    postSpecificDateRequest,
    show,
    hide,
    scheduleId,
    specificdateRequesting,
    profileData
  } = props

  const [checkTab, setCheckTab] = useState("1")

  const stateSchema = {
    date: {
      value: "",
      error: ""
    },
    workingTimeFrom: {
      value: "",
      error: ""
    },
    workingTimeTo: {
      value: "",
      error: ""
    },
    maxLoads: {
      value: "",
      error: ""
    },
    maxTrucks: {
      value: "",
      error: ""
    }
  }

  const validationStateSchema = {
    date: {
      required: false
    },
    workingTimeFrom: {
      required: false
    },
    workingTimeTo: {
      required: false
    },
    maxLoads: {
      required: false
    },
    maxTrucks: {
      required: false
    }
  }
  const { state, handleOnChange, disable, setState } = useForm(
    stateSchema,
    validationStateSchema
  )

  dayjs.extend(customParseFormat)
  const ManageSpecificDate = () => {
    return (
      <div className="mb-3 dateCalender">
        <Calendar
          onChange={e => handleOnChange("date", dayjs(e).format("YYYY-MM-DD"))}
          value={state?.date?.value}
        />
      </div>
    )
  }

  const onTabChange = key => {
    setCheckTab(key)
  }

  const [daysSelect, setDaysSelect] = useState(Days)
  
  const SelectDays = id => {
    const selectedDay = daysSelect?.findIndex(e => e.id === id)
    if (daysSelect[selectedDay].check) {
      daysSelect[selectedDay].check = false
    } else {
      daysSelect[selectedDay].check = true
    }
    setDaysSelect(prevState => [...prevState])
  }

  useEffect(() => {
    if (profileData && profileData[0]) {
      setState(prevState => ({
        ...prevState,
        date: {
          ...prevState.date,
          value:
            profileData && profileData[0]?.date
              ? profileData && profileData[0]?.date
              : prevState.date.value
        },
        workingTimeFrom: {
          ...prevState.workingTimeFrom,
          value:
            profileData && profileData[0]?.working_time_from
              ? profileData && profileData[0]?.working_time_from
              : "08:00 AM"
        },
        workingTimeTo: {
          ...prevState.workingTimeTo,
          value:
            profileData && profileData[0]?.working_time_to
              ? profileData && profileData[0]?.working_time_to
              : "05:00 PM"
        },
        maxLoads: {
          ...prevState.maxLoads,
          value: profileData && profileData[0].max_load_per_day
        },
        maxTrucks: {
          ...prevState.maxTrucks,
          value: profileData && profileData[0].max_truck_per_day
        }
      }))
      profileData &&
        profileData[0].week_days &&
        setDaysSelect(prevState => [
          ...prevState.map((day, index) => ({
            ...day,
            check: profileData && profileData[0].week_days[index + 1]
          }))
        ])
    }
  }, [profileData])

  const ScheduleInGeneral = () => {
    return (
      <div className="ScheduleDaysStyle">
        {daysSelect?.map(day => {
          return (
            <div
              key={day.id}
              className={day.check ? "selectedDays daylist" : "days daylist"}
              onClick={() => {
                SelectDays(day.id)
              }}
            >
              <span>{day?.name}</span>
            </div>
          )
        })}
      </div>
    )
  }

  const items = [
    {
      key: "1",
      label: `Manage Specific Date`,
      children: <ManageSpecificDate />
    },
    {
      key: "2",
      label: `Schedule in General`,
      children: <ScheduleInGeneral />
    }
  ]

  const Submit = () => {
    if (checkTab === "2") {
      let payload = {}
      daysSelect.forEach(item => (payload[item.id] = !!item.check))
      const postData = {
        request_availability: scheduleId,
        general_scheduled: true,
        week_days: payload,
        working_time_from: state?.workingTimeFrom?.value || "08:00 AM",
        working_time_to: state?.workingTimeTo?.value || "05:00 PM",
        max_load_per_day: state.maxLoads.value,
        max_truck_per_day: state.maxTrucks.value
      }

      postSpecificDateRequest(postData, hide)
    } else {
      const postData = {
        request_availability: scheduleId,
        general_scheduled: false,
        date: state?.date?.value,
        working_time_from: state?.workingTimeFrom?.value || "08:00 AM",
        working_time_to: state?.workingTimeTo?.value || "05:00 PM",
        max_load_per_day: state.maxLoads.value,
        max_truck_per_day: state.maxTrucks.value
      }
      postSpecificDateRequest(postData, hide)
    }
  }

  return (
    <div>
      <Toaster />
      <Modal show={show} onHide={hide} className="truckArivalModal">
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <Tabs defaultActiveKey="1" items={items} onChange={onTabChange} />
          <Row className="selectTimeStyle" gutter={10}>
            <Col sm={12} xs={24}>
              <h1>Working time from</h1>
              <TimePicker
                placeholder="00:00"
                className="InputStyle"
                onChange={(_, timeString) => {
                  handleOnChange("workingTimeFrom", timeString)
                }}
                value={dayjs(
                  state?.workingTimeFrom?.value || "08:00 AM",
                  "hh:mm A"
                )}
                format={"hh:mm A"}
                popupClassName="workingTime"
                use12Hours
                allowClear={false}
              />
            </Col>
            <Col sm={12} xs={24}>
              <h1>Working time to</h1>
              <TimePicker
                placeholder="00:00"
                className="InputStyle"
                onChange={(_, timeString) => {
                  handleOnChange("workingTimeTo", timeString)
                }}
                value={dayjs(
                  state?.workingTimeTo?.value || "05:00 PM",
                  "hh:mm A"
                )}
                popupClassName="workingTime"
                format={"hh:mm A"}
                use12Hours
                allowClear={false}
              />
            </Col>
            <Col sm={12} xs={24}>
              <h1>Max loads per day</h1>
              <Input
                type="number"
                name=""
                className="InputStyle"
                value={state?.maxLoads ? state.maxLoads.value : ""}
                showError={true}
                errorMessage={state.maxLoads.error}
                onChange={val => handleOnChange("maxLoads", val.target.value)}
              />
            </Col>
            <Col sm={12} xs={24}>
              <h1>Max trucks per day</h1>
              <Input
                type="number"
                className="InputStyle"
                value={state?.maxTrucks ? state.maxTrucks.value : ""}
                showError={true}
                errorMessage={state.maxTrucks.error}
                onChange={val => handleOnChange("maxTrucks", val.target.value)}
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            style={{ width: "100%" }}
            className="aes-btn green-btn "
            onClick={Submit}
            showSpinner={specificdateRequesting}
            title="save"
          />
        </Modal.Footer>
      </Modal>
    </div>
  )
}
const mapStateToProps = state => ({
  scheduleData: state.schedule.data,
  scheduleId: state.login.scheduleId,
  specificdateRequesting: state.schedule.specificdateRequesting,
  profileData: state.schedule.profile
})

const mapDispatchToProps = dispatch => ({
  postSpecificDateRequest: (data, hide) => {
    dispatch(postSpecificDateRequest(data, hide))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleModal)
