import {
  GET_OWNER_TEAM_MEMBER,
  GET_OWNER_TEAM_MEMBER_SUCCESS,
  GET_OWNER_TEAM_MEMBER_FAILURE,
  PUT_OWNER_TEAM_MEMBER,
  PUT_OWNER_TEAM_MEMBER_SUCCESS,
  PUT_OWNER_TEAM_MEMBER_FAILURE
} from "./type"

// GET Team Member Profile
export const getOwnerTeamMember = id => ({
  type: GET_OWNER_TEAM_MEMBER,
  id
})

export const getOwnerTeamMemberSuccess = data => ({
  type: GET_OWNER_TEAM_MEMBER_SUCCESS,
  data
})

export const getOwnerTeamMemberFailure = data => ({
  type: GET_OWNER_TEAM_MEMBER_FAILURE,
  data
})

// PUT TEAM MEMBER
export const putOwnerTeamMember = (data, id) => ({
  type: PUT_OWNER_TEAM_MEMBER,
  data,
  id
})

export const putOwnerTeamMemberSuccess = data => ({
  type: PUT_OWNER_TEAM_MEMBER_SUCCESS,
  data
})

export const putOwnerTeamMemberFailure = data => ({
  type: PUT_OWNER_TEAM_MEMBER_FAILURE,
  data
})
