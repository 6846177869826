import {
  GET_FILLSITE_AVAILABILITY_REQUEST,
  GET_FILLSITE_AVAILABILITY_SUCCESS,
  GET_FILLSITE_AVAILABILITY_FAILURE,
  POST_BOOK_LOAD_REQUEST,
  POST_BOOK_LOAD_SUCCESS,
  POST_BOOK_LOAD_FAILURE,

  
} from "./type"

//  GET_NOTIFICATION_FAILURE
// export const getFillsiteRequest =(filterModel) => ({
//   type: GET_FILLSITE_AVAILABILITY_REQUEST,
//   // data
//   filterModel

// })
export const getFillsiteRequest = (id, reqID, date) => ({
 type: GET_FILLSITE_AVAILABILITY_REQUEST, 
 id, 
 reqID, 
 date
})

export const getFillsiteSuccess = data => ({
  type: GET_FILLSITE_AVAILABILITY_SUCCESS,
  data
})
export const getFillsiteFailure = data => ({
  type: GET_FILLSITE_AVAILABILITY_FAILURE,
  data
})
export const postBookRequest= (data) => {

  return { type:  POST_BOOK_LOAD_REQUEST,data }
}

// export const postBookRequest = () => ({
//   type:   POST_BOOK_LOAD_REQUEST,
  
// })
export const postBookSucess = data => ({
  type:  POST_BOOK_LOAD_SUCCESS,
  data
})
export const postBookFailure = data => ({
  type:    POST_BOOK_LOAD_FAILURE  ,
  data
})
