import React, { useState, useEffect } from "react"
import "./style.scss"
import splashLogo from "../../../assets/images/splashLogo.svg"
import logo from "../../../assets/images/logo.svg"
import Input from "../../../components/Input"
import Button from "../../../components/Button"
import useForm from "../../../utils/useForm"
import validator from "../../../utils/validation"
import {
  postConfirmPassword,
  postConfirmPasswordFailure,
  postUserPassword
} from "./redux/action"
import { connect } from "react-redux"
import { useHistory } from "react-router-dom"

const ChangePasword = props => {
  const {
    postConfirmRequest,
    confirmInfo,
    requesting,
    error,
    postConfirmPasswordFailure,
    postUserPassword,
    loginInfo,
    userInfo
  } = props

  const stateSchema = {
    password: {
      value: "",
      error: ""
    },
    confirmPassword: {
      value: "",
      error: ""
    },
    CurrentPassword: {
      value: "",
      error: ""
    }
  }

  const validationStateSchema = {
    password: {
      required: true,
      validator: validator.currentPassword
    },
    confirmPassword: {
      required: true
      // validator: validator.password
    },
    CurrentPassword: {
      required: true
      // validator: validator.password
    }
  }
  const { state, handleOnChange, disable, setState } = useForm(
    stateSchema,
    validationStateSchema
  )
  // const [token, setToken] = useState("")
  const [conFirmPassError, setConFirmPassError] = useState("")

  const history = useHistory()
  const query = new URLSearchParams(props.location.search)
  const token = query.get("token")
  const onSubmit = async () => {
    if (loginInfo == false) {
      const data = {
        password: `${state.password.value}`,
        token: `${token}`
      }
      if (data.password == state.confirmPassword?.value) {
        await postConfirmRequest(data)
      } else {
        setConFirmPassError(
          "The password and confirmation password do not match."
        )
      }
    } else {
      const newdata = {
        new_password: `${state.password.value}`,
        confirm_password: `${state.confirmPassword.value}`,
        current_password: `${state.CurrentPassword.value}`
      }
      // if (data.password == state.confirmPassword?.value) {
      await postUserPassword(newdata)
      // }
      // else {
      //   setConFirmPassError(
      //     "The password and confirmation password do not match."
      //   )
      // }
    }
  }
  const matchPassword = value => {
    if (!state.password.value) {
      setConFirmPassError(false)
    } else if (value == state.password.value) {
      if (loginInfo && loginInfo.user) {
        loginInfo.user.change_password_status = true
      }
      setConFirmPassError(false)
    } else {
      setConFirmPassError("The password does not match.")
    }
  }
  useEffect(() => {
    postConfirmPasswordFailure(false)
  }, [])
  return (
    <>
      <section className="ChangePasword">
        {/* <img src={splashLogo}></img> */}
        <div className="change-box">
          <div className="login-box">
            <div className="logo">
              {/* <img src={logo}></img> */}
              <h3>Change Password </h3>
            </div>
            <div className="input-wrapper">
              {loginInfo.user &&
                <div className="one-input ">
                  <h4> Current Password </h4>
                  <Input
                    type="password"
                    // showIcon={true}
                    showHideIcon={true}
                    showError={true}
                    maxlength={16}
                    errorMessage={state.CurrentPassword.error || error?.current_password}
                    value={state.CurrentPassword.value}
                    placeholder="Current Password"
                    onChange={val => handleOnChange("CurrentPassword", val.target.value)}
                  />
                </div>
              }
              <div className="one-input ">
                <h4> New password </h4>
                <Input
                  type="password"
                  // showIcon={true}
                  showHideIcon={true}
                  showError={true}
                  maxlength={16}
                  errorMessage={state.password.error || error?.confirm_password || error?.password}
                  value={state.password.value}
                  placeholder="New password"
                  onChange={val => handleOnChange("password", val.target.value)}
                />
              </div>
              <div className="two-input">
                <h4>Confirm password</h4>
                <Input
                  type="password"
                  // showIcon={true}

                  showHideIcon={true}
                  value={state.confirmPassword.value}
                  showError={true}
                  placeholder="Confirm password"
                  onChange={val => {
                    matchPassword(val.target.value)
                    handleOnChange("confirmPassword", val.target.value)
                  }}
                  errorMessage={conFirmPassError}
                />
                {/* <div>
                  {error.detail && (
                    <label style={{ color: "red" }}> {error.detail} </label>
                  )}
                </div> */}
              </div>
            </div>
          </div>
          <div className="button-wrapper">
            <Button
              onClick={() => onSubmit()}
              showSpinner={requesting}
              title="Save"
              className="aes-btn"
            />
          </div>
        </div>
      </section>
    </>
  )
}
const mapStateToProps = state => ({
  loginInfo: state.login.loginInfo,
  confirmInfo: state.confirmPassword,
  requesting: state.confirmPassword.requesting,
  error: state.confirmPassword.error,
  userInfo: state.confirmPassword.userInfo
})

const mapDispatchToProps = dispatch => ({
  postConfirmRequest: (data, history) =>
    dispatch(postConfirmPassword(data, history)),
  postConfirmPasswordFailure: data =>
    dispatch(postConfirmPasswordFailure(data)),
  postUserPassword: (data, history) => dispatch(postUserPassword(data, history))
})

export default connect(mapStateToProps, mapDispatchToProps)(ChangePasword)
