import React, { useEffect, useState, useRef } from "react"
import Search from "../../../components/Search"
import HomeLayout from "../../../layout/HomeLayout"

import TeamMembersTable from "../../../components/TeamMembersTable"
import "./style.scss"
import FillSiteTable from "../../../components/FillSiteTable"
import InvoicesTable from "../../../components/InvoicesTable"
import {
  getFillSiteRequest,
  getInvoicesRequest,
  getFillSiteSuccess,
  getInvoicesSuccess

  // searchFillSiteRequest,
  // searchInvoicesRequest
} from "./redux/action"
import { connect } from "react-redux"

const AccountFillSite = props => {
  const {
    getFillSiteRequest,
    getFillSite,
    getInvoicesRequest,
    getInvoices,
    Novicesrequesting,
    loginInfo,
    requesting
  } = props

  const [active, setActive] = useState("Fill Sites")
  const toggleItems = ["Fill Sites", "Invoices"]

  useEffect(() => {

    // getFillSiteSuccess(false)
    getFillSiteRequest("")
    // getInvoicesSuccess(false)
    getInvoicesRequest("")
  }, [])

  const specialFormRef = useRef()
  let timeOutId = null
  const filldataSearch = e => {
    if (timeOutId) {
      clearTimeout(timeOutId)
    }
    timeOutId = setTimeout(() => {
      if (active === "Fill Sites") {
        getFillSiteRequest(`?name=${e.target.value}`, "namefill")
        e.preventDefault()
      } else {
        getInvoicesRequest(`?name=${e.target.value}`, "invoicefill")
      }
    }, 2000)
  }

  return (
    <>

      <HomeLayout dashboardHeader={true}>
        {loginInfo && loginInfo?.user?.role == "Operator" || loginInfo && loginInfo?.user?.role == "Manager" ? (
          <div className="errordiv">
            <p>You are Operator and not permitted</p>
          </div>
        ) : (
          <section className="content-section fill-site-section">
            <div className="fill-site-content-section">
              <div className="heading-Account">
                <h1
                // onClick={()=>{}}
                >
                  Account Overview
                </h1>
                <form ref={specialFormRef}>
                  <Search
                    // value={}
                    //  onChange={val =>
                    //   setTimeout(()=>{searchFillSiteRequest(val.target.value)
                    //  }, 200000)
                    // }
                    // value={searchFill}

                    onChange={e => {
                      filldataSearch(e)
                    }}
                  />
                </form>
              </div>
              <div className="fill-site-table-wrapper">
                <div className="switch-modes">
                  {toggleItems.map(item => (
                    <>
                      <div
                        key={item.id}
                        className={`mode ${active == item ? "active" : ""}`}
                        onClick={e => {
                          setActive(item)
                          specialFormRef.current.reset()
                          e.preventDefault()
                        }}
                      >
                        {item}
                      </div>
                    </>
                  ))}
                </div>
                {active == "Fill Sites" ? (
                  <FillSiteTable FillSiteTableData={getFillSite} isloading={requesting} />
                ) : (
                  <InvoicesTable
                    InvoicesTableData={getInvoices}
                    isloading={Novicesrequesting}

                  />
                )}
              </div>
            </div>
          </section>
        )}
      </HomeLayout>
    </>
  )
}

const mapStateToProps = state => {
  // debugger

  return {
    getFillSite: state.FillsiteTable?.fillsiteData?.length ? state.FillsiteTable.fillsiteData : [],
    // : state.FillsiteTable.fillsiteData,
    getInvoices: state.FillsiteTable.novicesData,
    Novicesrequesting: state.FillsiteTable.Novicesrequesting,
    searchFill: state.FillsiteTable.searchFill,
    requesting: state.FillsiteTable.requesting,

    Novicesrequesting: state.FillsiteTable.Novicesrequesting,
    loginInfo: state.login.loginInfo
  }
}

const mapDispatchToProps = dispatch => ({
  getFillSiteRequest: data => dispatch(getFillSiteRequest(data)),
  getInvoicesRequest: data => dispatch(getInvoicesRequest(data)),
  getFillSiteSuccess: data => dispatch(getFillSiteSuccess(data)),
  getInvoicesSuccess: data => dispatch(getInvoicesSuccess(data))


  // searchFillSiteRequest:data => dispatch(searchFillSiteRequest(data)),
  // searchInvoicesRequest:data => dispatch(searchInvoicesRequest(data)),

  // searchFillSiteSuccess:data => dispatch(searchFillSiteSuccess(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(AccountFillSite)
