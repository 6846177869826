import {
  POST_REQUEST_SOIL_DEPOSIT,
  POST_REQUEST_SOIL_DEPOSIT_SUCCESS,
  POST_REQUEST_SOIL_DEPOSIT_FAILURE,
  GET_MATERIALS_REQUEST,
  GET_MATERIALS_SUCCESS,
  GET_MATERIALS_FAILURE,

  GET_ADMIN_PROJECT,
  SUCCESS_GETTING_ADMIN_PROJECT,
  ERROR_GETTING_ADMIN_PROJECT
} from "./type"

const initialState = {
  soilDepositdata: [],
  materialNameData: [],
  getAdminProject: [],
  error: false,
  requesting: false
  // accessToken: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case POST_REQUEST_SOIL_DEPOSIT:
      return {
        ...state,
        requesting: true,
        soilDepositdata: []

      }

    case POST_REQUEST_SOIL_DEPOSIT_SUCCESS:
      return {
        ...state,
        requesting: false,
        soilDepositdata: action.data
      }
    case POST_REQUEST_SOIL_DEPOSIT_FAILURE:
      return {
        ...state,
        requesting: false,
        error: action.data
      }
    case GET_MATERIALS_REQUEST:
      return {
        ...state,
        requesting: true
      }
    case GET_MATERIALS_SUCCESS:
      return {
        ...state,
        requesting: false,
        materialNameData: action.data
      }
    case GET_MATERIALS_FAILURE:
      return {
        ...state,
        requesting: false,
        error: action.data
      }
      case GET_ADMIN_PROJECT:
        return {
          ...state,
          requesting: true
        }
      case SUCCESS_GETTING_ADMIN_PROJECT:
        return {
          ...state,
          requesting: false,
          getAdminProject: action.data
        }
  
      case ERROR_GETTING_ADMIN_PROJECT:
        return {
          ...state,
          requesting: false,
          error: action.data
        }
    default:
      return state
  }
}
