import {
  POST_REQUEST_ADD_TRUCK,
  POST_ADD_TRUCK_SUCCESS,
  POST_ADD_TRUCK_FAILURE
} from "./type"

const initialState = {
  projects: false,
  error: false,
  requesting: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case POST_REQUEST_ADD_TRUCK:
      return {
        ...state,
        requesting: true
      }
    case POST_ADD_TRUCK_SUCCESS:
      return {
        ...state,
        requesting: false,
        projects: action.data
      }
    case POST_ADD_TRUCK_FAILURE:
      return {
        ...state,
        requesting: false,
        error: action.data,
        projects: false
      }
    default:
      return state
  }
}
