import { DatePicker, Select, Space } from "antd"
import moment from "moment"

import React, { useEffect, useState } from "react"
import { Spinner } from "react-bootstrap"

import filter from "../../assets/images/filter.svg"
import dropDown from "../../assets/images/dropDown.svg"
import dropUp from "../../assets/images/dropUp.svg"
import uploadfile from "../../assets/images/uploadfile.svg"

import "./style.scss"
import { connect } from "react-redux"
import { getInvoicesRequest } from "../../pages/Contractor/AccountFillSite/redux/action"
const InvoiceDetailsdDrop = props => {
  const { novicesData, invoiceId } = props
  const capFirstL = string => {
    return string.charAt(0).toUpperCase() + string.slice(1)
  }

  return (
    <div className="details-wrapper">
      {novicesData && (
        <div className="details-item" key={novicesData?.id}>
          <div className={`top-header`}>
            <h3>{novicesData?.fill_site}</h3>
          </div>
          <div className={`list-item-wrapper`}>
            <div className="list-item-header">
              <div className="row item-header-row">
                <div className="col-md-6">
                  <div className="item">All Invoices:</div>
                </div>
                <div className="col-md-6">
                  <div className="item">Status:</div>
                </div>
              </div>
            </div>
            {novicesData &&
              novicesData?.invoice_file?.map(item => (
                <div className="row list-item-row" key={item.id}>
                  <div className="col-md-6">
                    <div className="list-item">
                      <div
                        className="item"
                        onClick={() =>
                          window.open(item?.invoice_file, "_blank")
                        }
                      >
                        {item.invoice_file.length > 0 && (
                          <img src={uploadfile} alt="" />
                        )}

                        {item?.file_name == null ? (
                          <span>Invoice:{item.id}</span>
                        ) : (
                          <span> {item?.file_name}</span>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div
                      className={`list-item green 
                      ${
                        item.status === "paid_overdue"
                          ? "red"
                          : item.status === "not_paid"
                          ? "yellow"
                          : ""
                      }
                    `}
                    >
                      {item.status === "paid_overdue"
                        ? "Paid Overdue"
                        : item.status === "not_paid"
                        ? "Not Paid"
                        : capFirstL(item.status)}
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      )}
    </div>
  )
}
const mapStateToProps = state => {
  return {
    getInvoices: state.FillsiteTable.novicesData,
    // novicesData: state.FillsiteTable.novicesData,
    invoiceId: state.FillsiteTable.invoiceId
  }
}

const mapDispatchToProps = dispatch => ({})
export default connect(mapStateToProps, mapDispatchToProps)(InvoiceDetailsdDrop)
