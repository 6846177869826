import {
  GET_REQUEST_LIST_REQUEST,
  GET_REQUEST_LIST_SUCCESS,
  GET_REQUEST_LIST_FAILURE,
 GET_ID_REQUEST,
 GET_ID_SUCCESS,
 GET_ID_FAILURE,

} from "./type"

export const getRequestListRequest = (data) => ({
  type: GET_REQUEST_LIST_REQUEST,
  data
  //filterModel
})


export const getRequestListSuccess = data => ({
  type: GET_REQUEST_LIST_SUCCESS,
  data
})

export const getRequestListFailure = data => ({
  type: GET_REQUEST_LIST_FAILURE,
  data
})

export const getIdRequest = member => ({
  type:GET_ID_REQUEST,
  member
})

export const getIdSuccess = (data) => ({
  type:GET_ID_SUCCESS,
  data
})

export const getIdFailure = data => ({
  type:GET_ID_FAILURE,
  data
})

