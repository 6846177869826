import React, { useEffect, useState } from "react"
import "./style.scss"
import HomeLayout from "../../../layout/HomeLayout"

import Button from "../../../components/Button"
import { Table } from "antd"

import report from "../../../assets/images/report-img.png"
import "react-loading-skeleton/dist/skeleton.css"
import { toast, Toaster } from "react-hot-toast"

import { history } from "../../../reduxStore/store"


import moment from "moment"
import NewNotification from "../../../components/AdminModal/newNotification"
import { useDispatch, useSelector } from "react-redux"
import { deleteNotificationRequest, getAdminNotificationRequest, PostAdminRequest } from "./redux/action"
import Skeleton from "react-loading-skeleton"
import PaginationPack from "../../../components/PaginationPack"
const Notifications = (props) => {
  const [activeTab, setActiveTab] = useState('all');
  const [modalShow, setModalShow] = useState(false)
  const [loading, setLoading] = useState(0)
  const [loadingResend, setLoadingResend] = useState(0)
  const dispatch = useDispatch()
  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  useEffect(() => {
    const fetchNotifications = () => {
      console.log(`Fetching notifications for activeTab: ${activeTab}`);
      if (activeTab === "all") {
        dispatch(getAdminNotificationRequest(''));
      } else {
        dispatch(getAdminNotificationRequest(`?${activeTab}=${activeTab}`));
      }
    };

    // Initial fetch
    fetchNotifications();

    // Set up interval to fetch notifications every 10 seconds
    const intervalId = setInterval(fetchNotifications, 10000);

    // Clean up interval on component unmount
    return () => clearInterval(intervalId);
  }, [dispatch, activeTab]);

  
  const { NotificationData, requesting, delRequesting, PostAdminrequesting } = useSelector(
    state => state.Notification
  )
  const { loginInfo } = useSelector(
    state => state.login
  )
  const onResendClick = async (item, index) => {
    setLoadingResend(index);
    const idArray = item?.send_to.map(item => item.id);
    const postdata = {
      subject: item.subject,
      message: item.message,
      send_to: item?.send_to,
      resend_notification: true,
      send_from: loginInfo.user
    };



    await dispatch(PostAdminRequest(postdata));
    // Show a success toast
    toast.success('Notification has been resent successfully');

  };


  const onRemoveClick = (id, index) => {
    setLoading(index)
    dispatch(deleteNotificationRequest(id))
  }
  const tabs = [
    { id: 'all', label: 'All Notifications' },
    { id: 'received', label: 'Received Notifications' },
    { id: 'sent', label: 'Sent Notifications' },
  ];
  const handlePageChange = page => {
    dispatch(getAdminNotificationRequest(`?page=${page}`))

  }
  function OwnerNotification(props) {
    const { date, title, subtitle, tableData, time, onRemoveClick, aes_po_no, Sent, onResendClick, index, send_to_detail, message_sender,data } = props;
    console.log("Sent", send_to_detail);
    return (
      <div className="notification-wrapper">

        <div className="notification-item date-time-wrapper">
          <div className="date" style={{ textTransform: "uppercase", fontSize: 13 }}>{date} </div>
          <hr />
          <div className="time">{time}</div>
        </div>

        <div className="notification-item notification-body">
          <div className="notification-header">
            <div className="notification-title">
              {
                send_to_detail ? (
                  <>
                    <p className="notification-subtitle mb-0">
                      <strong>SENT TO: </strong>
                      {send_to_detail.map((item) => item.username).join(', ')}
                    </p>
                  </>
                ) : null
              }
              <h3>{title}</h3>
              {message_sender ?
                <p className="notification-subtitle">{message_sender}</p> :
                (<p className="notification-subtitle">{subtitle}</p>)}
            </div>


            <div style={{ gap: 10, }} className="d-flex my-2">

              {
                data.resend_notification? (
                  <>
                    <Button
                      title="Resend "
                      className="aes-btn green-btn"
                      onClick={onResendClick}
                      showSpinner={loadingResend == index && PostAdminrequesting}

                    />
                    
                  </>
                ) : (

                  null
                )
              }
              <>
              {
                data.extra_data? ( <>
                    <Button
                      title="View Details"
                      className="aes-btn green-btn"
                      onClick={() => {
                        localStorage.setItem("materialId", data.extra_data?.id)
                        history.push("soil-request-detail")
                      }}
                      showSpinner={loadingResend == index && PostAdminrequesting}

                    />
                    
                  </> ) : (null)}
              </>
              
              </div>

          </div>


          <div className="notification-body">
            <div className="notification-table">
              {/* {tableData && (<Table dataSource={tableData?.dataSource} columns={tableData?.columns} pagination={false} size="small" />)} */}

            </div>
          </div>
        </div>
      </div >
    )
  }



  return (
    <HomeLayout dashboardHeader={true}>
      <Toaster position="top-center" />
      {/* <section className="content-section Reports">

                <div className="headingStyle">
                    <span
                        style={{
                            display: "flex",
                            gap: "10px",
                            flexGrow: "1",
                            alignItems: "baseline"
                        }}
                    >
                        <h1 className="w-100">Reports</h1>
                    </span>
                    <form>
                        <Search
                            placeholder="Search Document"
                            onChange={e => {
                                SearchData(e)
                            }}
                        />
                    </form>
                    <div className="buttondiv">
                        <Button
                            title="Request Report"
                            className="newFileStyle"
                            onClick={e => setModalShow(true)}
                        />
                    </div>
                    <div className="buttondiv">
                        <Button
                            title="Upload New Report"
                            className="newFileStyle"
                            onClick={e => { setNewReportModalShow(!newReportModalShow) }}
                        />
                    </div>
                </div>
                <div className="bodyScroll">

               
                {requesting ? (
                    [1, 2, 3, 4].map(item => (
                        <div className="report-card ">
                            <div className="right-side"
                                key={item.id}
                            >
                                <div > <Skeleton width="50px" />

                                </div>
                            </div>
                            <div className="d-flex align-items-center justify-content-between w-100 px-4">

                                <div className="d-flex align-items-center">

                                    <div>  <Skeleton width="100px" /></div>
                                    <p className="text">  <Skeleton width="200px" /></p>

                                </div>
                                <div>
                                    <Skeleton width="200px" />


                                </div>

                            </div>

                        </div>

                    ))
                ) :
                reportData && reportData.length > 0 ? (
                    reportData.map((item) => (
                      <>
                        <div className="report-card " key={item.id}>
                          <div className="right-side">
                            <div className="date">
                              <span>{moment(item.created_at).format("DD MMM")}</span>
                            </div>
                          </div>
                          <div className="d-flex align-items-center justify-content-between w-100 px-4">
                            <div className="d-flex align-items-center">
                              <img className="img-upload mr-2" src={report} alt="report"
                               width={30}
                               height={30}
                              />
                              <p className="text">{item.report_category?.name}</p>
                            </div>
                            <div>
                              <Button
                                title="See details"
                                className="aes-btn transparent-btn"
                                onClick={() => {
                                  history.push("Report-Details");
                                  localStorage.setItem("reportId", item.id);
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </>
                    ))
                  ) : (
                    <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "70vh"
                    }}
                  >
                    <h3 >No Data Found</h3>
                  </div>
                
                  )}
                    </div>


            </section> */}
      <section className="content adminNotification">

        <div className="row">
          <div className="col-md-6">
            <h1 className="heading">Dashboard</h1>
          </div>
          <div className="col-md-6 headerFilter">

            <Button title="Send Message" className="aes-btn green-btn" onClick={() => setModalShow(true)} />
          </div>
        </div>
        <div className="d-flex mt-3 mb-4" style={{ gap: 20 }}>
          {tabs.map((tab) => (
            <div
              key={tab.id}
              className={`toggle ${activeTab === tab.id ? 'active' : ''}`}
              onClick={() => handleTabChange(tab.id)}
            >
              <h3 className="text-color">{tab.label}</h3>
            </div>
          ))}
        </div>
        {/* <div className="bodyScroll"> */}

        {/* {NotificationData && NotificationData?.results.map((item) => (
          <>
            <OwnerNotification
              Sent={item.send_from?.username}
              date={moment(item.created_at).format("DD MMM")}
              time={moment(item.created_at).format("LT")}
              title={item.subject}
              subtitle={item.message}
              // onResendClick={onResendClick}
              onRemoveClick={() => onRemoveClick(item.id)}
            />
          </>
        ))} */}

        {requesting ? (
          [1, 2, 3, 4, 5, 6, 7].map(item => (
            <div className="notification-wrapper">
              <div className="notification-item date-time-wrapper">
                <Skeleton width="100%" />
                <hr />
                <Skeleton width="100%" />
              </div>

              <div className="notification-item notification-body">
                <div className="notification-header ">
                  <div className="w-50">
                    <Skeleton width="100%" />
                    <div className="w-50">
                      <Skeleton width="100%" />
                    </div>
                  </div>
                  <div className="w-25">
                    <Skeleton width="100%" />
                  </div>
                </div>

                {/* <div className="notification-body">
                    <div className="notification-table">
                    {tableData && (<Table dataSource={tableData?.dataSource} columns={tableData?.columns}  pagination={false} size="small"/>)}
        
                    </div>
                  </div> */}
              </div>
            </div>
          ))
        ) : NotificationData && NotificationData?.results.length > 0 ? (
          NotificationData && NotificationData?.results.map((item, index) => {
            // console.log("item",item);
            return (
              <>
                <OwnerNotification
                  Sent={item.send_from}
                  date={moment(item.created_at).format("DD MMM")}
                  time={moment(item.created_at).format("LT")}
                  title={item.subject}
                  subtitle={item.message}
                  onResendClick={() => (onResendClick(item, index))}
                  onRemoveClick={() => onRemoveClick(item.id, index)}
                  index={index}
                  send_to_detail={item.send_to_detail}
                  message_sender={item.message_sender}
                  data={item}

                />
              </>
            )
          })
        ) : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "70vh"
            }}
          >
            <p>No Data Found</p>
          </div>
        )}
        <div
          className="mt-4 d-flex justify-content-end mr-5"
          style={{ paddingBottom: 10, alignItems: "baseline" }}
        >
          {NotificationData && NotificationData?.results?.length > 0 ? (
            // requesting ? null : (
            <>
              <p
                style={{
                  marginRight: "9px",
                  fontSize: "16px",
                  marginBottom: "0px"
                }}
              >
                Pages:
              </p>

              <PaginationPack
                onChange={handlePageChange}
                itemsCountPerPage={NotificationData?.page_size}
                totalItemsCount={NotificationData?.total_objects}
                activePage={NotificationData?.current_page}
              />
            </>
          ) : // )
            null}
        </div>
        {/* <PaginationPack
                    onChange={handlePageChange}
                    itemsCountPerPage={NotificationData?.page_size}
                    totalItemsCount={NotificationData?.total_objects}
                    activePage={NotificationData?.current_page}
                  /> */}
        {/* </div> */}
      </section>
      <NewNotification
        setModalShow={setModalShow}
        modalShow={modalShow}
      />
    </HomeLayout >
  )
}


export default Notifications

