import {
  ADMIN_DASHBOARD_USERLIST,
  ADMIN_DASHBOARD_USERLIST_FAILURE,
  ADMIN_DASHBOARD_USERLIST_SUCCESS,
  ADMIN_DASHBOARD_UPDATE_USER,
  ADMIN_DASHBOARD_UPDATE_USER_SUCCESS,
  ADMIN_DASHBOARD_UPDATE_USER_FAILURE,
  USER_ACTIVE_REQUEST,
  USER_ACTIVE_SUCCESS,
  GET_MATERIAL_REQUEST,
  GET_MATERIAL_SUCCESS,
  GET_MATERIAL_FAILURE,

  DELETE_FIILL_REQUEST,
  DELETE_FIILL_SUCCESS
} from "./type"

const initialState = {
  userListRequestList: [],
  userListRequesting: false,
  userListError: false,

  updateUserRequest: false,
  updateUserRequesting: false,
  updateUserError: false,
  materialNameData: [],
  requesting: false,
  activeRequesting: false,
  activeRequest: false,
  error: "",
  deleteRequesting:false
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
  switch (action.type) {
    case ADMIN_DASHBOARD_USERLIST:
      return {
        ...state,
        userListRequesting: true
      }
    case ADMIN_DASHBOARD_USERLIST_SUCCESS:
      return {
        ...state,
        userListRequesting: false,
        userListRequestList: action.data
      }
    case ADMIN_DASHBOARD_USERLIST_FAILURE:
      return {
        ...state,
        userListRequesting: false,
        userListError: action.data
      }
    case ADMIN_DASHBOARD_UPDATE_USER:
      return {
        ...state,
        updateUserRequesting: true
      }
    case ADMIN_DASHBOARD_UPDATE_USER_SUCCESS:
      return {
        ...state,
        updateUserRequesting: false,
        updateUserRequest: action.data
      }
    case ADMIN_DASHBOARD_UPDATE_USER_FAILURE:
      return {
        ...state,
        updateUserRequesting: false,
        updateUserError: action.data
      }

    case USER_ACTIVE_REQUEST:
      return {
        ...state,
        activeRequesting: true
      }
    case USER_ACTIVE_SUCCESS:
      return {
        ...state,
        activeRequesting: false,
        activeRequest: action.data
      }
    case GET_MATERIAL_REQUEST:
      return {
        ...state,
        requesting: true
      }
    case GET_MATERIAL_SUCCESS:
      return {
        ...state,
        requesting: false,
        materialNameData: action.data
      }
    case GET_MATERIAL_FAILURE:
      return {
        ...state,
        requesting: false,
        error: action.data
      }
      case DELETE_FIILL_REQUEST:
        return {
          ...state,
          deleteRequesting: false,
          error: action.data
        }
        case DELETE_FIILL_SUCCESS:
          return {
            ...state,
            deleteRequesting: false,
            updateUserRequest: action.data
          }
    default:
      return state
  }
}
