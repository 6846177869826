import { all, call, put, takeLatest } from "redux-saga/effects"
import { BASE_URL } from "../../../../config/app"
import XHR from "../../../../utils/XHR"
// types
import {
  GET_PROFILE_DATA,
  GET_PROFILE_DATA_SUCCESS,
  GET_PROFILE_DATA_FAILURE,
  PUT_PROFILE_DATA,
  PUT_PROFILE_DATA_SUCCESS,
  PUT_PROFILE_DATA_FAILURE
} from "./type"
// actions
import {
  getProfileDatarSuccess,
  getProfileDataFailure,
  putProfileDataSuccess,
  putProfileDataFailure
} from "./action"
import toast from "react-hot-toast"

async function getProfileDataAPI() {
  const URL = `${BASE_URL}api/v1/profile/`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "GET"
  }
  return XHR(URL, options)
}

function* getProfileData() {
  try {
    const response = yield call(getProfileDataAPI)
    yield put(getProfileDatarSuccess(response.data))
  } catch (e) {
    const { response } = e
    yield put(getProfileDataFailure(response?.data))
  }
}

async function putProfileDataAPI(data) {
  const URL = `${BASE_URL}api/v1/profile/`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "PUT",
    data
  }

  return XHR(URL, options)
}

function* putProfileData({ data }) {
  try {
    const response = yield call(putProfileDataAPI, data)
    yield put(putProfileDataSuccess(response.data))
    toast.success('success', {
      id: 'card',
    });
  } catch (e) {
    const { response } = e
    yield put(putProfileDataFailure(response?.data))
  }
}

export default all([
  takeLatest(GET_PROFILE_DATA, getProfileData),
  takeLatest(PUT_PROFILE_DATA, putProfileData)
])
