import { all, call, put, takeLatest } from "redux-saga/effects"
import { BASE_URL } from "../../../../config/app"
import XHR from "../../../../utils/XHR"
import {
  getAdmintApprovedRequest,
  getAdminApprovedSuccess,
  getAdminApprovedFailure,
  putAdminSoilSuccess,
  putAdminSoilFailure
} from "./action"
import {
  GET_ADMIN_REQUEST_APPROVED_REQUEST,
  PUT_ADMIN_SOIL_REQUEST
} from "./type"

const AdminApprovedAPI = async data => {
  const URL = `${BASE_URL}api/v1/admin/approval_request/${data}`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "GET"
  }
  return XHR(URL, options)
}

function* AdminApprovedData({ data }) {
  try {
    const response = yield call(AdminApprovedAPI, data)
    yield put(getAdminApprovedSuccess(response.data))
  } catch (e) {
    const { response } = e
    yield put(getAdminApprovedFailure(response.data))
  }
}

const PutSolilAdminAPI = async (data, id) => {
  const URL = `${BASE_URL}api/v1/admin/approval_request/${id}/`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    data,
    method: "PUT"
  }
  return XHR(URL, options)
}

function* PutSolilAdminData({ data, id }) {
  try {
    const response = yield call(PutSolilAdminAPI, data, id)
    yield put(putAdminSoilSuccess(response.data))
   
  } catch (e) {
    const { response } = e
    yield put(putAdminSoilFailure(response.data))
 
  }
}

export default all([
  takeLatest(GET_ADMIN_REQUEST_APPROVED_REQUEST, AdminApprovedData),
  takeLatest(PUT_ADMIN_SOIL_REQUEST, PutSolilAdminData)
])
