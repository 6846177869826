/* eslint-disable import/no-anonymous-default-export */
import {
    GET_REPORT_REQUEST,
    GET_REPORT_SUCCESS,
    POST_REPORT_REQUEST,
    POST_REPORT_SUCCESS,
    POST_REPORT_FAILED,
    GET_CATEGORY_REQUEST,
    GET_CATEGORY_SUCCESS,
    GET_CONTRACTOR_REQUEST,
    GET_CONTRACTOR_SUCCESS 

} from "./types"

const initialState = {
    reportData: false,
    requesting: false,
    error:false,
    Postrequesting:false,
    categoryData:false,
    contractorData:false

}

export default (state = initialState, action) => {
    switch (action.type) {
        case   GET_REPORT_REQUEST:
            return {
                ...state,
                requesting: true
            } 
        case GET_REPORT_SUCCESS:
            return {
                ...state,
                requesting: false,
                reportData: action.data
            }
            case   GET_CONTRACTOR_REQUEST:
            return {
                ...state,
                contractorRequesting: true
            }
            case GET_CONTRACTOR_SUCCESS:
            return {
                ...state,
                contractorRequesting: false,
                contractorData: action.data
            }
            
            case   GET_CATEGORY_REQUEST:
                return {
                    ...state,
                    categoryRequesting: true
                }
            case GET_CATEGORY_SUCCESS:
                return {
                    ...state,
                    categoryRequesting: false,
                    categoryData: action.data
                }
            case   POST_REPORT_REQUEST:
            return {
                ...state,
                Postrequesting: true
            }
        case POST_REPORT_SUCCESS:
            return {
                ...state,
                Postrequesting: false,
               
            }
            case POST_REPORT_FAILED:
            return {
                ...state,
                Postrequesting: false,
                error: action.data
            }

        
        default:
            return state
    }
}
