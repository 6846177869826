import React from "react"
import { Select } from "antd"
import "./style.scss"

const AeSelect = ({
  defaultValue,
  options,
  onChange,
  placeholder,
  onSelect,
  value,
  onFocus,
  showLabel,
  label = "",
  disabled
}) => {
  const showLabelMessage = showLabel ? showLabel : false

  return (
    <div className="board-select-wrapper">
      {showLabelMessage && <label className="select-label">{label}</label>}

      <Select
        value={value || undefined}
        onSelect={onSelect}
        placeholder={placeholder}
        defaultValue={defaultValue}
        suffixIcon={false}
        options={options}
        onChange={onChange}
        onFocus={onFocus}
        disabled={disabled}
      />
    </div>
  )
}

export default AeSelect
