import { all, call, put, takeLatest } from "redux-saga/effects";
import { BASE_URL } from "../../../../config/app";
import XHR from "../../../../utils/XHR";
import { POST_CONFIRM_PASSWORD, POST_USER_PASSWORD } from "./type";
import {
  postConfirmPasswordSuccess,
  postConfirmPasswordFailure,
  postUserPasswordSuccess,
  postUserPasswordFailure
} from "./action";
import { toast } from "react-hot-toast"
import { history } from "../../../../reduxStore/store"
import { logOut, loginRequestSuccess } from "../../LoginScreen/redux/action";


async function postConfirmAPI(data) {
  const URL = `${BASE_URL}api/v1/password/reset/confirm/`
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    method: "POST",
    data
  }
  return XHR(URL, options)
}

function* postConfirmInfo({ data }) {
  try {
    const response = yield call(postConfirmAPI, data)
    yield put(postConfirmPasswordSuccess(response.data))
    // localStorage.clear()
    // logOut()
    // window.location.reload(false);
    history.push("/login")
    loginRequestSuccess(false)




  } catch (e) {
    const { response } = e
    yield put(postConfirmPasswordFailure(response.data))
  }
}

async function postUserAPI(data) {
  const URL = `${BASE_URL}api/v1/change-password/`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "PUT",
    data
  }
  return XHR(URL, options)
}

function* postUserpasswordInfo({ data }) {
  try {
    const response = yield call(postUserAPI, data)


    // Delay for a certain duration


    yield put(postUserPasswordSuccess(response.data));
    // yield put(toast.success('Password changed successfully'));
    // yield delay(2000); // Adjust the duration as needed
    (toast.success('Password changed successfully'));
    history.push('/dashboard');

    // localStorage.clear()
    //   logOut()
    // window.location.reload(false);

    // history.push("/dashboard")
    //  if (response.data.status=="success") {
    //   history.push("/dashboard")
    //   // window.location.reload(false);
    // }else history.push("/dashboard")


  } catch (e) {
    const { response } = e

    response.data?.msg && toast.error(response.data?.msg)
    yield put(postUserPasswordFailure(response.data))
  }
}

export default all([
  takeLatest(POST_CONFIRM_PASSWORD, postConfirmInfo),
  takeLatest(POST_USER_PASSWORD, postUserpasswordInfo)

])