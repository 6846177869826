import React, { useState, useEffect, useRef } from "react"

import { Col, Collapse, DatePicker, Modal, Row, Table } from "antd"

import pdfupload2 from "../../../assets/images/pdfupload2.svg"
import uploadfile from "../../../assets/images/uploadfile.svg"
import Input from "../../Input"
import { connect } from "react-redux"
import "./style.scss"
import Button from "../../Button"
import { PostDocumentFailed, PostDocumentRequest } from "../../../pages/Admin/DocumentAgreement/redux/action"
import useForm from "../../../utils/useForm"


const UploadDocoment = props => {
    const { modalShow, setModalShow, notification, PostDocumentRequest, postrequesting, error, PostDocumentFailed } = props
    const [selectedFile, setSelectedFile] = useState(null)

    const fileRef = useRef()
   
    const HandleCancel = () => {
        setModalShow(false)
        setState(stateSchema)
        PostDocumentFailed(false)
        setSelectedFile(null)
        if (fileRef.current) {
            fileRef.current.value = '';
          }
        

    }
    const stateSchema = {
        Title: {
            value: "",
            error: ""
        },
    }
    const validationStateSchema = {
        Title: {
            required: true
        },
    }
    const { state, handleOnChange, disable, setState } = useForm(
        stateSchema,
        validationStateSchema
    )
    const handleChangeup = e => {
        const file = e.target.files[0]
        setSelectedFile(file)
    }

    const submit = () => {
        const formData = new FormData()
        formData.append("attachment", selectedFile)
        formData.append("title", state.Title.value)
        PostDocumentRequest(formData, HandleCancel)
    }




    return (
        <>

            <Modal
                title={` `}
                open={modalShow}
                onCancel={HandleCancel}
                className="UploadDocoment-modal"
                cancelButtonProps={{ style: { display: "none" } }}
                // confirmLoading={postLoading}
                footer={null}
            >


                <div className="modal-div">
                    <h3>
                        Upload Document
                    </h3>

                    <h2>Title </h2>
                    <Input
                        type="text"
                        placeholder="Enter Title"
                        className="tile-input"
                        value={state.Title.value}
                        showError={false}
                        errorMessage={
                            state.Title.error ||
                            (!state?.Title?.value &&
                                error?.title)
                        }

                        onChange={val =>
                            handleOnChange("Title", val.target.value)
                        }
                    />
                    <label
                        className={`error-message mb-2 ${error ? "opacity-1" : ""}`}
                    >
                        {error?.title}
                    </label>
                    <input
                        type="file"
                        name="file"
                        ref={fileRef}
                        accept=".pdf"
                        onChange={handleChangeup}
                        hidden
                    />
                    <div className="uplod-file
                    
                    "
                        onClick={() => fileRef.current.click()}>

                        <div className="attch-div">
                            <img className="pdfupload2"
                                src={ selectedFile?uploadfile:pdfupload2 }

                            />
                            <div>


                                <a className="attch">{selectedFile?selectedFile.name:"Attach Document"}</a>
                            </div>
                        </div>
                    </div>
                    <label
                        className={`error-message mb-2 ${error ? "opacity-1" : ""}`}
                    >
                        {error?.attachment}
                    </label>
                    <div className="btn-wraper">

                        <Button
                            title="Upload"
                            className="aes-btn green-btn "
                            onClick={submit}
                            showSpinner={postrequesting}
                        />
                    </div>
                </div>



            </Modal>
        </>
    )
}
const mapStateToProps = state => ({
    documentData: state.DocumentAgreement.documentData,
    postrequesting: state.DocumentAgreement.postrequesting,
    error: state.DocumentAgreement.error,
})

const mapDispatchToProps = dispatch => ({
    PostDocumentRequest: (data, handleCancel) => dispatch(PostDocumentRequest(data, handleCancel)),
    PostDocumentFailed: data => dispatch(PostDocumentFailed(data)),



})

export default connect(mapStateToProps, mapDispatchToProps)(UploadDocoment)
