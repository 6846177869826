import React, { useEffect, useState } from "react"
import backIcon from "../../../assets/images/back-icon-circle.svg"
import Button from "../../../components/Button"
import Input from "../../../components/Input"
import HomeLayout from "../../../layout/HomeLayout"
import { history } from "../../../reduxStore/store"
import useForm from "../../../utils/useForm"
import validator from "../../../utils/validation"
import { putTeamMemberRequest } from "./redux/action"
import {
  getTeamMembersRequest,
  getTeamMembersSuccess
} from "../MyTeam/redux/action"
import { connect } from "react-redux"

import "./style.scss"
import AesPhoneInput from "../../../components/PhoneInput"
import { addTeamMemberSuccess } from "../AddTeamMember/redux/action"
import AeSelect from "../../../components/AeSelect"
import { Toaster } from "react-hot-toast"
import { BASE_URL } from "../../../config/app"
import { toast } from "react-hot-toast"

import axios from "axios"
const TeamMemberProfile = props => {
  const {
    user,
    getTeamMembersRequest,
    getTeamMembersSuccess,
    putTeamMemberRequest,
    myTeamMembers,
    teamMember,
    requesting,
    putRequesting,
    error
  } = props
  const [role, setRole] = useState("")

  const stateSchema = {
    fullName: {
      value: "",
      error: ""
    },
    email: {
      value: "",
      error: ""
    },
    phone: {
      value: "",
      error: ""
    },
    location: {
      value: "",
      error: ""
    },
    role: {
      value: "",
      error: ""
    }
  }

  const validationStateSchema = {
    fullName: {
      required: true
    },
    email: {
      required: true,
      validator: validator.email
    },
    phone: {
      required: true,
      validator: validator.phone
    },
    location: {
      required: true
    },
    role: {
      required: true
    }
  }

  const { state, handleOnChange, disable, setState } = useForm(
    stateSchema,
    validationStateSchema
  )
  const TeamMemberId = localStorage.getItem("TeamMemberId")

  useEffect(
    function () {
      setState(stateSchema)
      getTeamMembersRequest(`/${TeamMemberId}`)
    },
    [TeamMemberId]
  )

  useEffect(
    function () {
      if (myTeamMembers) {
        stateSchema.fullName.value = myTeamMembers.name
        stateSchema.email.value = myTeamMembers.email
        stateSchema.phone.value = myTeamMembers.phone_number
        stateSchema.location.value = myTeamMembers.location
        stateSchema.role.value = myTeamMembers.role

        setState(stateSchema)
      }
    },
    [myTeamMembers]
  )

  const submit = async () => {
    const formData = new FormData()
    formData.append("name", state.fullName.value)
    formData.append("email", state.email.value)
    formData.append("location", state.location.value)
    formData.append("role", role)
    formData.append("phone_number", state.phone.value)

    await putTeamMemberRequest(formData, TeamMemberId)
  }
  const handleFormatChanger = value => {
    if (value.length > 0) {
      handleOnChange("phone", ` +${value}`)
    } else handleOnChange("phone", value)
  }

  // Replace with your API base URL
  const URL = `${BASE_URL}/api/v1/change_password_email/`

  const teamPassword = async value => {
    try {
      const value={

        user_id: myTeamMembers?.add_user
      }
      const response = await axios.post(URL, value)
     
      toast.success(response.data, {
        id: "card"
      })
    
    } catch (error) {

      console.error(error)
    }
  }

  return (
    <>
      <HomeLayout dashboardHeader={true}>
        {error && error ? (
          <div className="errordiv">
            <p>You are Operator and not permitted</p>
          </div>
        ) : (
          <section className="content-section team-member-profile-section">
            <Toaster />
            <div className="team-member-profile-content-section">
              <div className="heading">
                <div
                  className="back"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    history.push("my-team")
                    getTeamMembersSuccess(false)
                  }}
                >
                  <img src={backIcon} alt="" />
                </div>
                <h3>{myTeamMembers.name}</h3>
              </div>
              <div className="add-members-form-section">
                <div className="container">
                  <div className="row">
                    <div className="col-md-4 add-member-field">
                      <Input
                        showLabel={true}
                        label="Full Name"
                        value={myTeamMembers.name ? state.fullName.value : ""}
                        showError={true}
                        errorMessage={state.fullName.error || error?.name}
                        onChange={val =>
                          handleOnChange("fullName", val.target.value)
                        }
                      />
                    </div>
                    <div className="col-md-4 add-member-field">
                      <Input
                        placeholder="Enter email"
                        showLabel={true}
                        label="Email"
                        type="email"
                        
                        showError={true}
                        errorMessage={state.email.error || error?.email}
                        value={state.email.value}
                        onChange={val =>
                          handleOnChange("email", val.target.value)
                        }
                      />
                    </div>
                    <div className="col-md-4 add-member-field">
                      <div className="input-wraper">
                        <label>Phone</label>

                        <AesPhoneInput
                          label="Phone"
                          placeholder="Enter phone"
                          type="number"
                          showError={true}
                          onChange={handleFormatChanger}
                          errorMessage={error?.phone_number}
                          value={state.phone.value}
                        />
                      </div>
                    </div>
                    <div className="col-md-4 add-member-field">
                      <Input
                        placeholder="Enter location"
                        showLabel={true}
                        label="Location"
                        value={state.location.value}
                        showError={true}
                        errorMessage={state.location.error || error?.location}
                        onChange={val =>
                          handleOnChange("location", val.target.value)
                        }
                      />
                    </div>
                    <div className="col-md-4 add-member-field">
                      {/* <Input
                      placeholder="Enter role"
                      showLabel={true}
                      label="Role"
                      value={state.role.value}
                      showError={true}
                      errorMessage={state.role.error || error?.role}
                      onChange={val => handleOnChange("role", val.target.value)}
                    /> */}
                      <label>Role</label>
                      <AeSelect
                        options={[
                          {
                            value: "Manager",
                            label: "Manager"
                          },
                          {
                            value: "Operator",
                            label: "Operator"
                          }
                        ]}
                        placeholder={myTeamMembers.role}
                        defaultValue={myTeamMembers.role}
                        // value={item.material_name}
                        onChange={item => {
                          setRole(item)
                        }}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="button-wrapper">
                      <Button
                        showSpinner={putRequesting}
                        title="Save"
                        className="aes-btn green-btn"
                        onClick={() => submit()}
                      />
                      <div
                        className="change"
                        onClick={() => {
                          teamPassword()
                        }}
                      >
                        Change Password
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
      </HomeLayout>
    </>
  )
}

const mapStateToProps = state => ({
  user: state.login?.loginInfo.user,
  myTeamMembers: state.TeamMembers.myTeamMembers,
  requesting: state.TeamMemberProfile.requesting,
  putRequesting: state.TeamMemberProfile.putRequesting,
  error: state.TeamMemberProfile.error,
  teamMember: state.TeamMemberProfile.teamMember
})

const mapDispatchToProps = dispatch => ({
  getTeamMembersRequest: id => dispatch(getTeamMembersRequest(id)),
  // getTeamMemberFailure: data => dispatch(getTeamMemberFailure(data)),
  putTeamMemberRequest: (data, id) => dispatch(putTeamMemberRequest(data, id)),
  getTeamMembersSuccess: data => dispatch(getTeamMembersSuccess(data))
  // signUpRequestSuccess: data => dispatch(signUpRequestSuccess(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(TeamMemberProfile)
