import { all, call, put, takeLatest } from "redux-saga/effects"
import { BASE_URL } from "../../../../config/app"
import XHR from "../../../../utils/XHR"
import {
  deleteFillSuccess,
  getAdminDashboardListFailure,
  getAdminDashboardListSuccess,
  getAdminDashboardUpdateUserFailure,
  getAdminDashboardUpdateUserSuccess,
  getMaterialFailure,
  getMaterialSuccess,
  userActiveSuccess
} from "./action"
import {
  ADMIN_DASHBOARD_USERLIST,
  ADMIN_DASHBOARD_UPDATE_USER,
  USER_ACTIVE_REQUEST,
  GET_MATERIAL_REQUEST,
  DELETE_FIILL_REQUEST
} from "./type"
import { toast } from "react-hot-toast"
import { history } from "../../../../reduxStore/store"

const AdminListAPI = async data => {
  // const id = data ? `${data}/` : ""
  const URL = `${BASE_URL}api/v1/admin/add_new_fill_site/${data}`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "GET"
  }
  return XHR(URL, options)
}

function* AdminDashboardUserData({ data }) {
  try {
    const response = yield call(AdminListAPI, data)
    yield put(getAdminDashboardListSuccess(response?.data))
  } catch (e) {
    const { response } = e
    yield put(getAdminDashboardListFailure(response?.data))
  }
}

const AdminUpdateUserAPI = (id, data) => {
  const URL = `${BASE_URL}api/v1/admin/add_new_fill_site/${id}/`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "PUT",
    data
  }
  return XHR(URL, options)
}

function* AdminDashboardUpdateUserData({ id, data }) {
  try {
    const response = yield call(AdminUpdateUserAPI, id, data)
    yield put(getAdminDashboardUpdateUserSuccess(response?.data))
    toast.success("Saved Successfully")
  } catch (e) {
    const { response } = e
    yield put(getAdminDashboardUpdateUserFailure(response.data))
    toast.error("An Error Occured while Updating")
  }
}

const UserActiveAPI = (id, data) => {
  const URL = `${BASE_URL}api/v1/admin/update_fill_site_status/${id}/`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "PUT",
    data
  }
  return XHR(URL, options)
}

function* UserActiveData({ id, data, showSuccessToast }) {
  try {
    const response = yield call(UserActiveAPI, id, data)
    yield put(userActiveSuccess(response?.data))
    yield put(showSuccessToast())
    // toast.success("Saved Successfully")
  } catch (e) {
    const { response } = e
  }
}

const MaterialDataAPI = async () => {
  const URL = `${BASE_URL}material/materialname/`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "GET"
  }
  return XHR(URL, options)
}

function* MaterialData() {
  try {
    const response = yield call(MaterialDataAPI)
    yield put(getMaterialSuccess(response?.data))
  } catch (e) {
    const { response } = e
    yield put(getMaterialFailure(response?.data))
  }
}


const DeleteFillAPI = (id) => {
 
  const URL = `${BASE_URL}api/v1/admin/add_new_fill_site/${id}/`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "DELETE",
  
  }
  return XHR(URL, options)
}

function* DeleteFillrData({id}) {
  try {
    const response = yield call(DeleteFillAPI,id)
    yield put(deleteFillSuccess(response?.data))
    yield toast.success("Removed Successfully", {
      id: "card"
    })
    setTimeout(() => {
       history.push("dashboard")
    }, 2000);
   
  } catch (e) {
    const { response } = e
    // yield put(getAdminDashboardUpdateUserFailure(response.data))
    // toast.error("An Error Occured while Updating")
  }
}

export default all([
  takeLatest(ADMIN_DASHBOARD_USERLIST, AdminDashboardUserData),
  takeLatest(ADMIN_DASHBOARD_UPDATE_USER, AdminDashboardUpdateUserData),
  takeLatest(USER_ACTIVE_REQUEST, UserActiveData),
  takeLatest(GET_MATERIAL_REQUEST, MaterialData),
  takeLatest(DELETE_FIILL_REQUEST, DeleteFillrData),

  
])
