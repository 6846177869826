import { all, call, put, takeLatest } from "redux-saga/effects"
import { BASE_URL } from "../../../../config/app"
import XHR from "../../../../utils/XHR"
import { getHomeScreenFailure, getHomeScreenSuccess } from "./action"
import { GET_HOME_SCREEN_REQUEST } from "./type"

const HomeScreenAPI = async () => {
  let URL = `${BASE_URL}api/v1/home_page/`

  // const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    method: "GET",
  
  }
  return XHR(URL, options)
}

function* HomeScreenData() {
  try {
    const response = yield call(HomeScreenAPI)
    yield put(getHomeScreenSuccess(response?.data))
  } catch (e) {
    const { response } = e
    yield put(getHomeScreenFailure(response?.data))
  }
}

export default all([takeLatest(GET_HOME_SCREEN_REQUEST, HomeScreenData)])
