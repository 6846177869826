import React, { useEffect, useState } from "react"
import backIcon from "../../../assets/images/back-icon-circle.svg"
import Button from "../../../components/Button"
import Input from "../../../components/Input"
import HomeLayout from "../../../layout/HomeLayout"
import { history } from "../../../reduxStore/store"
import useForm from "../../../utils/useForm"
import validator from "../../../utils/validation"
import { addScheduleRequest, addScheduleFailure,getAdminSchedule } from "./redux/action"
import { connect } from "react-redux"
import "./style.scss"
import AesPhoneInput from "../../../components/PhoneInput"
import AeSelect from "../../../components/AeSelect"
import { toast } from "react-hot-toast"
import TextArea from "../../../components/TextArea"
import { Col, Collapse, DatePicker, Modal, Space, Select } from "antd"
import moment from "moment"

const AddSchedule = props => {
  const {
    addScheduleRequest,
    addScheduleFailure,
    getAdminSchedule,
    projects,
    requesting,
    error,
    loginInfo,
    selectData,
  } = props
  const [role, setRole] = useState("Operator")
  const stateSchema = {
    noOfTruck: {
      value: "",
      error: ""
    },
    noOfRounds: {
      value: "",
      error: ""
    },
    date: {
      value: "",
      error: ""
    },
    configuration: {
      value: "",
      error: ""
    },
  }

  const validationStateSchema = {
    noOfTruck: {
      required: true
    },
    noOfRounds: {
      required: true
    },
    date: {
      required: true
    },
    configuration: {
      required: true
    },
  }

  const { state, handleOnChange, disable, setState } = useForm(
    stateSchema,
    validationStateSchema
  )
  const [search, setSearch] = useState()
  useEffect(() => {
    if (addScheduleRequest) {
      setState(stateSchema)
      addScheduleFailure(false)
    }
  }, [addScheduleRequest])

  useEffect(() => {
    getAdminSchedule()
  }, [])

  useEffect(() => {
    projects && history.push("schedule")
  }, [projects])

  const submit = async () => {
    const formData = new FormData()
    formData.append("no_of_trucks", state.noOfTruck.value)
    formData.append("no_of_rounds", state.noOfRounds.value)
    formData.append("enter_time", moment(state.date.value).format("YYYY-MM-DD HH:mm"))
    formData.append("configuration", state.configuration.value)
    formData.append("approved_request", search)
    await addScheduleRequest(formData)

    if (!state.noOfTruck.value) {
      loginInfo?.user?.user_status &&
        toast.error("Mandatory fields are required")
    }
  }
  const handleScheduleChange = (value) => {
    setSearch(value)

  }
  const onOk = (value) => {
    console.log('onOk: ', value);
  };
  const { Option } = Select
  return (
    <>
      <HomeLayout dashboardHeader={true} title="Schedule Soil Deposits" subtitle="Add New">
        {loginInfo && loginInfo?.user?.role == "Operator" || loginInfo && loginInfo?.user?.role == "Manager" ? (
          <div className="errordiv">
            <p>You are Operator and not permitted</p>
          </div>
        ) :
          <section className="content-section add-team-members-section">
            <div className="add-team-members-content-section">
              <div className="heading-add-team-members">
                <div
                  className="back"
                  style={{ cursor: "pointer" }}
                  onClick={() => history.push("schedule")}
                >
                  <img src={backIcon} alt="" />
                </div>
                <h1>Add Schedule Soil Deposits</h1>
              </div>
              <div className="add-members-form-section">
                <div className="row">
                  <div className="col-md-6 selector-api">
                      <p style={{fontWeight:'600',marginBottom:0}}>Select Approved Soil Deposit:</p>
                      <Select
                        placeholder="Select Approved Soil Deposit"
                        maxTagCount="responsive"
                        style={{
                          width: "100%",
                          height: 45
                        }}
                        value={search}
                        filterOption={(inputValue, option) =>
                          option.label
                            .toLowerCase()
                            .indexOf(inputValue.toLowerCase()) !== -1
                        }
                        // label={adminData?.username}
                        onChange={handleScheduleChange}
                      >
                        {selectData ? (
                          selectData.map(data => (
                            data.status == 'accepted'&& <Option
                              key={data.id}
                              value={data.id}
                              label={data.project_name+" - "+data.aes_po_no}
                            >
                              <Space>{data.project_name+" - "+data.aes_po_no}</Space>
                            </Option>
                          ))
                        ) : (
                          <Option value={null} disabled>
                            No data
                          </Option>
                        )}
                      </Select>
                  </div>
                  <div className="col-md-6 add-member-field">
                    <Input
                      placeholder="Enter no of trucks"
                      showLabel={true}
                      label="Show Max "
                      value={state.noOfTruck.value}
                      showError={true}
                      errorMessage={state.noOfTruck.error || error?.name}
                      onChange={val =>
                        handleOnChange("noOfTruck", val.target.value)
                      }
                    />
                  </div>
                  
                  <div className="col-md-6 add-member-field">
                    <Input
                      placeholder="Enter no of rounds"
                      showLabel={true}
                      label="No of Rounds"
                      value={state.noOfRounds.value}
                      showError={true}
                      errorMessage={state.noOfRounds.error || error?.name}
                      onChange={val =>
                        handleOnChange("noOfRounds", val.target.value)
                      }
                    />
                  </div>
                  <div className="col-md-6 add-member-field dateP">
                    <p style={{fontWeight:'600',marginBottom:0}}>Enter Date</p>
                    <DatePicker
                      style={{
                        width: "100%",
                        backgroundColor: "#fff",
                        height: "49px",
                        border: "unset"
                      }}
                      onChange={val =>
                        handleOnChange("date", val)
                      }
                      value={state.date.value}
                      
                      
                    />
                  </div>
                  <div className="col-md-12 add-member-field">
                    <TextArea
                      placeholder="Enter configuration details"
                      showLabel={true}
                      label="Configuration Details"
                      value={state.configuration.value}
                      showError={true}
                      errorMessage={state.configuration.error || error?.name}
                      onChange={val =>
                        handleOnChange("configuration", val.target.value)
                      }
                    />
                  </div>

                </div>
                <div className="row">
                  <div className="button-wrapper">
                    <Button
                      showSpinner={requesting}
                      title="Add Schedule a Soil Deposit"
                      className="aes-btn green-btn"
                      onClick={() => submit()}
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
        }
      </HomeLayout>
    </>
  )
}

const mapStateToProps = state => ({
  addScheduleRequest: state.AddSchedule.addScheduleRequest,
  requesting: state.AddSchedule.requesting,
  error: state.AddSchedule.error,
  projects: state.AddSchedule.projects,
  loginInfo: state.login.loginInfo,
  selectData: state.AddSchedule.getAdminSchedule
})

const mapDispatchToProps = dispatch => ({
  addScheduleRequest: data => dispatch(addScheduleRequest(data)),
  addScheduleFailure: data => dispatch(addScheduleFailure(data)),
  getAdminSchedule: data => dispatch(getAdminSchedule(data))
  // signUpRequestSuccess: data => dispatch(signUpRequestSuccess(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(AddSchedule)
