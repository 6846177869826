import { all, call, put, takeLatest } from "redux-saga/effects"
import { BASE_URL } from "../../../../config/app"
import XHR from "../../../../utils/XHR"
import {
    getAdminNotificationRequest,
    getNotificationSucess,
    deleteNotificationRequest,
    deleteNotificationSucess,

    PostAdminFailed,
    PostAdminSucess


} from "./action"
import {
    GET_Admin_NOTIFICATION_REQUEST,
    GET_NOTIFICATION_SUCCESS,
    DELETE_NOTIFICATION_REQUEST,
    DELETE_NOTIFICATION_SUCCESS,
    POST_Admin_NOTIFICATION_REQUEST,
    POST_ADMIN_REQUEST
} from "./type"
import { toast } from "react-hot-toast"

const getNotificationAPI = async (data) => {

    const URL = `${BASE_URL}api/v1/admin/notification/${data}`
    const token = localStorage.getItem("token")
    const options = {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Token ${token}`
        },
        method: "GET"
    }
    return XHR(URL, options)
}

function* getNotificationrData({ data }) {
    try {
        const response = yield call(getNotificationAPI, data)
        yield put(getNotificationSucess(response?.data))

    } catch (e) {
        console.log(e);
    }
}
const delNotificationrAPI = async (data) => {
    const URL = `${BASE_URL}api/v1/admin/notification/${data}/`
    const token = localStorage.getItem("token")
    const options = {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Token ${token}`
        },
        method: "DELETE"
    }
    return XHR(URL, options)
}

function* delNotificationrData({ data }) {
    try {
        const response = yield call(delNotificationrAPI, data)
        yield put(deleteNotificationSucess(response?.data))
        yield toast.success("Removed Successfully", {
            id: "card"
        })

        yield put(getAdminNotificationRequest(""))
    } catch (e) {
        console.log(e);
    }
}
// const geCategryAPI = async (data) => {
//     const URL = `${BASE_URL}api/v1/category/`
//     const token = localStorage.getItem("token")
//     const options = {
//         headers: {
//             Accept: "application/json",
//             "Content-Type": "application/json",
//             Authorization: `Token ${token}`
//         },
//         method: "GET"
//     }
//     return XHR(URL, options)
// }

// function* getCategryData({ data }) {
//     try {
//         const response = yield call(geCategryAPI, data)
//         yield put(getCategrySucess(response?.data))

//     } catch (e) {
//         console.log(e);
//     }
// }
const PostAdminAPI = async (data) => {
    const URL = `${BASE_URL}api/v1/admin/notification/`
    const token = localStorage.getItem("token")
    const options = {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Token ${token}`
        },
        method: "POST",
        data
    }
    return XHR(URL, options)
}

function* PostAdminData({ data, handleCancel }) {
    try {
        const response = yield call(PostAdminAPI, data)
        yield put(PostAdminSucess(response?.data))
        handleCancel()
        yield put(getAdminNotificationRequest(""))
        toast.success("Notification has been sent successfully")
    } catch (e) {
        const { response } = e
        yield put(PostAdminFailed(response?.data))
        // toast.error("There was an error")
    }
}
export default all([
    takeLatest(
        POST_ADMIN_REQUEST, PostAdminData),
    takeLatest(GET_Admin_NOTIFICATION_REQUEST, getNotificationrData),


    takeLatest(DELETE_NOTIFICATION_REQUEST, delNotificationrData),

    // takeLatest(GET_CATEGORY_REQUEST, getCategryData),
])