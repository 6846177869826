import React from "react"
// import "./style.scss"
import { useHistory } from "react-router-dom"
import splashLogo from "../../assets/images/splashLogo.svg"
import Button from "../../components/Button"

const NotFound = props => {
  const history = useHistory()
  const {} = props
  return (
    <>
      <section className="splsah-page-section">
        <img src={splashLogo}></img>
        <div>
          <h1
            style={{
              textAlign: "center",
              fontWeight: "Bold",
              fontFamily: "khula",
              marginTop: "10%",
              color: "white"
            }}
          >
            Lost your way?
          </h1>

          <p
            style={{
              textAlign: "center",
              fontSize: "20px",
              fontFamily: "khula",
              color: "white"
            }}
          >
            Sorry, we can't find that page. You'll find loads to explore on the
            home page.
          </p>
        </div>
        <Button
          title=" Home"
          onClick={() => history.push("/auth/account")}
        />
      </section>
    </>
  )
}

export default NotFound
