import {
    GET_REPORT_REQUEST,
    GET_REPORT_SUCCESS,
    POST_REPORT_REQUEST,
    POST_REPORT_SUCCESS,
    POST_REPORT_FAILED,
    GET_CATEGORY_REQUEST,
    GET_CATEGORY_SUCCESS,
    GET_CONTRACTOR_REQUEST,
    GET_CONTRACTOR_SUCCESS 

} from "./types"
export const getReportRequest = data => ({
    type: GET_REPORT_REQUEST,
    data
})

export const getReportSucess = data => ({
    type: GET_REPORT_SUCCESS,
    data
}) 
export const getCategryRequest = data => ({
    type: GET_CATEGORY_REQUEST,
    data
})

export const getCategrySucess = data => ({
    type: GET_CATEGORY_SUCCESS,
    data
})

export const getContractorRequest = data => ({
    type: GET_CONTRACTOR_REQUEST,
    data
})

export const getContractorSucess = data => ({
    type: GET_CONTRACTOR_SUCCESS,
    data
})
export const PostReportRequest = (data,handleCancel) => ({
    type:  POST_REPORT_REQUEST,
    data,handleCancel
})
export const PostReportSucess = data => ({
    type:POST_REPORT_SUCCESS,
    data
})
export const PostReportFailed = data => ({
    type:   POST_REPORT_FAILED,
    data
})