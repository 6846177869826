import React from 'react';
import { Route, Switch, useLocation  } from 'react-router-dom';
import { routes } from '../../Router';
const Visiting = () => {
  let location = useLocation();
  let pathname = location.pathname.split("/").filter(val => val !== "visiting").join("/")
    window.history.replaceState({},'', pathname);

    const getRoutes = routes => {
        return routes.map((prop, key) => {
          if (prop.layout === '/visiting') {
            return (
              <Route
                path={prop.layout + prop.path}
                component={prop.component}
                key={key}
              />
            )
          } else {
            return null
          }
        })
      }
      
        return (
            <Switch>{getRoutes(routes)}</Switch>
        )
      }

export default Visiting;
