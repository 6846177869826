import React, { useEffect } from "react"
import Button from "../../components/Button"
import Modal from "react-bootstrap/Modal"
import Input from "../../components/Input"
import closeIcon from "../../assets/images/closeIcon.svg"
import useForm from "../../utils/useForm"
import validator from "../../utils/validation"
import { connect } from "react-redux"
import {
  postForgotRequest,
  postForgotRequestSuccess,
  postForgotRequestFailure,
  setNotFirst
} from "./redux/action"
import "./style.scss"

const ForgetModal = props => {
  const {
    forgotRequest,
    forgotInfo,
    requesting,
    error,
    modalShow,
    setModalShow,
    postForgotRequestSuccess,
    postForgotRequestFailure,
    isFirst,
    setNotFirst,
    email
  } = props

  useEffect(() => {
    if (forgotInfo?.status == "OK") {
      setState(stateSchema)
      postForgotRequestSuccess(false)
    }
  }, [forgotInfo?.status])

  const stateSchema = {
    email: {
      value: "",
      error: ""
    }
  }
  const validationStateSchema = {
    email: {
      required: true,
      validator: validator.email
    }
  }
  const { state, handleOnChange, disable, setState } = useForm(
    stateSchema,
    validationStateSchema
  )
  const onSubmit = async (email = state.email.value) => {
    const data = {
      email
    }
    await forgotRequest(data)
  }

  return (
    <>
      <Modal
        show={modalShow}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="Forget-modal"
      >
        <Modal.Body className="warning-modal-body">
          <div
            className="close-icon"
            onClick={() => {
              setNotFirst({ status: true, email: "" })
              setModalShow(false)
            }}
          >
            <img src={closeIcon} alt="" />
          </div>
          <div className="warning-wrapper">
            <div className="input-wrapper">
              <h4>Email</h4>
              {!isFirst ? (
                <Input type="email" value={email} disabled />
              ) : (
                <Input
                  type="email"
                  placeholder="Enter email"
                  value={state.email.value}
                  onChange={val => handleOnChange("email", val.target.value)}
                  onFocus={() => postForgotRequestFailure(false)}
                />
              )}
              <label
                className={`error-message mb-2 ${
                  error?.email && error?.email[0] ? "opacity-1" : ""
                }`}
              >
                {error?.email && error?.email[0]}
              </label>
            </div>
            {isFirst ? (
              <div className="button-wrapper">
                <Button
                  title="Send me the password reset link"
                  onClick={() => {
                    onSubmit()
                  }}
                  showSpinner={requesting}
                />
              </div>
            ) : (
              <div className="last-text">
                <p>
                  Didn't get the link?
                  <span>
                    <a onClick={() => onSubmit(email)}>&nbsp; Resend</a>
                  </span>
                </p>
              </div>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

const mapStateToProps = state => ({
  forgotInfo: state.forgotPassword.forgotInfo,
  requesting: state.forgotPassword.requesting,
  error: state.forgotPassword.error,
  isFirst: state.forgotPassword.isFirst,
  email: state.forgotPassword.email
})

const mapDispatchToProps = dispatch => ({
  forgotRequest: data => dispatch(postForgotRequest(data)),
  postForgotRequestSuccess: data => dispatch(postForgotRequestSuccess(data)),
  postForgotRequestFailure: data => dispatch(postForgotRequestFailure(data)),
  setNotFirst: data => dispatch(setNotFirst(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(ForgetModal)
