import {
  GET_PROFILE_FILL_DATA,
  GET_PROFILE_FILL_DATA_SUCCESS,
  GET_PROFILE_FILL_DATA_FAILURE,
  PUT_FILL_PROFILE_DATA,
  PUT_FILL_PROFILE_DATA_SUCCESS,
  PUT_FILL_PROFILE_DATA_FAILURE
} from "./type"

// GET Team Member Profile
export const getFillProfileDataRequest = () => ({
  type: GET_PROFILE_FILL_DATA,

})

export const getFillProfileDataSuccess = data => ({
  type: GET_PROFILE_FILL_DATA_SUCCESS,
  data
})

export const getFillProfileDataFailure = data => ({
  type: GET_PROFILE_FILL_DATA_FAILURE,
  data
})

// PUT TEAM MEMBER
export const putFillProfileDataRequest = (data, id) => ({
  type: PUT_FILL_PROFILE_DATA,
  data,
  id

})

export const putFillProfileDataSuccess = data => ({
  type: PUT_FILL_PROFILE_DATA_SUCCESS,
  data
})

export const putFillProfileDataFailure = data => ({
  type: PUT_FILL_PROFILE_DATA_FAILURE,
  data
})
