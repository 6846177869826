import { all, call, put, takeLatest } from "redux-saga/effects"
import { BASE_URL } from "../../../../config/app"
import XHR from "../../../../utils/XHR"
import {
  getMaterialTrackingRequest,
  getMaterialTrackingSuccess,
  getMaterialTrackingFailure,
} from "./action"
import {
  GET_MATERIAL_TRACKING_REQUEST,
} from "./type"

const AdminApprovedAPI = async (data) => {
  const URL = `${BASE_URL}api/v1/admin/material_tracking/${data}`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "GET"
  }
  return XHR(URL, options)
}

function* MaterialTrackingData({ data }) {
  try {
    const response = yield call(AdminApprovedAPI, data)
    yield put(getMaterialTrackingSuccess(response.data))
  } catch (e) {
    const { response } = e
    yield put(getMaterialTrackingFailure(response.data))
  }
}

export default all([
  takeLatest(GET_MATERIAL_TRACKING_REQUEST, MaterialTrackingData),
])
