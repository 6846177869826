import React, { useState, useEffect } from "react"
import HomeLayout from "../../../layout/HomeLayout"
import Search from "../../../components/Search"
import filter from "../../../assets/images/filter.svg"
import "./style.scss"
import { Menu, Dropdown, Table, Space } from "antd"
import Button from "../../../components/Button"
import { getInvoiceRequest } from "./redux/action"
import { connect } from "react-redux"
import { history } from "../../../reduxStore/store"
import { setInvoiceId } from "../../AuthScreens/LoginScreen/redux/action"
import Skeleton from "react-loading-skeleton"
import InvoiceUploadDocoment from "../../../components/AdminModal/InvoiceUploadDocoment"
const Invoices = props => {
  const { getInvoiceRequest, invoiceData, setInvoiceId, requesting } = props
  const [modalShow, setModalShow] = useState(false)
  const [status, setStatus] = useState("Status")

  useEffect(() => {
    getInvoiceRequest("")
  }, [modalShow])

  const handleMenuClick = ({ key }) => {
    setStatus(key);

    const isActive = key === "Active" ? true : false;

    getInvoiceRequest(``);

  };

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="Active">Active</Menu.Item>
      <Menu.Item key="Blocked">Blocked</Menu.Item>
    </Menu>
  )

  const seeMore = id => {
    if (id) {
      setInvoiceId(id)
      history.push("admin-invoice-details")
    }
  }

  const columns = [
    {
      title: "Company name:",
      dataIndex: "company_name",
      key: "company_name",
      render: company_name =>
        !(company_name == null) ? company_name : <span>---</span> // using == for auto type coercion of undefined to null as well
    },
    {
      title: "Invoices Number",
      dataIndex: "invoices_number",
      key: "invoices_number",
      align: 'center',
      render: invoices_number =>
        !(invoices_number == null) ? invoices_number : <span>---</span>
    },
    {
      title: "Invoices Created Date",
      dataIndex: "created_at",
      key: "created_at",
      align: 'center',
      render: created_at =>
        !(created_at == null) ? new Date(created_at).toDateString() : <span>---</span>
    },{
      title: "Invoice",
      dataIndex: "attachment",
      key: "attachment",
      align: 'center',
      render: attachment =>
        !(attachment == null) ? <a target="_blank" href={
          attachment
        }> View </a> : <span>---</span>
    },
  ]

  const onSearch = val => {
    getInvoiceRequest(`?name=${val}`)
  }

  return (
    <HomeLayout dashboardHeader={true}>
      <section className="content-section add-team-members-section">
        <div className="add-team-members-content-section">
          <div className="heading-add-team-members">
            <div><h1>Invoices</h1></div>
            <div className="buttondiv">
                        <Button
                            title="Upload Invoice"
                            className="newFileStyle"
                            onClick={e => setModalShow(true)}
                        />
            </div>
          </div>
          <div className="searchBar">
            <Search onChange={val => onSearch(val.target.value)} />

          </div>
          <div>
            {requesting ? (
              <Table
                rowKey="key"
                pagination={false}
                dataSource={[...Array(10)].map((_, index) => ({
                  key: `key${index}`
                }))}
                columns={columns.map(column => {
                  return {
                    ...column,
                    render: function renderPlaceholder() {
                      return <Skeleton width="20%" />
                    }
                  }
                })}
              />
            ) : (
              <Table
                className="invoice-table"
                dataSource={invoiceData}
                columns={columns}
                pagination={false}
                scroll={{
                  y: 500
                }}
              />
            )}
          </div>
        </div>
      </section>
      <InvoiceUploadDocoment
                setModalShow={setModalShow}
                modalShow={modalShow}

            />
    </HomeLayout>
  )
}

const mapStateToProps = state => ({
  invoiceData: state.Invoices.invoiceData,
  requesting: state.Invoices.requesting
})

const mapDispatchToProps = dispatch => ({
  getInvoiceRequest: data => dispatch(getInvoiceRequest(data)),
  setInvoiceId: data => dispatch(setInvoiceId(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(Invoices)
