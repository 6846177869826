import { Checkbox, Col, Row, Select } from "antd"
import React, { useState, useEffect } from "react"
import BoardSelect from "../../../components/BoardSelect"
import Button from "../../../components/Button"
import Input from "../../../components/Input"
import AesPhoneInput from "../../../components/PhoneInput"
import HomeLayout from "../../../layout/HomeLayout"
import "./style.scss"
import useForm from "../../../utils/useForm"
import { patchData, postDataSuccess } from "../AdminDashboard/NewFileSite/redux/action"
import { getCountryRequest } from "../../AuthScreens/SignUp/redux/action"
import {
  getAdmindashboardList,
  getAdmindashboardUpdateUser,
  getMaterialRequest,
  deleteFillRequest
} from "../AdminDashboard/redux/action"
import { connect } from "react-redux"
import { Toaster } from "react-hot-toast"
// import {backIcon} from "../../../"
import backIcon from "../../../assets/images/back-icon-circle.svg"
import { history } from "../../../reduxStore/store"

const NewFileSite = props => {
  const {
    getAdmindashboardList,
    userListRequestList,
    patchData,
    getCountryRequest,
    getAdmindashboardUpdateUser,
    getMaterialRequest,
    CountryData,
    PostError,
    PostLoading,
    PostInfo,
    postDataSuccess,
    userListRequesting,
    requesting
  } = props

  const [siteOffers, setSiteOffers] = useState({
    check1: false,
    check2: false,
    check3: false,
    check4: false,

  })

  const { Option } = Select

  const stateSchema = {
    sitename: {
      value: "",
      error: ""
    },
    street: {
      value: "",
      error: ""
    },
    city: {
      value: "",
      error: ""
    },
    zipcode: {
      value: "",
      error: ""
    },
    countryState: {
      value: "",
      error: ""
    },
    country: {
      value: "",
      error: ""
    },
    countrypermit: {
      value: "",
      error: ""
    },
    fieldemail: {
      value: "",
      error: ""
    },
    fieldphonenumber: {
      value: "",
      error: ""
    },
    notes: {
      value: "",
      error: ""
    },
    qep: {
      value: "",
      error: ""
    },
    max_load_per_day: {
      value: "",
      error: ""
    }
  }
  const validationSchema = {
    sitename: {
      required: false
    },
    street: {
      required: false
    },
    city: {
      required: false
    },
    zipcode: {
      required: false
    },
    countryState: {
      required: false
    },
    country: {
      required: false
    },
    countrypermit: {
      required: false
    },
    fieldemail: {
      required: false
    },
    fieldphonenumber: {
      required: false
    },
    notes: {
      required: false
    },
    qep: {
      required: false
    },
    max_load_per_day: {
      required: false
    }
  }

  const handleFormatChanger = value => {
    if (value.length > 0) {
      handleOnChange("fieldphonenumber", ` +${value}`)
    } else handleOnChange("fieldphonenumber", value)
  }
  const options = CountryData?.map((items, index) => {
    return (
      <Option key={index} value={items.code}>
        {items.name}
      </Option>
    )
  })

  const { state, handleOnChange, disable, setState } = useForm(
    stateSchema,
    validationSchema
  )
  const onSubmit = () => {
    const id = localStorage.getItem("AdmindashboardId")
    let apiData = {
      id: id,
      email: state.fieldemail.value,
      name: state.sitename.value,
      phone_number: `${state.fieldphonenumber.value}`,
      user_detail: {
        postal_code: state.zipcode.value,
        Country_permit_no: state.countrypermit.value,
        street: state.street.value,
        city: state.city.value,
        state: state.countryState.value,
        country: CountryData[state.country.value]?.id,
        qep: state.qep.value,
        max_load_per_day: state.max_load_per_day.value
      },
      material_offer: {
        industrial_land: siteOffers.check1,
        commercial_land: siteOffers.check2,
        residential_land: siteOffers.check3,
        industrial_land_plus: siteOffers.check4,
        note: state.notes.value
      }
    }
    patchData(apiData,id)
  }

  useEffect(() => {
    if (PostInfo?.id) {
      setState(stateSchema)
      setSiteOffers({
        check1: false,
        check2: false,
        check3: false,
        check4: false,


      })
    }
    postDataSuccess(false)
  }, [PostInfo?.id])
  useEffect(() => {
    const AdmindashboardId = localStorage.getItem("AdmindashboardId")
    getCountryRequest("")
    getAdmindashboardList(`${AdmindashboardId}/`)
  }, [])

  useEffect(() => {
    if (!requesting && !userListRequesting) {
      let index = CountryData.findIndex( x => x.id === Number(userListRequestList?.user_detail?.country) );
      setState(prevState => ({
        ...prevState,
        sitename: {
          ...prevState.sitename,
          value: userListRequestList?.name
        },
        fieldemail: {
          ...prevState.email,
          value: userListRequestList?.email
        },
        fieldphonenumber: {
          ...prevState.phone_number,
          value: userListRequestList?.phone_number
        },
        street: {
          ...prevState?.street,
          value: userListRequestList?.user_detail?.street
        },
        city: {
          ...prevState?.city,
          value: userListRequestList?.user_detail?.city
        },
        zipcode: {
          ...prevState?.city,
          value: userListRequestList?.user_detail?.postal_code
        },
        countryState: {
          ...prevState?.countryState,
          value: userListRequestList?.user_detail?.state
        },
        country: {
          ...prevState?.countrypermit,
          value: index
        },
        countrypermit: {
          ...prevState?.countrypermit,
          value: userListRequestList?.user_detail?.Country_permit_no
        },
        qep: {
          ...prevState?.qep,
          value: userListRequestList?.user_detail?.qep
        },
        max_load_per_day: {
          ...prevState?.max_load_per_day,
          value: userListRequestList?.user_detail?.max_load_per_day
        },
        notes: {
          ...prevState?.notes,
          value: userListRequestList?.material_offer?.length > 0 ? userListRequestList?.material_offer[0].note : ""
        }
      }))
      if(userListRequestList?.material_offer?.length > 0){
        setSiteOffers({
          check1: userListRequestList?.material_offer[0].industrial_land,
          check2: userListRequestList?.material_offer[0].commercial_land,
          check3: userListRequestList?.material_offer[0].residential_land,
          check4: userListRequestList?.material_offer[0].industrial_land_plus
        })
      }
    }
  }, [userListRequestList])
  return (
    <HomeLayout dashboardHeader={true}>
      <Toaster />

      <div className="siteContainer">
        <div className="heading">
          <div className="back" onClick={() => history.push("fill-sites")}>
            <img src={backIcon} alt="" />
          </div>
          <h1>Edit Soil Deposit Sites</h1>
        </div>

        <Row gutter={10}>
          <Col span={8} className="inputWrapper">
            <h2>Soil Deposit Site Name</h2>
            <Input
              placeholder="Enter site name"
              type="text"
              value={state.sitename.value}
              onChange={val => handleOnChange("sitename", val.target.value)}
              showError={true}
              errorMessage={PostError?.name && PostError?.name}
            />
          </Col>
          <Col span={8} className="inputWrapper">
            <h2>Street</h2>
            <Input
              placeholder="Enter street"
              type="text"
              value={state.street.value}
              onChange={val => handleOnChange("street", val.target.value)}
              showError={true}
              errorMessage={
                PostError?.user_detail?.street && PostError?.user_detail?.street
              }
            />
          </Col>
          <Col span={8} className="inputWrapper">
            <h2>City</h2>
            <Input
              placeholder="Enter city"
              type="text"
              value={state.city.value}
              onChange={val => handleOnChange("city", val.target.value)}
              showError={true}
              errorMessage={
                PostError?.user_detail?.city && PostError?.user_detail?.city
              }
            />
          </Col>
          <Col span={8} className="inputWrapper">
            <h2>ZIP/Postal code</h2>
            <Input
              placeholder="Enter ZIP/Postal code"
              type="text"
              value={state.zipcode.value}
              onChange={val => handleOnChange("zipcode", val.target.value)}
              showError={true}
              errorMessage={
                PostError?.user_detail?.postal_code &&
                PostError?.user_detail?.postal_code
              }
            />
          </Col>
          <Col span={8} className="inputWrapper">
            <h2>State/Province</h2>
            <Input
              placeholder="Enter State/Povince"
              type="text"
              value={state.countryState.value}
              onChange={val => handleOnChange("countryState", val.target.value)}
              showError={true}
              errorMessage={
                PostError?.user_detail?.state && PostError?.user_detail?.state
              }
            />
          </Col>
          <Col span={8} className="inputWrapper">
            <h2>Country</h2>
            <BoardSelect
              options={options}
              placeholder="Select Country"
              onChange={e => {
                alert(e)
                handleOnChange("country", e)
              }}
              showSearch={true}
              filterOption={(input, option) =>
                (option?.label ?? "").includes(input)
              }
              optionFilterProp="children"
              value={CountryData[state?.country?.value]?.name}
              showError={true}
              errorMessage={
                PostError?.user_detail?.state && PostError?.user_detail?.country
              }
            />
          </Col>
          <Col span={8} className="inputWrapper">
            <h2>Country permit #</h2>
            <Input
              placeholder="Enter number"
              type="text"
              value={state.countrypermit.value}
              onChange={val =>
                handleOnChange("countrypermit", val.target.value)
              }
              showError={true}
              errorMessage={
                PostError?.user_detail?.Country_permit_no &&
                PostError?.user_detail?.Country_permit_no
              }
            />
          </Col>
          <Col span={8} className="inputWrapper">
            <h2>Site field email</h2>
            <Input
              placeholder="Enter site field email"
              type="text"
              value={state.fieldemail.value}
              onChange={val => handleOnChange("fieldemail", val.target.value)}
              showError={true}
              errorMessage={PostError?.email && PostError?.email}
            />
          </Col>
          <Col span={8} className="inputWrapper">
            <h2>Site field contact phone</h2>
            <AesPhoneInput
              placeholder="Enter phone"
              type="number"
              value={state.fieldphonenumber.value}
              onChange={handleFormatChanger}
              showError={true}
              errorMessage={PostError?.phone_number && PostError?.phone_number}
            />
          </Col>
          <Col span={8} className="inputWrapper">
            <h2>QEP</h2>
            <Input
              placeholder="Assign a QEP"
              type="text"
              value={state.qep.value}
              onChange={val => handleOnChange("qep", val.target.value)}
              showError={true}
              errorMessage={PostError?.user_detail?.qep && PostError?.user_detail?.qep}
            />
          </Col>
          <Col span={8} className="inputWrapper">
            <h2>Max Number of Loads Per Day</h2>
            <Input
              placeholder="Max Number of Loads Per Day"
              type="text"
              value={state.max_load_per_day.value}
              onChange={val => handleOnChange("max_load_per_day", val.target.value)}
              showError={true}
              errorMessage={PostError?.user_detail?.max_load_per_day && PostError?.user_detail?.max_load_per_day}
            />
          </Col>
        </Row>
        <div className="materialSiteStyle">
          <h1>Material fill site offers</h1>
          <Checkbox
            checked={siteOffers.check3}
            onChange={e =>
              setSiteOffers({ ...siteOffers, check3: e.target.checked })
            }
          >
            Residential Land (RL)
          </Checkbox>
          <Checkbox
            checked={siteOffers.check2}
            onChange={e =>
              setSiteOffers({ ...siteOffers, check2: e.target.checked })
            }
          >
            Commercial Land (CL)
          </Checkbox>
          <Checkbox
            checked={siteOffers.check1}
            onChange={e =>
              setSiteOffers({ ...siteOffers, check1: e.target.checked })
            }
          >
            Industrial Land (IL)
          </Checkbox>
          <Checkbox
            checked={siteOffers.check4}
            onChange={e =>
              setSiteOffers({ ...siteOffers, check4: e.target.checked })
            }
          >
            Industrial Land + (IL+)
          </Checkbox>


        </div>
        {/* <div className="noteAreaStyle">
          <h1>Notes</h1>
          <Input
            placeholder="Type notes"
            type="text"
            className="noteArea"
            value={state.notes.value}
            onChange={val => handleOnChange("notes", val.target.value)}
            showError={true}
            errorMessage={
              PostError?.material_offer?.note && PostError?.material_offer?.note
            }
          />
        </div> */}
        <div className="note-wrapper mt-4">
          <h3 className="note-text">Notes: </h3>
          <textarea
            className="note-textarea"
            // type=""
            type="text"
            placeholder="Enter text"
            value={state.notes.value}
            onChange={val => handleOnChange("notes", val.target.value)}
          // showError={true}
          // errorMessage={state.note.error || error?.note}
          />
          <label
            className={`error-message ${PostError.material_offer ? "opacity-1" : ""
              }`}
          >
            {PostError?.material_offer?.note && PostError?.material_offer?.note}
          </label>
        </div>
        <Button
          title="Save"
          className="SaveBtnStyle"
          onClick={onSubmit}
          showSpinner={PostLoading}
        />
      </div>
    </HomeLayout>
  )
}
const mapStateToProps = state => ({
  userListRequestList: state.adminDashboardUserData.userListRequestList,
  CountryData: state.signUp.CountryData,
  PostError: state.EditAndViewUser.PostError,
  PostLoading: state.EditAndViewUser.PostLoading,
  userListRequesting: state.adminDashboardUserData.userListRequesting,
  PostInfo: state.EditAndViewUser.PostInfo,
  requesting: state.adminDashboardUserData.requesting,
})

const mapDispatchToProps = dispatch => ({
  getAdmindashboardList: data => dispatch(getAdmindashboardList(data)),
  getMaterialRequest: () => dispatch(getMaterialRequest()),
  patchData: data => dispatch(patchData(data)),
  getCountryRequest: data => dispatch(getCountryRequest(data)),
  postDataSuccess: data => dispatch(postDataSuccess(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(NewFileSite)
