import React, { useState, useEffect } from "react"
import "./style.scss"
import backIcon from "../../../../assets/images/back-icon-circle.svg"
import uploadfile from "../../../../assets/images/uploadfile.svg"
import { useDispatch, useSelector } from "react-redux"
import HomeLayout from "../../../../layout/HomeLayout"
import Button from "../../../../components/Button"
import { history } from "../../../../reduxStore/store"
import { Space, Table } from "antd"
import ChangeStatus from "../../../../components/ChangeStatus"
import { getSingleInvoiceRequest } from "../redux/action"
import UploadInvoice from "../../../../components/UploadInvoice"
import Skeleton from "react-loading-skeleton"
import { getAdminFillRequest } from "../../RequestConfirmPage/redux/action"
import { Toaster } from "react-hot-toast"

const AdminInvoiceDetails = props => {
  const [modalShow, setModalShow] = useState(false)
  const [modalInvoiceShow, setModalInvoiceShow] = useState(false)
  const { invoiceId } = useSelector(state => state.login)
  const { requesting, singleInvoiceData } = useSelector(state => state.Invoices)
  const { invoice_list } = singleInvoiceData
  const [selectedInvoices, setSelectedInvoices] = useState("")
  const { getAdminFillSite: allFillsites } = useSelector(
    state => state.SoilConfirm
  )

  const dispatch = useDispatch()

  const data =
    invoice_list &&
    invoice_list?.map(invoice => ({
      ...invoice,
      key: invoice?.id?.toString()
    }))

  const columns = [
    {
      title: "All Invoices",
      dataIndex: "invoice_file",
      render: (id, row) => (
        <div className="col-md-3" onClick={() => window.open(id, "_blank")}>
          <img src={uploadfile} alt="upload" />
          &nbsp;&nbsp;
          {row.file_name == null ? (
            <span> Invoice {row.id}</span>
          ) : (
            <span
              style={{ cursor: "pointer" }}
              title={row.file_name.length >= 15 ? row.file_name : null}
            >
              {/* {row.file_name} */}
              {row.file_name && row.file_name?.length >= 15
                ? row.file_name.slice(0, 15) + "..."
                : row.file_name}
            </span>
          )}
        </div>
      )
    },
    {
      title: "Status",
      dataIndex: "status",
      render: status => {
        let data = status.split("_")
        return <Space
          size="middle"
        >{data.join(" ")}</Space>
      }
    },
    {
      title: "Fill Site",
      dataIndex: "fill_site",
      render: fillSiteName => {
        // let data = status.split("_")
        return <Space
          size="middle"
        >

          {fillSiteName}</Space>
      }
    },
    {
      align: "right",
      title: () => (
        <div className=" last-heading">
          <span
            style={{
              cursor: selectedInvoiceId ? "pointer" : "not-allowed",
              color: !selectedInvoiceId && "gray"
            }}
            onClick={() => selectedInvoiceId && setModalShow(true)}
          >
            Change Status
          </span>
        </div>
      )
    }
  ]

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const [selectedInvoiceId, setSelectedInvoiceId] = useState(null);

  const onSelectChange = (newSelectedRowKeys) => {
    if (newSelectedRowKeys.length > 0) {
      setSelectedInvoiceId(newSelectedRowKeys[newSelectedRowKeys.length - 1]); // Take the last selected ID
    } else {
      setSelectedInvoiceId(null);
    }
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  useEffect(() => {
    dispatch(getSingleInvoiceRequest(invoiceId))
    return () => { }
  }, [])
  useEffect(() => {
    dispatch(getAdminFillRequest())
  }, [])

  return (
    <HomeLayout dashboardHeader={true}>
      <Toaster />

      <section className="content-section Invoices-Information-section">
        <div className="Invoices-Information-content-section">
          <div className="d-flex justify-content-between ">
            <div
              className="heading-Invoices-Information d-flex align-items-center"
              style={{ gap: "10px" }}
            >
              <div className="back " onClick={() => history.goBack()}>
                <img src={backIcon} alt="" />
              </div>
              {requesting ? (
                <h1
                  style={{ display: "flex", alignItems: "center", gap: "5px" }}
                >
                  <span style={{ width: "80px" }}>
                    <Skeleton
                      width="100%"
                      height="10px"
                      baseColor="#fff"
                      highlightColor="#dbd9d9"
                    />
                  </span>
                  /
                  <span style={{ width: "80px" }}>
                    <Skeleton
                      width="100%"
                      height="10px"
                      baseColor="#fff"
                      highlightColor="#dbd9d9"
                    />
                  </span>
                </h1>
              ) : (
                <h1>{`${singleInvoiceData?.contractor || ""} / ${singleInvoiceData?.status || ""
                  }`}</h1>
              )}
            </div>
            <div className="d-flex" style={{ gap: "30px" }}>
              <Button
                title="Upload Invoice"
                className="aes-btn green-btn"
                onClick={() => setModalInvoiceShow(true)}
              />
              {/* <Button
                title="LOCK"
                className=" aes-btn danger-transparent-btn"
              /> */}
            </div>
          </div>
          {!data && requesting ? (
            <Table
              rowKey="key"
              pagination={false}
              dataSource={[...Array(10)].map((_, index) => ({
                key: `key${index}`
              }))}
              columns={columns.map(column => {
                return {
                  ...column,
                  render: function renderPlaceholder() {
                    return <Skeleton width="20%" />
                  }
                }
              })}
            />
          ) : (
            <Table
              className="invoice-info-table"
              rowSelection={{
                ...rowSelection,
                selectedRowKeys: selectedInvoiceId ? [selectedInvoiceId] : [],
              }}
              columns={columns}
              dataSource={data}
              pagination={false}
              scroll={{
                y: 500
              }}
            />
          )}
        </div>
      </section>
      <ChangeStatus
        modalShow={modalShow}
        setModalShow={setModalShow}
        selectedInvoices={selectedInvoiceId}
        setSelectedInvoiceId={setSelectedInvoiceId}
      />
      <UploadInvoice
        modalShow={modalInvoiceShow}
        setModalShow={setModalInvoiceShow}
        selectedInvoices={selectedInvoices}
        contractor={singleInvoiceData?.contractor}
        // invoiceList={invoice_list}
        allFillsites={allFillsites && [...allFillsites]}
      />
    </HomeLayout>
  )
}

export default AdminInvoiceDetails
