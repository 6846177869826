import React, { useEffect, useState } from "react"
import HomeLayout from "../../../layout/HomeLayout"
import { history } from "../../../reduxStore/store"
import Button from "../../../components/Button"
import { Spinner } from "react-bootstrap"
import { connect } from "react-redux"

import "./style.scss"
import TeamMembersTable from "../../../components/TeamMembersTable"

import {
  deleteOwnerTeamMember,
  getOwnerTeamMembers,
  updateOwnerTeamMembers
} from "./redux/action"

const SiteOwnerMyTeam = props => {
  const {
    myTeamMembers,
    getOwnerTeamMembers,
    deleteOwnerTeamMember,
    updateOwnerTeamMembers,
    requesting,
    deleteRequest,
    deleteStatus,
    error
  } = props

  const [selectMember, setSelectMember] = useState()

  useEffect(function () {
    getOwnerTeamMembers()
  }, [])

  useEffect(
    function () {
      if (deleteStatus && selectMember) {
        const storeMembers = myTeamMembers
        const newMembers = storeMembers.filter(m => m.id !== selectMember.id)
        updateOwnerTeamMembers(newMembers)
        setSelectMember()
      }
    },
    [deleteStatus]
  )

  const handleRemove = async member => {
    setSelectMember(member)
    await deleteOwnerTeamMember(member)
  }

  return (
    <>
      <HomeLayout dashboardHeader={true}>
        <section className="content-section team-members-section">
          <div className="team-members-content-section">
            <div className="heading">
              <h1>My Team</h1>
              <div className="button-wrapper">
                <Button
                  title="Add Member"
                  className="aes-btn green-btn"
                  onClick={() => history.push("add-member")}
                />
              </div>
            </div>
            <div className="team-members-table-wrapper">
              {requesting ? (
                <div className="loader-container">
                  <Spinner variant="primary" />
                </div>
              ) : myTeamMembers?.length === 0 ? (
                <p>There are no team members.</p>
              ) : (
                <TeamMembersTable
                  data={myTeamMembers}
                  onRemove={handleRemove}
                />
              )}
            </div>
          </div>
        </section>
      </HomeLayout>
    </>
  )
}

const mapStateToProps = state => ({
  myTeamMembers: state.OwnerTeamMembers.myTeamMembers,
  requesting: state.OwnerTeamMembers.requesting,
  error: state.OwnerTeamMembers.error,
  deleteRequest: state.OwnerTeamMembers.deleteRequest,
  deleteStatus: state.OwnerTeamMembers.deleteStatus
})

const mapDispatchToProps = dispatch => ({
  getOwnerTeamMembers: () => dispatch(getOwnerTeamMembers()),
  deleteOwnerTeamMember: member => dispatch(deleteOwnerTeamMember(member)),
  updateOwnerTeamMembers: members => dispatch(updateOwnerTeamMembers(members))
})

export default connect(mapStateToProps, mapDispatchToProps)(SiteOwnerMyTeam)
//export default MyTeam
