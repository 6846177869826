import React, { useEffect, useState } from 'react'
import HomeLayout from '../../../layout/HomeLayout'
import "./style.scss"
import { Button, Dropdown, Menu, Table } from 'antd'
import filter from "../../../assets/images/filter.svg"
import { getAccountRequest, patchAccountRequest } from './redux/action'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from "react-hot-toast"
import { capitalize } from 'lodash'

const AccountRequest = () => {
  const [status, setStatus] = useState("Select")

  const dispatch = useDispatch()

  const { accountRequestData, patchRequestData } = useSelector(state => state?.AccountRequest)

  useEffect(() => {
    dispatch(getAccountRequest(""))
  }, [patchRequestData])

  const handleMenuClick = ({ key }) => {
    setStatus(key);
    dispatch(getAccountRequest(key))
  };

  const handleReject = async (status, id) => {
    const payload = status == "reject" ? { is_rejected: true, approved_account: false } : { approved_account: true, is_rejected: false }
    await dispatch(patchAccountRequest(id, payload))
    if (status == 'reject') {
      toast.success("The account has been rejected.", { id: "card" })
    }
    else {
      toast.success("The account has been approved successfully.", { id: "card" })
    }
  }

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="approved">Approved</Menu.Item>
      <Menu.Item key="rejected">Rejected</Menu.Item>
    </Menu>
  )

  const columns = [
    {
      title: "Company name:",
      dataIndex: "company",
      key: "compnay_name",
      render: (company) => {
        return (
          <h1 className="companyName">
            {company?.company_name || "--"}
          </h1>
        )
      }
    },

    {
      title: "Email:",
      dataIndex: "email",
      key: "email",
      render: (email) => {
        return (
          <h1 className="emailStyle">
            {email || "--"}
          </h1>
        )
      }
    },
    {
      title: "Phone number:",
      dataIndex: "phone_number",
      key: "phone_number",
      render: (phone) => {
        return (
          <h1 className="commonStyle">
            {phone || "--"}
          </h1>
        )
      }
    },
    {
      title: "Location",
      dataIndex: "billing",
      key: "location",
      render: (billing) => {
        return (
          <h1 className="commonStyle">
            {billing?.city || "--"}
          </h1>
        )
      }
    },
    {
      title: "Status:",
      dataIndex: "status",
      key: "status",
      render: (_, data) => {
        return (
          <h1 className="commonStyle">
            {data.is_rejected ? "Rejected" : data.approved_account ? "Approved" : 'Pending'}
          </h1>
        )
      }
    },
    {
      title: "Action",
      render: (_, data) => {
        return (
          <div className='d-flex'>
            <>
              {!data?.is_rejected ?
                <Button
                  className="rejectBtn"
                  onClick={() => handleReject("reject", data?.id)}
                >Reject</Button>
                :
                <div style={{ width: "101px" }}></div>
              }
              <Button
                className="approveBtn"
                onClick={() => handleReject("approve", data?.id)}
              >Approve</Button>
            </>
          </div>
        )
      }
    },
  ]

  return (
    <HomeLayout dashboardHeader={true}>
      <section className='accountRequestContainer'>
        <div className="headingStyle">
          <h1 className="w-100">Contractor Account Requests</h1>
          <div>
            <Dropdown overlay={menu} trigger={["click"]}>
              <div className="button-filter">
                <img src={filter} />
                <p>
                  Filter by status:
                  <span style={{ "text-transform": "capitalize" }}>{status}</span>
                </p>
              </div>
            </Dropdown>
          </div>
        </div>
        <div>
          <Table
            className="accountRequestTable"
            dataSource={accountRequestData}
            columns={columns}
            pagination={false}
          />
        </div>
      </section >
    </HomeLayout >
  )
}

export default AccountRequest