import {
    GET_ACCOUNT_REQUEST,
    GET_ACCOUNT_REQUEST_FAILURE,
    GET_ACCOUNT_REQUEST_SUCCESS,
    PATCH_ACCOUNT_REQUEST,
    PATCH_ACCOUNT_REQUEST_FAILURE,
    PATCH_ACCOUNT_REQUEST_SUCCESS
} from "./type"


export const getAccountRequest = (data) => ({
    type: GET_ACCOUNT_REQUEST,
    data
})

export const getAccountRequestSucess = data => ({
    type: GET_ACCOUNT_REQUEST_SUCCESS,
    data
})
export const getAccountRequestFailure = data => ({
    type: GET_ACCOUNT_REQUEST_FAILURE,
    data
})

export const patchAccountRequest = (id, data) => ({
    type: PATCH_ACCOUNT_REQUEST,
    id,
    data
})

export const patchAccountRequestSucess = data => ({
    type: PATCH_ACCOUNT_REQUEST_SUCCESS,
    data
})
export const patchAccountRequestFailure = data => ({
    type: PATCH_ACCOUNT_REQUEST_FAILURE,
    data
})