import React, { useState } from 'react'
import Button from "../../components/Button"
import Modal from 'react-bootstrap/Modal';

import closeIcon from '../../assets/images/closeIcon.svg'
import { useHistory } from 'react-router-dom';

import './style.scss';

const SucessSignUp = (props) => {
    const history=useHistory()
    const{modalShow, setModalShow}=props


    return (
        <>
            <Modal
                // {...props}
                show={modalShow}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="warning-modal"
            >
                <Modal.Body className='warning-modal-body'>
                    <div className="close-icon" onClick={() => setModalShow(false)}>
                        <img src={closeIcon} alt="" />
                    </div>
                    <div className="warning-wrapper">
                        {/* <img src={warningSign} alt="" /> */}
                        <h3>Thank you for signing up </h3>
                        <p className='mt-3 mb-0'>You can now access our contractor dashboard
                             </p>
                             {/* <p className='mb-0'>Mauris ut efficitur diam. Nullam hendrerit.</p> */}
                        <div className="button-wrapper mt-5">
                            <Button
                             onClick={()=>history.push("/auth/login")}
                                title="OK"
                            />
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default SucessSignUp