import {
  POST_REQUEST_ADD_SCHEDULE,
  POST_ADD_SCHEDULE_SUCCESS,
  POST_ADD_SCHEDULE_FAILURE,

  SUCCESS_GETTING_ADMIN_SCHEDULE,
  ERROR_GETTING_ADMIN_SCHEDULE,
  GET_ADMIN_SCHEDULE
} from "./type"

// POST_PAYMENT_INFORMATION
export const addScheduleRequest = data => ({
  type: POST_REQUEST_ADD_SCHEDULE,
  data
})

export const addScheduleSuccess = data => ({
  type: POST_ADD_SCHEDULE_SUCCESS,
  data
})

export const addScheduleFailure = data => ({
  type: POST_ADD_SCHEDULE_FAILURE,
  data
})


export const getAdminSchedule = data => {
  return { type: GET_ADMIN_SCHEDULE, data }
}

export const getAdminScheduleSuccess = data => ({
  type: SUCCESS_GETTING_ADMIN_SCHEDULE,
  data
})
export const getAdminScheduleFailure = data => ({
  type: ERROR_GETTING_ADMIN_SCHEDULE,
  data
})
