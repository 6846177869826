import { useState } from "react";
import closeEye from '../../assets/images/closeEye.svg';
import showEye from '../../assets/images/showEye.svg';
import './style.scss';

const Input = ({
    type = 'text',
    className = 'form-control',
    imgClass,
    label = '',
    placeholder = '',
    showHideIcon,
    showIcon,
    name = '',
    src,
    onFocus,
    required = false,
    errorMessage = '',
    showLabel,
    maxLength,
    onKeyPress,
    disabled,
    max,
    ...props
}) => {

    const showIconRight = showHideIcon ? showHideIcon : false
    const showIconLeft = showIcon ? showIcon : false
    const showLabelMessage = showLabel ? showLabel : false
    const [showPassword, setShowPassword] = useState(false);
    return (
        <>
            <div className="input-field">
                {showIconLeft &&
                    <span className="icon left-icon" style={{display: 'none'}}>
                        <img src={src} className={imgClass} alt="icon" />
                    </span>
                }
                {showLabelMessage &&
                    <label className="input-label">{label}</label>
                }
                <input
                    type={showPassword ? 'text' : type}
                    name={name}
                    onChange={props.onChange}
                    className={className}
                    onClick={props.onClick}
                    id={props.id}
                    value={props.value}
                    required={required}
                    placeholder={placeholder}
                    onFocus={onFocus}
                    disabled={disabled}
                    maxLength={maxLength} 
                    onKeyPress={onKeyPress}
                    max={max}
                />
                {errorMessage &&
                    <label className={`error-message ${errorMessage ? 'displayed' : ''}`}>{errorMessage}</label>
                }
                {showIconRight &&
                    <span className="hide-icon right-icon">
                        <img src={showPassword ? showEye : closeEye} onClick={() => setShowPassword(!showPassword)} alt="" />
                    </span>
                }
            </div>
        </>
    )
}

export default Input
