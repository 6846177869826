// GET TEAM MEMBERS
export const GET_TEAM_MEMBERS_REQUEST = "GET_TEAM_MEMBERS_REQUEST"
export const GET_TEAM_MEMBERS_SUCCESS = "GET_TEAM_MEMBERS_SUCCESS"
export const GET_TEAM_MEMBERS_FAILURE = "GET_TEAM_MEMBERS_FAILURE"

// DELETE TEAM MEMBER
export const DELETE_TEAM_MEMBER_REQUEST = "DELETE_TEAM_MEMBER_REQUEST"
export const DELETE_TEAM_MEMBER_SUCCESS = "DELETE_TEAM_MEMBER_SUCCESS"
export const DELETE_TEAM_MEMBER_FAILURE = "DELETE_TEAM_MEMBER_FAILURE"

export const UPDATE_TEAM_MEMBERS = "UPDATE_TEAM_MEMBERS"
export const UPDATE_TEAM_MEMBERS_SUCCESS = "UPDATE_TEAM_MEMBERS_SUCCESS"
