
import { all, call, put, takeLatest } from "redux-saga/effects"
import { BASE_URL } from "../../../../config/app"
import { history } from "../../../../reduxStore/store"
import XHR from "../../../../utils/XHR"
import {   getHomeSuccess,
  getHomeFailure,} from "./action"
import { GET_HOME_REQUEST } from "./type"

const HomeDataAPI = async (data) => {
  let URL =  `${BASE_URL}services/contact_us/`


  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
 
    },
    method: data? "POST":"GET",
    data
  }
  return XHR(URL, options)
}


function* HomeData({data}) {
  
  try {
    const response = yield call(HomeDataAPI,data)

    yield put(getHomeSuccess(response.data))
    yield put (data && history.push("/home"))
  
  } catch (e) {
    const { response } = e
    yield put(getHomeFailure(response?.data))
   
  }
}

export default all([takeLatest(GET_HOME_REQUEST, HomeData)])

