

import { all, call, put, takeLatest } from "redux-saga/effects"
import { BASE_URL } from "../../../../config/app"
import XHR from "../../../../utils/XHR"
import {
  postSaveSendRequestSuccess,
  postSaveSendRequestFailure,
  getAvailabilitySuccess,
  getAvailabilityFailure
} from "./action"
import {
  POST_SAVE_SEND,
  GET_AVAILABILITY_DATA
} from "./type"
import toast from "react-hot-toast"


const postSaveSendAPI = async (data) => {
  const URL = `${BASE_URL}api/v1/truck_information/`
  const token = localStorage.getItem("token")

  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "POST",
    data
  }
  return XHR(URL, options)
}

const getAvailabilityAPI = async (data) => {
  const URL = `${BASE_URL}api/v1/request_availability/${data}/`
  const token = localStorage.getItem("token")

  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "GET",
  }
  return XHR(URL, options)
}

function* availabilityData({ data }) {

  try {
    const response = yield call(getAvailabilityAPI, data)
    yield put(getAvailabilitySuccess(response.data))
  } catch (e) {
    const { response } = e
    yield put(getAvailabilityFailure(response.data))

  }
}

function* postSaveSendData({ data }) {

  try {
    const response = yield call(postSaveSendAPI, data)
    yield put(postSaveSendRequestSuccess(response.data))
  } catch (e) {
    const { response } = e
    if (response?.status == 400 && response?.data?.msg) {
      toast.error(response?.data?.msg)
    }
    yield put(postSaveSendRequestFailure(response.data))

  }
}

export default all([
  takeLatest(POST_SAVE_SEND, postSaveSendData),
  takeLatest(GET_AVAILABILITY_DATA, availabilityData)
])


