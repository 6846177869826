import React, { useEffect, useState } from "react"
// import HomeLayout from "../../../layout/HomeLayout"
import darkLogo from "../../../assets/images/logo-dark.png"
import "./style.css"
import { history } from "../../../reduxStore/store"

const Header = props => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  const show = isOpen ? "show" : ""
  return (
    
    <>
     

      <section class="navbar-section">
        <nav class="navbar navbar-expand-lg fixed-top bg-body-tertiary">
          <div class="container-fluid navbar-container">
            <a class="navbar-brand" href="#">
             
              <img src={darkLogo} alt="" style={{ width: "270px" }} />
            </a>
            <button
              class="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
              onClick={() => toggle()}
            >
              <span class="navbar-toggler-icon"></span>
            </button>
            {<div class={"collapse navbar-collapse " + show} id="navbarSupportedContent">
              {<ul class="navbar-nav ms-auto">
                <li
                  class="nav-item"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    history.push("/visiting/home")
                  }}
                >
                  <a class="nav-link active" aria-current="page">
                    Home
                  </a>
                </li>
                <li class="nav-item" style={{ cursor: "pointer" }}>
                  <a
                    onClick={() => {
                      history.push("/visiting/services")
                    }}
                    class="nav-link active"
                    aria-current="page"
                  >
                    Services
                  </a>


                </li>
                <li class="nav-item" style={{ cursor: "pointer" }}>
                  <a
                    onClick={() => {
                      history.push("/visiting/contact")
                    }}
                    class="nav-link active"
                    aria-current="page"
                  >
                    Contact Us
                  </a>


                </li>
                <li class="nav-item" style={{ cursor: "pointer" }}>
                  <a
                    onClick={() => {
                      history.push("/visiting/soil-deposite-site")
                    }}
                    class="nav-link active"
                    aria-current="page"
                  >
                    Soil Deposite Site
                  </a>


                </li>
              </ul>}
            </div>}
          </div>
        </nav>
      </section>


    </>
  )
}

export default Header
