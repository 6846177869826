export const GET_REPORT_REQUEST = "GET_REPORT_REQUEST"
export const GET_REPORT_SUCCESS = "GET_REPORT_SUCCESS" 

export const GET_CATEGORY_REQUEST = "GET_CATEGORY_REQUEST"
export const GET_CATEGORY_SUCCESS = "GET_CATEGORY_SUCCESS"

export const GET_CONTRACTOR_REQUEST = "GET_CONTRACTOR_REQUEST"
export const GET_CONTRACTOR_SUCCESS = "GET_CONTRACTOR_SUCCESS"

export const POST_REPORT_REQUEST = " POST_REPORT_REQUEST"
export const POST_REPORT_SUCCESS = " POST_REPORT_SUCCESS"
export const POST_REPORT_FAILED = " POST_REPORT_FAILED"
