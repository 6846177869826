import { all, call, put, takeLatest } from "redux-saga/effects"
import axios from "axios"

// types
import { GET_SITE_API_DATA, POST_SITE_API_DATA, PATCH_SITE_API_DATA } from "./type"
import {
  getDataSuccess,
  getDataFailure,
  postDataSuccess,
  postDataFailure
} from "./action"

import { BASE_URL } from "../../../../../config/app"
import XHR from "../../../../../utils/XHR"
import { toast } from "react-hot-toast"
import { history } from "../../../../../reduxStore/store"

async function postFillSIteAPI(data) {
  const URL = `${BASE_URL}api/v1/admin/add_new_fill_site/`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "POST",
    data
  }
  return XHR(URL, options)
}

function* postFillSiteInfo({ data }) {
  try {
    const response = yield call(postFillSIteAPI, data)
    yield put(postDataSuccess(response.data))
    yield toast.success("Fill site added successfully", {
      id: "card"
    })
    setTimeout(() => {
      history.push("fill-sites")
    }, 2000);

  } catch (e) {
    const { response } = e
    yield put(postDataFailure(response.data))
    toast.error("Please enter in all required fields highlighted on the form")
  }
}


async function patchFillSIteAPI(data) {
  const URL = `${BASE_URL}api/v1/admin/add_new_fill_site/${data.id}/`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "PATCH",
    data
  }
  return XHR(URL, options)
}

function* patchFillSiteInfo({ data }) {
  try {
    const response = yield call(patchFillSIteAPI, data)
    yield put(postDataSuccess(response.data))
    yield toast.success("Fill site added successfully", {
      id: "card"
    })
    setTimeout(() => {
     history.push("fill-sites")
    }, 2000);

  } catch (e) {
    const { response } = e
    yield put(postDataFailure(response.data))
    toast.error("Please enter in all required fields highlighted on the form")
  }
}

export default all([
  takeLatest(POST_SITE_API_DATA, postFillSiteInfo),
  takeLatest(PATCH_SITE_API_DATA, patchFillSiteInfo)
])
