import {
  GET_HOME_REQUEST,
  GET_HOME_SUCCESS,
  GET_HOME_FAILURE,
} from "./type"

const initialState = {
  homeData: false,
  requesting: false,
  error: false,

}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_HOME_REQUEST:
      return {
        ...state,
        requesting: true
      }
    case GET_HOME_SUCCESS:
      return {
        ...state,
        requesting: false,
        homeData: action.data
      }
    case GET_HOME_FAILURE:
      return {
        ...state,
        requesting: false,
        error: action.data
      }

   
    default:
      return state
  }
}
