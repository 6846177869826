import {
  POST_REQUEST_ADD_PROJECT,
  POST_ADD_PROJECT_SUCCESS,
  POST_ADD_PROJECT_FAILURE
} from "./type"

const initialState = {
  projects: false,
  error: false,
  requesting: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case POST_REQUEST_ADD_PROJECT:
      return {
        ...state,
        requesting: true
      }
    case POST_ADD_PROJECT_SUCCESS:
      return {
        ...state,
        requesting: false,
        projects: action.data
      }
    case POST_ADD_PROJECT_FAILURE:
      return {
        ...state,
        requesting: false,
        error: action.data,
        projects: false
      }
    default:
      return state
  }
}
