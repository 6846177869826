import React, { useEffect } from "react"
import backIcon from "../../../assets/images/back-icon-circle.svg"
import Button from "../../../components/Button"
import Input from "../../../components/Input"
import HomeLayout from "../../../layout/HomeLayout"
import { history } from "../../../reduxStore/store"
import useForm from "../../../utils/useForm"
import validator from "../../../utils/validation"
import { addOwnerTeamMember, addOwnerTeamMemberFailure } from "./redux/action"
import { connect } from "react-redux"
import "./style.scss"

const OwnerAddTeamMember = props => {
  const {
    addOwnerTeamMember,
    addOwnerTeamMemberFailure,
    teamMember,
    requesting,
    error
  } = props

  const stateSchema = {
    fullName: {
      value: "",
      error: ""
    },
    email: {
      value: "",
      error: ""
    },
    phone: {
      value: "",
      error: ""
    },
    location: {
      value: "",
      error: ""
    },
    role: {
      value: "",
      error: ""
    }
  }

  const validationStateSchema = {
    fullName: {
      required: true
    },
    email: {
      required: true,
      validator: validator.email
    },
    phone: {
      required: true,
      validator: validator.phone
    },
    location: {
      required: true
    },
    role: {
      required: true
    }
  }

  const { state, handleOnChange, disable, setState } = useForm(
    stateSchema,
    validationStateSchema
  )

  useEffect(() => {
    if (addOwnerTeamMember) {
      setState(stateSchema)
      addOwnerTeamMemberFailure(false)
    }
  }, [addOwnerTeamMember])

  useEffect(() => {
    teamMember && history.push("my-team")
  }, [teamMember])

  const submit = async () => {
    const formData = new FormData()
    formData.append("name", state.fullName.value)
    formData.append("email", state.email.value)
    formData.append("location", state.location.value)
    formData.append("role", state.role.value)
    formData.append("phone_number", state.phone.value)

    await addOwnerTeamMember(formData)
  }

  return (
    <>
      <HomeLayout dashboardHeader={true}>
        <section className="content-section add-team-members-section">
          <div className="add-team-members-content-section">
            <div className="heading">
              <div className="back" onClick={() => history.push("my-team")}>
                <img src={backIcon} alt="" />
              </div>
              <h1>Add team member</h1>
            </div>
            <div className="add-members-form-section">
              <div className="container">
                <div className="row">
                  <div className="col-md-4 add-member-field">
                    <Input
                      placeholder="Enter full name"
                      showLabel={true}
                      label="Full Name"
                      value={state.fullName.value}
                      showError={true}
                      errorMessage={state.fullName.error || error?.name}
                      onChange={val =>
                        handleOnChange("fullName", val.target.value)
                      }
                    />
                  </div>
                  <div className="col-md-4 add-member-field">
                    <Input
                      placeholder="Enter email"
                      showLabel={true}
                      label="Email"
                      type="email"
                      showError={true}
                      errorMessage={state.email.error || error?.email}
                      value={state.email.value}
                      onChange={val =>
                        handleOnChange("email", val.target.value)
                      }
                    />
                  </div>
                  <div className="col-md-4 add-member-field">
                    <Input
                      placeholder="Enter phone"
                      showLabel={true}
                      label="Phone"
                      type="number"
                      showError={true}
                      errorMessage={state.phone.error}
                      value={state.phone.value || error?.phone_number}
                      onChange={val =>
                        handleOnChange("phone", val.target.value)
                      }
                    />
                  </div>
                  <div className="col-md-4 add-member-field">
                    <Input
                      placeholder="Enter location"
                      showLabel={true}
                      label="Location"
                      value={state.location.value}
                      showError={true}
                      errorMessage={state.location.error || error?.location}
                      onChange={val =>
                        handleOnChange("location", val.target.value)
                      }
                    />
                  </div>
                  <div className="col-md-4 add-member-field">
                    <Input
                      placeholder="Enter role"
                      showLabel={true}
                      label="Role"
                      value={state.role.value}
                      showError={true}
                      errorMessage={state.role.error || error?.role}
                      onChange={val => handleOnChange("role", val.target.value)}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="button-wrapper">
                    <Button
                      showSpinner={requesting}
                      title="Add Member"
                      className="aes-btn green-btn"
                      onClick={() => submit()}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </HomeLayout>
    </>
  )
}

const mapStateToProps = state => ({
  addTeamMemberRequest: state.OwnerAddTeamMember.addTeamMemberRequest,
  requesting: state.OwnerAddTeamMember.requesting,
  error: state.OwnerAddTeamMember.error,
  teamMember: state.OwnerAddTeamMember.teamMember
})

const mapDispatchToProps = dispatch => ({
  addOwnerTeamMember: data => dispatch(addOwnerTeamMember(data)),
  addOwnerTeamMemberFailure: data => dispatch(addOwnerTeamMemberFailure(data))
  // signUpRequestSuccess: data => dispatch(signUpRequestSuccess(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(OwnerAddTeamMember)
