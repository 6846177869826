import {
  POST_REQUEST_ADD_TEAM_MEMBER,
  POST_ADD_TEAM_MEMBER_SUCCESS,
  POST_ADD_TEAM_MEMBER_FAILURE
} from "./type"

const initialState = {
  teamMember: false,
  error: false,
  requesting: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case POST_REQUEST_ADD_TEAM_MEMBER:
      return {
        ...state,
        requesting: true
      }
    case POST_ADD_TEAM_MEMBER_SUCCESS:
      return {
        ...state,
        requesting: false,
        teamMember: action.data
      }
    case POST_ADD_TEAM_MEMBER_FAILURE:
      return {
        ...state,
        requesting: false,
        error: action.data,
        teamMember: false
      }
    default:
      return state
  }
}
