import {
  CONTRACTOR_DETAIL_REQUEST,
  CONTRACTOR_DETAIL_REQUEST_FAILURE,
  CONTRACTOR_DETAIL_REQUEST_SUCCESS,
  GET_ADMINCONTRACTORS_REQUEST,
  GET_ADMINCONTRACTORS_SUCCESS,
  USER_ACTIVECONTRACTOR_REQUEST,
  USER_ACTIVECONTRACTOR_SUCCESS,

} from "./type"
const initialState = {
  AdminContractorRequesting: false,
  getAdminContractor: [],
  activeRequesting: false,
  activeRequest: false,

  adminContractorDetailData: false,
  adminContractorDetailRequesting: false,
  adminContractorError: false
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ADMINCONTRACTORS_REQUEST:
      return {
        ...state,
        AdminContractorRequesting: true
      }
    case GET_ADMINCONTRACTORS_SUCCESS:
      return {
        ...state,
        AdminContractorRequesting: false,
        getAdminContractor: action.data
      }
    case USER_ACTIVECONTRACTOR_REQUEST:
      return {
        ...state,
        activeRequesting: true
      }
    case USER_ACTIVECONTRACTOR_SUCCESS:
      return {
        ...state,
        activeRequesting: false,
        activeRequest: action.data
      }

    case CONTRACTOR_DETAIL_REQUEST:
      return {
        ...state,
        adminContractorDetailRequesting: true
      }
    case CONTRACTOR_DETAIL_REQUEST_SUCCESS:
      return {
        ...state,
        adminContractorDetailRequesting: false,
        adminContractorDetailData: action.data
      }

    case CONTRACTOR_DETAIL_REQUEST_FAILURE:
      return {
        ...state,
        adminContractorDetailRequesting: false,
        adminContractorError: action.data
      }

    default:
      return state
  }
}
