import {
  POST_SAVE_SEND,
  POST_SAVE_SEND_SUCCESS,
  POST_SAVE_SEND_FAILURE,
  GET_AVAILABILITY_DATA,
  GET_AVAILABILITY_DATA_SUCCESS,
  GET_AVAILABILITY_DATA_FAILURE
} from "./type"

  const initialState = {
    postSaveSend: false,
    postSaveSendLoad: false,
    postSaveSendError: false,
    availabilityData: false,
    availabilityDataLoad: false,
    availabilityDataError: false
  }

  export default (state = initialState, action) => {
    switch (action.type) {
      case  POST_SAVE_SEND:
        return {
          ...state,
        postSaveSendLoad: true
        };
      case POST_SAVE_SEND_SUCCESS:
        return {
          ...state,
          postSaveSendLoad: false,
          postSaveSend: action.data
        };
      case POST_SAVE_SEND_FAILURE:
       
        return {
          ...state,
        postSaveSendLoad: false,
        postSaveSendError: action.data
        };
        case  GET_AVAILABILITY_DATA:
        return {
          ...state,
        availabilityDataLoad: true
        };
      case GET_AVAILABILITY_DATA_SUCCESS:
        return {
          ...state,
          availabilityDataLoad: false,
          availabilityData: action.data
        };
      case GET_AVAILABILITY_DATA_FAILURE:
        return {
          ...state,
        availabilityDataLoad: false,
        availabilityDataError: action.data
        };
      default:
        return state;
    }
  };
  
  
  
  
  
  
  
  