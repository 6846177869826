import { Route, Switch, useLocation } from "react-router-dom"
import { routes } from "../../Router"
import { useEffect } from "react"

const Auth = () => {
  let location = useLocation()
  let pathname = location.pathname
    .split("/")
    .filter(val => val !== "auth")
    .join("/")
  useEffect(() => {
    window.history.replaceState({}, "", pathname)
  }, [pathname])

  const getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.layout === "/auth") {
        return (
          <Route
            path={[prop.layout + prop.path, prop.path]}
            component={prop.component}
            key={key}
          />
        )
      } else {
        return null
      }
    })
  }

  return <Switch>{getRoutes(routes)}</Switch>
}

export default Auth
