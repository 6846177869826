import {
 
  GET_SITE_API_DATA,
  GET_SITE_API_DATA_SUCCESS,
  GET_SITE_API_DATA_FAILURE,
  POST_SITE_API_DATA,
  POST_SITE_API_DATA_SUCCESS,
  POST_SITE_API_DATA_FAILURE,
  PATCH_SITE_API_DATA
} from "./type"

const initialState = {
  responseData: false,
  loading: false,
  responseError: false,
  PostData: false,
  PostInfo: false,
  PostLoading: false,
  PostError: false,

}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_SITE_API_DATA:
      return {
        ...state,
        loading: true
      }
    case GET_SITE_API_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        responseData: action.data
      }
    case GET_SITE_API_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        responseError: action.data
      }

    case POST_SITE_API_DATA:
      return {
        ...state,
        PostLoading: true
      }
    case POST_SITE_API_DATA_SUCCESS:
      return {
        ...state,
        PostLoading: false,
        PostInfo: action.data,
        PostError: {}
      }
    case POST_SITE_API_DATA_FAILURE:
      return {
        ...state,
        PostLoading: false,
        PostError: action.data,
        PostInfo: {}
      }
   
    case PATCH_SITE_API_DATA:
        return {
          ...state,
          PostLoading: true
        }
    default:
      return state
  }
}
