import {
  GET_TEAM_MEMBERS_REQUEST,
  GET_TEAM_MEMBERS_SUCCESS,
  GET_TEAM_MEMBERS_FAILURE,
  DELETE_TEAM_MEMBER_REQUEST,
  DELETE_TEAM_MEMBER_SUCCESS,
  DELETE_TEAM_MEMBER_FAILURE,
  UPDATE_TEAM_MEMBERS,
  UPDATE_TEAM_MEMBERS_SUCCESS
} from "./type"

export const getTeamMembersRequest = (id) => ({
  type: GET_TEAM_MEMBERS_REQUEST,
  id
  //filterModel
})

export const getTeamMembersSuccess = data => ({
  type: GET_TEAM_MEMBERS_SUCCESS,
  data
})

export const getTeamMembersFailure = data => ({
  type: GET_TEAM_MEMBERS_FAILURE,
  data
})

// delete team memeber

export const deleteTeamMemberRequest = member => ({
  type: DELETE_TEAM_MEMBER_REQUEST,
  member
})

export const deleteTeamMemberSuccess = () => ({
  type: DELETE_TEAM_MEMBER_SUCCESS
})

export const deleteTeamMemberFailure = data => ({
  type: DELETE_TEAM_MEMBER_FAILURE,
  data
})

export const updateTeamMembers = data => ({
  type: UPDATE_TEAM_MEMBERS,
  members: data
})

export const updateTeamMembersSuccess = data => ({
  type: UPDATE_TEAM_MEMBERS_SUCCESS,
  data
})
