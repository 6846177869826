import { all, call, put, takeLatest } from "redux-saga/effects"
// import AsyncStorage from '@react-native-community/async-storage';
// config
import { BASE_URL } from "../../../../config/app"
// utils
// import XHR from "../../../../utils/XHR"
import XHR from "../../../../utils/XHR"
// types
import { POST_REQUEST_LOGIN } from "./type"
// actions
import {
  loginRequestSuccess,
  loginRequestFailure,
  setAccessToken
} from "./action"
import toast from "react-hot-toast"

async function postLoginAPI(data) {
  const URL = `${BASE_URL}api/v1/login/`
  
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    method: "POST",
    data
  }
  return XHR(URL, options)
}

function* postLoginInfo({ data, history }) {
  try {
    const response = yield call(postLoginAPI, data)
    yield put(loginRequestSuccess(response.data))
    yield put(setAccessToken(response.data.token))
    localStorage.setItem("token", response.data.token)
  } catch (e) {
    const { response } = e
    yield put(loginRequestFailure(response.data))
    if(response.data.non_field_errors ){
      toast.error(`${response.data.non_field_errors && response.data.non_field_errors[0]}`, {
        id: "card fields"
      })
    }else if(response.data.msg){
      toast.error(response.data.msg, {
        id: "card fields"
      })
    }
    
  }
}

export default all([takeLatest(POST_REQUEST_LOGIN, postLoginInfo)])
