import {
  POST_REQUEST_SIGNUP,
  POST_REQUEST_SIGNUP_SUCCESS,
  POST_REQUEST_SIGNUP_FAILURE,
  GET_COUNTRY_SUCCESS,
  GET_COUNTRY_REQUEST
} from "./type"

const initialState = {
  signUpdata: false,
  error: false,
  requesting: false,
  CountryData: []
  // accessToken: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case POST_REQUEST_SIGNUP:
      return {
        ...state,
        requesting: true
      }

    case POST_REQUEST_SIGNUP_SUCCESS:
      return {
        ...state,
        requesting: false,
        signUpdata: action.data
      }
    case POST_REQUEST_SIGNUP_FAILURE:
      return {
        ...state,
        requesting: false,
        error: action.data
      }
    case GET_COUNTRY_REQUEST:
      return {
        ...state
        // requesting: true
      }
    case GET_COUNTRY_SUCCESS:
      return {
        ...state,
        CountryData: action.data
      }

    default:
      return state
  }
}
