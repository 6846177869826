import {
  GET_SCHEDULE_REQUEST,
  GET_SCHEDULE_SUCCESS,
  GET_SCHEDULE_FAILURE,
  POST_SPECIFIC_DATE_REQUEST,
  POST_SPECIFIC_DATE_SUCCESS,
  POST_SPECIFIC_DATE_FAILURE,
  PATCH_SOIL_AUTHORIZATION,
  PATCH_SOIL_AUTHORIZATION_SUCCESS,
  PATCH_SOIL_AUTHORIZATION_FAILURE,
  GET_PROFILE_REQUEST,
  GET_PROFILE_SUCCESS,
  GET_PROFILE_FAILURE
} from "./type"

export const getScheduleRequest = filterModel => ({
  type: GET_SCHEDULE_REQUEST,
  filterModel
})

export const getScheduleSuccess = data => ({
  type: GET_SCHEDULE_SUCCESS,
  data
})
export const getScheduleFailure = data => ({
  type: GET_SCHEDULE_FAILURE,
  data
})

export const postSpecificDateRequest = (data ,hide)=> ({
  type: POST_SPECIFIC_DATE_REQUEST,
  data,
  hide
})

export const postSpecificDateSuccess = (data )=> ({
  type: POST_SPECIFIC_DATE_SUCCESS,
  data,
  
})

export const postSpecificDateFailure = data => ({
  type: POST_SPECIFIC_DATE_FAILURE,
  data
})

export const patchSoilAuthorization = (id, data) => ({
  type: PATCH_SOIL_AUTHORIZATION,
  id,
  data
})

export const patchSoilAuthorizationSuccess = data => ({
  type: PATCH_SOIL_AUTHORIZATION_SUCCESS,
  data
})

export const patchSoilAuthorizationFailure = data => ({
  type: PATCH_SOIL_AUTHORIZATION_FAILURE,
  data
})

export const getProfileRequest = () => ({
  type: GET_PROFILE_REQUEST
})

export const getProfileSuccess = data => ({
  type: GET_PROFILE_SUCCESS,
  data
})
export const getProfileFailure = data => ({
  type: GET_PROFILE_FAILURE,
  data
})
