import { all, call, put, takeLatest } from "redux-saga/effects"
import { BASE_URL } from "../../../../config/app"
import XHR from "../../../../utils/XHR"
// types
import { GET_OWNER_TEAM_MEMBER, PUT_OWNER_TEAM_MEMBER } from "./type"
// actions
import {
  getOwnerTeamMemberSuccess,
  getOwnerTeamMemberFailure,
  putOwnerTeamMemberSuccess,
  putOwnerTeamMemberFailure
} from "./action"

async function getOwnerTeamMemberAPI(id) {
  const URL = `${BASE_URL}fillsite/team_member/${id}/`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "GET"
  }
  return XHR(URL, options)
}

function* getOwnerTeamMember({ id }) {
  try {
    const response = yield call(getOwnerTeamMemberAPI, id)
    yield put(getOwnerTeamMemberSuccess(response.data))
  } catch (e) {
    const { response } = e
    yield put(getOwnerTeamMemberFailure(response?.data))
  }
}

async function putOwnerTeamMemberAPI(data, id) {
  const URL = `${BASE_URL}fillsite/team_member/${id}/`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "PUT",
    data
  }

  return XHR(URL, options)
}

function* putOwnerTeamMember({ data, id }) {
  try {
    const response = yield call(putOwnerTeamMemberAPI, data, id)
    yield put(putOwnerTeamMemberSuccess(response.data))
  } catch (e) {
    const { response } = e
    yield put(putOwnerTeamMemberFailure(response?.data))
  }
}

export default all([
  takeLatest(GET_OWNER_TEAM_MEMBER, getOwnerTeamMember),
  takeLatest(PUT_OWNER_TEAM_MEMBER, putOwnerTeamMember)
])
