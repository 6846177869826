import {
  GET_OWNER_TEAM_MEMBERS,
  GET_OWNER_TEAM_MEMBERS_SUCCESS,
  GET_OWNER_TEAM_MEMBERS_FAILURE,
  DELETE_OWNER_TEAM_MEMBER,
  DELETE_OWNER_TEAM_MEMBER_SUCCESS,
  DELETE_OWNER_TEAM_MEMBER_FAILURE,
  UPDATE_OWNER_TEAM_MEMBERS,
  UPDATE_OWNER_TEAM_MEMBERS_SUCCESS
} from "./type"

export const getOwnerTeamMembers = () => ({
  type: GET_OWNER_TEAM_MEMBERS
  //filterModel
})

export const getOwnerTeamMembersSuccess = data => ({
  type: GET_OWNER_TEAM_MEMBERS_SUCCESS,
  data
})

export const getOwnerTeamMembersFailure = data => ({
  type: GET_OWNER_TEAM_MEMBERS_FAILURE,
  data
})

// delete team memeber

export const deleteOwnerTeamMember = member => ({
  type: DELETE_OWNER_TEAM_MEMBER,
  member
})

export const deleteOwnerTeamMemberSuccess = () => ({
  type: DELETE_OWNER_TEAM_MEMBER_SUCCESS
})

export const deleteOwnerTeamMemberFailure = data => ({
  type: DELETE_OWNER_TEAM_MEMBER_FAILURE,
  data
})

export const updateOwnerTeamMembers = data => ({
  type: UPDATE_OWNER_TEAM_MEMBERS,
  members: data
})

export const updateOwnerTeamMembersSuccess = data => ({
  type: UPDATE_OWNER_TEAM_MEMBERS_SUCCESS,
  data
})
