import {
  POST_CONFIRM_PASSWORD,
  POST_CONFIRM_PASSWORD_SUCCESS,
  POST_CONFIRM_PASSWORD_FAILURE,
  POST_USER_PASSWORD,
  POST_USER_PASSWORD_SUCCESS,
  POST_USER_PASSWORD_FAILURE
} from "./type"

export const postConfirmPassword = (data, history) => ({
  type: POST_CONFIRM_PASSWORD,
  data,
  history
})

export const postConfirmPasswordSuccess = data => ({
  type: POST_CONFIRM_PASSWORD_SUCCESS,
  data
})

export const postConfirmPasswordFailure = data => ({
  type: POST_CONFIRM_PASSWORD_FAILURE,
  data
})

export const postUserPassword = (data, history) => ({
  type:   POST_USER_PASSWORD,
  data,
  history
})

export const postUserPasswordSuccess = data => ({
  type: POST_USER_PASSWORD_SUCCESS,
  data
})

export const postUserPasswordFailure = data => ({
  type: POST_USER_PASSWORD_FAILURE,
  data
})
