import React, { useEffect, useState, useRef } from "react"
import HomeLayout from "../../../layout/HomeLayout"
import homebanner from "../images/home-banner-bg.png"
import iconsvg from "../images/icon.svg"
import pauseicon from "../images/pause-icon.png"
import aboutright from "../images/about-right-img.png"
import engineer from "../images/engineer-pic.png"
import Header from "../Header"
import Foter from "../Foter"
import { connect } from "react-redux"
import { getHomeRequest } from "./redux/action"
import "./style.scss"

const Home = props => {
  const { getHomeRequest, homeData, mydataFooter } = props


  useEffect(() => {
    getHomeRequest()
  }, [])
  const videoRef = useRef()
  const [stop, setStop] = useState(false)

  const handleVideo = () => {
    setStop(!stop)
    if (stop === true) {
      videoRef.current.pause()
    } else {
      videoRef.current.play()
    }
  }
  return (
    <>
      <div className="home-main-banner-section">
        <Header />
        <section
          className="home-banner-section"

        >
          <div className="container">
            <div className="row">
              <div className="col-md-12 content-section">
                <div className="title">
                  SHAPING THE FUTURE OF SUSTAINABLE BUILDING
                </div>
                <div className="sub-title">
                  Vivamus porttitor fringilla mi, vitae pellentesque massa
                  iaculis nec. Sed congue neque dolor, vitae tempus lectus
                  hendrerit ac. Morbi mattis facilisis sodales.
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="about-section">
          <div className="container">
            <div className="row">
              <div className="col-md-12 image-wrapper">
                {" "}
                <img src={engineer} alt="" className="img-fluid" />
              </div>
            </div>
            <div className="row content-row">
              <div className="col-lg-6 left-details">
                <div className="title">ABOUT US</div>
                <div className="sub-title">
                  SHAPING THE FUTURE OF SUSTAINABLE BUILDING
                </div>
                <p className="content-para">
                  Donec rutrum rhoncus pharetra. Aliquam aliquam felis augue, id
                  blandit nulla feugiat in. Sed pellentesque mauris nec odio
                  tristique mattis. Integer condimentum non metus ac
                  consectetur. Aliquam ultricies dolor sed elementum dictum.
                  Fusce facilisis tincidunt arcu tristique consectetur. Mauris
                  interdum quam sed diam tincidunt.
                </p>
              </div>
              <div className="col-lg-6 right-details">
                <div className="img">
                  {" "}
                  <img src={aboutright} alt="" className="img-fluid" />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="work-section">
          <div className="container">
            <div className="row card-row">
              <h2 className="text-center">A FEW EASY STEPS</h2>
              <h3 className="text-center">HOW WE WORK </h3>

              {homeData?.steps != undefined &&
                homeData.steps.map((item, index) => (
                  <>
                    <div className="col-lg-4 col-sm-6 work-card-wrapper">
                      <div className="work-card">
                        <div className="number">0{index + 1}</div>
                        <div className="heading-text">{item.heading}</div>
                        <p className="ontent-para">{item.text}</p>
                      </div>
                    </div>
                  </>
                ))}
            </div>
            <div className="row content-row">
              <div className="col-xl-6 left-details">
                <div className="title">our expertise</div>
                <div className="sub-title">{homeData?.file?.heading}</div>
                <p className="content-para">{homeData?.file?.text}</p>
              </div>
              <div className="col-xl-6 right-details">
                <div className="video" id="videoWrapper">
                  <video
                
                    ref={videoRef}
                   
                    src={homeData?.file?.video}
                  ></video>
                  {
                    <>
                      {stop ? (
                         <div
                         className="pause-icon play-pause-icon"
                         id="pauseIcon"
                      
                         onClick={() => handleVideo()}

                       >
                         <img src={pauseicon} />

                     
                       </div>
                       
                      ) : (
                        <div
                        className="play-icon play-pause-icon"
                        id="playIcon"
                        onClick={() => handleVideo()}
                    
                      >
                        <img src={iconsvg} />
                    
                      </div>
                      )}
                    </>
                  }
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Foter />
    </>
  )
}

const mapStateToProps = state => ({
  homeData: state.homeData.homeData,
  requesting: state.homeData.requesting,
  mydataFooter: state.dataFooter.mydataFooter
})

const mapDispatchToProps = dispatch => ({
  getHomeRequest: data => dispatch(getHomeRequest(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(Home)
