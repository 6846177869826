import {
  GET_SCHEDULE_LIST_REQUEST,
  GET_SCHEDULE_LIST_SUCCESS,
  GET_SCHEDULE_LIST_FAILURE,
  GET_ID_SCHEDULE,
  GET_ID_SUCCESS,
  GET_ID_FAILURE,

} from "./type"

export const getScheduleListRequest = (data) => ({
  type: GET_SCHEDULE_LIST_REQUEST,
  data
  //filterModel
})


export const getScheduleListSuccess = data => ({
  type: GET_SCHEDULE_LIST_SUCCESS,
  data
})

export const getScheduleListFailure = data => ({
  type: GET_SCHEDULE_LIST_FAILURE,
  data
})

export const getIdProject = member => ({
  type:GET_ID_SCHEDULE,
  member
})

export const getIdSuccess = (data) => ({
  type:GET_ID_SUCCESS,
  data
})

export const getIdFailure = data => ({
  type:GET_ID_FAILURE,
  data
})

