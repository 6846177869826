import { all, call, put, takeLatest } from "redux-saga/effects"
import { BASE_URL } from "../../../../config/app"
import XHR from "../../../../utils/XHR"
import {
    getAccountRequestFailure,
    getAccountRequestSucess,
    patchAccountRequestFailure,
    patchAccountRequestSucess
} from "./action"
import { GET_ACCOUNT_REQUEST, PATCH_ACCOUNT_REQUEST } from "./type"

const AccountRequestAPI = async (data) => {
    const URL = `${BASE_URL}api/v1/admin/contractors_list/?${data}=${data}`
    const token = localStorage.getItem("token")
    const options = {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Token ${token}`
        },
        method: "GET"
    }
    return XHR(URL, options)
}

const PatchRequestAPI = async (id, data) => {
    console.log(id)
    const URL = `${BASE_URL}api/v1/admin/contractors_list/${id}/`
    const token = localStorage.getItem("token")
    const options = {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Token ${token}`
        },
        method: "PATCH",
        data: data
    }
    return XHR(URL, options)
}


function* AccountRequestData({ data }) {
    try {
        const response = yield call(AccountRequestAPI, data)
        yield put(getAccountRequestSucess(response.data))
    } catch (e) {
        const { response } = e
        yield put(getAccountRequestFailure(response.data))
    }
}

function* PatchAccountRequestData({ id, data }) {
    try {
        const response = yield call(PatchRequestAPI, id, data)
        yield put(patchAccountRequestSucess(response.data))
    } catch (e) {
        const { response } = e
        yield put(patchAccountRequestFailure(response.data))
    }
}

export default all([
    takeLatest(GET_ACCOUNT_REQUEST, AccountRequestData),
    takeLatest(PATCH_ACCOUNT_REQUEST, PatchAccountRequestData),
])