import {
  GET_SCHEDULE_REQUEST,
  GET_SCHEDULE_SUCCESS,
  GET_SCHEDULE_FAILURE,
  POST_SPECIFIC_DATE_REQUEST,
  POST_SPECIFIC_DATE_SUCCESS,
  POST_SPECIFIC_DATE_FAILURE,
  PATCH_SOIL_AUTHORIZATION,
  PATCH_SOIL_AUTHORIZATION_SUCCESS,
  PATCH_SOIL_AUTHORIZATION_FAILURE,
  GET_PROFILE_REQUEST,
  GET_PROFILE_SUCCESS,
  GET_PROFILE_FAILURE
} from "./type"

const initialState = {
  data: false,
  requesting: false,
  error: false,
  specificdate: null,
  specificdateRequesting: false,
  patchSoil: false,
  patchSoilLoad: false,
  patchSiolError: false,
  profile: null
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_SCHEDULE_REQUEST:
      return {
        ...state,
        requesting: true
      }
    case GET_SCHEDULE_SUCCESS:
      return {
        ...state,
        requesting: false,
        data: action.data
      }
    case GET_SCHEDULE_FAILURE:
      return {
        ...state,
        requesting: false,
        error: action.data
      }

    case GET_PROFILE_REQUEST:
      return {
        ...state,
        requesting: true
      }
    case GET_PROFILE_SUCCESS:
      return {
        ...state,
        requesting: false,
        profile: action.data
      }
    case GET_PROFILE_FAILURE:
      return {
        ...state,
        requesting: false,
        error: action.data
      }

    case POST_SPECIFIC_DATE_REQUEST:
      return {
        ...state,
        specificdateRequesting: true
      }
    case POST_SPECIFIC_DATE_SUCCESS:
      return {
        ...state,
        specificdateRequesting: false,
        specificdate: action.data
      }
    case POST_SPECIFIC_DATE_FAILURE:
      return {
        ...state,
        specificdateRequesting: false,
        error: action.data
      }
    case PATCH_SOIL_AUTHORIZATION:
      return {
        ...state,
        patchSoilLoad: true
      }
    case PATCH_SOIL_AUTHORIZATION_SUCCESS:
      return {
        ...state,
        patchSoilLoad: false,
        patchSoil: action.data
      }
    case PATCH_SOIL_AUTHORIZATION_FAILURE:
      return {
        ...state,
        patchSoilLoad: false,
        patchSiolError: action.data
      }
    default:
      return state
  }
}
