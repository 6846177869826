import {
  GET_TRUCK_LIST_REQUEST,
  GET_TRUCK_LIST_SUCCESS,
  GET_TRUCK_LIST_FAILURE,
  GET_ID_TRUCK,
  GET_ID_SUCCESS,
  GET_ID_FAILURE,

} from "./type"

export const getTruckListRequest = (data) => ({
  type: GET_TRUCK_LIST_REQUEST,
  data
  //filterModel
})


export const getTruckListSuccess = data => ({
  type: GET_TRUCK_LIST_SUCCESS,
  data
})

export const getTruckListFailure = data => ({
  type: GET_TRUCK_LIST_FAILURE,
  data
})

export const getIdProject = member => ({
  type:GET_ID_TRUCK,
  member
})

export const getIdSuccess = (data) => ({
  type:GET_ID_SUCCESS,
  data
})

export const getIdFailure = data => ({
  type:GET_ID_FAILURE,
  data
})

