import {
  POST_SOIL_ACCEPT_REQUEST,
  POST_SOIL_ACCEPT_SUCCESS,
  POST_SOIL_ACCEPT_FAILURE,
  GET_ADMIN_FILL_SITE,
  SUCCESS_GETTING_ADMIN_FILL_SITE,
  ERROR_GETTING_ADMIN_FILL_SITE
} from "./type"

export const postSoilAcceptRequest = data => {
  return { type: POST_SOIL_ACCEPT_REQUEST, data }
}

export const postSoilAcceptSucess = data => ({
  type: POST_SOIL_ACCEPT_SUCCESS,
  data
})
export const postSoilAcceptFailure = data => ({
  type: POST_SOIL_ACCEPT_FAILURE,
  data
})
export const getAdminFillRequest = data => {
  return { type: GET_ADMIN_FILL_SITE, data }
}

export const getAdminFillSucess = data => ({
  type: SUCCESS_GETTING_ADMIN_FILL_SITE,
  data
})
export const getAdminFillFailure = data => ({
  type: ERROR_GETTING_ADMIN_FILL_SITE,
  data
})
