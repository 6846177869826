import React from "react"
import { history } from "../../reduxStore/store"

import "./style.scss"
import Button from "../Button"

import { connect } from "react-redux"
import {
  logOut,
  loginRequestSuccess
} from "../../pages/AuthScreens/LoginScreen/redux/action"

const Header = props => {
  const dashboardHeader = props.dashboardHeader ? props.dashboardHeader : false

  const logOut = () => {
    localStorage.clear()
    props.logOut()
    props.loginRequestSuccess(false)
    // props.history.push("/account")
  }
  const { loginInfo, title, subtitle } = props

  const handleOnChange = () => {
    if (loginInfo.user.user_type === "admin") {
      console.log("admin")
    } else if (loginInfo.user.user_type === "owner") {
      history.push("Account-information")
    } else {
      history.push("account_info")
    }
  }
  return (
    <section>
      <div className="header-section">
        <div style={{flexDirection:'row',display:'flex',alignContent:'center',alignItems:'center',}}>
          {title && <p className="header-title" style={{fontSize:'1em',color:'#0a5f59',marginBottom:0}}>{title} </p>}
          {subtitle && <p className="header-subtitle" style={{fontSize:'1em',marginLeft:5,marginRight:5,color:'#0a5f59',marginBottom:0}}>/</p>}
          {subtitle && <p className="header-subtitle" style={{fontSize:'1em',fontWeight:'bold',color:'#0a5f59',marginBottom:0}}>{subtitle}</p>}
        </div>
        <div style={{flexDirection:'row',display:'flex',alignContent:'center',alignItems:'center',}}>
          <div className="main-text" style={{marginRight:25}}>
            {dashboardHeader && (
              <div className="dashboardHeader">
                <div className="dashboard-wrapper">
                  <div
                    className="wrapper-heading"
                    onClick={() => {
                      handleOnChange()
                    }}
                  >
                    {loginInfo.user.profile_picture ? (
                      <img
                        src={loginInfo.user.profile_picture}
                        style={{
                          height: 34,
                          width: 34,
                          borderRadius: " 50%"
                        }}
                      />
                    ) : (
                      <div className="avatar">
                        <div className="profile">
                          {loginInfo?.user.username &&
                            loginInfo?.user.username
                              .match(/(^\S\S?|\s\S)?/g)
                              .map(v => v.trim())
                              .join("")
                              .match(/(^\S|\S$)?/g)
                              .join("")
                              .toLocaleUpperCase()}
                        </div>
                      </div>
                    )}
                    <div>
                      <h3>{loginInfo?.user.username}</h3>

                      <p>
                        {loginInfo?.user.role
                          ? loginInfo?.user.role
                          : loginInfo?.user.user_type}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="button-wrapper showbutton">
            <Button
              onClick={logOut}
              title="Log Out"
              className="aes-btn transparent-btn"
            />
          </div>
        </div>
      </div>
      {loginInfo?.user?.user_type !== "admin" && !loginInfo?.user?.user_status &&
        <h1 className="inactive-user">Your account has been temporarily blocked, please contact support </h1>
      }
    </section>
  )
}
const mapStateToProps = state => ({
  loginInfo: state.login.loginInfo
})

const mapDispatchToProps = dispatch => ({
  logOut: data => dispatch(logOut(data)),
  loginRequestSuccess: data => dispatch(loginRequestSuccess(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(Header)
