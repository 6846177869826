import {
 
  PUT_TEAM_MEMBER,
  PUT_TEAM_MEMBER_SUCCESS,
  PUT_TEAM_MEMBER_FAILURE
} from "./type"



// PUT TEAM MEMBER
export const putTeamMemberRequest = (data, id) => ({
  type: PUT_TEAM_MEMBER,
  data,
  id
})

export const putTeamMemberSuccess = data => ({
  type: PUT_TEAM_MEMBER_SUCCESS,
  data
})

export const putTeamMemberFailure = data => ({
  type: PUT_TEAM_MEMBER_FAILURE,
  data
})
