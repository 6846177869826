import React, { useState, useEffect, useRef } from "react"
import { Modal, Form, Select, Space } from "antd"
import { useDispatch, useSelector } from "react-redux"
import Button from "../../Button"
import AeSelect from "../../AeSelect"

import { getAdminFillRequest } from "../../../pages/Admin/RequestConfirmPage/redux/action"
import TextArea from "../../TextArea"
import Input from "../../Input"
import "./style.scss"
import { PostAdminRequest } from "../../../pages/Admin/Notifications/redux/action"
import { getContractorRequest } from "../../../pages/Admin/Reports/redux/action"

const NewNotification = props => {
    const { modalShow, setModalShow } = props

    const [title, setTitle] = useState("")
    const [fillSite, setfillSite] = useState([])
    const [contractors, setContractors] = useState([])
    const [selectUser, setSelectUser] = useState("All")

    const [selectType, setSelectType] = useState("all")

    const [selectefillSite, setSelectefillSite] = useState()

    const [note, setNote] = useState("")


    const handleCancel = () => {
        setModalShow(false)
        setNote("")
        setSelectefillSite([])
        formRef.current?.resetFields();
    }

    const { loginInfo } = useSelector(
        state => state.login
    )

    const dispatch = useDispatch()
    const submit = () => {
        const data = {
            subject: title,
            message: note,
            send_to: selectefillSite,
            send_from: loginInfo.user,
            resend_notification: true,
            message_type: selectType,
            send_to_type: selectUser
        }
        dispatch(PostAdminRequest(data, handleCancel))
    }

    useEffect(() => {
        dispatch(getContractorRequest())
        dispatch(getAdminFillRequest())
    }, [])

    const { contractorData, } = useSelector(
        state => state.Report

    )
    const { getAdminFillSite, } = useSelector(
        state => state.SoilConfirm
    )
    const { PostAdminrequesting } = useSelector(
        state => state.Notification
    )

    useEffect(() => {
        if (getAdminFillSite) {
            const fill_site_array = []
            getAdminFillSite?.map((a, b) => {
                fill_site_array.push({ value: a.id, label: a.name })
            })
            setfillSite(fill_site_array)
        }
        if (contractorData) {
            const Contractor_array = []
            contractorData?.map((a, b) => {
                Contractor_array.push({ value: a.id, label: a.username })
            })
            setContractors(Contractor_array)
        }
    }, [getAdminFillSite, contractorData])


    const formRef = useRef(null)
    const { Option } = Select

    return (
        <>
            <Modal
                title={` `}
                open={modalShow}
                onCancel={handleCancel}
                className="notifiaction-modal"
                cancelButtonProps={{ style: { display: "none" } }}
                footer={null}>
                <div className="modal-div">
                    <h3>Send Message</h3>
                    <h4>Subject:</h4>
                    <Form
                        ref={formRef}
                        name="control-ref"
                        labelWrap={true}
                        layout="vertical"
                        onFinish={submit}
                    >
                        <Form.Item
                            name="category"
                            rules={[
                                {
                                    required: true,
                                    message: "This field may not be blank.",
                                },
                            ]}
                        >
                            <Input
                                onChange={(e) => setTitle(e.target.value)}
                                value={title}
                                type="text"
                                placeholder="Subject"
                                className="tile-input"
                            // disabled={true}
                            />
                        </Form.Item>
                        <h4>Content:</h4>
                        <Form.Item
                            name="note:"
                            rules={[
                                {
                                    required: true,
                                    message: "This field may not be blank.",
                                },
                            ]}
                        >
                            <TextArea
                                value={note}
                                onChange={(e) => setNote(e.target.value)}
                                placeholder="Enter text"
                                autoSize={{
                                    minRows: 3,
                                    maxRows: 5,
                                }}
                            />
                        </Form.Item>
                        <h4>Send To</h4>
                        <Form.Item
                            name="Send To"
                            rules={[
                                {
                                    required: false,
                                    message: "This field may not be blank.",
                                },
                            ]}
                        >
                            <AeSelect
                                options={[
                                    {
                                        value: "Contractors",
                                        label: "Contractors Only"
                                    },
                                    {
                                        value: "TeamMembers",
                                        label: "Team Members Only"
                                    },
                                    {
                                        value: "FillSite",
                                        label: "Fill Sites Only"
                                    },
                                    
                                    {
                                        value: "All",
                                        label: "All Users"
                                    }
                                ]}

                                placeholder="Select from list"
                                value={selectUser}
                                onChange={item => {
                                    setSelectUser(item)
                                }}
                            />
                        </Form.Item>
                        <h4>Send only to specific users</h4>
                        <Form.Item
                            name="fillSite"
                            rules={[
                                {
                                    required: false,
                                    message: "This field may not be blank.",
                                },
                            ]}
                        >
                            {/* <AeSelect
                               multiple={true}
                                placeholder={"Fill site"}
                                options={fillSite}
                                onChange={(e) => setSelectefillSite(e)}
                            /> */}
                            <Select
                                placeholder="Start typing name"

                                mode="multiple"
                                style={{
                                    width: "100%"
                                }}
                                value={selectefillSite}
                                filterOption={(inputValue, option) =>
                                    option.label
                                        .toLowerCase()
                                        .indexOf(inputValue.toLowerCase()) !== -1
                                }
                                // label={adminData?.username}
                                onChange={(e) => setSelectefillSite(e)}
                            >
                                {/* {selectUser=="Contractors"  &&  contractors ? (
                                    contractors.map(data => (

                                        <Option
                                            key={data.value}
                                            value={data.value}
                                            label={data.label}
                                        >
                                            <Space>{data.label}</Space>
                                        </Option>
                                    ))
                                ) : (
                                    <Option value={null} disabled>
                                        No data
                                    </Option>
                                )}
                              
                                {selectUser=="FillSite"  &&  fillSite ? (
                                    fillSite.map(data => (

                                        <Option
                                            key={data.value}
                                            value={data.value}
                                            label={data.label}
                                        >
                                            <Space>{data.label}</Space>
                                        </Option>
                                    ))
                                ) : (
                                    <Option value={null} disabled>
                                        No data
                                    </Option>
                                )} */}
                                {selectUser === "All" ? (
                                    <>
                                        {/* Render options for "Contractors" */}
                                        {contractors && contractors.map((data) => (
                                            <Option key={data.value} value={data.value} label={data.label}>
                                                <Space>{data.label}</Space>
                                            </Option>
                                        ))}
                                        {/* Render options for "Fill Site" */}
                                        {fillSite && fillSite.map((data) => (
                                            <Option key={data.value} value={data.value} label={data.label}>
                                                <Space>{data.label}</Space>
                                            </Option>
                                        ))}
                                    </>
                                ) : (
                                    // Render options based on the selected value in the first dropdown
                                    selectUser === "Contractors" && contractors ? (
                                        contractors.map((data) => (
                                            <Option key={data.value} value={data.value} label={data.label}>
                                                <Space>{data.label}</Space>
                                            </Option>
                                        ))
                                    ) : selectUser === "FillSite" && fillSite ? (
                                        fillSite.map((data) => (
                                            <Option key={data.value} value={data.value} label={data.label}>
                                                <Space>{data.label}</Space>
                                            </Option>
                                        ))
                                    ) : (
                                        <Option value={null} disabled>
                                            No data
                                        </Option>
                                    )
                                )}
                                {/* {fillSite ? (
                                    fillSite.map(data => (

                                        <Option
                                            key={data.value}
                                            value={data.value}
                                            label={data.label}
                                        >
                                            <Space>{data.label}</Space>
                                        </Option>
                                    ))
                                ) : (
                                    <Option value={null} disabled>
                                        No data
                                    </Option>
                                )} */}
                            </Select>
                            </Form.Item>
                            <h4 style={{marginTop:20}}>Message Type</h4>
                            <Form.Item
                                name="Message Type"
                                rules={[
                                    {
                                        required: false,
                                        message: "This field may not be blank.",
                                    },
                                ]}
                            >
                                <AeSelect
                                    options={[
                                        {
                                            value: "all",
                                            label: "Email/In-App Notification",
                                        },
                                        {
                                            value: "email",
                                            label: "Email Notification"
                                        },
                                        {
                                            value: "in-app",
                                            label: "In-App Notification"
                                        }
                                    ]}

                                    placeholder="Select from list"
                                    value={selectType}
                                    onChange={item => {
                                        setSelectType(item)
                                    }}
                                />
                            </Form.Item>
                        


                        <div className="btn-wraper">
                            <Button
                                title="send"
                                className="aes-btn green-btn  "
                                type="submit"
                                showSpinner={PostAdminrequesting}
                            />
                        </div>
                    </Form>

                </div>
            </Modal>
        </>
    )
}
export default NewNotification;