import {
  POST_FORGOT_PASSWORD,
  POST_FORGOT_PASSWORD_SUCCESS,
  POST_FORGOT_PASSWORD_FAILURE,
  NOT_IS_FIRST
} from "./type"

export const postForgotRequest = data => ({
  type: POST_FORGOT_PASSWORD,
  data
})

export const postForgotRequestSuccess = data => ({
  type: POST_FORGOT_PASSWORD_SUCCESS,
  data
})

export const postForgotRequestFailure = error => ({
  type: POST_FORGOT_PASSWORD_FAILURE,
  error
})
export const setNotFirst = ({ status, email }) => ({
  type: NOT_IS_FIRST,
  data: {
    status,
    email
  }
})
