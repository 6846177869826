import React, { useEffect, useState, useRef } from "react"
import backIcon from "../../../assets/images/back-icon-circle.svg"
import BoardSelect from "../../../components/BoardSelect"
import Button from "../../../components/Button"
import Input from "../../../components/Input"
import HomeLayout from "../../../layout/HomeLayout"
import { history } from "../../../reduxStore/store"
import useForm from "../../../utils/useForm"
import "./style.scss"
// import { getProfileDataRequest, getProfileDatarSuccess, putProfileDataRequest,putProfileDataSuccess } from "./redux/action"
import { connect } from "react-redux"
import validator from "../../../utils/validation"
import { CountryName } from "../../../components/CountryName"
import { Toaster } from "react-hot-toast"
import { Select } from "antd"
import { getCountryRequest } from "../../AuthScreens/SignUp/redux/action"
import AesPhoneInput from "../../../components/PhoneInput"
import { loginRequestSuccess } from "../../AuthScreens/LoginScreen/redux/action"
import {
  getProfileDataRequest,
  getProfileDatarSuccess,
  putProfileDataRequest,
  putProfileDataSuccess
} from "../../Contractor/AccountInformation/redux/action"

import {
  getFillProfileDataRequest,
  putFillProfileDataRequest,
  putFillProfileDataSuccess
} from "./redux/action"

const FillAccountInformation = props => {
  const {
    getProfileDataRequest,
    putProfileDataRequest,
    loginRequestSuccess,
    putProfileDataSuccess,
    putFillProfileDataSuccess,
    ProfileData,
    putRequesting,
    error,
    CountryData,
    getCountryRequest,
    loginInfo,
    profileFillData,
    getFillProfileDataRequest,
    putFillProfileDataRequest
  } = props
  useEffect(() => {
    getCountryRequest()
  }, [])

  useEffect(() => {
    const firstProfilePicture = profileFillData && profileFillData[0]?.user_detail?.profile_picture;

    if (firstProfilePicture) {
      const updatedUser = {
        ...loginInfo.user,
        profile_picture: firstProfilePicture,
      };

      loginRequestSuccess({
        user: updatedUser,
      });
      putFillProfileDataSuccess(false)
    }
  }, [])

  // const [country, setCountry] = useState("")
  const stateSchema = {
    companyName: {
      value: "",
      error: ""
    },
    email: {
      value: "",
      error: ""
    },
    street: {
      value: "",
      error: ""
    },
    city: {
      value: "",
      error: ""
    },
    postalCode: {
      value: "",
      error: ""
    },
    province: {
      value: "",
      error: ""
    },
    country: {
      value: "",
      error: ""
    },
    phone: {
      value: "",
      error: ""
    }
  }

  const validationStateSchema = {
    companyName: {
      required: true
    },
    email: {
      required: true,
      validator: validator.email
    },
    phone: {
      required: true
      // validator: validator.phone
    },
    street: {
      required: true
    },
    city: {
      required: true
    },
    province: {
      required: true
    },
    country: {
      required: true
    },
    postalCode: {
      required: true
    }
  }

  const { state, handleOnChange, disable, setState } = useForm(
    stateSchema,
    validationStateSchema
  )
  useEffect(() => {
    getFillProfileDataRequest()
  }, [])

  useEffect(() => {
    if (Array.isArray(profileFillData)) {
      profileFillData.forEach((item) => {
        handleOnChange("country", item?.country);
        item?.site_field_name && handleOnChange("companyName", item?.site_field_name);
        item?.site_field_email && handleOnChange("email", item?.site_field_email);
        item?.street && handleOnChange("street", item?.street);
        item?.zip_code && handleOnChange("postalCode", item?.zip_code);
        item?.province && handleOnChange("province", item?.province);
        item?.site_field_contact_no && handleOnChange("phone", item?.site_field_contact_no);
        item?.city && handleOnChange("city", item?.city);
      });
    }
  }, [profileFillData]);

  const Option = Select
  const options = CountryData?.map((items, index) => {
    return (
      <Option key={index} value={items?.id}>
        {items?.name}
      </Option>
    )
  })

  const submit = async () => {
    const formData = new FormData();
    const idFillsite = profileFillData && profileFillData?.map((item) => item.id);

    formData.append("site_field_contact_no", state.phone.value);
    formData.append("site_field_name", state.companyName.value);
    formData.append("street", state.street.value);
    formData.append("zip_code", state.postalCode.value);
    formData.append("province", state.province.value);
    formData.append("city", state.city.value);
    formData.append("site_field_email", state.email.value);
    formData.append("country", state.country.value);

    if (selectedImage) {
      formData.append("profile_picture", selectedImage);
    }

    await putFillProfileDataRequest(formData, idFillsite);
  };

  const handleFormatChanger = value => {
    if (value.length > 0) {
      handleOnChange("phone", ` +${value}`)
    } else handleOnChange("phone", value)
  }
  const [selectedImage, setSelectedImage] = useState("")
  const fileInputRef = useRef(null)

  const handleImageUpload = () => {
    fileInputRef.current.click()
  }

  const handleFileChange = event => {
    const file = event.target.files[0]
    setSelectedImage(file)
  }

  return (
    <>
      <HomeLayout dashboardHeader={true}>
        <Toaster position="top-center" />

        <section className="content-section account-info-section">
          <div className="account-info-content-section">
            <div className="heading-account-info">
              <h3>Account Information</h3>
            </div>
            {/* <div className="top-form-section"> */}
            <div className="row top-form-row">
              <div className="col-md-4 field">
                <Input
                  placeholder="Site field name"
                  showLabel={true}
                  label="Site field name"
                  value={state.companyName.value}
                  showError={true}
                  // errorMessage={
                  //   state.companyName.error
                  // }
                  onChange={val =>
                    handleOnChange("companyName", val.target.value)
                  }
                />
              </div>
              <div className="col-md-4 field">
                <Input
                  placeholder="Street"
                  showLabel={true}
                  label="Street"
                  value={state?.street ? state.street.value : ""}
                  showError={true}
                  // errorMessage={state.street.error}
                  onChange={val => handleOnChange("street", val.target.value)}
                />
              </div>
              <div className="col-md-4 field">
                <Input
                  placeholder="City"
                  showLabel={true}
                  label="City"
                  value={state?.city ? state.city.value : ""}
                  showError={true}
                  // errorMessage={state.city.value}
                  onChange={val => handleOnChange("city", val.target.value)}
                />
              </div>
            </div>
            <div className="row top-form-row mt-3">
              <div className="col-md-4 field">
                <Input
                  placeholder="ZIP/Postal code"
                  showLabel={true}
                  label="ZIP/Postal code"
                  value={state.postalCode.value}
                  type="text"
                  // showError={true}
                  // errorMessage={state.postalCode.error}
                  onChange={val =>
                    handleOnChange("postalCode", val.target.value)
                  }
                />
              </div>
              <div className="col-md-4 field">
                <Input
                  placeholder="State/Province"
                  showLabel={true}
                  label="State/Province"
                  value={state?.province ? state.province.value : ""}
                  showError={true}
                  // errorMessage={state.province.error}
                  onChange={val => handleOnChange("province", val.target.value)}
                />
              </div>
              <div className="col-md-4 field  ">
                <label
                  className="label"
                // style={{ fontSize: "15px", fontWeight: "500" }}
                >
                  Country
                </label>
                <BoardSelect
                  options={options}
                  // placeholder={ProfileData?.country_name}
                  onChange={e => {
                    handleOnChange("country", e)
                  }}
                  showSearch={true}
                  // filterOption={(input, option) =>
                  //   (option?.label ?? "").includes(input)
                  // }
                  optionFilterProp="children"
                  value={state.country.value}
                />
              </div>
            </div>
            <div className="row top-form-row mt-3">
              <div className="col-md-4 field">
                <Input
                  placeholder="Site field email"
                  showLabel={true}
                  label="Site field email"
                  value={state.email.value}
                  showError={true}
                  // errorMessage={error?.company.email}
                  onChange={val => handleOnChange("email", val.target.value)}
                />
              </div>
              <div className="col-md-4 field  ">
                <label
                  className="label"
                // style={{ fontSize: "15px", fontWeight: "500" }}
                >
                  Site field contact phone
                </label>
                <AesPhoneInput
                  placeholder={"Site field contact phone"}
                  type="number"
                  value={state.phone.value}
                  onChange={handleFormatChanger}
                // showError={true}
                // errorMessage={
                //   PostError?.phone_number && PostError?.phone_number
                // }
                />
              </div>
              <div
                className="col-md-4 field"
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: 20,
                  justifyContent: "center"
                }}
              >
                <input
                  type="file"
                  onChange={handleFileChange}
                  hidden
                  ref={fileInputRef}
                />
                <Button
                  onClick={handleImageUpload}
                  title="Upload company logo"
                />
                {/* {selectedImage && (
        <div>
          <h4>Selected Image:</h4>
          <img src={selectedImage} alt="Selected" />
        </div>
      )} */}
              </div>
            </div>

            <div className="row mt-3">
              <div className="button-wrapper col-lg-5 ">
                <Button
                  title="Save Changes"
                  className="aes-btn green-btn"
                  showSpinner={putRequesting}
                  onClick={() => {
                    submit()
                  }}
                />
                <div
                  onClick={() => history.push("changePasword")}
                  className="change col-lg-4 "
                  style={{ whiteSpace: "nowrap" }}
                >
                  Change Password
                </div>
                <hr className="m-0" />
              </div>
            </div>
          </div>
        </section>
      </HomeLayout>
    </>
  )
}
const mapStateToProps = state => ({
  loginInfo: state.login?.loginInfo,
  // profileFillData: state.profileFillData.profileFillData,
  putRequesting: state.profileFillData.putRequesting,
  error: state.ProfileData.error,
  CountryData: state.signUp.CountryData,
  profileFillData: state.profileFillData.profileFillData

  // ProfileData: state.ProfileData.profileData
})

const mapDispatchToProps = dispatch => ({
  getProfileDataRequest: () => dispatch(getProfileDataRequest()),
  putProfileDataRequest: data => dispatch(putProfileDataRequest(data)),
  getCountryRequest: data => dispatch(getCountryRequest(data)),
  loginRequestSuccess: data => dispatch(loginRequestSuccess(data)),
  putProfileDataSuccess: data => dispatch(putProfileDataSuccess(data)),
  getFillProfileDataRequest: data => dispatch(getFillProfileDataRequest(data)),
  putFillProfileDataRequest: (data, id) => dispatch(putFillProfileDataRequest(data, id)),
  putFillProfileDataSuccess: data => dispatch(putFillProfileDataSuccess(data)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FillAccountInformation)
