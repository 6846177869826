import React, { useEffect, useState } from "react"
import Button from "../../components/Button"
import Modal from "react-bootstrap/Modal"
import closeIcon from "../../assets/images/closeIcon.svg"
import { useDispatch, useSelector } from "react-redux"
import "./style.scss"
import {
  patchInvoiceStatus,
  patchInvoiceStatusSucess,
  getSingleInvoiceRequest
} from "../../pages/Admin/Invoices/redux/action"

const ChangeStatus = props => {
  const [statusValue, setStatusValue] = useState("")
  const dispatch = useDispatch()
  const { patchInvoiceLoad, patchInvoice } = useSelector(
    state => state.Invoices
  )
  const { invoiceId } = useSelector(state => state.login)
  const { modalShow, setModalShow, selectedInvoices,setSelectedInvoiceId } = props
  useEffect(() => {
    if (patchInvoice?.id) {
      setModalShow(false)
      dispatch(getSingleInvoiceRequest(invoiceId))
      dispatch(patchInvoiceStatusSucess(false))
      setSelectedInvoiceId(null)
    }
  }, [patchInvoice?.id])

  const patchRequest = () => {
    if (selectedInvoices) {
      const data = {
        status: statusValue
      }
      dispatch(patchInvoiceStatus(selectedInvoices, data))
    }
  }

  return (
    <>
      <Modal
        show={modalShow}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="ChangeStatus"
      >
        <Modal.Body className="ChangeStatus-modal-body">
          <div className="close-icon" onClick={() => setModalShow(false)}>
            <img src={closeIcon} alt="" />
          </div>
          <div className="ChangeStatus-wrapper">
            <h3>Change Status</h3>
            <div className="checkbox-wrapper2">
              <label className="check">
                <input
                  type="radio"
                  className="checkbox-round"
                  name="smaterialamount"
                  value="paid"
                  onChange={e => setStatusValue(e.target.value)}
                />
                <span>Paid</span>
              </label>
              <label className="check">
                <input
                  type="radio"
                  className="checkbox-round"
                  name="smaterialamount"
                  value="not_paid"
                  onChange={e => setStatusValue(e.target.value)}
                />
                <span>Not Paid</span>
              </label>
              <label className="check">
                <input
                  type="radio"
                  className="checkbox-round"
                  name="smaterialamount"
                  value="paid_overdue"
                  onChange={e => setStatusValue(e.target.value)}
                />
                <span>Overdue</span>
              </label>
            </div>
            <div className="button-wrapper mt-5">
              <Button
                title="Save"
                onClick={patchRequest}
                showSpinner={patchInvoiceLoad}
              />
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default ChangeStatus
