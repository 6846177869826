import { all, call, put, takeLatest } from "redux-saga/effects"
import { BASE_URL } from "../../../../config/app"
import XHR from "../../../../utils/XHR"
import {
    getDocumentSucess,
    DeleteDocumentSucess,
    PostDocumentSucess,
    PostDocumentFailed,
    getDocumentRequest
} from "./action"
import {
    GET_DOCUMENT_REQUEST,
    DELETE_DOCUMENT_REQUEST,

    POST_DOCUMENT_REQUEST
} from "./types"
import { toast } from "react-hot-toast"

const DocumentAPI = async (data) => {
    const URL = `${BASE_URL}api/v1/admin/upload_docs/${data}`
    const token = localStorage.getItem("token")
    const options = {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Token ${token}`
        },
        method: "GET"
    }
    return XHR(URL, options)
}

function* DocumentData({ data }) {
    try {
        const response = yield call(DocumentAPI, data)
        yield put(getDocumentSucess(response?.data))

    } catch (e) {
        console.log(e);
    }
}
const DeleteDocumentAPI = async (data) => {
    const URL = `${BASE_URL}api/v1/admin/upload_docs/${data}/`
    const token = localStorage.getItem("token")
    const options = {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Token ${token}`
        },
        method: "DELETE",
    }
    return XHR(URL, options)
}

function* DeleteDocumentData({ data }) {
    try {
        const response = yield call(DeleteDocumentAPI, data)
        yield put(DeleteDocumentSucess(response?.data))
        yield put (getDocumentRequest(""))

    } catch (e) {
        console.log(e);
    }
}

const PostDocumentAPI = async (data) => {
    const URL = `${BASE_URL}api/v1/admin/upload_docs/`
    const token = localStorage.getItem("token")
    const options = {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Token ${token}`
        },
        method: "POST",
        data
    }
    return XHR(URL, options)
}

function* PostDocumentData({ data ,handleCancel}) {
    try {
        const response = yield call(PostDocumentAPI, data)
        yield put(PostDocumentSucess(response?.data))
        yield put (getDocumentRequest(""))
        handleCancel()

    } catch (e) {
        const { response } = e
        yield put(PostDocumentFailed(response?.data))
        toast.error("There was an error")

    }
}
export default all([
    takeLatest(DELETE_DOCUMENT_REQUEST, DeleteDocumentData),

    takeLatest(POST_DOCUMENT_REQUEST, PostDocumentData),

    takeLatest(GET_DOCUMENT_REQUEST, DocumentData),

])