import {
  POST_REQUEST_ADD_PROJECT,
  POST_ADD_PROJECT_SUCCESS,
  POST_ADD_PROJECT_FAILURE
} from "./type"

// POST_PAYMENT_INFORMATION
export const addProjectRequest = data => ({
  type: POST_REQUEST_ADD_PROJECT,
  data
})

export const addProjectSuccess = data => ({
  type: POST_ADD_PROJECT_SUCCESS,
  data
})

export const addProjectFailure = data => ({
  type: POST_ADD_PROJECT_FAILURE,
  data
})
