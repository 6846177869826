import React, { useState, useEffect, useRef } from "react"
import "./style.scss"
import HomeLayout from "../../../layout/HomeLayout"
import Button from "../../../components/Button"
import { connect } from "react-redux"
import {
  getNotificationRequest,
  getModaldatasuccess,
  getTruckListRequest
} from "./redux/action"
import moment from "moment"
import DashboardModal from "../../../constratarsModal/dashboardModal"
import Modal from "react-bootstrap/Modal"
import Filter from "../../../components/Filter"
import PaginationPack from "../../../components/PaginationPack"
import dayjs from "dayjs"
import Skeleton from "react-loading-skeleton"
import {
  setBookID,
  setSeeMoreID
} from "../../AuthScreens/LoginScreen/redux/action"
import { history } from "../../../reduxStore/store"
import { postBookSucess } from "../DepositSite/redux/action"
import TruckListModal from "../../../constratarsModal/TructListModal"
import uploadfile from "../../../assets/images/uploadfile.svg"

const Dashbord = props => {
  const [modalShowTruck, setModalShowTruck] = useState(false)
  const [modalShow, setModalShow] = useState(false)
  const [showStyle, setshowStyle] = useState(false)
  const [loadingdate, setLoadingdate] = useState(null)
  const [filterState, setfilterState] = useState(false)
  const [modalData, setModalData] = useState()
  const [open, setOpen] = useState()
  const [invoiceModal, setInvoiceModal] = useState({
    show: false,
    data: {}
  })

  const ref = useRef()

  const {
    notificationData,
    requesting,
    getNotificationRequest,
    setBookID,
    truckList,
    setSeeMoreID
  } = props

  useEffect(() => {
    getNotificationRequest("")
  }, [])

  const dateChange = (date, dateString, type) => {
    let filterModel = {
      fromDate: "",
      toDate: ""
    }

    setfilterState(false)
    switch (type) {
      case "date":
        filterModel.fromDate = dateString

        getNotificationRequest(
          `?start_date=${dateString}&end_date=${filterModel.toDate}`
        )
        setLoadingdate(filterModel)
        break
      case "week":
        filterModel.fromDate = dateString

        filterModel.toDate = dayjs(dateString)
          .add(7, "day")
          .format("YYYY-MM-DD")

        const dateStr = `?start_date=${filterModel.fromDate}&end_date=${filterModel.toDate}`

        getNotificationRequest(dateStr)
        setLoadingdate(filterModel)
        break
      case "month":
        const arrDate = dateString.split("-")
        filterModel.fromDate = date.year() + "-" + arrDate[1] + "-01"
        filterModel.toDate =
          date.year() + "-" + arrDate[1] + "-" + date.daysInMonth()
        getNotificationRequest(
          `?start_date=${filterModel.fromDate}&end_date=${filterModel.toDate}`
        )
        setLoadingdate(filterModel)
        break
      default:
        getNotificationRequest()
        break
    }
  }

  const handleShowDetail = item => {
    if (item.subject === "Invoice Created" || item?.subject == "Invoice Updated") {
      const jsonData = JSON.parse(item?.extra_data)
      setInvoiceModal({ show: true, data: jsonData })
    }
    if (item.subject == "Approved Soil Request") {
      setSeeMoreID(item.message)
      history.push("/contractor/approved-requests")
    }
    if (item.subject == "Soil request status") {
      setSeeMoreID(item.extra_data?.id)
      history.push("/contractor/approved-requests")
    }
    if (item.subject == "Soil Request has been rejected") {
      setModalData(item)
      setModalShow(true)
      setshowStyle(true)
    }
    if (
      item.subject === "Booking Schedule" ||
      item.subject == "Schedule Trucks Sent"
    ) {
      const jsonMessage = item.message.replace(/'/g, '"')
      const parsedMessage = JSON.parse(jsonMessage)
      setBookID(parsedMessage.request_availability)
      history.push("soil-form")
      postBookSucess(false)
    }
  }

  const handlePageChange = page => {
    getNotificationRequest(`?page=${page}`)
  }

  const InvoiceFileName = invoiceModal?.data?.invoice_file?.split("/")[5]
  const InvoiceFile = invoiceModal?.data?.invoice_file

  const InvoiceStatus = [
    {
      value: "paid",
      name: "Paid",
      color: "#0A5F59"
    },
    {
      value: "not_paid",
      name: "Not Paid",
      color: "#F99806"
    },
    {
      value: "paid_overdue",
      name: "Paid Overdue",
      color: "#C13434"
    }
  ]
  const parsedData =
    notificationData.results &&
    notificationData.results?.map(item => {
      let messageObj = item.message
      try {
        messageObj = JSON.parse(item.message.replace(/'/g, '"'))
      } catch (error) { }
      return { ...item, message: messageObj }
    })
  return (
    <>
      <HomeLayout dashboardHeader={true}>
        <section className="content Dashbord-section">
          <div className="headerText">
            <h1>Notification Board</h1>

            <div ref={ref}>
              <Filter
                setCalenderState={setfilterState}
                onClickOutside={() => setfilterState(!filterState)}
                open={open}
                maskClosable={false}
                setOpen={setOpen}
                onDateChange={dateChange}
                onClick={() => setfilterState(!filterState)}
                calenderState={filterState}
                dataData={loadingdate}
              />
            </div>
          </div>

          <div className="contractornotification">
            <div className="inner">
              {requesting ? (
                [1, 2, 3, 4, 5, 6, 7].map(item => (
                  <div key={item.id} className="listitem">
                    <div
                      className={`notification-section ${showStyle == true ? "off-notification" : ""
                        }`}
                    >
                      <div class="right-section">
                        <p class="first-text">
                          <Skeleton width="90%" />
                        </p>
                        <p class="sec-text">
                          <Skeleton width="90%" />
                        </p>
                      </div>
                      <div class="left-section">
                        <p class="not-text">
                          <Skeleton width="20%" />
                        </p>
                        <p style={{ minWidth: "130px", marginBottom: "0" }}>
                          <Skeleton width="100%" />
                        </p>
                      </div>
                    </div>
                  </div>
                ))
              ) : parsedData && parsedData.length > 0 ? (
                parsedData.map(item => {
                  return (
                    <div key={item.id} className="listitem">
                      <div className={`notification-section `}>
                        <div className="right-section">
                          <p className="first-text">
                            {moment(item.created_at).format("DD MMM")}
                          </p>
                          <p className="sec-text">
                            {moment(item.created_at).format("LT")}{" "}
                          </p>
                        </div>

                        <div className="left-section">
                          <div>

                            <p className="title-text">{item.subject}</p>
                            {item.message_receiver ?
                              <p className="message-text">{item.message_receiver}</p>
                              : typeof item.message === 'object' ?
                                <p className="message-text">{item.message.message}</p>
                                : <p className="notification-subtitle">
                                  <p className="message-text">{item.message}</p>
                                </p>}
                          </div>

                          {
                            item.subject == "Approved Soil Request" ||
                              item.subject === "Invoice Created" || item?.subject == "Invoice Updated" ||
                              item.subject == "Soil Request has been rejected" || item.subject ==  "Soil request status" ? (
                              <Button
                                onClick={e => {
                                  handleShowDetail(item)
                                }}
                                title="View"
                                className="aes-btn transparent-btn"
                              />
                            ) : (
                              ""
                            )}
                        </div>
                      </div>
                    </div>
                  )
                })
              ) : (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "70vh"
                  }}
                >
                  <p>No Data Found</p>
                </div>
              )}
            </div>
            <div
              className="mt-4 d-flex justify-content-end mr-5"
              style={{ paddingBottom: 10, alignItems: "baseline" }}
            >
              {notificationData && notificationData?.results?.length > 0 ? (
                <>
                  <p
                    style={{
                      marginRight: "9px",
                      fontSize: "16px",
                      marginBottom: "0px"
                    }}
                  >
                    Pages:
                  </p>
                  <PaginationPack
                    onChange={handlePageChange}
                    itemsCountPerPage={notificationData?.page_size}
                    totalItemsCount={notificationData?.total_objects}
                    activePage={notificationData?.current_page}
                  />
                </>
              ) : null}
            </div>
          </div>
        </section>
        <Modal
          show={invoiceModal.show}
          onHide={() => setInvoiceModal({ show: false })}
          className="invoiceDetailModal"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Invoice Details
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="mb-3 d-flex align-items-center justify-content-between gap-2">
              <h5 className="m-0">Invoice Number:</h5>
              <p className="m-0">{invoiceModal?.data?.invoice_number || ""}</p>
            </div>
            <div className="mb-3 d-flex align-items-center justify-content-between gap-2">
              <h5 className="m-0">Fill Site Name:</h5>
              <p className="m-0">{invoiceModal?.data?.fill_site_name || ""}</p>
            </div>
            <div className="mb-3 d-flex align-items-center justify-content-between gap-2">
              <h5 className="m-0">Invoice Status:</h5>
              <p
                className="m-0"
                style={{
                  color: InvoiceStatus?.find(
                    e => e?.value === invoiceModal?.data?.invoice_Status
                  )?.color
                }}
              >
                {InvoiceStatus?.find(
                  e => e?.value === invoiceModal?.data?.invoice_Status
                )?.name || ""}
              </p>
            </div>
            <div className="mb-3 d-flex align-items-center justify-content-between gap-2">
              <h5 className="m-0">Payment Due Date:</h5>
              <p className="m-0">
                {moment(invoiceModal?.data?.payment_due_date).format(
                  "MMM DD, YYYY"
                ) || ""}
              </p>
            </div>
            <div className="mb-3 d-flex align-items-center justify-content-between gap-2">
              <h5 className="m-0">Invoice File:</h5>
              <div
                className="d-flex align-items-center justify-content-between gap-2 invoiceStyle"
                onClick={() => window.open(InvoiceFile, "_blank")}
              >
                <p className="m-0">{InvoiceFileName || ""}</p>
                {invoiceModal?.data?.invoice_file && (
                  <img src={uploadfile} alt="upload file icon" />
                )}
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <DashboardModal
          modalShow={modalShow}
          setModalShow={setModalShow}
          notification={modalData}
        />
        <TruckListModal
          modalShow={modalShowTruck}
          setModalShow={setModalShowTruck}
          notification={truckList}
        />
      </HomeLayout>
    </>
  )
}
const mapStateToProps = state => ({
  notificationData: state.noficationInfo.data,
  truckList: state.noficationInfo.truckList,

  detail: state.noficationInfo.detail,
  requesting: state.noficationInfo.requesting,
  error: state.noficationInfo.error,
  detailRequest: state.noficationInfo.detailRequest,
  pagination: state.noficationInfo.pagination
})

const mapDispatchToProps = dispatch => ({
  getNotificationRequest: (filterModel, id) => {
    dispatch(getNotificationRequest(filterModel, id))
  },
  getModaldatasuccess: (filterModel, id) => {
    dispatch(getModaldatasuccess(filterModel, id))
  },
  setBookID: data => {
    dispatch(setBookID(data))
  },
  getTruckListRequest: data => {
    dispatch(getTruckListRequest(data))
  },
  setSeeMoreID: data => {
    dispatch(setSeeMoreID(data))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(Dashbord)
