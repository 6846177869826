import {
  GET_ADMIN_REQUEST_APPROVED_REQUEST,
  GET_ADMIN_REQUEST_APPROVED_SUCCESS,
  GET_ADMIN_REQUEST_APPROVED_FAILURE,
  PUT_ADMIN_SOIL_REQUEST ,
  PUT_ADMIN_SOIL_SUCCESS,
  PUT_ADMIN_SOIL_FAILURE 
} from "./type"

//  GET_NOTIFICATION_FAILURE
export const getAdminApprovedRequest = data => ({
  type: GET_ADMIN_REQUEST_APPROVED_REQUEST,
  data
})

export const getAdminApprovedSuccess = data => ({
  type: GET_ADMIN_REQUEST_APPROVED_SUCCESS,
  data
})
export const getAdminApprovedFailure = data => ({
  type: GET_ADMIN_REQUEST_APPROVED_FAILURE,
  data
})
export const putAdminSoilRequest = (data,id) => ({
  type: PUT_ADMIN_SOIL_REQUEST ,
  data,
  id
})

export const putAdminSoilSuccess = data => ({
  type:PUT_ADMIN_SOIL_SUCCESS,
  data
})
export const putAdminSoilFailure = data => ({
  type: PUT_ADMIN_SOIL_FAILURE ,
  data
})
