export const ADMIN_DASHBOARD_USERLIST = "ADMIN_DASHBOARD_USERLIST"
export const ADMIN_DASHBOARD_USERLIST_SUCCESS =
  "ADMIN_DASHBOARD_USERLIST_SUCCESS"
export const ADMIN_DASHBOARD_USERLIST_FAILURE =
  "ADMIN_DASHBOARD_USERLIST_FAILURE"

export const ADMIN_DASHBOARD_UPDATE_USER = "ADMIN_DASHBOARD_UPDATE_USER"
export const ADMIN_DASHBOARD_UPDATE_USER_SUCCESS =
  "ADMIN_DASHBOARD_UPDATE_USER_SUCCESS"
export const ADMIN_DASHBOARD_UPDATE_USER_FAILURE =
  "ADMIN_DASHBOARD_UPDATE_USER_FAILURE"
export const GET_MATERIAL_REQUEST = "GET_MATERIAL_REQUEST"
export const GET_MATERIAL_SUCCESS = "GET_MATERIAL_SUCCESS"
export const GET_MATERIAL_FAILURE = "GET_MATERIAL_FAILURE"

export const USER_ACTIVE_REQUEST = "USER_ACTIVE_REQUEST"
export const USER_ACTIVE_SUCCESS = "USER_ACTIVE_SUCCESS"


export const DELETE_FIILL_REQUEST = "DELETE_FIILL_REQUEST"
export const DELETE_FIILL_SUCCESS = "DELETE_FIILL_SUCCESS"
