import {
 
  PUT_TEAM_MEMBER,
  PUT_TEAM_MEMBER_SUCCESS,
  PUT_TEAM_MEMBER_FAILURE
} from "./type"

const initialState = {
  teamMember: false,
  error: false,
  requesting: false,
  putRequesting: false
}

export default (state = initialState, action) => {
  switch (action.type) {
  
    case PUT_TEAM_MEMBER:
      return {
        ...state,
        putRequesting: true,
        teamMember: false
      }
    case PUT_TEAM_MEMBER_SUCCESS:
      return {
        ...state,
        putRequesting: false,
        teamMember: action.data
      }
    case PUT_TEAM_MEMBER_FAILURE:
      return {
        ...state,
        putRequesting: false,
        error: action.data,
        teamMember: false
      }
    default:
      return state
  }
}
