import {
  GET_PROFILE_DATA,
  GET_PROFILE_DATA_SUCCESS,
  GET_PROFILE_DATA_FAILURE,
  PUT_PROFILE_DATA,
  PUT_PROFILE_DATA_SUCCESS,
  PUT_PROFILE_DATA_FAILURE
} from "./type"

// GET Team Member Profile
export const getProfileDataRequest = () => ({
  type: GET_PROFILE_DATA,
  
})

export const getProfileDatarSuccess = data => ({
  type: GET_PROFILE_DATA_SUCCESS,
  data
})

export const getProfileDataFailure = data => ({
  type: GET_PROFILE_DATA_FAILURE,
  data
})

// PUT TEAM MEMBER
export const putProfileDataRequest = (data) => ({
  type: PUT_PROFILE_DATA,
  data,
 
})

export const putProfileDataSuccess = data => ({
  type: PUT_PROFILE_DATA_SUCCESS,
  data
})

export const putProfileDataFailure = data => ({
  type: PUT_PROFILE_DATA_FAILURE,
  data
})
