import {
  POST_REQUEST_LOGIN,
  POST_REQUEST_LOGIN_SUCCESS,
  POST_REQUEST_LOGIN_FAILURE,
  SET_ACCESS_TOKEN,
  LOGOUT,
  IS_VISITING,
  SET_SEEMORE_ID,
  SET_DEPOSITESITE_ID,
  SET_BOOK_ID,
  SCHEDULE_ID,
  SET_INV0ICE_ID,
  SET_FILLSITE_ID,
  RESET
} from "./type"

// POST_PAYMENT_INFORMATION
export const loginRequest = data => ({
  type: POST_REQUEST_LOGIN,
  data
})

export const setAccessToken = accessToken => ({
  type: SET_ACCESS_TOKEN,
  accessToken
})

export const loginRequestSuccess = data => ({
  type: POST_REQUEST_LOGIN_SUCCESS,
  data
})

export const loginRequestFailure = data => ({
  type: POST_REQUEST_LOGIN_FAILURE,
  data
})

export const logOut = () => ({
  type: LOGOUT
})

export const scheduleId = data => ({
  type: SCHEDULE_ID,
  data
})

export const isVistingAction = data => ({
  type: IS_VISITING,
  data
})

export const setSeeMoreID = data => ({
  type: SET_SEEMORE_ID,
  data
})

export const setDepositeSiteID = data => ({
  type: SET_DEPOSITESITE_ID,
  data
})

export const setBookID = data => ({
  type: SET_BOOK_ID,
  data
})

export const setInvoiceId = data => ({
  type: SET_INV0ICE_ID,
  data
})
export const setFillSiteId = data => ({
  type: SET_FILLSITE_ID,
  data
})
export const Reset = () => ({
  type: RESET
})