import React, { useEffect } from "react"
import { Space, Table } from "antd"
import moment from "moment"
import Button from "../Button"
import { history } from "../../reduxStore/store"
import "./style.scss"
import { connect } from "react-redux"
import { Spinner } from "react-bootstrap"
import { getRequestListRequest } from "../../pages/Contractor/RequestList/redux/action"
import { setSeeMoreID } from "../../pages/AuthScreens/LoginScreen/redux/action"
import Skeleton from "react-loading-skeleton"
import "react-loading-skeleton/dist/skeleton.css"

const RequestListTable = props => {
  const { getRequestListRequest, setSeeMoreID, myReguestData, requesting } =
    props

  useEffect(() => {
    getRequestListRequest("")
  }, [])

  const loadingData = new Array(10).fill().map(() => ({ loading: true }))

  const onSeeMore = id => {
    if (id) {
      setSeeMoreID(id)
      history.push("/contractor/approved-requests")
    }
  }

  const columns = [
    {
      title: "Project Name",
      dataIndex: "project_name",
      key: "project_name",
      render: (_, { project_name, loading }) => {
        if (loading) {
          return <Skeleton width="40%" />
        } else {
          return (
            <Space size="middle" className="full-name">
              {project_name??'-'}
            </Space>
          )
        }
      }
    },
    {
      title: "MEN P.O. Number",
      dataIndex: "aes_po_no",
      key: "aes_po_no",
      render: (_, { aes_po_no, loading }) => {
        if (loading) {
          return <Skeleton width="40%" />
        } else {
          return (
            <Space size="middle" className="full-name">
              {aes_po_no}
            </Space>
          )
        }
      }
    },
    {
      title: "Created",
      dataIndex: "created_at",
      key: "created_at",
      render: (_, { created_at, loading }) => {
        if (loading) {
          return <Skeleton width="40%" />
        } else {
          return (
            <Space size="middle" className="email">
              {moment(created_at).format("DD/MM/YYYY")}{" "}
            </Space>
          )
        }
      }
    },
    {
      title: "Status",
      key: "status",
      render: (_, { status, loading }) => {
        if (loading) {
          return <Skeleton width="20%" />
        } else {
          return (
            <div className="role">
             <p className={`mb-0 ${status === "accepted" ? "green-color" : status === "rejected" ? "red-color" : ""}`}>{status=="accepted"?"APPROVED":status.toUpperCase()}</p> 

          </div>
          )
        }
      }
    },

    {
      title: " ",
      key: "status",
      render: (_, { status, loading }) => {
        if (loading) {
          return (
            <div style={{ textAlign: "right" }}>
              <Skeleton width="20%" />
            </div>
          )
        } else {
          return (
            <div className="roleAction">
              {(status?.toLowerCase() === "review" ||
                status?.toLowerCase() === "scheduled" ||
                status?.toLowerCase() === "accepted") && (
                <Button
                  title="View"
                  className="aes-btn transparent-btn"
                  onClick={() => onSeeMore(_?.id)}
                />
              )}
            </div>
          )
        }
      }
    }
  ]
  return (
    <>
      {/* {requesting ? (
        <div className="loader-container">
          <Spinner variant="primary" />
        </div>
      ) : ( */}
      <Table
        className="fill-site-table2"
        columns={columns}
        dataSource={requesting ? loadingData : myReguestData}
        pagination={false}
        rowKey="id"
        scroll={{ y: 560 }}
      />
      {/* )} */}
    </>
  )
}

const mapStateToProps = state => ({
  myReguestData: state.RequestData.requestList,
  requesting: state.RequestData.requesting
})

const mapDispatchToProps = dispatch => ({
  getRequestListRequest: data => dispatch(getRequestListRequest(data)),
  setSeeMoreID: data => dispatch(setSeeMoreID(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(RequestListTable)
