import {
  GET_FOOTER_REQUEST,
  GET_FOOTER_SUCCESS,
  GET_FOOTER_FAILURE,
 
} from "./type"

export const getFooterRequest = () => ({
  type: GET_FOOTER_REQUEST
  //filterModel
})

export const getFooterSuccess = data => ({
  type: GET_FOOTER_SUCCESS,
  data
})

export const getFooterFailure = data => ({
  type: GET_FOOTER_FAILURE,
  data
})



