import {
  POST_REQUEST_ADD_SCHEDULE,
  POST_ADD_SCHEDULE_SUCCESS,
  POST_ADD_SCHEDULE_FAILURE,

  GET_ADMIN_SCHEDULE,
  SUCCESS_GETTING_ADMIN_SCHEDULE,
  ERROR_GETTING_ADMIN_SCHEDULE
} from "./type"

const initialState = {
  projects: false,
  error: false,
  requesting: false,
  getAdminSchedule: [],
}

export default (state = initialState, action) => {
  switch (action.type) {
    case POST_REQUEST_ADD_SCHEDULE:
      return {
        ...state,
        requesting: true
      }
    case POST_ADD_SCHEDULE_SUCCESS:
      return {
        ...state,
        requesting: false,
        projects: action.data
      }
    case POST_ADD_SCHEDULE_FAILURE:
      return {
        ...state,
        requesting: false,
        error: action.data,
        projects: false
      }
    case GET_ADMIN_SCHEDULE:
        return {
          ...state,
          requesting: true
        }
      case SUCCESS_GETTING_ADMIN_SCHEDULE:
        return {
          ...state,
          requesting: false,
          getAdminSchedule: action.data
        }
  
      case ERROR_GETTING_ADMIN_SCHEDULE:
        return {
          ...state,
          requesting: false,
          error: action.data
        }
    default:
      return state
  }
}
