import { combineReducers } from "redux"

import login from "../pages/AuthScreens/LoginScreen/redux/reducer"
import signUp from "../pages/AuthScreens/SignUp/redux/reducer"
import forgotPassword from "../authModal/ForgetModal/redux/reducer"
import confirmPassword from "../pages/AuthScreens/ChangePasword/redux/reducer"
import FillsiteTable from "../pages/Contractor/AccountFillSite/redux/reducer"
import noficationInfo from "../pages/Contractor/Dashboard/redux/reducer"
import soilDeposit from "../pages/Contractor/RequestSoilDeposit/redux/reducer"
import RequestData from "../pages/Contractor/RequestList/redux/reducer"
import TeamMembers from "../pages/Contractor/MyTeam/redux/reducer"
import AddTeamMember from "../pages/Contractor/AddTeamMember/redux/reducer"
import TeamMemberProfile from "../pages/Contractor/TeamMemberProfile/redux/reducer"
import FillsiteData from "../pages/Contractor/DepositSite/redux/reducer"
import ProfileData from "../pages/Contractor/AccountInformation/redux/reducer"
import OwnerNotification from "../pages/FillSiteOwner/OwnerFillSiteDashbord/redux/reducer"
import OwnerTeamMembers from "../pages/FillSiteOwner/MyTeam/redux/reducer"
import OwnerAddTeamMember from "../pages/FillSiteOwner/AddTeamMember/redux/reducer"
import OwnerTeamMemberProfie from "../pages/FillSiteOwner/TeamMemberProfile/redux/reducer"
import HomeScreenData from "../pages/AuthScreens/HomeScreen/redux/reducer"
import dataFooter from "../pages/VisitingSite/Foter/redux/reducer"
import adminDashboardUserData from "../pages/Admin/AdminDashboard/redux/reducer"
import homeData from "../pages/VisitingSite/Home/redux/reducer"
import NewFileSite from "../pages/Admin/AdminDashboard/NewFileSite/redux/reducer"
import AdminSoilRequest from "../pages/Admin/SoilRequest/redux/reducer"
import SoilConfirm from "../pages/Admin/RequestConfirmPage/redux/reducer"
import schedule from "../pages/FillSiteOwner/Schedule/redux/reducer"
import SoilAuthorizationForm from "../pages/Contractor/SoilAuthorizationForm/redux/reducer"
import Invoices from "../pages/Admin/Invoices/redux/reducer"
import UploadInvoice from "../components/UploadInvoice/redux/reducer"
import profileFillData from "../pages/FillSiteOwner/AccountInformation/redux/reducer"

import Project from "../pages/Contractor/ProjectsList/redux/reducer"
import AddProject from "../pages/Contractor/AddProjects/redux/reducer"

import DocumentAgreement from "../pages/Admin/DocumentAgreement/redux/reducer"
import Report from "../pages/Admin/Reports/redux/reducer"
import { persistReducer } from "redux-persist"
import storage from "redux-persist/lib/storage"
import Notification from "../pages/Admin/Notifications/redux/reducer"
import adminContractor from "../pages/Admin/AdminContractors/redux/reducer"
import AccountRequest from "../pages/Admin/AccountRequest/redux/reducer"
import MaterialTracking from "../pages/Admin/MaterialTracking/redux/reducer"

import Truck from "../pages/Contractor/TruckList/redux/reducer"
import Schedule from "../pages/Contractor/ScheduleList/redux/reducer"

import AddTruck from "../pages/Contractor/AddTruck/redux/reducer"
import AddSchedule from "../pages/Contractor/AddSchedule/redux/reducer"

import EditAndViewUser from "../pages/Admin/EditAndViewUser/redux/reducer"

const presistConfig = {
  key: "login",
  storage
}

export const combinedReducers = combineReducers({
  login: persistReducer(presistConfig, login),
  adminContractor,
  Notification,
  signUp,
  forgotPassword,
  confirmPassword,
  noficationInfo,
  soilDeposit,
  RequestData,
  adminDashboardUserData,
  FillsiteData,
  TeamMembers,
  AddTeamMember,
  TeamMemberProfile,
  ProfileData,
  OwnerNotification,
  FillsiteTable,
  OwnerTeamMembers,
  OwnerAddTeamMember,
  OwnerTeamMemberProfie,
  HomeScreenData,
  dataFooter,
  homeData,
  NewFileSite,
  AdminSoilRequest,
  SoilConfirm,
  schedule,
  SoilAuthorizationForm,
  Invoices,
  UploadInvoice,
  profileFillData,
  DocumentAgreement,
  Report,
  AccountRequest,
  MaterialTracking,
  AddProject,
  Project,
  Schedule,
  Truck,
  AddSchedule,
  AddTruck,
  EditAndViewUser
})
