import React, { useEffect, useState } from "react"
import "./style.scss"
import HomeLayout from "../../../layout/HomeLayout"
import Search from "../../../components/Search"
import Button from "../../../components/Button"
import { Col, Row } from "antd"
import {
  activeReauest,
  activeSuccess,
  getAdminContractorReauest,

} from "./redux/action"
import { useDispatch, useSelector } from "react-redux"
import backIcon from "../../../assets/images/back-icon-circle.svg"
import { history } from "../../../reduxStore/store"
import { Spinner } from "react-bootstrap"
import Skeleton from "react-loading-skeleton"
import "react-loading-skeleton/dist/skeleton.css"
import { toast, Toaster } from "react-hot-toast"

const AdminContractors = props => {
  const [loading, setLoading] = useState(0)

  const dispatch = useDispatch()
  const { getAdminContractor, AdminContractorRequesting, activeRequest, activeRequesting } = useSelector(
    state => state.adminContractor
  )
  useEffect(() => {
    dispatch(getAdminContractorReauest(""))
  }, [])

  const userActiveUnactive = (id, status) => {

    const data = {
      user_status: !status,
    };

    dispatch(activeReauest(data, id, showSuccessToast(status)));
  };

  const showSuccessToast = (status) => {
    // Show a success toast with a message based on the status
    toast.success(
      `Status updated to ${status ? "inactive" : "active"}` // Use a ternary operator to display "inactive" or "active"
    );
  };

  const HandelSearch = e => {
    dispatch(getAdminContractorReauest(`&search=${e.target.value}`))
  }

  return (
    <HomeLayout dashboardHeader={true}>
      <section className="content-section adminContractors-section">
        <div className="headingStyle">
          <span
            style={{
              display: "flex",
              gap: "10px",
              flexGrow: "1",
              alignItems: "baseline"
            }}
          >
            <h1 className="w-100">Contractors List</h1>
          </span>
          <form>
            <Search
              placeholder="Search by contractor"
              onChange={e => {
                HandelSearch(e)
              }}
            />
          </form>

        </div>
        <div className="bodyScroll">
          {!getAdminContractor.length && AdminContractorRequesting ? (
            [1, 2, 3].map(item => (
              <div className="">
                <Row className="contentStyle">
                  <Col span={17} className="border-bottom pb-4">
                    <Skeleton width="50%" />
                  </Col>
                  <Col
                    span={7}
                    className="text-end border-bottom pb-4"
                    style={{ display: "flex", gap: "5px" }}
                  >
                    <div style={{ flexBasis: "33%" }}>
                      <Skeleton width="100%" />
                    </div>
                    <div style={{ flexBasis: "33%" }}>
                      <Skeleton width="100%" />
                    </div>
                    <div style={{ flexBasis: "33%" }}>
                      <Skeleton width="100%" />
                    </div>
                  </Col>
                  <Col span={12} className="border-bottom py-2">
                    <Skeleton width="50%" />
                  </Col>
                  <Col span={12} className="border-bottom py-2">
                    <Skeleton width="50%" />
                  </Col>
                  <Col span={12} className="border-bottom py-2">
                    <Skeleton width="60%" />
                  </Col>
                  <Col span={12} className="border-bottom py-2">
                    <Skeleton width="60%" />
                  </Col>
                  <Col span={12} className="border-bottom py-2">
                    <Skeleton width="70%" />
                  </Col>
                  <Col span={12} className="border-bottom py-2">
                    <Skeleton width="70%" />
                  </Col>
                </Row>
              </div>
            ))
          ) : getAdminContractor && getAdminContractor?.length > 0 ? (
            getAdminContractor.map((item, index) => {
              return (
                <Row className="contentStyle">
                  <span className="border-bottom w-100 d-md-flex">
                    <Col md={12} sm={24} key={index} className=' pb-4'>
                      <h1 style={{cursor:'pointer'}} className="heading-name" onClick={() => {
                          history.push("admin-contractor-detail", { id: item?.id })
                        }}>
                        {item?.company.company_name ? item?.company.company_name : "---"}
                      </h1>
                    </Col>
                    <Col md={12} sm={24} className="text-end pb-4">
                      <Button
                        key={index}
                        title={"See Details"}
                        className="BtnsStyle"
                        onClick={() => {
                          history.push("admin-contractor-detail", { id: item?.id })
                        }}
                      />

                      <Button
                        showSpinner={loading == index && activeRequesting}
                        key={index}
                        title={item.user_status ? "Inactive" : "Active"} // Set the button title based on the user's status
                        className="BtnsStyle"
                        onClick={() => {
                          // Call userActiveUnactive when the button is clicked, passing the user ID and status
                          userActiveUnactive(item.id, item.user_status);
                          setLoading(index)
                        }}
                      />
                    </Col>
                  </span>
                  <Col sm={12} xs={24} className="border-bottom py-2">
                    <h2>
                      Emai:{" "}
                      <span>{item?.email ? item?.email : "---"}</span>
                    </h2>
                  </Col>
                  <Col sm={12} xs={24} className="border-bottom py-2">
                    <h2>
                      Position in company:{" "}
                      <span>
                        {item?.company.position_in_company
                          ? item?.company.position_in_company
                          : "---"}
                      </span>
                    </h2>
                  </Col>
                  <Col sm={12} xs={24} className="border-bottom py-2">
                    <h2>
                      Location:{" "}
                      {/* <span>
                        {item?.phone_number && !isNaN(Number(item?.phone_number.slice(1)))
                          ? item?.phone_number
                          : "---"}
                      </span> */}
                      <span>
                        {/* {item.billing.street } */}
                        <span>
                          {/* {item?.fill_site_info[0]?.street || "--"} */}
                          {(item.billing?.street &&
                            item.billing?.street) ||
                            "--"}
                          ,
                          {(item.billing?.city &&
                            item.billing?.city) ||
                            "--"}
                          ,
                          {(item.billing?.province &&
                            item.billing?.province) ||
                            "--"}
                          ,
                          {(item.billing.city_postal_code &&
                            item.billing.city_postal_code) ||
                            "--"}
                        </span>
                      </span>
                    </h2>
                  </Col>
                  <Col sm={12} xs={24} className="border-bottom py-2">
                    <h2>
                      CC Email:{" "}
                      <span>
                        {item?.billing.cc_email
                          ? item?.billing.cc_email
                          : "---"}
                      </span>
                    </h2>
                  </Col>
                  <Col sm={12} xs={24} className="py-2">
                    <h2>
                      Phone number:{" "}
                      <span>
                        {item?.phone_number && !isNaN(Number(item?.phone_number.slice(1)))
                          ? item?.phone_number
                          : "---"}
                      </span>
                    </h2>
                  </Col>
                  {/* <Col sm={12} xs={24} className="border-bottom py-2">
                    <h2>
                      Freeload slots:{" "}
                      <span>
                        {item?.freeload_slot ? item?.freeload_slot : "---"}
                      </span>
                    </h2>
                  </Col> */}
                </Row>
              )
            })
          ) : (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "70vh"
              }}
            >
              <p>No Data Found</p>
            </div>
          )}
        </div>
      </section>
    </HomeLayout>
  )
}


export default AdminContractors
