// GET NOTIFICATION MEMBERS
export const GET_Admin_NOTIFICATION_REQUEST = "GET_Admin_NOTIFICATION_REQUEST"
export const GET_Admin_NOTIFICATION_SUCCESS = "GET_Admin_NOTIFICATIONSUCCESS"


// DELETE NOTIFICATION MEMBER
export const DELETE_NOTIFICATION_REQUEST = "DELETE_NOTIFICATION_REQUEST"
export const DELETE_NOTIFICATION_SUCCESS = "DELETE_NOTIFICATION_SUCCESS"
// Post
export const POST_ADMIN_REQUEST = " POST_ADMIN_REQUEST"
export const POST_ADMIN_SUCCESS = " POST_ADMIN_SUCCESS"
export const POST_ADMIN_FAILED = " POST_ADMIN_FAILED"
