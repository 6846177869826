export const GET_SCHEDULE_REQUEST = "GET_SCHEDULE_REQUEST"
export const GET_SCHEDULE_SUCCESS = "GET_SCHEDULE_SUCCESS"
export const GET_SCHEDULE_FAILURE = "GET_SCHEDULE_FAILURE"
export const GET_MODAL_DATA_SUCCESS = "GET_MODAL_DATA_SUCCESS"

export const POST_SPECIFIC_DATE_REQUEST = "POST_SPECIFIC_DATE_REQUEST"
export const POST_SPECIFIC_DATE_SUCCESS = "POST_SPECIFIC_DATE_SUCCESS"
export const POST_SPECIFIC_DATE_FAILURE = "POST_SPECIFIC_DATE_FAILURE"

export const PATCH_SOIL_AUTHORIZATION = "PATCH_SOIL_AUTHORIZATION"
export const PATCH_SOIL_AUTHORIZATION_SUCCESS =
  "PATCH_SOIL_AUTHORIZATION_SUCCESS"
export const PATCH_SOIL_AUTHORIZATION_FAILURE =
  "PATCH_SOIL_AUTHORIZATION_FAILURE"

export const GET_PROFILE_REQUEST = "GET_PROFILE_REQUEST"
export const GET_PROFILE_SUCCESS = "GET_PROFILE_SUCCESS"
export const GET_PROFILE_FAILURE = "GET_PROFILE_FAILURE"
