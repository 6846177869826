import { Space, Table } from "antd"
import React from "react"
import Button from "../Button"
import "./style.scss"
import Skeleton from "react-loading-skeleton"
import { history } from "../../reduxStore/store"

const TeamMembersTable = ({ data, onRemove, loader, handleEditTeam }) => {
  const info = []
  data &&
    data.map(item =>
      info.push({
        key: item?.id,

        fullName: "Alfredo Curtis",
        email: "alfrecocurts@office.com",
        contact_phone: "(0800) 756-5675",
        location: "Los Angeles, CA",
        role: "Dispatcher"
      })
    )

  const loadingData = new Array(10).fill().map(() => ({ loading: true }))
  // const handleEditTeam = TeamMemberId => {
  //   editTeam(TeamMemberId)
  // }
  const TeamMemberId = localStorage.getItem("TeamMemberId")

  const columns = [
    {
      title: "Full Name",
      dataIndex: "name",

      render: (_, { name, loading, id }) => {
        if (loading) {
          return <Skeleton width="100%" />
        } else {
          return (
            <Space
              style={{ cursor: "pointer" }}
              className="mb-0"
              title={name.length >= 20 ? name : null}
              onClick={() => {
                localStorage.setItem("TeamMemberId", id)
                handleEditTeam(`${TeamMemberId}/`)
              }}
            >
              {name && name?.length >= 20 ? name.slice(0, 17) + "..." : name}
            </Space>
          )
        }
      }
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (_, { email, loading, id }) => {
        if (loading) {
          return <Skeleton width="100%" />
        } else {
          return (
            <Space
              size="middle"
              className="email"
              title={email.length >= 50 ? email : null}
              style={{ cursor: "pointer"  }}
              onClick={() => {
                localStorage.setItem("TeamMemberId", id)
                handleEditTeam(`${TeamMemberId}/`)
              }}
            >
              {email && email?.length >= 50
                ? email.slice(0, 50) + "..."
                : email}
            </Space>
          )
        }
      }
    },
    {
      title: "Contact Phone",
      dataIndex: "phone_number",
      key: "phone_number",
      align: "end",
      render: (_, { phone_number, loading }) => {
        if (loading) {
          return <Skeleton width="100%" />
        } else {
          return (
            <Space
              size="middle"
              className="contact-phone"member-profile
              // style={{display:"flex",justifyContent:"end"}}
            >
              {phone_number ? phone_number : "----------"}
            </Space>
          )
        }
      }
    },
    {
      title: "Location",
      key: "location",
      dataIndex: "location",
      align: "end",

      render: (_, { location, loading }) => {
        if (loading) {
          return <Skeleton width="100%" />
        } else {
          return (
            <Space
              size="middle"
              className="location"
              title={location.length >= 20 ? location : null}
              style={{ cursor: location?.length >= 20 ? "pointer" : "auto" }}
            >
              {location && location?.length >= 20
                ? location.slice(0, 17) + "..."
                : location}
            </Space>
          )
        }
      }
    },
    {
      title: "Role",
      key: "role",
      align: "end",

      render: (_, { role, loading }) => {
        if (loading) {
          return <Skeleton width="100%" />
        } else {
          return (
            <div className="role">
              <p
                className="mb-0"
                title={role.length >= 20 ? role : null}
                style={{ cursor: role?.length >= 20 ? "pointer" : "auto" }}
              >
                {role && role?.length >= 20 ? role.slice(0, 17) + "..." : role}
              </p>
            </div>
          )
        }
      }
    },
    {
      title: "Action",
      key: "role",
      render: (_, { role, loading }) => {
        if (loading) {
          return <Skeleton width="100%" />
        } else {
          return (
            <div className="role">
              <Button
                title="Remove"
                className="aes-btn transparent-btn"
                onClick={data => onRemove(_)}
              />
            </div>
          )
        }
      }
    }
  ]
  const locale = {
    emptyText: "You don’t have any team members added"
  }
  return (
    <Table
      className="teamemberTable"
      columns={columns}
      dataSource={loader ? loadingData : data}
      pagination={false}
      scroll={{ y: 560 }}
      locale={locale}
    />
  )
}

export default TeamMembersTable
