import React, { useEffect, useRef } from "react"
import "./style.scss"
import { Col, Collapse, DatePicker, Modal, Row, Table } from "antd"
import { useState } from "react"
import dayjs from "dayjs"
import "dayjs/locale/zh-cn"
import pdfupload from "../../assets/images/pdfupload.svg"
import uploadfile from "../../assets/images/uploadfile.svg"
import dayLocaleData from "dayjs/plugin/localeData"
import {
  getFillsiteTrucksFailure,
  getFillsiteTrucksRequest,
  postUploadInvoiceRequest
} from "./redux/action"
import { useDispatch, useSelector } from "react-redux"
import { getAdminFillRequest } from "../../pages/Admin/RequestConfirmPage/redux/action"
import { Spinner } from "react-bootstrap"
import { toast } from "react-hot-toast"
import { Button, Form, Select } from "antd"
import moment from "moment/moment"
import AeSelect from "../AeSelect"

dayjs.extend(dayLocaleData)

const UploadInvoice = ({
  modalShow,
  setModalShow,
  contractor
}) => {
  const initialLocalState = {
    isFilePicked: false,
    selectedFile: false,
    selectedTruck: [],
    truckList: [],
    start_date: null,
    end_date: null
  }
  const { Option } = Select
  const [selectValue, setSelectValue] = useState("")
  const [uploadInvoiceLocalState, setUploadInvoiceLocalState] = useState(initialLocalState)

  const {
    isFilePicked,
    selectedFile,
    truckList
  }
    = uploadInvoiceLocalState

  const dispatch = useDispatch()
  const { invoiceId } = useSelector(state => state.login)
  const { getAdminFillSite: allFillsites } = useSelector(
    state => state.SoilConfirm
  )

  const { loading, responseData, postLoading, postData } = useSelector(
    state => state.UploadInvoice
  )

  let selectData = []
  allFillsites && allFillsites?.map((a, b) => {
    selectData.push({ label: a.name, value: a.id })
  })

  const formRef = React.useRef(null)

  const onReset = () => {
    formRef.current?.resetFields()
  }
  const handleDateChange = value => {
    formRef.current?.setFieldsValue({
      payment_due_date: value
    })
  }
  const handleCancel = () => {
    setModalShow(false)
    onReset()
    setInitialLocalState()
    dispatch(getAdminFillRequest())
    dispatch(getFillsiteTrucksFailure())
    setEndDate(null)
    setStartDate(null)
  }

  const fileRef = useRef()
  const handleChangeup = e => {
    const file = e.target.files[0]
    if (file.size > 50 * 1024 * 1024) {
      alert("File size exceeds 50 MB. Please select a smaller file.")
      setUploadInvoiceLocalState(prevState => ({
        ...prevState,
        isFilePicked: false
      }))
    } else {
      setUploadInvoiceLocalState(prevState => ({
        ...prevState,
        isFilePicked: true,
        selectedFile: file
      }))
    }
  }

  const truckListData = truckList?.map(item => item.id)

  useEffect(() => {
    postData && setModalShow(false)
  }, [postData])


  const submit = async ({
    fill_site,
    payment_due_date
  }) => {
    if (truckList.length == 0) {
      toast.error(`There is no truck engaged between ${moment(startDate).format(" DD MMM YYYY")} to ${moment(endDate).format(" DD MMM YYYY")}`)
    } else {
      const formData = new FormData()
      formData.append("contractor", Number(invoiceId))
      formData.append("fill_site", Number(fill_site))
      formData.append("select_truck", `${JSON.stringify(truckListData)}`)
      formData.append("payment_due_date", dayjs(payment_due_date).toISOString())
      formData.append("invoice_file", selectedFile)
      formData.append("start_date", startDate)
      formData.append("end_date", endDate)

      await dispatch(postUploadInvoiceRequest(formData))
      setInitialLocalState()
      onReset()
    }
  }
  const setInitialLocalState = () => {
    setUploadInvoiceLocalState(initialLocalState)
  }
  useEffect(() => {
    if (responseData) {
      setUploadInvoiceLocalState(prevState => ({
        ...prevState,
        truckList: [...responseData]
      }))
    }
  }, [responseData])
  const handleChange = (id, value) => {
    const data = value.map(item => item.value)

    setUploadInvoiceLocalState(prevState => ({
      ...prevState,
      selectedTruck: JSON.stringify(data)
    }))
  }
  const { RangePicker } = DatePicker
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const handleDatePicker = (dates, dateStrings) => {
    const startDateFormatted = moment(dateStrings[0]).format("YYYY-MM-DD")
    const endDateFormatted = moment(dateStrings[1]).format("YYYY-MM-DD")
    setStartDate(startDateFormatted) // set the start date state variable
    setEndDate(endDateFormatted)
  }

  useEffect(() => {
    if (startDate != 'Invalid date' && endDate != 'Invalid date' && selectValue) {
      dispatch(
        getFillsiteTrucksRequest({
          contractor: invoiceId,
          fill_site: selectValue,
          startDate: startDate,
          endDate: endDate
        })
      )
    }
  }, [startDate, endDate])

  useEffect(() => {
    setSelectValue("")
    setInitialLocalState()
  }, [modalShow])

  const { Panel } = Collapse

  const columns = [
    {
      title: "Truck Company",
      dataIndex: "truck_company",
      key: "truck_company",
      render: truck_company => {
        return truck_company && truck_company?.length >= 15
          ? truck_company.slice(0, 15) + "..."
          : truck_company
      }
    },
    {
      title: "License Plate",
      dataIndex: "licence_plate",
      key: "licence_plate"
    },

    {
      title: "Truck tracking",
      dataIndex: "truck_tracking",
      key: "truck_tracking"
    }
  ]

  const ShowColums = () => {
    if (loading) {
      return (
        <div
          className="w-100"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <Spinner />
        </div>
      )
    }

    if (truckList.length == 0 && startDate && endDate) {
      return (
        <div className="w-100" style={{ textAlign: "center" }}>
          No Data
        </div>
      )
    }

    if (truckList.length > 0 && startDate && endDate) {
      return (
        <Collapse
          className="w-100"
          style={{
            marginRight: "27px",
            marginLeft: "10px",
            marginBottom: "20px"
          }}
        >
          <Panel header={` ${truckList?.length} Trucks in Date Range`} key="1">
            <Table
              columns={columns}
              dataSource={truckList && truckList}
              pagination={false}
            />
          </Panel>
        </Collapse>
      )
    }
  }

  return (
    <>
      <Modal
        title={`${contractor} / Upload Invoice`}
        open={modalShow}
        onCancel={handleCancel}
        className="invoice-Upload-modal"
        cancelButtonProps={{ style: { display: "none" } }}
        confirmLoading={postLoading}
        footer={null}
      >
        <Form
          ref={formRef}
          name="control-ref"
          labelWrap={true}
          layout="vertical"
          onFinish={submit}
        >
          <Row gutter={20}>
            <Col span={24} className="inputWrapper">
              <Form.Item
                name="fill_site"
                label="Select Fill Site"
                rules={[
                  {
                    required: true,
                    message: "This field is required"
                  }
                ]}
              >
                <AeSelect
                  options={selectData}
                  placeholder="Select Fill Site"
                  value={selectValue}
                  onChange={value => {
                    setSelectValue(value)
                  }}
                />
              </Form.Item>
            </Col>

            <Col span={24} className="inputWrapper">
              <Form.Item
                name="selected_trucks"
                label="Select Date"
                rules={[
                  {
                    required: true,
                    message: "This field is required"
                  }
                ]}
              >
                <RangePicker onChange={handleDatePicker} disabled={selectValue ? false : true} style={{}} />
              </Form.Item>
            </Col>
            <ShowColums />
          </Row>
          <Form.Item
            name="upload_file"
            label="Upload Invoice"
            rules={[
              {
                required: true,
                message: "This field is required"
              }
            ]}
          >
            <div className="upload-fileInvoice">
              <img
                src={isFilePicked == false ? pdfupload : uploadfile}
                alt="upload"
              />
              <div className="icon-wrapper">
                <div className="sample"></div>
                <input
                  type="file"
                  name="file"
                  ref={fileRef}
                  accept=".pdf"
                  onChange={handleChangeup}
                  hidden
                />
                {isFilePicked == false ? (
                  <div onClick={() => fileRef.current.click()}>
                    <p>
                      <a href="#">Upload File</a>
                    </p>
                  </div>
                ) : (
                  <p>
                    <a
                      onClick={() => {
                        setUploadInvoiceLocalState(prevState => ({
                          ...prevState,
                          isFilePicked: false,
                          selectedFile: false
                        }))
                        fileRef.current.value = null
                      }}
                      href="#"
                    >
                      Remove File
                    </a>
                  </p>
                )}
              </div>
            </div>
          </Form.Item>
          <Form.Item
            name="payment_due_date"
            label="Payment Due Date"
            rules={[
              {
                required: true,
                message: "Please Select a date",
                validator: (_, value) => {
                  if (value) {
                    return Promise.resolve()
                  }
                  return Promise.reject(new Error("Please Select a date"))
                },
                validateTrigger: "onChange"
              }
            ]}
          >
            <div className="mb-3 dateCalender">
              <DatePicker
                style={{
                  width: "100%",
                  backgroundColor: "#f4f4f4",
                  height: "49px",
                  border: "unset"
                }}
                onChange={handleDateChange}
                disabledDate={(current) => {
                  let customDate = moment().format("YYYY-MM-DD");
                  return current && current < moment(customDate, "YYYY-MM-DD");
                }}
              />
            </div>
          </Form.Item>
          <Form.Item flex>
            <Row justify="end" className="submit-row">
              <Col>
                <Button type="primary" htmlType="submit" loading={postLoading}>
                  Add
                </Button>
              </Col>
            </Row>
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}
export default UploadInvoice
