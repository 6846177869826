import { Space, Table } from "antd"
import React, { useState } from "react"
import Button from "../Button"
import "./style.scss"
import { history } from "../../reduxStore/store"
import { getFillSiteRequest } from "../../pages/Contractor/AccountFillSite/redux/action"
import { connect } from "react-redux"

const OnwerDashboardTable = props => {
  const { FillSiteTableData, getFillSiteRequest } = props
  const [memberID, setMemberData] = useState()

  const FillSiteonerTableData = [
    {
      SpecifictruckID: "fillSiteName",
      Truckweight: "Truckweight",
      TrucksIDs: "TrucksIDs",
      total_money: "total_money",
      Net: "Net",
      Scaleticket: "Scaleticket",
      PO: "PO",
      CSRclassification: "CSRclassification",
      Tare: "Tare",
      Gross: "Gross"
    }
  ]
  const dataSource = [
    {
      key: "1",
      name: "Mike",
      age: 32,
      address: "10 Downing Street"
    }
  ]
  const info = []
  FillSiteonerTableData &&
    FillSiteonerTableData.map(item => {
      // setMemberID(item.id)

      info.push({
        key: item.id,
        SpecifictruckID: `${item.SpecifictruckID}`,
        Truckweight: item.Truckweight,
        TrucksIDs: item.TrucksIDs,
        Gross: item.Gross,
        Tare: item.Tare,
        Net: item.Net,
        Scaleticket: item.Scaleticket,
        PO: item.PO,
        CSRclassification: item.CSRclassification
      })
    })
  const columns = [
    {
      title: "Specific truck ID",
      dataIndex: "SpecifictruckID",
      key: "SpecifictruckID",
      render: (_, SpecifictruckID) => (
        <Space size="middle" className="full-name">
          {SpecifictruckID.SpecifictruckID}
        </Space>
      )
    },
    {
      title: "Truck weight",
      dataIndex: "Truckweight",
      key: "Truckweight",
      render: (_, Truckweight) => (
        <Space size="middle">{Truckweight.Truckweight}</Space>
      )
    },
    {
      title: "Trucks IDs",
      dataIndex: "TrucksIDs",
      key: "TrucksIDs",
      render: (_, TrucksIDs) => (
        <Space size="middle">{TrucksIDs.TrucksIDs}</Space>
      )
    },
    {
      title: "Gross",
      dataIndex: "Gross",
      key: "Gross",
      render: (_, Gross) => <Space size="middle">{Gross.Gross}</Space>
    },
    {
      title: "Tare",
      dataIndex: "Tare",
      key: "Tare",
      render: (_, Tare) => <Space size="middle">{Tare.Tare}</Space>
    },
    ,
    {
      title: "Net",
      dataIndex: "Net",
      key: "Net",
      render: (_, Net) => <Space size="middle">{Net.Net}</Space>
    },
    ,
    {
      title: "Scale ticket #",
      dataIndex: "Scaleticket",
      key: "Scaleticket",
      render: (_, Scaleticket) => (
        <Space size="middle">{Scaleticket.Scaleticket}</Space>
      )
    },
    {
      title: "PO #",
      dataIndex: "PO",
      key: "PO",
      render: (_, PO) => <Space size="middle">{PO.PO}</Space>
    },
    {
      title: "CSR classification",
      key: "CSRclassification",
      dataIndex: "CSRclassification",
      render: (_, CSRclassification) => {
        return (
          <div className="role">
            <p className="mb-0">{CSRclassification.CSRclassification}</p>
          </div>
        )
      }
    }
  ]
  return (
    <Table
      className="OnwerDashboardTable"
      columns={columns}
      dataSource={info}
      pagination={false}
    />
  )
}

const mapStateToProps = state => {
  return {
    getFillSite: state.FillsiteTable.fillsiteData
  }
}

const mapDispatchToProps = dispatch => ({
  getFillSiteRequest: data => dispatch(getFillSiteRequest(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(OnwerDashboardTable)
