import { all, call, put, takeLatest } from "redux-saga/effects"
import { BASE_URL } from "../../../../config/app"
import XHR from "../../../../utils/XHR"
import {
    getReportSucess,
    PostReportFailed,
    PostReportSucess,
    getCategrySucess,
    getReportRequest,
    getContractorSucess

} from "./action"
import {
    GET_REPORT_REQUEST,
    POST_REPORT_REQUEST,
    GET_CATEGORY_REQUEST,
    GET_CONTRACTOR_REQUEST
} from "./types"
import { toast } from "react-hot-toast"

const getContractorAPI = async (data) => {
    const URL = `${BASE_URL}api/v1/admin/contractor_list/`
    const token = localStorage.getItem("token")
    const options = {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Token ${token}`
        },
        method: "GET"
    }
    return XHR(URL, options)
}

function* getContractorData({ data }) {
    try {
        const response = yield call(getContractorAPI, data)
        yield put(getContractorSucess(response?.data))

    } catch (e) {
        console.log(e);
    }
}
const getReportAPI = async (data) => {
    const URL = `${BASE_URL}api/v1/report/${data}`
    const token = localStorage.getItem("token")
    const options = {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Token ${token}`
        },
        method: "GET"
    }
    return XHR(URL, options)
}

function* getReportData({ data }) {
    try {
        const response = yield call(getReportAPI, data)
        yield put(getReportSucess(response?.data))

    } catch (e) {
        console.log(e);
    }
}
const geCategryAPI = async (data) => {
    const URL = `${BASE_URL}api/v1/category/`
    const token = localStorage.getItem("token")
    const options = {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Token ${token}`
        },
        method: "GET"
    }
    return XHR(URL, options)
}

function* getCategryData({ data }) {
    try {
        const response = yield call(geCategryAPI, data)
        yield put(getCategrySucess(response?.data))

    } catch (e) {
        console.log(e);
    }
}
const PostReportAPI = async (data) => {
    const URL = `${BASE_URL}api/v1/report/`
    const token = localStorage.getItem("token")
    const options = {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Token ${token}`
        },
        method: "POST",
        data
    }
    return XHR(URL, options)
}

function* PostReportData({ data, handleCancel }) {
    try {
        const response = yield call(PostReportAPI, data)
        yield put(PostReportSucess(response?.data))
        handleCancel()
        yield put(getReportRequest(""))
        toast.success("Report request has been sent successfully")



    } catch (e) {
        const { response } = e
        yield put(PostReportFailed(response?.data))
        toast.error("There was an error")
    }
}
export default all([
    takeLatest(POST_REPORT_REQUEST, PostReportData),
    takeLatest(GET_CONTRACTOR_REQUEST, getContractorData),


    takeLatest(GET_REPORT_REQUEST, getReportData),

    takeLatest(GET_CATEGORY_REQUEST, getCategryData),
])