import {
  GET_HOME_REQUEST,
  GET_HOME_SUCCESS,
  GET_HOME_FAILURE,
 
} from "./type"

export const getHomeRequest = (data) => ({
  type: GET_HOME_REQUEST,
  data
  //filterModel
})

export const getHomeSuccess = data => ({
  type: GET_HOME_SUCCESS,
  data
})

export const getHomeFailure = data => ({
  type: GET_HOME_FAILURE,
  data
})



