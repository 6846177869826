import React, { useState, useEffect } from "react"
import Button from "../../components/Button"
import Modal from "react-bootstrap/Modal"
import Input from "../../components/Input"
import { useHistory } from "react-router-dom"
import closeIcon from "../../assets/images/closeIcon.svg"
import truck from "../../assets/images/truck.png"
// import "./style.scss"
import { connect } from "react-redux"
import moment from "moment"
import { Col, Row, Skeleton, Space, Table } from "antd"


const TruckListModal = props => {
  const { modalShow, setModalShow, notification } = props
 
  // const dataSore=[notification]
 
  
  const columns = [
    {
      title: 'License plate',
      dataIndex: 'licence_plate',
      key: 'licence_plate',
      render: license_plate => <span>{license_plate }
      
      {console.log("license_plate",license_plate)}
      </span>,
    },
    {
      title: 'Truck configuration',
      dataIndex: 'truck_tracking',
      key: 'truck_tracking',
      render: truck_tracking => <span>{truck_tracking}</span>,
    },
    {
      title: 'Company name',
      dataIndex: 'truck_company',
      key: 'truck_company',
      render: truck_company => <span>{truck_company}</span>,

    },
    {
      title: 'Leave Time',
      dataIndex: 'created_at',
      key: 'created_at',
      render: created_at => (
        <span>
          <span style={{ fontWeight: '600' }}>
            {moment(created_at).format('DD MMM')}
          </span>{' '}
          /{' '}
          <span style={{ fontWeight: '500' }}>
            {moment(created_at).format('LT')}
          </span>
        </span>
      ),
    },
    {
      title: 'Confirm Arrival',
      dataIndex: 'status',
      key: 'status',
      render: (status, item) => (
        <span>
          {status === 'pending' ? (
            <>On the way</>
          ) : (
            <>
              <span style={{ fontWeight: '600' }}>
                {moment(item.updated_at).format('DD MMM')}
              </span>{' '}
              /{' '}
              <span style={{ fontWeight: '500' }}>
                {moment(item.updated_at).format('LT')}
              </span>{' '}
            </>
          )}
        </span>
      ),
    },
  ];
  return (
    <>
      <Modal
        // {...props}
        show={modalShow}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="warning-modal"
      >
        <Modal.Body className="modal-body">
          <div className="close-icon" onClick={() => setModalShow(false)}>
            <img src={closeIcon} alt="" />
          </div>
          <div className="p-3">
            <div className="time-text">
              <p>
                {moment(notification?.created_at).format("DD MMM")}
                <span>
                  {" "}
                  /{moment(notification?.created_at).format("LT")} /{" "}
                  {moment(notification?.created_at).format("dddd")}
                </span>
              </p>
            </div>
            <div className="text-body">
              {/* <h3 className="heading-text"> {notification.subject}</h3> */}
              <lable className="lable">Schedule Trucks:</lable>

              {/* <p className="para">{notification?.message}</p> */}
              {/* <div className="img-wrap">
              {notification?.image?.length === 0 && (
                <div
                  style={{
                    minHeight: "100px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    margin: "auto"
                  }}
                >
                  {" "}
                  no image found
                </div>
              )}
              {notification?.image &&
                notification?.image.map(item => (
                  <div>
                    <img src={item.image} />
                  </div>
                ))}
            </div> */}

<Table
      columns={columns}
      dataSource={[notification]}
      pagination={false}
      // rowKey="id"
    />
            <div className="button-wraper">
            <Button title="Ok" onClick={() => setModalShow(false)} />
          </div>
            </div>
          </div>
         
        
        </Modal.Body>
        
      </Modal>
    </>
  )
}

export default TruckListModal
